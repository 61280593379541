import { Box, CircularProgress, Stack } from '@kitalulus/web-ui-kit';

import { SubscriptionPlanProps } from './SubscriptionPlanV2.types';
import SubscriptionPlanItem from './SubscriptionPlanItem';

const SubscriptionPlanV2 = ({
  plans,
  isLoading,
  onPlanSelect,
  pageLocation,
  linkPosition,
  ...props
}: SubscriptionPlanProps) => {
  return (
    <Box px={{ xs: 4, md: 2, lg: 0 }} {...props}>
      {/* Loading */}
      {isLoading ? (
        <Stack minHeight="500px" alignItems="center" justifyContent="center">
          <CircularProgress />
        </Stack>
      ) : (
        <Stack
          direction="row"
          flexWrap="wrap"
          gap={2}
          px={5}
          justifyContent="center"
        >
          {plans.map((item) => (
            <Box
              key={item.id}
              maxWidth="400px"
              sx={{ flexGrow: 1, flexBasis: '20%' }}
            >
              <SubscriptionPlanItem
                pageLocation={pageLocation}
                linkPosition={linkPosition}
                item={item}
                onPlanSelect={onPlanSelect}
              />
            </Box>
          ))}
        </Stack>
      )}
    </Box>
  );
};

export default SubscriptionPlanV2;
