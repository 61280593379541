import { Stack, Typography, brandColors, Button } from '@kitalulus/web-ui-kit';
import { useTranslation } from 'react-i18next';

import CrownIcon from '~/components/Icons/CrownIcon';
import { useAppDispatch } from '~/hooks/use-store';
import { useFeatureFlag } from '~/hooks/firebase/use-feature-flag';
import { setDialog } from '~/store/views/dialogs-slice';
import * as mpe from '~/utils/mixpanel';
import usePayPerPost from '~/hooks/use-pay-per-post';

const PremiumBannerSquare = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { isFreePlan } = usePayPerPost();

  const isFreePlanActive = useFeatureFlag('free_plan');

  const isDisplay = isFreePlan && isFreePlanActive;

  return (
    <Stack
      p={2}
      mb={2}
      spacing={2}
      sx={{
        display: isDisplay ? 'block' : 'none',
        backgroundColor: brandColors.grey[200],
        borderRadius: 1,
      }}
      data-test-id="ctPremiumBannerSquare"
    >
      <Stack spacing="6px">
        <CrownIcon sx={{ fill: '#FBB040' }} />
        <Typography
          variant="subtitle2"
          data-test-id="lbPremiumBannerSquareTitle"
        >
          {t('common:freePlanBannerTitle')}
        </Typography>
        <Typography
          variant="body2"
          pb="6px"
          data-test-id="lbPremiumBannerSquareSubtitle"
        >
          {t('common:freePlanBannerSubtitle')}
        </Typography>
      </Stack>
      <Button
        fullWidth
        color="secondary"
        variant="contained"
        onClick={() => {
          mpe.premiumOffering({
            linkPosition: 'limitBasicVacancy',
          });

          dispatch(
            setDialog({
              subscriptionPlanDialog: {
                open: true,
                content: {
                  linkPosition: 'limitBasicVacancy',
                },
              },
            }),
          );
        }}
        data-test-id="btnPremiumBannerSquareUpgradePackage"
      >
        {t('common:upgradePackage')}
      </Button>
    </Stack>
  );
};

export default PremiumBannerSquare;
