import { format } from 'date-fns';
import { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  InterviewInvitationFormType,
  InterviewInvitationFormFirstStep,
  InterviewInvitationFormSecondStep,
  InterviewInvitationFormThirdStep,
} from '~/components/InterviewInvitationForm';
import { InterviewInvitationVacancy } from '~/types/features/interview-invitation';
import {
  EpInterviewSessionDropdown,
  EpInterviewSessionSizeType,
} from '~/types/graphql/graphql';
import {
  InterviewInvitationDialogSizeKey,
  InterviewInvitationFormSessionSelectTypeKey,
} from '~/utils/constants/interview-invitation';
import { sleep } from '~/utils/helper';
import { mapInterviewInvitationDataToFormValue } from '~/utils/interview-invitation-form';

export const useInterviewInvitationForm = (
  vacancy: InterviewInvitationVacancy,
  sizeKey: InterviewInvitationDialogSizeKey,
) => {
  const { t } = useTranslation();

  const defaultSizeKey = useMemo<
    InterviewInvitationFormType['sizeType']
  >(() => {
    switch (sizeKey) {
      case InterviewInvitationDialogSizeKey.BULK:
        return EpInterviewSessionSizeType.Group;
      case InterviewInvitationDialogSizeKey.SINGLE:
        return EpInterviewSessionSizeType.Personal;
      default:
        return null;
    }
  }, [sizeKey]);

  const firstStepFormState = useForm<InterviewInvitationFormFirstStep>({
    mode: 'all',
    defaultValues: {
      newSessionName: t('interview-invitation-form:newSessionDefaultValue', {
        name: vacancy.positionName,
        date: format(new Date(), 'dd/MM/yyyy - HH:mm:ss'),
      }),
      isNewSessionNameValid: false,
      selectedSession: null,
      sessionSelectType: null,
    },
  });

  const secondStepFormState = useForm<InterviewInvitationFormSecondStep>({
    mode: 'all',
    defaultValues: {
      sizeType: defaultSizeKey,
      schedule: null,
      scheduleEndTime: null,
      scheduleStartTime: null,
      type: null,
      offlineDetail: { address: '', url: '' },
      onlineDetail: { password: '', url: '' },
    },
  });

  const thirdStepFormState = useForm<InterviewInvitationFormThirdStep>({
    mode: 'all',
    defaultValues: {
      recruiterMessage: '',
      recruiterPhoneNumber: '',
    },
  });

  const prefillForm = useCallback(
    async (session: EpInterviewSessionDropdown) => {
      // map session dropdown data to form value
      const formDefaultValue = mapInterviewInvitationDataToFormValue(session);

      // prefill second and third step forms
      secondStepFormState.reset(formDefaultValue.secondStep, {
        keepDefaultValues: true,
      });
      thirdStepFormState.reset(formDefaultValue.thirdStep, {
        keepDefaultValues: true,
      });

      // to trigger validation after the form is prefilled
      // there's a slightly delay after the form reset
      await sleep(100);

      secondStepFormState.trigger();
      thirdStepFormState.trigger();
    },
    [],
  );

  // on sessionSelectType or selectedSession changes, reset the second and third forms
  const sessionSelectType = firstStepFormState.watch('sessionSelectType');
  const selectedSession = firstStepFormState.watch('selectedSession');
  const isNewSession =
    sessionSelectType ===
    InterviewInvitationFormSessionSelectTypeKey.NEW_SESSION;
  useEffect(() => {
    // when it selected new session
    // the form will be reset to its default value
    if (isNewSession) {
      // reset the second and third forms
      secondStepFormState.reset();
      thirdStepFormState.reset();
      return;
    }

    // when it selected existing session
    // the form will be autofilled with selected session data
    const session = selectedSession?.additional;
    if (!session) return;
    prefillForm(session);
  }, [isNewSession, selectedSession, prefillForm]);

  return { firstStepFormState, secondStepFormState, thirdStepFormState };
};
