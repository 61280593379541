import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Nullable } from '~/types';
import { AUTH_STATUS } from '~/utils/constants';
import { createCookiesPersist } from '~/utils/redux-persist';

export const AUTH_KEY = 'auth';

export type AuthState = Nullable<{
  accessToken?: string;
  refreshToken?: string;
  expiryTime?: string;
  status?: AUTH_STATUS;
  companyCode?: string;
}>;

export const authSlice = createSlice({
  name: AUTH_KEY,
  initialState: null as AuthState,
  reducers: {
    setAuth: (state, action: PayloadAction<Partial<AuthState>>) => ({
      ...state,
      ...action.payload,
    }),
    clearAuth: () => null,
  },
});

export const { setAuth, clearAuth } = authSlice.actions;

export default createCookiesPersist(AUTH_KEY, authSlice.reducer);
