import { SvgIcon, SvgIconProps } from '@kitalulus/web-ui-kit';

export const FiSsDocument = (props: SvgIconProps) => {
  return (
    <SvgIcon width="20" height="20" viewBox="0 0 20 20" {...props}>
      <g>
        <path d="M17.8453 4.16667H14.167V0.488333L17.8453 4.16667ZM18.3337 5.83333V20H1.66699V2.5C1.66699 1.83696 1.93038 1.20107 2.39923 0.732233C2.86807 0.263392 3.50395 0 4.16699 0L12.5003 0V5.83333H18.3337ZM5.83366 13.3333H11.667V11.6667H5.83366V13.3333ZM14.167 15H5.83366V16.6667H14.167V15ZM14.167 8.33333H5.83366V10H14.167V8.33333Z" />
      </g>
    </SvgIcon>
  );
};
