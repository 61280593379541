import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { EpJobApplicationHistoryLabel } from '~/types/graphql/graphql';

export type jobApplicantHistoryLabelSliceType = EpJobApplicationHistoryLabel[];

export const jobApplicantHistoryLabelSlice = createSlice({
  name: 'jobApplicantHistoryLabel',
  initialState: [] as jobApplicantHistoryLabelSliceType,
  reducers: {
    setJobApplicantHistoryLabel: (
      _,
      action: PayloadAction<jobApplicantHistoryLabelSliceType>,
    ) => action.payload,
    clearJobApplicantHistoryLabel: () => [],
  },
});

export const { setJobApplicantHistoryLabel, clearJobApplicantHistoryLabel } =
  jobApplicantHistoryLabelSlice.actions;

export default jobApplicantHistoryLabelSlice.reducer;
