import { useLazyQuery, useMutation } from '@apollo/client';
import { useCallback, useEffect } from 'react';

import { MUTATION_UPDATE_USER_CONFIG, QUERY_USER_CONFIG } from '~/graphql/user';
import { setUserConfig } from '~/store/features/user-config-slice';
import { refresh } from '~/store/helpers/refresh-count-slice';
import {
  EpUserConfigUpdateInput,
  UpdateUserConfigMutation,
  UpdateUserConfigMutationVariables,
  UserConfigQuery,
} from '~/types/graphql/graphql';
import { defaultGlobalDataContext } from '~/utils/apollo-request';
import { useAuthValue } from './use-auth';
import { useAppDispatch, useAppSelector } from './use-store';

export const useUserConfig = () => {
  const { isAuthenticated } = useAuthValue();
  const dispatch = useAppDispatch();
  const userConfigRefreshCount = useAppSelector(
    (state) => state.refreshCount.userConfig,
  );

  const [getUserConfig, { loading }] = useLazyQuery<UserConfigQuery>(
    QUERY_USER_CONFIG,
    {
      fetchPolicy: 'no-cache',
      context: defaultGlobalDataContext,
      onCompleted: ({ userConfig }) => dispatch(setUserConfig(userConfig)),
    },
  );

  useEffect(() => {
    if (!isAuthenticated) return;
    getUserConfig();
  }, [isAuthenticated, userConfigRefreshCount]);

  return { isLoading: loading };
};

export const useUpdateUserConfig = () => {
  const dispatch = useAppDispatch();
  const [updateUserConfig, { loading }] = useMutation<
    UpdateUserConfigMutation,
    UpdateUserConfigMutationVariables
  >(MUTATION_UPDATE_USER_CONFIG);

  const handleUpdateUserConfig = useCallback(
    async (data: EpUserConfigUpdateInput) => {
      const result = await updateUserConfig({ variables: { data } });
      if (result.data?.updateUserConfig.isSuccess) {
        // immediately refetch global user config
        dispatch(refresh('userConfig'));
      }
    },
    [],
  );

  return { handleUpdateUserConfig, isLoading: loading };
};
