import { Alert, Box, Stack, Typography } from '@kitalulus/web-ui-kit';
import { useTranslation } from 'react-i18next';

import { useVacancyEthicCode } from '~/hooks/vacancy-ethic-code/use-vacancy-ethic-code';

const JobVacancyEthicContent = () => {
  const { t } = useTranslation();
  const { ethics } = useVacancyEthicCode();

  return (
    <>
      <Alert severity="info" icon={false}>
        <Typography variant="body2" color="text.secondary">
          {t('vacancy-form:jobVacancyEthicAlert')}
        </Typography>
      </Alert>
      <Stack spacing={2}>
        {ethics.map((ethic) => (
          <Stack
            direction="row"
            alignItems="center"
            key={ethic.content}
            spacing={1}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              height="44px"
              width="44px"
              flexShrink={0}
            >
              <ethic.icon sx={{ fontSize: '24px' }} />
            </Box>
            <Typography variant="body2" flex={1}>
              {ethic.content}
            </Typography>
          </Stack>
        ))}
      </Stack>
    </>
  );
};

export default JobVacancyEthicContent;
