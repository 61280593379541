// db.ts
import Dexie, { Table } from 'dexie';
import { TApplicantScreeningQuestionFilterDialogData } from '~/pages/Applicants/ApplicantScreeningQuestionFilterDialog';
import { Nullable } from '~/types';

export type ApplicantScreeningQuestionFilter = {
  username: string;
  displayName: string;
  vacancies: Nullable<Array<ApplicantScreeningQuestionVacancies>>;
};

export type ApplicantScreeningQuestionVacancies = {
  jobVacancyId: string;
  jobVacancyName: string;
  lastUpdated: string;
  createdDate: string;
  screeningQuestionsFilter: Nullable<
    Array<TApplicantScreeningQuestionFilterDialogData>
  >;
};

export class KLEPDatabase extends Dexie {
  // Add Table and Typing here
  applicantScreeningQuestionFilter!: Table<ApplicantScreeningQuestionVacancies>;
  applicantScreeningQuestionFilters!: Table<ApplicantScreeningQuestionFilter>;

  constructor() {
    super('klep-database'); //db name
    this.version(2).stores({
      applicantScreeningQuestionFilter: null, // Primary key and indexed props
      applicantScreeningQuestionFilters: 'username', // Primary key and indexed props
    });
  }
}

export const db = new KLEPDatabase();
export default db;
