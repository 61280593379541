import { gql } from '~/types/graphql/gql';

export const QUERY_MENUS = gql(`
  query menus {
    menus: epMenus {
      id
      name
      displayName
      path
      roleAccess {
        id
        canCreate
        canRead
        canUpdate
        canApprove
        canDownload
      }
      additionalRoleAccess {
        id
        keyParam
        value
      }
    }
  }
`);
