import { brandColors, Grid, Stack, Typography } from '@kitalulus/web-ui-kit';
import { useTranslation } from 'react-i18next';
import { useWorkingDayOptions } from '~/hooks/use-working-days';
import { VacancyPreviewDialogContent } from '../VacancyPreviewDialog.types';
import ConsChip from '~/components/ConsComponents/ConsChip';
import ConsCheckIcon from '~/components/ConsComponents/Icons/ConsCheckIcon';

const BoxThree = ({ content }: { content: VacancyPreviewDialogContent }) => {
  const { t } = useTranslation();

  const { workingDaysObj } = useWorkingDayOptions();

  const workingDays =
    content.workingDays &&
    `${workingDaysObj[content.workingDays[0]].label} - ${
      workingDaysObj[content.workingDays[1]].label
    }`;

  return (
    <Stack sx={{ bgcolor: '#FFF', py: 4, px: 2 }} gap={1}>
      <Typography variant="caption" color={brandColors.neutral[700]}>
        {t('vacancy-form:preview.thisJobRequires')}
      </Typography>
      <Stack direction="row" gap={1} sx={{ flexWrap: 'wrap' }}>
        {content?.skills.map((skill) => (
          <ConsChip
            key={skill}
            startIcon={<ConsCheckIcon color="primary" viewBox="-1 -1 20 20" />}
            label={skill}
          />
        ))}
      </Stack>
      <Typography variant="caption" color={brandColors.neutral[700]} mt={1}>
        {t('vacancy-form:preview.jobDescription')}
      </Typography>
      <Typography variant="body2" sx={{ whiteSpace: 'pre-line' }}>
        {content.description}
      </Typography>

      <Grid container mt={1}>
        <Grid item xs={6}>
          <Typography variant="caption" color={brandColors.neutral[700]}>
            {t('vacancy-form:preview.workingDays')}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="caption" color={brandColors.neutral[700]}>
            {t('vacancy-form:preview.workingHours')}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">
            {workingDays ?? t('vacancy-form:preview.furtherInfo')}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">{content.workingHours}</Typography>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default BoxThree;
