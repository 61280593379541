import {
  DialogActions,
  Paper,
  Stack,
  Button,
  Typography,
} from '@kitalulus/web-ui-kit';
import { useTranslation } from 'react-i18next';

const ApplicantActions = ({
  doViewDetail,
  handleCloseDialog,
}: {
  doViewDetail?: VoidFunction;
  handleCloseDialog: VoidFunction;
}) => {
  const { t } = useTranslation();

  return (
    <DialogActions sx={{ padding: 0, flexDirection: 'column' }}>
      <Paper elevation={8} sx={{ width: 1, borderRadius: 0 }}>
        <Stack
          width={1}
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          padding={2}
          gap={2}
          sx={{
            px: {
              xs: 2,
              sm: 4,
            },
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              handleCloseDialog();
              if (doViewDetail) doViewDetail();
            }}
            sx={{ alignItems: 'center' }}
          >
            <Typography variant="caption" fontWeight={500}>
              {t('applicant:viewDetail')}
            </Typography>
          </Button>
        </Stack>
      </Paper>
    </DialogActions>
  );
};

export default ApplicantActions;
