import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Nullable } from '~/types';
import { EpUser } from '~/types/graphql/graphql';
import { FETCH_STATUS } from '~/utils/constants';

export type UserState = Nullable<
  EpUser & {
    fetchStatus: FETCH_STATUS;
  }
>;
export const userSlice = createSlice({
  name: 'user',
  initialState: null as UserState,
  reducers: {
    setUser: (_, action: PayloadAction<UserState>) => action.payload,
    clearUser: () => null,
  },
});

export const { setUser, clearUser } = userSlice.actions;

export default userSlice.reducer;
