import { Button, Image, Stack, Typography } from '@kitalulus/web-ui-kit';
import { useTranslation } from 'react-i18next';
import { useCompanyAccManagerWhatsappLink } from '~/hooks/use-company';
import { MIXPANEL_ACCOUNT_HELP_LINK_POSITION } from '~/utils/constants/mixpanel';
import { accountHelp } from '~/utils/mixpanel';

const ErrorMain = () => {
  const { t } = useTranslation();
  const waLink = useCompanyAccManagerWhatsappLink('errorBoundaryIssue');

  const handleContact = () => {
    accountHelp({
      linkPosition: MIXPANEL_ACCOUNT_HELP_LINK_POSITION.UNDER_MAINTENANCE,
    });
    window.open(waLink, '_blank');
  };

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      paddingX={2}
      minHeight={`calc(100vh - 111px)`}
    >
      <Stack alignItems="center" spacing={4}>
        <Stack alignItems="center" spacing={0.75} textAlign="center">
          <Image
            src="/illustrations/no-service.webp"
            alt="no service"
            sx={{ maxWidth: '281px', width: '100%' }}
          />
          <Typography variant="h5" color="text.secondary">
            {t('error:mainPage.title')}
          </Typography>

          <Typography variant="body2" color="text.secondary" maxWidth={420}>
            {t('error:mainPage.subtitle')}
          </Typography>
        </Stack>

        <Button
          variant="contained"
          sx={{ width: '223px', textTransform: 'none' }}
          onClick={handleContact}
        >
          {t('error:mainPage.buttonText')}
        </Button>
      </Stack>
    </Stack>
  );
};

export default ErrorMain;
