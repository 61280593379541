import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FETCH_STATUS } from '~/utils/constants';

export type ScreeningFeatureFlagState = {
  fetchStatus: FETCH_STATUS;
  showScreeningQuestion: boolean;
  suffixTitle: string;
};

const initialState = {
  fetchStatus: FETCH_STATUS.IDLE,
  showScreeningQuestion: false,
  suffixTitle: '',
};

export const ScreeningFeatureFlagSlice = createSlice({
  name: 'screeningFeatureFlag',
  initialState,
  reducers: {
    setScreeningFeatureFlag: (
      state,
      action: PayloadAction<Partial<ScreeningFeatureFlagState>>,
    ) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    clearScreeningFeatureFlag: () => {
      return initialState;
    },
  },
});

export const { setScreeningFeatureFlag, clearScreeningFeatureFlag } =
  ScreeningFeatureFlagSlice.actions;

export default ScreeningFeatureFlagSlice.reducer;
