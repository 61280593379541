import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Nullable } from '~/types';
import { EpUserConfig } from '~/types/graphql/graphql';

export type UserConfigState = Nullable<EpUserConfig>;

export const userConfigSlice = createSlice({
  name: 'userConfig',
  initialState: null as UserConfigState,
  reducers: {
    setUserConfig: (_, action: PayloadAction<UserConfigState>) =>
      action.payload,
    clearUserConfig: () => null,
  },
});

export const { setUserConfig, clearUserConfig } = userConfigSlice.actions;

export default userConfigSlice.reducer;
