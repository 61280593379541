import { Button } from '@kitalulus/web-ui-kit';
import { useTranslation } from 'react-i18next';

import { useCompanyAccManagerWhatsappLink } from '~/hooks/use-company';
import { AuthLayoutHelpProps } from './AuthLayoutHelp.types';
import * as mpe from '~/utils/mixpanel';
import { FiSsHeadset } from '~/components/Icons/FiSsHeadset';

const AuthLayoutHelp = ({ linkPosition, ...props }: AuthLayoutHelpProps) => {
  const { t } = useTranslation();
  const waLink = useCompanyAccManagerWhatsappLink('registerV2');

  return (
    <Button
      data-test-id="btnAuthLayoutHelp"
      startIcon={<FiSsHeadset />}
      onClick={() => {
        mpe.accountHelp({
          linkPosition,
        });
        window.open(waLink, '_blank');
      }}
      {...props}
    >
      {t('common:contactKitalulus')}
    </Button>
  );
};

export default AuthLayoutHelp;
