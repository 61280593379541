import { SvgIcon, SvgIconProps } from '@kitalulus/web-ui-kit';

const ConsGajiIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 16 16" {...props}>
      <g>
        <path d="M8.16899 3.86699L11.067 6.76499V13.467H3.86699V3.86699H8.16899ZM8.66699 2.66699H3.86699C3.20699 2.66699 2.66699 3.20699 2.66699 3.86699V13.467C2.66699 14.127 3.20699 14.667 3.86699 14.667H11.067C11.727 14.667 12.267 14.127 12.267 13.467V6.26699L8.66699 2.66699ZM9.26699 8.06699H6.86699V8.66699H8.66699C8.99699 8.66699 9.26699 8.93699 9.26699 9.26699V11.067C9.26699 11.397 8.99699 11.667 8.66699 11.667H8.06699V12.267H6.86699V11.667H5.66699V10.467H8.06699V9.86699H6.26699C5.93699 9.86699 5.66699 9.59699 5.66699 9.26699V7.46699C5.66699 7.13699 5.93699 6.86699 6.26699 6.86699H6.86699V6.26699H8.06699V6.86699H9.26699V8.06699Z" />
      </g>
    </SvgIcon>
  );
};

export default ConsGajiIcon;
