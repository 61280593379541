import { Typography, Link } from '@kitalulus/web-ui-kit';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { appConfig } from '~/config/app';
import { kitalulusLink } from '~/utils/kitalulus-link';
import * as mpe from '~/utils/mixpanel';
import { AuthLayoutCopyRightProps } from './AuthLayoutCopyRight.types';

const AuthLayoutCopyRight = ({
  helpLink,
  privacyPolicyLink,
  ...props
}: AuthLayoutCopyRightProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const year = new Date().getFullYear();

  return (
    <Typography variant="caption" color="text.secondary" {...props}>
      {`@ ${year} ${appConfig.appName} · `}
      <Link
        data-test-id="lnAuthLayoutPrivacyPolicy"
        sx={{ cursor: 'pointer', color: 'text.secondary' }}
        onClick={() => navigate('/info/privacy')}
        {...privacyPolicyLink}
      >
        {t('auth:privacyAndPolicy')}
      </Link>
      {' · '}
      <Link
        data-test-id="lnAuthLayoutHelpCenter"
        sx={{ cursor: 'pointer', color: 'text.secondary' }}
        onClick={() => {
          if (helpLink)
            mpe.accountFaq({ linkPosition: helpLink?.linkPosition });
          window.open(kitalulusLink.helpCenter, '_blank');
        }}
      >
        {t('common:helpCenter')}
      </Link>
    </Typography>
  );
};

export default AuthLayoutCopyRight;
