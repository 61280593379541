import { useEffect } from 'react';

import * as mpe from '~/utils/mixpanel';
import { useAppSelector } from './use-store';
import { useTranslation } from 'react-i18next';

export const useMixpanelUserProperties = () => {
  const { user, company, realtimeData } = useAppSelector((state) => state);
  const realtimeDataCompany = realtimeData.company;
  const { t } = useTranslation();

  useEffect(() => {
    if (!user?.username) return;
    mpe.setUserProperties({
      id: user.username,
      email: user.username,
      name: user.displayName ?? '',
      phoneNumber: user.phoneNumber ? `62${user.phoneNumber}` : '',
      companyCode: company?.code ?? '',
      companyName: company?.displayName ?? '',
      companyIndustry: company?.jobCompanyIndustry?.name ?? '',
      companySize: company?.jobCompanySize?.size ?? '',
      companyType: company?.companyType ?? '',
      userRole: user?.roleStr ?? '',
      companyPIC: company?.accManager ?? '',
      companyStatus: realtimeDataCompany?.data?.company?.status ?? '',
      companyPackage:
        `${t('common:package')} ${
          realtimeData.packageByCompanyId.data?.currentHighestPackageName
        }` ?? '',
      isPremium: `${realtimeData?.packageByCompanyId?.data?.isPremium}` ?? '',
    });
  }, [user, company, realtimeData]);
};
