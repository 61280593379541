import { useQuery } from '@apollo/client';
import { useState } from 'react';

import { QUERY_COMPANY_INDUSTRIES } from '~/graphql/common';
import { DropdownOption } from '~/types';
import { CompanyIndustriesQuery } from '~/types/graphql/graphql';

export const useCompanyIndustryOptions = () => {
  const [companyIndustries, setCompanyIndustries] = useState<DropdownOption[]>(
    [],
  );

  const { loading } = useQuery<CompanyIndustriesQuery>(
    QUERY_COMPANY_INDUSTRIES,
    {
      fetchPolicy: 'network-only',
      onCompleted: ({ companyIndustries: industry }) => {
        if (!industry?.list) return;
        setCompanyIndustries(
          industry.list
            .map((d) => ({
              value: d?.id,
              label: d?.name,
            }))
            .sort((a, b) => {
              if (!a.label || !b.label) return 0;
              return a.label.localeCompare(b.label);
            }),
        );
      },
      onError: () => setCompanyIndustries([]),
    },
  );

  return {
    companyIndustries,
    isLoading: loading,
  };
};
