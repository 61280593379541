const isProduction = import.meta.env.VITE_NODE_ENV === 'production';

export const appConfig = {
  appName: 'KitaLulus',
  graphqlURL: import.meta.env.VITE_GRAPHQL_URL,
  authSecretKey: import.meta.env.VITE_AUTH_SECRET_KEY,
  isDevelopment: !isProduction,
  isProduction,
  googleAnalyticsId: import.meta.env.VITE_GOOGLE_ANALYTICS_ID,
  googleAdsId: import.meta.env.VITE_GOOGLE_ADS_ID,
  mixpanelToken: import.meta.env.VITE_MIXPANEL_TOKEN,
  mode: import.meta.env.MODE,
};
