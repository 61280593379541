import { User } from 'firebase/auth';
import { createContext, useEffect, useMemo, useState } from 'react';
import { firebaseAuth } from '~/config/firebase';
import { useAppDispatch } from '~/hooks/use-store';
import { setAuth } from '~/store/features/auth-slice';
import { AUTH_STATUS } from '~/utils/constants';

type AuthContextType = {
  currentUser: User | null;
};

export const AuthContext = createContext<AuthContextType | null>(null);

export const AuthProvider = ({ children }: any) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      setAuth({
        status: AUTH_STATUS.PENDING,
      }),
    );
    const unsubscribed = firebaseAuth.onAuthStateChanged((user) => {
      if (user?.emailVerified) {
        setCurrentUser(user);
        dispatch(
          setAuth({
            status: AUTH_STATUS.AUTHENTICATED,
          }),
        );
      } else {
        dispatch(
          setAuth({
            status: AUTH_STATUS.UNAUTHENTICATED,
          }),
        );
      }
      setLoading(false);
    });
    return () => unsubscribed();
  }, []);

  const user = useMemo(() => ({ currentUser }), [currentUser]);

  return <AuthContext.Provider value={user} children={!loading && children} />;
};
