import { Box, BoxProps, Typography } from '@kitalulus/web-ui-kit';
import { useMemo } from 'react';
import { maxSelectedRejectReasons } from '~/utils/validation';

const LimitInfo = ({
  totalSelected,
  noMoreSelectable,
  ...props
}: BoxProps & { totalSelected: number; noMoreSelectable: boolean }) => {
  const color = useMemo(() => {
    if (totalSelected === maxSelectedRejectReasons || noMoreSelectable)
      return 'error';
    if (totalSelected > 0) return 'primary';
    return 'text.disabled';
  }, [totalSelected, maxSelectedRejectReasons, noMoreSelectable]);
  return (
    <Box {...props}>
      <Typography variant="subtitle2" color={color}>
        {`${totalSelected}/${maxSelectedRejectReasons}`}
      </Typography>
    </Box>
  );
};

export default LimitInfo;
