import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DropdownOption } from '~/types';

import { EpJobVacanciesV2, EpJobVacancyState } from '~/types/graphql/graphql';
import { FETCH_STATUS } from '~/utils/constants';

export type VacancySummaryState = {
  fetchStatus: FETCH_STATUS;
  label: string;
  value: EpJobVacancyState;
  count: number;
};

export type VacancyState = {
  list: Partial<EpJobVacanciesV2> & {
    fetchStatus: FETCH_STATUS;
    isLoading: boolean;
    selectedCity?: DropdownOption | null;
  };
  summary: {
    summaries: VacancySummaryState[];
    fetchStatus: FETCH_STATUS;
    isLoading: boolean;
    fetchCount: number;
    isFirstFetch: boolean;
  };
  selectedCity: {
    data: DropdownOption | null;
    fetchStatus: FETCH_STATUS;
  };
};

export const vacancyDefaultValue: VacancyState = {
  list: {
    fetchStatus: FETCH_STATUS.IDLE,
    isLoading: false,
    selectedCity: null,
  },
  summary: {
    summaries: [],
    fetchStatus: FETCH_STATUS.IDLE,
    isLoading: false,
    fetchCount: 0,
    isFirstFetch: false,
  },
  selectedCity: {
    data: null,
    fetchStatus: FETCH_STATUS.IDLE,
  },
};

export const vacancyListSlice = createSlice({
  name: 'vacancyList',
  initialState: vacancyDefaultValue,
  reducers: {
    setVacancyList: (
      state,
      action: PayloadAction<Partial<VacancyState['list']>>,
    ) => ({
      ...state,
      list: {
        ...state.list,
        ...action.payload,
      },
    }),
    setVacancySummary: (
      state,
      action: PayloadAction<Partial<VacancyState['summary']>>,
    ) => ({
      ...state,
      summary: {
        ...state.summary,
        ...action.payload,
        isFirstFetch: Boolean(state.summary.fetchCount <= 0),
        fetchCount: state.summary.fetchCount + 1,
      },
    }),
    setVacancySelectedCity: (
      state,
      action: PayloadAction<Partial<VacancyState['selectedCity']>>,
    ) => ({
      ...state,
      selectedCity: {
        ...state.selectedCity,
        ...action.payload,
      },
    }),
    clearVacancyList: () => vacancyDefaultValue,
    clearVacancySelectedCity: (state) => ({
      ...state,
      selectedCity: vacancyDefaultValue.selectedCity,
    }),
  },
});

export const {
  setVacancyList,
  setVacancySummary,
  setVacancySelectedCity,
  clearVacancyList,
  clearVacancySelectedCity,
} = vacancyListSlice.actions;

export default vacancyListSlice.reducer;
