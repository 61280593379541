import { SvgIcon, SvgIconProps } from '@kitalulus/web-ui-kit';

const PrivacyTip = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path d="M3.84083 4.09751C3.18083 4.39084 2.75 5.05084 2.75 5.77501V10.0833C2.75 15.1708 6.27 19.9283 11 21.0833C15.73 19.9283 19.25 15.1708 19.25 10.0833V5.77501C19.25 5.05084 18.8192 4.39084 18.1592 4.09751L11.7425 1.24667C11.2658 1.03584 10.725 1.03584 10.2575 1.24667L3.84083 4.09751ZM11 6.41667C11.5042 6.41667 11.9167 6.82917 11.9167 7.33334C11.9167 7.83751 11.5042 8.25001 11 8.25001C10.4958 8.25001 10.0833 7.83751 10.0833 7.33334C10.0833 6.82917 10.4958 6.41667 11 6.41667ZM11 10.0833C11.5042 10.0833 11.9167 10.4958 11.9167 11V14.6667C11.9167 15.1708 11.5042 15.5833 11 15.5833C10.4958 15.5833 10.0833 15.1708 10.0833 14.6667V11C10.0833 10.4958 10.4958 10.0833 11 10.0833Z" />
    </SvgIcon>
  );
};

export default PrivacyTip;
