import {
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  TextArea,
  Typography,
  useMediaQuery,
  useTheme,
} from '@kitalulus/web-ui-kit';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import CloseIcon from '~/components/Icons/CloseIcon';
import LoadingButton from '~/components/LoadingButton';
import { useCandidateRecommendationInvite } from '~/hooks/candidate-recommendation';
import { useAppDispatch, useAppSelector } from '~/hooks/use-store';
import { closeDialog, setDialog } from '~/store/views/dialogs-slice';
import { CandidateRecommendationInviteDialogProps } from './CandidateRecommendationInviteDialog.types';
import * as mpe from '~/utils/mixpanel';
import { convertDateToISOString } from '~/utils/helper';

const CandidateRecommendationInviteDialog = ({
  content,
  ...props
}: CandidateRecommendationInviteDialogProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const { inviteCandidate, inviteLoading } = useCandidateRecommendationInvite();
  const {
    candidateRecommendationDetail,
    vacancyDetail: vacancy,
    company,
  } = useAppSelector((state) => state);

  const candidateDetailData = candidateRecommendationDetail?.detail?.data;

  const handleClose = useCallback(() => {
    dispatch(closeDialog('candidateRecommendationInviteDialog'));
  }, []);

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  if (!content) return null;
  const { emailContent, defaultTemplateStr, onSuccess, triggerSource } =
    content;
  return (
    <Dialog
      maxWidth="sm"
      {...props}
      PaperProps={{ sx: { p: { xs: 0.3, sm: 1 }, m: 1 } }}
    >
      <Box sx={{ p: 0 }}>
        <DialogTitle>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="start"
          >
            <Typography
              component="div"
              variant="h5"
              color="text.primary"
              sx={{ flexBasis: '90%' }}
            >
              {`${t('candidate-recommendation-detail:inviteDialog.title')} ${
                candidateDetailData?.userProfile?.name
              }`}
            </Typography>
            <Stack sx={{ flexBasis: '10%', alignItems: 'end' }}>
              <IconButton
                sx={{ top: -5, right: -5 }}
                size="small"
                onClick={handleClose}
              >
                <CloseIcon />
              </IconButton>
            </Stack>
          </Stack>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Typography variant="h6">
            {t('candidate-recommendation-detail:inviteDialog.header')}
          </Typography>
          <Typography variant="body1" sx={{ mt: 1, color: 'text.disabled' }}>
            {t('candidate-recommendation-detail:inviteDialog.instructions')}
          </Typography>
          <TextArea
            label=""
            fullWidth
            sx={{ mt: 3 }}
            rows={14}
            InputLabelProps={{ shrink: false }}
            placeholder={`${t(
              'candidate-recommendation-detail:inviteDialog.template',
              {
                companyName: company?.name,
              },
            )}`}
            value={emailContent}
            onChange={(e) =>
              dispatch(
                setDialog({
                  candidateRecommendationInviteDialog: {
                    open: true,
                    content: {
                      emailContent: e.target.value,
                      defaultTemplateStr,
                      onSuccess,
                      triggerSource,
                    },
                  },
                }),
              )
            }
          />
        </DialogContent>
        <DialogActions
          sx={{
            boxShadow: theme.shadows[4],
            mb: { xs: -0.3, sm: -1 },
            px: 4,
            mx: { xs: -0.3, sm: -1 },
          }}
        >
          <Button
            size={isMobile ? 'medium' : 'large'}
            variant="outlined"
            sx={{ width: { xs: 'auto', sm: 150 } }}
            onClick={() => {
              handleClose();
              dispatch(
                setDialog({
                  candidateRecommendationEmailPreviewDialog: {
                    open: true,
                  },
                }),
              );
            }}
            autoFocus
          >
            {t('candidate-recommendation-detail:inviteDialog.previewEmail')}
          </Button>
          <LoadingButton
            size={isMobile ? 'medium' : 'large'}
            variant="contained"
            sx={{ width: { xs: 'auto', sm: 150 } }}
            onClick={async () => {
              if (!candidateDetailData?.id) return;
              mpe.candidateActionConfirm({
                vacancyCode: vacancy?.code ?? '',
                vacancyCreatedAt: convertDateToISOString(vacancy?.createdAt),
                vacancyCloseDate: convertDateToISOString(vacancy?.closeDate),
                candidateID: candidateDetailData.id,
                candidateName: candidateDetailData?.userProfile?.name ?? '',
                action: 'invite',
                actionPosition: triggerSource,
              });
              const { isSuccess } = await inviteCandidate(
                candidateDetailData.id,
                emailContent,
              );
              handleClose();
              if (isSuccess && onSuccess) return onSuccess();
            }}
            loading={inviteLoading}
          >
            {t('candidate-recommendation-detail:sendEmail')}
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default CandidateRecommendationInviteDialog;
