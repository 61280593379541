import { SvgIcon, SvgIconProps } from '@kitalulus/web-ui-kit';

const ConsPersonIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 16 16" {...props}>
      <g>
        <path d="M8.00032 4.00033C8.73366 4.00033 9.33366 4.60033 9.33366 5.33366C9.33366 6.06699 8.73366 6.66699 8.00032 6.66699C7.26699 6.66699 6.66699 6.06699 6.66699 5.33366C6.66699 4.60033 7.26699 4.00033 8.00032 4.00033ZM8.00032 10.667C9.80032 10.667 11.867 11.527 12.0003 12.0003H4.00033C4.15366 11.5203 6.20699 10.667 8.00032 10.667ZM8.00032 2.66699C6.52699 2.66699 5.33366 3.86033 5.33366 5.33366C5.33366 6.80699 6.52699 8.00032 8.00032 8.00032C9.47366 8.00032 10.667 6.80699 10.667 5.33366C10.667 3.86033 9.47366 2.66699 8.00032 2.66699ZM8.00032 9.33366C6.22032 9.33366 2.66699 10.227 2.66699 12.0003V13.3337H13.3337V12.0003C13.3337 10.227 9.78032 9.33366 8.00032 9.33366Z" />
      </g>
    </SvgIcon>
  );
};

export default ConsPersonIcon;
