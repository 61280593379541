import {
  Box,
  Paper,
  PaperProps,
  Stack,
  Typography,
  useTheme,
} from '@kitalulus/web-ui-kit';
import { Skeleton } from '@mui/material';
import camelCase from 'lodash.camelcase';

interface ICardSalary {
  title: string;
  emptyState?: string;
  salary?: string | null;
  spacing?: number;
  fullWidth?: boolean;
  PaperProps?: PaperProps;
  isLoading?: boolean;
}

const CardSalary = ({
  title,
  salary,
  spacing,
  fullWidth,
  emptyState,
  PaperProps,
  isLoading,
}: ICardSalary) => {
  const theme = useTheme();
  return (
    <>
      <Paper
        id={camelCase(`applicant ${title}`)}
        sx={{
          borderRadius: theme.spacing(2),
          padding: theme.spacing(3),
          width: {
            xs: '100%',
            lg: fullWidth ? '100%' : '320px',
          },
          overflowWrap: 'anywhere',
          boxShadow: theme.customShadows['card'],
        }}
        {...PaperProps}
      >
        <>
          <Typography variant="h6">{title}</Typography>
          <Stack paddingTop={theme.spacing(4)} gap={spacing ?? 2}>
            <Box>
              {!isLoading ? (
                <Typography
                  variant="subtitle2"
                  color="text.secondary"
                  fontWeight={400}
                >
                  {salary ?? emptyState}
                </Typography>
              ) : (
                <Skeleton variant="rounded" height={22} width={177} />
              )}
            </Box>
          </Stack>
        </>
      </Paper>
    </>
  );
};

export default CardSalary;
