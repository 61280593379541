import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DropdownOption, StateSetterFunc } from '~/types';
import {
  InterviewSessionCountQuery,
  InterviewSessionsQuery,
} from '~/types/graphql/graphql';
import { FETCH_STATUS } from '~/utils/constants';

type TError = {
  message?: string;
  state: 'ERROR_QUERY';
} | null;

type TInterviewSessionsListState = {
  fetchStatus: FETCH_STATUS;
  data: Partial<InterviewSessionsQuery['interviewSessions']>;
  error: TError;
};

type TInterviewSessionSort = {
  fetchStatus: FETCH_STATUS;
  data: DropdownOption[];
  error: TError;
};

type TInterviewSessionType = {
  fetchStatus: FETCH_STATUS;
  data: DropdownOption[];
  error: TError;
};

type TInterviewSessionCount = {
  fetchStatus: FETCH_STATUS;
  data: InterviewSessionCountQuery['interviewSessionCount'];
  error: TError;
};

type TVacancy = {
  fetchStatus: FETCH_STATUS;
  data: DropdownOption[];
  error: string | null;
  hasNoJobListings: boolean;
  selected: DropdownOption | null;
};

type TInitialState = {
  list: TInterviewSessionsListState;
  vacancyOptions: TVacancy;
  sort: TInterviewSessionSort;
  type: TInterviewSessionType;
  isOpenDialogFilter: boolean;
  countOfFilteredResults: TInterviewSessionCount;
  countOfFilteredApply: number;
};

const initialState: TInitialState = {
  list: {
    fetchStatus: FETCH_STATUS.IDLE,
    data: null,
    error: null,
  },
  vacancyOptions: {
    fetchStatus: FETCH_STATUS.IDLE,
    data: [],
    error: null,
    hasNoJobListings: false,
    selected: {
      label: '',
      value: '',
    },
  },
  sort: {
    fetchStatus: FETCH_STATUS.IDLE,
    data: [],
    error: null,
  },
  type: {
    fetchStatus: FETCH_STATUS.IDLE,
    data: [],
    error: null,
  },
  countOfFilteredResults: {
    fetchStatus: FETCH_STATUS.IDLE,
    data: null,
    error: null,
  },
  isOpenDialogFilter: false,
  countOfFilteredApply: 0,
};

export const interviewSessionsListSlice = createSlice({
  name: 'interviewSessionsList',
  initialState: initialState,
  reducers: {
    setInterviewSessionsList: (
      state,
      action: PayloadAction<
        StateSetterFunc<Partial<TInterviewSessionsListState>>
      >,
    ) => {
      return {
        ...state,
        list: {
          ...state.list,
          ...action.payload,
        },
      };
    },
    setInterviewSessionsListError: (state, action: PayloadAction<TError>) => {
      return {
        ...state,
        list: {
          ...state.list,
          fetchStatus: FETCH_STATUS.FAILED,
          error: action.payload,
        },
      };
    },
    setInterviewSessionVacancyOptions: (
      state,
      action: PayloadAction<DropdownOption>,
    ) => {
      const payloadFiltered = state.vacancyOptions.data.filter(
        ({ value }) => value !== action.payload.value,
      );

      return {
        ...state,
        vacancyOptions: {
          ...state.vacancyOptions,
          fetchStatus: FETCH_STATUS.RESOLVED,
          data: [action.payload, ...payloadFiltered],
        },
      };
    },
    setInterviewSessionVacancyOptionsMore: (
      state,
      action: PayloadAction<DropdownOption[]>,
    ) => {
      const payloadValue = action.payload.map((data) => data.value);
      const payloadFiltered = state.vacancyOptions.data.filter(
        ({ value }) => !payloadValue.includes(value),
      );

      return {
        ...state,
        vacancyOptions: {
          ...state.vacancyOptions,
          fetchStatus: FETCH_STATUS.RESOLVED,
          data: [...payloadFiltered, ...action.payload],
        },
      };
    },
    setInterviewSessionVacancyOptionSelect: (
      state,
      action: PayloadAction<DropdownOption>,
    ) => {
      return {
        ...state,
        vacancyOptions: {
          ...state.vacancyOptions,
          selected: action.payload,
        },
      };
    },
    setInterviewSessionHasNoJobListings: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      return {
        ...state,
        vacancyOptions: {
          ...state.vacancyOptions,
          fetchStatus: FETCH_STATUS.RESOLVED,
          hasNoJobListings: action.payload,
        },
      };
    },
    setInterviewSessionSort: (
      state,
      action: PayloadAction<StateSetterFunc<Partial<TInterviewSessionSort>>>,
    ) => {
      return {
        ...state,
        sort: {
          ...state.sort,
          ...action.payload,
        },
      };
    },
    setInterviewSessionOpenDialogFilter: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      return {
        ...state,
        isOpenDialogFilter: action.payload,
      };
    },
    setInterviewSessionType: (
      state,
      action: PayloadAction<StateSetterFunc<Partial<TInterviewSessionType>>>,
    ) => {
      return {
        ...state,
        type: {
          ...state.type,
          ...action.payload,
        },
      };
    },
    setInterviewSessionCount: (
      state,
      action: PayloadAction<StateSetterFunc<Partial<TInterviewSessionCount>>>,
    ) => {
      return {
        ...state,
        countOfFilteredResults: {
          ...state.countOfFilteredResults,
          ...action.payload,
        },
      };
    },
    setInterviewSessionCountOfFilteredApply: (
      state,
      action: PayloadAction<number>,
    ) => {
      return {
        ...state,
        countOfFilteredApply: action.payload,
      };
    },
    clearInterviewSessionSelectedVacancyOption: (state) => {
      return {
        ...state,
        vacancyOptions: {
          ...state.vacancyOptions,
          selected: null,
        },
      };
    },
    clearInterviewSessionsList: (state) => {
      return {
        ...state,
        list: {
          ...initialState.list,
          data: {
            ...initialState.list,
            list: [],
          },
        },
      };
    },
    clearInterviewSessions: () => {
      return initialState;
    },
    clearSelectedVacancy: (state) => {
      return {
        ...state,
        list: initialState.list,
      };
    },
  },
});

export const {
  setInterviewSessionsList,
  setInterviewSessionsListError,
  setInterviewSessionSort,
  setInterviewSessionType,
  setInterviewSessionCount,
  setInterviewSessionVacancyOptions,
  setInterviewSessionVacancyOptionsMore,
  setInterviewSessionHasNoJobListings,
  setInterviewSessionVacancyOptionSelect,
  setInterviewSessionOpenDialogFilter,
  setInterviewSessionCountOfFilteredApply,
  clearInterviewSessionsList,
  clearInterviewSessions,
  clearInterviewSessionSelectedVacancyOption,
} = interviewSessionsListSlice.actions;

export default interviewSessionsListSlice.reducer;
