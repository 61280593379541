import {
  Box,
  brandColors,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@kitalulus/web-ui-kit';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import AuthLayoutCopyRight from '~/components/AuthLayoutV2/AuthLayoutCopyRight';
import CloseIcon from '~/components/Icons/CloseIcon';
import {
  useOfferingPackages,
  useUserSubscriptionRequest,
} from '~/hooks/use-user-subscription';
import { clearDialog } from '~/store/views/dialogs-slice';
import { SubscriptionPlanDialogProps } from './SubscriptionPlanDialogV2.types';
import { MIXPANEL_ACCOUNT_FAQ_LINK_POSITION } from '~/utils/constants/mixpanel';
import useOfferingModalConfirmation from '~/hooks/offering-modal/use-offering-modal-confirmation';
import { Nullable } from '~/types';
import SubscriptionPlanV2 from '~/components/SubscriptionPlanV2';
import { useFeatureFlag } from '~/hooks/firebase/use-feature-flag';
import { useAppDispatch } from '~/hooks/use-store';
import { EpOfferingPackage } from '~/types/graphql/graphql';

const SubscriptionPlanDialogV2 = ({
  content,
  ...props
}: SubscriptionPlanDialogProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isPayPerPostActive = useFeatureFlag('pay_per_post');

  const { packages, isLoading } = useOfferingPackages();
  const { handleRequest } = useUserSubscriptionRequest();
  const { saveWhatsappNumber } = useOfferingModalConfirmation();

  const handleClose = useCallback(() => {
    dispatch(clearDialog('subscriptionPlanDialog'));
  }, []);

  const onSelect = useCallback(
    async (
      plan: EpOfferingPackage,
      onCloseConfirmation: () => void,
      whatsapp: Nullable<string>,
    ) => {
      if (isPayPerPostActive) {
        const resWhatsApp =
          whatsapp &&
          (await saveWhatsappNumber({
            packageId: plan.id,
            phoneNumber: whatsapp,
          }));
        if (!resWhatsApp) return;
      } else {
        const res = await handleRequest(plan.id, {
          type: 'from-api',
          layout: 'dashboard',
        });
        if (!res?.isSuccess) return;
      }

      // close small confirmation modal
      onCloseConfirmation();
      // close big subscription modal
      handleClose();
    },
    [handleRequest, handleClose],
  );

  return (
    <Dialog
      maxWidth="xl"
      fullWidth
      onClose={handleClose}
      {...props}
      sx={{ '.MuiPaper-root': { p: 0 } }}
    >
      <DialogTitle
        display="flex"
        justifyContent="flex-end"
        sx={{ p: { xs: 1, md: 2 } }}
      >
        <IconButton
          onClick={handleClose}
          data-test-id="btnSubscriptionPlanDialogClose"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ px: 0, py: 0 }}>
        <Stack spacing={6} px={0}>
          {/* Header section */}
          <Stack
            alignItems="center"
            textAlign="center"
            position="relative"
            spacing={1}
            overflow="hidden"
            px={{ xs: 4, md: 8 }}
            pb={4}
          >
            <Typography
              component="div"
              variant="h3"
              sx={{
                maxWidth: '700px',
                span: {
                  color: isPayPerPostActive
                    ? brandColors.primary[500]
                    : brandColors.primary[700],
                },
              }}
            >
              <div
                style={{ whiteSpace: 'pre-line' }}
                dangerouslySetInnerHTML={{
                  __html: t('subscription:subscriptionModalTitleV2', {
                    highlighted: `<span>${t(
                      'subscription:subscriptionModalTitleHighlightedV2',
                    )}</span>`,
                  }),
                }}
              />
            </Typography>
            <Typography
              variant="body1"
              color={isPayPerPostActive ? 'text.primary' : 'text.secondary'}
            >
              {t('subscription:subscriptionModalSubtitleV2')}
            </Typography>

            {/* Blobs */}
            <Box
              display={{ xs: 'none', lg: 'block' }}
              component="img"
              src="/blobs/small-24-dots.svg"
              position="absolute"
              left="100px"
              top="20px"
            />

            <Box
              display={{ xs: 'none', lg: 'block' }}
              component="img"
              src="/blobs/gradient-orange-dot.svg"
              position="absolute"
              right="-35px"
              bottom="0"
            />
          </Stack>

          {/* Subscription plan list */}
          <Box position="relative" pb={5} sx={{ overflowX: 'hidden' }}>
            <SubscriptionPlanV2
              plans={packages}
              isLoading={isLoading}
              onPlanSelect={onSelect}
              pageLocation="dialog"
              linkPosition={content?.linkPosition}
            />

            {/* Blobs */}
            <Box
              display={{ xs: 'none', lg: 'block' }}
              component="img"
              src="/blobs/gradient-blue-dot.svg"
              position="absolute"
              top="15px"
              left="0"
            />

            <Box
              display={{ xs: 'none', lg: 'block' }}
              component="img"
              src="/blobs/small-24-dots.svg"
              position="absolute"
              right="-70px"
              bottom="250px"
            />

            <Box
              display={{ xs: 'none', lg: 'block' }}
              component="img"
              src="/blobs/gradient-blue-dot.svg"
              position="absolute"
              bottom="30px"
              right="0"
            />
          </Box>

          {/* Footer section */}
          <Stack>
            {/* Copyright section */}
            <Box py={4} textAlign="center">
              <AuthLayoutCopyRight
                helpLink={{
                  linkPosition:
                    MIXPANEL_ACCOUNT_FAQ_LINK_POSITION.SUBSCRIPTION_PLAN_DIALOG,
                }}
                privacyPolicyLink={{
                  onClick: () => {
                    handleClose();
                    navigate('/privacy');
                  },
                }}
              />
            </Box>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default SubscriptionPlanDialogV2;
