import { SvgIcon, SvgIconProps } from '@kitalulus/web-ui-kit';

export const EmailIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 24 25" {...props}>
      <path d="M5 4.24139H19C20.6569 4.24139 22 5.58454 22 7.24139V17.2414C22 18.8982 20.6569 20.2414 19 20.2414H5C3.34315 20.2414 2 18.8982 2 17.2414V7.24139C2 5.58454 3.34315 4.24139 5 4.24139ZM12.5 10.7114L19 6.24139H5L11.5 10.7114C11.8094 10.89 12.1906 10.89 12.5 10.7114Z" />
    </SvgIcon>
  );
};

export default EmailIcon;
