import { useLazyQuery } from '@apollo/client';
import { useCallback, useState } from 'react';

import { QUERY_VALIDATE_INTERVIEW_SESSION_NAME } from '~/graphql/interview-invitation';
import { ActionFunctionResult, PartialSpeficic } from '~/types';
import {
  ValidateInterviewSessionNameQuery,
  ValidateInterviewSessionNameQueryVariables,
} from '~/types/graphql/graphql';

export const useInterviewInvitationSessionNameValidation = (
  vacancyId: string,
) => {
  const [isLoading, setIsLoading] = useState(false);

  const [validateSessionName] = useLazyQuery<
    ValidateInterviewSessionNameQuery,
    ValidateInterviewSessionNameQueryVariables
  >(QUERY_VALIDATE_INTERVIEW_SESSION_NAME, {
    fetchPolicy: 'no-cache',
  });

  const handleValidateSessionName = useCallback(
    async (
      variables: PartialSpeficic<
        ValidateInterviewSessionNameQueryVariables,
        'vacancyId'
      >,
    ): Promise<
      ActionFunctionResult<
        NonNullable<
          ValidateInterviewSessionNameQuery['validateInterviewSessionName']
        >,
        NonNullable<
          ValidateInterviewSessionNameQuery['validateInterviewSessionName']
        >
      >
    > => {
      try {
        setIsLoading(true);
        const { data } = await validateSessionName({
          variables: { vacancyId, ...variables },
        });
        const res = data?.validateInterviewSessionName;
        if (!res) throw new Error('empty response');
        return { success: true, data: res };
      } catch (err: any) {
        return { success: false, error: err };
      } finally {
        setIsLoading(false);
      }
    },
    [validateSessionName, vacancyId],
  );

  return { handleValidateSessionName, isLoading };
};
