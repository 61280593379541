import {
  Box,
  Button,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@kitalulus/web-ui-kit';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import CheckBoxFilledIcon from '~/components/Icons/CheckBoxFilledIcon';
import { useInvitationStatusColor } from '~/hooks/candidate-recommendation';
import { TCandidateRecommendationDetailState } from '~/store/features/candidate-recommendation-detail-slice';
import * as mpe from '~/utils/mixpanel';

type ResponseCandidateStatusProps = {
  item: TCandidateRecommendationDetailState;
  vacancyCode?: string;
  handleCheckProfileNavigate?: () => void;
  ShowProcessImmediatelyButton?: boolean;
  isDisableTooltip?: boolean;
};

const ResponseCandidateStatus = ({
  item,
  vacancyCode,
  handleCheckProfileNavigate,
  ShowProcessImmediatelyButton = false,
  isDisableTooltip = false,
}: ResponseCandidateStatusProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { getStatusDetails } = useInvitationStatusColor();

  const {
    color,
    text: inviationStatusStr,
    tooltipStr,
  } = getStatusDetails(item?.invitationStatus ?? undefined);
  return (
    <Stack
      gap={1.5}
      direction={isMobile ? 'column' : 'row'}
      justifyContent="flex-end"
      width="100%"
    >
      <Stack gap={0.5} justifyContent="center">
        <Stack direction="row" flexWrap="nowrap" gap={0.5}>
          <Typography
            variant="subtitle1"
            sx={{
              color,
              whiteSpace: 'nowrap',
              display: 'flex',
              alignItems: 'center',
              flexWrap: isMobile ? 'wrap' : 'nowrap',
              gap: 1,
            }}
          >
            {t('candidate-recommendation:tableHeadStatus')}:{' '}
            <Box
              component="span"
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
              }}
            >
              {inviationStatusStr}
              {item?.jobApplicationId && (
                <Tooltip
                  disableHoverListener={isDisableTooltip}
                  disableTouchListener={isDisableTooltip}
                  onOpen={() => {
                    mpe.candidateTooltip({
                      candidateID: item?.id ?? '',
                      vacancyCode: vacancyCode ?? '',
                      candidateName: item?.userProfile?.name ?? '',
                      invitationStatus: inviationStatusStr,
                    });
                  }}
                  title={
                    <Stack gap={2} textAlign="center" maxWidth="178px">
                      <Typography variant="caption">{tooltipStr}</Typography>
                      <Typography variant="caption">
                        {t(
                          'candidate-recommendation:responseCandidate.appliedTooltipSegeraProses',
                        )}
                      </Typography>
                      <Button
                        variant="text"
                        onClick={() => handleCheckProfileNavigate?.()}
                      >
                        <Typography
                          variant="caption"
                          sx={{ textDecoration: 'underline', color: 'white' }}
                        >
                          {t(
                            'candidate-recommendation:responseCandidate.checkProfile',
                          )}
                        </Typography>
                      </Button>
                    </Stack>
                  }
                  enterTouchDelay={0}
                  arrow
                  placement="bottom-end"
                  PopperProps={{
                    sx: {
                      whiteSpace: 'pre-line',
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <CheckBoxFilledIcon
                      sx={{ color: 'success.main', fontSize: '22px' }}
                    />
                  </Box>
                </Tooltip>
              )}
            </Box>
          </Typography>
        </Stack>

        <Typography
          variant="caption"
          textAlign={isMobile ? 'left' : 'right'}
          sx={{ whiteSpace: 'nowrap', color: 'text.disabled' }}
        >
          {t('Tanggal Diundang:')}{' '}
          {item?.invitedAt && format(new Date(item?.invitedAt), 'MM/dd/yyyy')}
        </Typography>
      </Stack>
      {ShowProcessImmediatelyButton && item?.jobApplicationId && (
        <Stack alignItems="center" justifyContent="center">
          <Button
            variant="contained"
            size="small"
            fullWidth
            sx={{ px: 1.5, whiteSpace: 'nowrap' }}
            onClick={() => handleCheckProfileNavigate?.()}
          >
            <Typography variant="caption">
              {t(
                'candidate-recommendation:responseCandidate.processImmediately',
              )}
            </Typography>
          </Button>
        </Stack>
      )}
    </Stack>
  );
};

export default ResponseCandidateStatus;
