import { createSlice } from '@reduxjs/toolkit';

import { EpPackageFeatures } from '~/types/graphql/graphql';
import { FETCH_STATUS } from '~/utils/constants';

export type UserSubscriptionState = {
  packageFeatures: {
    fetchStatus: FETCH_STATUS;
    data: EpPackageFeatures;
  };
};

const initialState: UserSubscriptionState = {
  packageFeatures: {
    fetchStatus: FETCH_STATUS.IDLE,
    data: {
      list: [],
    },
  },
};

export const userSubscriptionSlice = createSlice({
  name: 'userSubscription',
  initialState,
  reducers: {
    setPackageFeatures: (state, action) => {
      return {
        ...state,
        packageFeatures: {
          ...state.packageFeatures,
          ...action.payload,
        },
      };
    },
  },
});

export const { setPackageFeatures } = userSubscriptionSlice.actions;

export default userSubscriptionSlice.reducer;
