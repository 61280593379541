import { Box, BoxProps } from '@kitalulus/web-ui-kit';
import { forwardRef, memo, useMemo } from 'react';

export type TruncateTextProps = BoxProps & {
  noOfLines?: number;
  maxCharacterLength?: number;
  showLastChars?: number;
};

const TruncateText = forwardRef<HTMLDivElement, TruncateTextProps>(
  (
    { noOfLines, maxCharacterLength, showLastChars, children, ...props },
    ref,
  ) => {
    let truncateSxProps: BoxProps['sx'] = {};
    if (noOfLines) {
      truncateSxProps = {
        display: '-webkit-box',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: noOfLines,
        wordBreak: 'break-all',
      };
    }

    if (maxCharacterLength) {
      truncateSxProps = {
        ...truncateSxProps,
        maxWidth: `${maxCharacterLength}ch`,
      };
    }

    const truncatedText = useMemo(() => {
      if (typeof children !== 'string') return children;

      const [text, lastChar] = children.split(', ');

      if (maxCharacterLength && showLastChars) {
        const maxTruncateLength =
          maxCharacterLength - (showLastChars ? showLastChars + 4 : 0);

        const truncatedName =
          text.length > maxTruncateLength
            ? `${text.slice(0, maxTruncateLength)}...`
            : text;

        return (
          <>
            {truncatedName}
            <Box
              component="span"
              sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
            >
              ,&nbsp;
            </Box>
            <Box component="span">
              {showLastChars ? lastChar.slice(-showLastChars) : lastChar}
            </Box>
          </>
        );
      }

      return children;
    }, [children, maxCharacterLength, showLastChars]);

    return (
      <Box ref={ref} {...props} sx={{ ...truncateSxProps, ...props.sx }}>
        {truncatedText}
      </Box>
    );
  },
);

export default memo(TruncateText);
