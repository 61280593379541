import { SvgIcon, SvgIconProps } from '@kitalulus/web-ui-kit';

const ConsTimeIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="17" height="16" viewBox="0 0 17 16" {...props}>
      <path d="M8.5026 14.6654C4.8206 14.6654 1.83594 11.6807 1.83594 7.9987C1.83594 4.3167 4.8206 1.33203 8.5026 1.33203C12.1846 1.33203 15.1693 4.3167 15.1693 7.9987C15.1693 11.6807 12.1846 14.6654 8.5026 14.6654ZM8.5026 13.332C9.91709 13.332 11.2736 12.7701 12.2738 11.7699C13.274 10.7697 13.8359 9.41319 13.8359 7.9987C13.8359 6.58421 13.274 5.22766 12.2738 4.22746C11.2736 3.22727 9.91709 2.66536 8.5026 2.66536C7.08812 2.66536 5.73156 3.22727 4.73137 4.22746C3.73117 5.22766 3.16927 6.58421 3.16927 7.9987C3.16927 9.41319 3.73117 10.7697 4.73137 11.7699C5.73156 12.7701 7.08812 13.332 8.5026 13.332ZM9.16927 7.9987H11.8359V9.33203H7.83594V4.66536H9.16927V7.9987Z" />
    </SvgIcon>
  );
};

export default ConsTimeIcon;
