import { useLazyQuery } from '@apollo/client';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { QUERY_COMPANY_ACC_MANAGER, QUERY_COMPANY } from '~/graphql/company';
import { setCompanyAccManager } from '~/store/features/company-acc-manager-slice';
import { setCompany } from '~/store/features/company-slice';
import {
  CompanyAccManagerQuery,
  CompanyAccManagerQueryVariables,
  CompanyQuery,
  EpCompanyVerificationStatus,
} from '~/types/graphql/graphql';
import { defaultGlobalDataContext } from '~/utils/apollo-request';
import { generateWhatsappLink } from '~/utils/whatsapp';
import { useAuthValue, useSignOut } from './use-auth';
import { useAppDispatch, useAppSelector } from './use-store';
import { clearVacancies } from '~/store/features/applicant-detail-freeplan-persist-slice';
import { useFeatureFlag } from '~/hooks/firebase/use-feature-flag';

export const useCompany = () => {
  const { isAuthenticated } = useAuthValue();
  const dispatch = useAppDispatch();
  const { handleSignOut } = useSignOut();
  const companyRefreshCount = useAppSelector(
    (state) => state.refreshCount.company,
  );
  const isPremium = useAppSelector(
    (state) => state.realtimeData.company.data.package.isPremium,
  );

  const [doneInitialCheckFraudPage, setDoneInitialCheckFraudPage] =
    useState(false);

  const companyId = useAppSelector((state) => state.user?.companyId);
  const realtimeData = useAppSelector((state) => state.realtimeData);

  const realtimeCompanyStatus = realtimeData?.company?.data?.company?.status;

  const [getCompany, { loading }] = useLazyQuery<CompanyQuery>(QUERY_COMPANY, {
    fetchPolicy: 'no-cache',
    context: defaultGlobalDataContext,
    onCompleted: ({ company }) => dispatch(setCompany(company)),
  });

  useEffect(() => {
    if (!isAuthenticated) {
      return setDoneInitialCheckFraudPage(false);
    }

    if (
      doneInitialCheckFraudPage &&
      realtimeCompanyStatus === EpCompanyVerificationStatus.Fraud
    ) {
      handleSignOut(false);
      return;
    }

    if (companyId || companyRefreshCount) {
      getCompany();
    }

    if (companyId && realtimeCompanyStatus) {
      setDoneInitialCheckFraudPage(true);
    }
  }, [isAuthenticated, companyRefreshCount, companyId, realtimeCompanyStatus]);

  // clear Company Applicant Free Plan Persisted Data if Premium
  useEffect(() => {
    if (!companyId) return;
    if (isPremium) dispatch(clearVacancies({ companyID: companyId }));
  }, [isPremium, companyId]);

  return { isLoading: loading };
};

export const useCompanyAccManager = () => {
  const dispatch = useAppDispatch();
  const company = useAppSelector((state) => state.company);

  const [getCompanyAccManager, { loading }] = useLazyQuery<
    CompanyAccManagerQuery,
    CompanyAccManagerQueryVariables
  >(QUERY_COMPANY_ACC_MANAGER, {
    fetchPolicy: 'no-cache',
    context: defaultGlobalDataContext,
    onCompleted: ({ companyAccManager }) =>
      dispatch(setCompanyAccManager(companyAccManager)),
  });

  useEffect(() => {
    getCompanyAccManager({ variables: { companyId: company?.id } });
  }, [company?.id]);

  return { isLoading: loading };
};

export const useCompanyAccManagerWhatsappLink = (
  type?:
    | 'register'
    | 'error'
    | 'default'
    | 'dashboard'
    | 'registerV2'
    | 'payPerPost'
    | 'upgradePackage'
    | 'repostIssue'
    | 'errorBoundaryIssue',
  selectedPackage?: string,
) => {
  const { t } = useTranslation();
  const isPayPerPostActive = useFeatureFlag('pay_per_post');
  const companyAccManager = useAppSelector((state) => state.companyAccManager);
  const realtimeData = useAppSelector((state) => state.realtimeData);
  const realtimeDataCompany = realtimeData?.company?.data;
  const realtimeDataPackage = realtimeData?.packageByCompanyId?.data;
  const companyName = realtimeDataCompany?.company?.name;
  const packageName = isPayPerPostActive
    ? realtimeDataPackage?.currentHighestPackageName
    : realtimeDataCompany?.package?.name;

  const copywriting = useMemo(() => {
    switch (type) {
      case 'register':
        return companyAccManager?.accManagerRegisterCopy;
      case 'registerV2':
        return companyAccManager?.accManagerRegisterCopyV2;
      case 'error':
        return companyAccManager?.accManagerErrorCopy;
      case 'dashboard':
        return companyAccManager?.accManagerDashboardCopy;
      case 'upgradePackage':
        return t('common:accManagerUpgradePackageCopy', {
          companyName,
          packageName,
          selectedPackage,
        });
      case 'repostIssue':
        return t('common:accManagerRepostIssueCopy', {
          companyName,
          packageName,
        });
      // Hardcoded the copy for fast track project
      // Need to handled it from BE later
      case 'payPerPost':
        return t('common:accManagerPayPerPostCopy', {
          companyName,
          packageName,
        });
      case 'errorBoundaryIssue':
        return t('common:accManagerIssueErrorBoundary', {
          companyName,
        });
      default:
        return companyAccManager?.accManagerCopy;
    }
  }, [type, companyAccManager, companyName, packageName]);

  const waLink = useMemo(() => {
    if (!companyAccManager?.accManagerWhatsapp) return;
    return generateWhatsappLink(
      companyAccManager.accManagerWhatsapp,
      copywriting ?? '',
    );
  }, [companyAccManager, copywriting]);

  return waLink ?? '';
};
