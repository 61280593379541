import { Box, Iconify } from '@kitalulus/web-ui-kit';

import { SnackbarIconProps } from './SnackbarIcon.types';

const SnackbarIcon = ({ color, icon }: SnackbarIconProps) => {
  return (
    <Box
      component="span"
      sx={{
        width: 30,
        height: 30,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: `${color}.main`,
      }}
    >
      <Iconify icon={icon} width={24} height={24} />
    </Box>
  );
};

export default SnackbarIcon;
