import { SvgIcon, SvgIconProps } from '@kitalulus/web-ui-kit';

const CrownIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 25 25" {...props}>
      <path d="M20.0939 14.6932L20.2773 12.9885C20.3751 12.079 20.4397 11.4784 20.389 11.1L20.4067 11.1001C21.2351 11.1001 21.9067 10.4285 21.9067 9.6001C21.9067 8.77167 21.2351 8.1001 20.4067 8.1001C19.5783 8.1001 18.9067 8.77167 18.9067 9.6001C18.9067 9.97476 19.0441 10.3173 19.2712 10.5802C18.9451 10.7815 18.5189 11.2061 17.8772 11.8452C17.3829 12.3376 17.1357 12.5838 16.86 12.622C16.7072 12.6431 16.5516 12.6214 16.4105 12.5593C16.1559 12.4471 15.9861 12.1428 15.6466 11.5341L13.8572 8.3255C13.6477 7.94997 13.4724 7.63567 13.3144 7.38275C13.9627 7.05197 14.4067 6.37788 14.4067 5.6001C14.4067 4.49553 13.5113 3.6001 12.4067 3.6001C11.3021 3.6001 10.4067 4.49553 10.4067 5.6001C10.4067 6.37788 10.8507 7.05197 11.499 7.38275C11.341 7.63569 11.1657 7.94994 10.9562 8.3255L9.1668 11.5341C8.8273 12.1428 8.65755 12.4471 8.40295 12.5593C8.26188 12.6214 8.10621 12.6431 7.95344 12.622C7.67771 12.5838 7.43055 12.3376 6.93624 11.8452C6.29461 11.2061 5.8683 10.7815 5.54227 10.5802C5.76938 10.3173 5.90674 9.97476 5.90674 9.6001C5.90674 8.77167 5.23517 8.1001 4.40674 8.1001C3.57831 8.1001 2.90674 8.77167 2.90674 9.6001C2.90674 10.4285 3.57831 11.1001 4.40674 11.1001L4.42445 11.1C4.37376 11.4784 4.43836 12.079 4.53619 12.9885L4.71954 14.6932C4.82132 15.6394 4.90595 16.5397 5.00961 17.3501H19.8038C19.9075 16.5397 19.9921 15.6394 20.0939 14.6932Z" />
      <path d="M11.3188 21.6001H13.4946C16.3306 21.6001 17.7485 21.6001 18.6946 20.7533C19.1076 20.3836 19.369 19.7173 19.5577 18.8501H5.25562C5.44431 19.7173 5.70579 20.3836 6.11874 20.7533C7.06483 21.6001 8.48278 21.6001 11.3188 21.6001Z" />
    </SvgIcon>
  );
};

export default CrownIcon;
