import { SvgIcon, SvgIconProps } from '@kitalulus/web-ui-kit';

const Help = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 18 18" {...props}>
      <path d="M9 1.5C4.86 1.5 1.5 4.86 1.5 9C1.5 13.14 4.86 16.5 9 16.5C13.14 16.5 16.5 13.14 16.5 9C16.5 4.86 13.14 1.5 9 1.5ZM9.75 14.25H8.25V12.75H9.75V14.25ZM11.3025 8.4375L10.6275 9.1275C10.2525 9.51 9.9825 9.855 9.8475 10.395C9.7875 10.635 9.75 10.905 9.75 11.25H8.25V10.875C8.25 10.53 8.31 10.2 8.415 9.8925C8.565 9.4575 8.8125 9.0675 9.1275 8.7525L10.0575 7.8075C10.4025 7.4775 10.5675 6.9825 10.47 6.4575C10.3725 5.9175 9.9525 5.46 9.4275 5.31C8.595 5.0775 7.8225 5.55 7.575 6.2625C7.485 6.54 7.2525 6.75 6.96 6.75H6.735C6.3 6.75 6 6.33 6.12 5.91C6.4425 4.8075 7.38 3.9675 8.5425 3.7875C9.6825 3.6075 10.77 4.2 11.445 5.1375C12.33 6.36 12.0675 7.6725 11.3025 8.4375Z" />
    </SvgIcon>
  );
};

export default Help;
