import { SvgIcon, SvgIconProps } from '@kitalulus/web-ui-kit';

const FiRsLink = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 14 14" {...props}>
      <path d="M6.17349 11.9853C5.62103 12.523 4.87908 12.8215 4.10817 12.8164C3.33726 12.8112 2.59938 12.5027 2.05417 11.9577C1.50896 11.4126 1.20028 10.6748 1.19489 9.90393C1.18951 9.13302 1.48787 8.39099 2.02541 7.83838L4.51157 5.35047L3.68616 4.52563L1.20057 7.01355C0.431898 7.78261 0.000214912 8.82552 0.000488411 9.91286C0.00076191 11.0002 0.43297 12.0429 1.20203 12.8116C1.97109 13.5803 3.01401 14.0119 4.10135 14.0117C5.1887 14.0114 6.2314 13.5792 7.00007 12.8101L9.48624 10.324L8.66141 9.49913L6.17349 11.9853Z" />
      <path d="M12.8007 1.21227C12.4211 0.830083 11.9694 0.52702 11.4719 0.320647C10.9743 0.114274 10.4408 0.0086935 9.9021 0.0100241C9.36372 0.00859396 8.8304 0.113903 8.33297 0.319862C7.83555 0.525822 7.38388 0.828345 7.0041 1.20994L4.51501 3.69669L5.33985 4.52211L7.82777 2.03594C8.09934 1.76274 8.42241 1.54612 8.77827 1.39863C9.13413 1.25113 9.51572 1.1757 9.90093 1.17669C10.4809 1.17688 11.0478 1.34903 11.53 1.67136C12.0122 1.99369 12.3879 2.45174 12.6098 2.9876C12.8317 3.52347 12.8898 4.11309 12.7766 4.68193C12.6634 5.25077 12.3842 5.77329 11.9741 6.18344L9.48793 8.66961L10.3133 9.49502L12.7995 7.01002C13.5672 6.24066 13.9984 5.19825 13.9987 4.11139C13.9989 3.02453 13.5681 1.98194 12.8007 1.21227Z" />
      <path d="M8.65981 4.52599L4.51562 8.67017L5.34058 9.49512L9.48477 5.35095L8.65981 4.52599Z" />
    </SvgIcon>
  );
};

export default FiRsLink;
