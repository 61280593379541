import { SvgIcon, SvgIconProps } from '@kitalulus/web-ui-kit';

const FavoriteIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path d="M12.0004 21C11.7346 21.0016 11.4791 20.8972 11.2904 20.71L3.52036 12.93C1.49191 10.8804 1.49191 7.57966 3.52036 5.53002C5.5655 3.49065 8.87522 3.49065 10.9204 5.53002L12.0004 6.61002L13.0804 5.53002C15.1255 3.49065 18.4352 3.49065 20.4804 5.53002C22.5088 7.57966 22.5088 10.8804 20.4804 12.93L12.7104 20.71C12.5216 20.8972 12.2662 21.0016 12.0004 21Z" />
    </SvgIcon>
  );
};

export default FavoriteIcon;
