import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { createLocalStoragePersist } from '~/utils/redux-persist';

// clearable means the state will be reset to default on every logout
export type VisibilityData = {
  value?: boolean;
  clearable?: boolean; // clear on logout
};

export type VisibilityKeys =
  | 'candidateRecommendationBanner'
  | 'companyVerificationStatusBanner'
  | 'interviewSessionsBanner';

export type VisibilityState = Record<VisibilityKeys, VisibilityData>;

export type VisibilityKeyValueParam<T = VisibilityKeys> = {
  key: T;
  value: boolean | ((currValue: boolean) => boolean);
};

const VISIBILITY_KEY = 'visibility';
const initialState: VisibilityState = {
  candidateRecommendationBanner: {
    value: true,
    clearable: false,
  },
  companyVerificationStatusBanner: {
    value: true,
    clearable: false,
  },
  interviewSessionsBanner: {
    value: true,
    clearable: false,
  },
};

export const visibilitySlice = createSlice({
  name: 'visibility',
  initialState,
  reducers: {
    setVisibility: <T extends VisibilityKeys>(
      state: VisibilityState,
      action: PayloadAction<VisibilityKeyValueParam<T>>,
    ) => {
      const currValue = state[action.payload.key].value ?? false;
      const newValue =
        typeof action.payload.value === 'function'
          ? action.payload.value(currValue)
          : action.payload.value;
      state[action.payload.key] = {
        ...state[action.payload.key],
        value: newValue,
      };
    },
    clearVisibility: (state) => {
      for (const key in state) {
        const typedKey = key as VisibilityKeys;
        const keyVal = state[typedKey];
        state[typedKey] = {
          ...initialState[typedKey],
          // reset value if it's clearable
          value: keyVal.clearable ? initialState[typedKey].value : keyVal.value,
        };
      }
    },
  },
});

export const { setVisibility, clearVisibility } = visibilitySlice.actions;

export default createLocalStoragePersist(
  VISIBILITY_KEY,
  visibilitySlice.reducer,
);
