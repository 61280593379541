import {
  Avatar,
  Grid,
  Stack,
  brandColors,
  useTheme,
  StackProps,
  Typography,
} from '@kitalulus/web-ui-kit';
import { AvatarGroup } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ArrowForwardIosIcon from '~/components/Icons/ArrowForwardIosIcon';
import LocationIcon from '~/components/Icons/LocationIcon';
import { useAppSelector } from '~/hooks/use-store';

const Header = ({ sx, ...props }: StackProps) => {
  const theme = useTheme();
  const { applicantAcceptDialog } = useAppSelector((state) => state.dialogs);
  const { t } = useTranslation();

  if (!applicantAcceptDialog?.content) return null;
  const { applicants } = applicantAcceptDialog.content;
  const isBulkAccept = applicants.length > 1;

  return (
    <Stack
      direction={{ xs: isBulkAccept ? 'column' : 'row', sm: 'row' }}
      justifyContent="space-between"
      alignItems={{ xs: 'start', sm: isBulkAccept ? 'center' : 'start' }}
      sx={{
        boxShadow: theme.customShadows.card,
        bgcolor: brandColors.grey[200],
        borderRadius: '16px',
        p: 3,
        ...sx,
      }}
      gap={3}
      {...props}
    >
      <AvatarGroup
        max={6}
        spacing="small"
        componentsProps={{
          additionalAvatar: {
            sx: {
              zIndex: applicants.length,
              fontSize: 14,
              color: '#000',
              bgcolor: brandColors.neutral[300],
            },
          },
        }}
      >
        {applicants.map((applicant, i) => (
          <Avatar
            key={applicant.id}
            src={applicant.image}
            displayName={applicant.name ?? ''}
            sx={{
              zIndex: i,
            }}
          />
        ))}
      </AvatarGroup>
      <Grid container spacing={2}>
        <Grid item sm={6}>
          {isBulkAccept && <BulkDisplay total={applicants.length} />}
          {!isBulkAccept && (
            <SingleDisplay
              name={applicants[0].name}
              location={applicants[0].location}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <Stack
            direction="row"
            gap={1}
            justifyContent={{ xs: 'start', sm: 'end' }}
            alignItems="center"
            height="100%"
          >
            <Typography variant="body2" sx={{ flexBasis: 100 }}>
              {t('applicant:acceptDialog.forPosition')}
            </Typography>
            <Typography
              component={'div'}
              variant="body2"
              sx={{ fontWeight: 700 }}
            >
              {applicantAcceptDialog?.content?.vacancy.positionName}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
};

const BulkDisplay = ({ total }: { total: number }) => {
  const { t } = useTranslation();
  return (
    <Stack direction="row" alignItems="center" height={'100%'} gap={2}>
      <ArrowForwardIosIcon sx={{ color: brandColors.neutral[600] }} />
      <Typography
        component="div"
        variant="subtitle2"
        dangerouslySetInnerHTML={{
          __html: t('applicant:acceptDialog.bulkMessage', { total }),
        }}
      />
    </Stack>
  );
};

const SingleDisplay = ({
  name,
  location,
}: {
  name: string;
  location: string;
}) => {
  return (
    <Stack>
      <Typography
        variant="subtitle2"
        sx={{
          fontWeight: 600,
          color: brandColors.grey[800],
          wordBreak: 'break-all',
        }}
      >
        {name}
      </Typography>
      <Stack direction="row" alignItems="center" gap={0.5}>
        <LocationIcon sx={{ color: brandColors.grey[600], fontSize: 16 }} />
        <Typography variant="body2">{location}</Typography>
      </Stack>
    </Stack>
  );
};

export default Header;
