import { useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ConfirmDialogV1 from '~/components/Dialogs/ConfirmDialogV1';
import { clearDialog } from '~/store/views/dialogs-slice';

import {
  VacancyRepostDialogAction,
  VacancyRepostDialogActionData,
  VacancyRepostDialogProps,
} from './VacancyRepostDialog.types';
import * as mpe from '~/utils/mixpanel';

const VacancyRepostDialog = ({
  content,
  ...props
}: VacancyRepostDialogProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleCancel = useCallback(() => {
    if (content?.onCancel) content?.onCancel();
    dispatch(clearDialog('vacancyRepostDialog'));
  }, []);

  const handleOpen = useCallback(() => {
    handleCancel();
    mpe.vacancyRepostConfirm({ vacancyCode: content?.vacancy?.code ?? '' });
    navigate(`/vacancy/${content?.vacancy?.id}/repost`);
  }, [content?.vacancy?.id]);

  const handleClose = useCallback(() => {
    if (content?.onAccept) content?.onAccept();
    handleCancel();
  }, []);

  const dataByAction = useMemo<
    Record<VacancyRepostDialogAction, VacancyRepostDialogActionData>
  >(() => {
    return {
      open: {
        title: t('vacancy:dialog.openRepost.title'),
        body: t('vacancy:dialog.openRepost.message'),
        acceptProps: {
          onClick: handleOpen,
          color: 'primary',
          children: t('common:continue'),
        },
      },
      close: {
        title: t('vacancy:dialog.closeRepost.title'),
        body: t('vacancy:dialog.closeRepost.message'),
        acceptProps: {
          onClick: handleClose,
          color: 'error',
          children: t('common:yesExit'),
        },
      },
    };
  }, []);

  if (!content) return null;

  const data = dataByAction[content.action];

  return (
    <ConfirmDialogV1
      {...props}
      data-test-id="ctVacancyRepostDialog"
      title={data.title}
      body={data.body}
      closeButtonProps={{
        sx: {
          top: 21,
        },
      }}
      acceptProps={data.acceptProps}
      cancelProps={{
        onClick: handleCancel,
        children: t('common:cancelled'),
      }}
      PaperProps={{
        sx: {
          px: { xs: '18px', lg: 3 },
          py: { xs: 3, lg: 3 },
          maxWidth: { lg: '480px' },
          gap: 3,
          '& > div': { p: 0 },
          '& .MuiDialogActions-root > button:last-of-type': {
            marginLeft: '12px',
          },
          '& .MuiDialogContent-root > div > div:last-of-type': {
            gap: { xs: '12px', lg: 3 },
          },
        },
      }}
    />
  );
};

export default VacancyRepostDialog;
