import {
  Dialog,
  DialogTitle,
  Stack,
  Typography,
  DialogContent,
  DialogProps,
  brandColors,
  IconButton,
} from '@kitalulus/web-ui-kit';
import { useTranslation } from 'react-i18next';
import CloseIcon from '~/components/Icons/CloseIcon';
import { useAppDispatch, useAppSelector } from '~/hooks/use-store';
import { clearDialog } from '~/store/views/dialogs-slice';

const ApplicantStatusLegendDialog = ({ ...props }: DialogProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { tabs } = useAppSelector((state) => state.applicants);

  const [firstTab] = tabs;

  const handleCloseDialog = () => {
    dispatch(clearDialog('applicantStatusLegendDialog'));
  };

  return (
    <Dialog
      {...props}
      data-test-id="ctApplicantStatusDefinition"
      maxWidth="xs"
      onClose={handleCloseDialog}
      PaperProps={{ sx: { p: { xs: 0, sm: 2 }, m: 2 } }}
      scroll="body"
    >
      <Stack>
        {/* container */}
        <IconButton
          sx={{ position: 'absolute', top: 10, right: 10 }}
          size="small"
          onClick={handleCloseDialog}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle>
          <Typography
            variant="h5"
            component="div"
            sx={{ color: brandColors.grey[900], maxWidth: '90%' }}
          >
            {t('applicant:statusLegend')}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Stack gap={3} justifyContent="start" alignItems="start" mt={2}>
            {firstTab?.nextLevelMenuTab?.map((tab) => {
              return (
                <Stack key={tab?.displayName} alignItems="start" gap={2}>
                  <Typography
                    variant="subtitle2"
                    component="div"
                    sx={{
                      color: tab?.countTextColor,
                      backgroundColor: tab?.countBgColor,
                      py: 0.5,
                      px: 2,
                      borderRadius: '8px',
                    }}
                  >
                    {tab?.displayName}
                  </Typography>
                  <Typography variant="subtitle2" component="div">
                    {tab?.description}
                  </Typography>
                </Stack>
              );
            })}
          </Stack>
        </DialogContent>
      </Stack>
    </Dialog>
  );
};

export default ApplicantStatusLegendDialog;
