export enum ACCOUNT_TABS {
  PROFILE = 'PROFILE',
  USER_PROFILE = 'USER-PROFILE',
  CHANGE_PASSWORD = 'CHANGE-PASSWORD',
  NOTIFICATION = 'NOTIFICATION',
  PACKAGE_DETAIL = 'PACKAGE-DETAIL',
  LOGOUT = 'LOGOUT',
}

export enum ONBOARDING_STEPS {
  COMPANY_FORM = 'COMPANY_FORM',
  SUBSCRIPTION_OFFER = 'SUBSCRIPTION_OFFER',
}

export enum APPLICANT_TAB_PRIMARY {
  ALL = 'ALL',
  AI_RECOMMENDATION = 'AI_RECOMMENDATION',
  INVITED_CANDIDATE = 'INVITED_CANDIDATE',
}

export enum APPLICANT_NO_RESULTS_VARIANTS {
  NO_VACANCY = 'NO_VACANCY',
  NO_APPLICANT = 'NO_APPLICANT',
  NO_SEARCH_RESULTS = 'NO_SEARCH_RESULTS',
  TOTAL_PENDING = 'TOTAL_PENDING',
  ON_PROGRESS = 'ON_PROGRESS',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  SAVED = 'SAVED',
  WITHDRAWN = 'WITHDRAWN',
}

export enum FETCH_STATUS {
  IDLE = 'IDLE',
  PENDING = 'PENDING',
  RESOLVED = 'RESOLVED',
  FAILED = 'FAILED',
}

export enum AUTH_STATUS {
  PENDING = 'PENDING',
  AUTHENTICATED = 'AUTHENTICATED',
  UNAUTHENTICATED = 'UNAUTHENTICATED',
}

export enum SORT {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum PACKAGE {
  NOS_STD = 'NOS_STD',
  FREE_PLUS = 'FREE_PLUS',
}

export const AVATAR_DEFAULT = '/icon/default-user.png';

export const AUTOMATION_USER_AGENT = 'KLAutomationUA';

export const SCROLL_THRESHOLD_PERCENTAGE = 0.9;

export const SEARCH_DEBOUNCE_DURATION = 500;

export const ROWS_PER_PAGE_OPTIONS = [5, 10, 25];
