import { Box, Typography } from '@kitalulus/web-ui-kit';

const PrivacyPolicy = () => {
  return (
    <>
      <Typography variant="body1" sx={{ py: '8px' }} textAlign="justify">
        Terima kasih telah memilih untuk menjadi bagian dari komunitas kami di
        PT Kita Lulus Internasional (“KitaLulus” atau “kami”). Saat Anda
        mengunjungi situs{' '}
        <a href="https://kitalulus.com/" target="_blank" rel="noreferrer">
          https://kitalulus.com/
        </a>{' '}
        (“Situs), aplikasi (“Aplikasi”), atau secara umum menggunakan layanan
        apa pun yang kami sediakan (“Layanan”), (“Layanan”), kami berkomitmen
        untuk melindungi informasi pribadi setiap pengguna Layanan. Dengan telah
        menggunakanLayanan kami, Anda mengakui bahwa Anda telah membaca dan
        memahami Kebijakan Privasi yang kami miliki. Jika Anda merasa keberatan
        dengan salah satu atau beberapa ketentuan pada Kebijakan Privasi,
        dimohon untuk tidak bergabung, mengakses, melihat, mengunduh, atau
        menggunakan layanan apapun yang ditawarkan olehLayanan.
      </Typography>

      <Typography variant="body1" sx={{ py: '8px' }} textAlign="justify">
        KitaLulus berhak untuk mengubah, menambah, menghapus atau mengubah
        Kebijakan Privasi sewaktu-waktu tanpa pemberitahuan sebelumnya. Anda
        disarankan untuk meninjau Kebijakan Privasi secara berkala. Apabila di
        kemudian hari anda merasa keberatan dengan perubahan Kebijakan Privasi
        padaLayanan, sebaiknya Anda menghentikan penggunaan. Penggunaan yang
        berlanjut merupakan bentuk penerimaan Anda atas semua perubahan yang
        diterapkan pada Kebijakan Privasi ini.
      </Typography>

      <Typography variant="body1" sx={{ py: '8px' }} textAlign="justify">
        Pemberitahuan privasi ini berlaku untuk semua informasi yang dikumpulkan
        melalui Layanan kami (yang sebagaimana dijelaskan di atas, termasuk
        Situs dan Aplikasi kami), serta semua layanan, penjualan, pemasaran,
        atau acara terkait.
      </Typography>

      <Typography variant="body1" sx={{ py: '8px' }} textAlign="justify">
        Kami telah dan akan selalu menyesuaikan Kebijakan Privasi ini dengan
        peraturan perundang-undangan yang berlaku.
      </Typography>

      <Typography variant="body1" sx={{ py: '8px' }} textAlign="justify">
        Harap baca Kebijakan Privasi ini dengan cermat karena ini akan membantu
        Anda untuk memahami apa yang kami lakukan dengan informasi atau data
        yang kami kumpulkan.
      </Typography>

      <Typography
        variant="body1"
        sx={{ py: '16px' }}
        textAlign="justify"
        fontWeight={600}
      >
        Tujuan Pengumpulan dan Penggunaan Informasi dan Data Pribadi
      </Typography>

      <Typography variant="body1" textAlign="justify">
        Tujuan KitaLulus untuk mengumpulkan dan menggunakan informasi dan data
        di Layanan termasuk namun tidak terbatas pada hal-hal berikut:
      </Typography>

      <ul style={{ margin: 0, padding: '0 24px' }}>
        <li>
          Mendapatkan statistik penggunaan Layanan serta menganalisisnya demi
          membantu pengembangan Layanan lebih lanjut;
        </li>
        <li>
          Membantu KitaLulus dalam mengembangkan pemasaran dan promosi di
          kemudian hari atau penggunaan semacamnya;
        </li>
        <li>
          Membantu KitaLulus dalam mengembangkan pemasaran dan promosi di
          kemudian hari atau penggunaan semacamnya;
        </li>
        <li>Memastikan keresmian dari data-data yang diberikan;</li>
        <li>
          Untuk mengetahui informasi kontak pengguna yang mungkin akan kami
          hubungi terkait dengan Layanan di masa yang akan datang;
        </li>
        <li>Untuk mengelola Layanan di antara para pengguna;</li>
        <li>
          Untuk mendeteksi dan mencegah adanya pelanggaran terhadap Syarat dan
          Ketentuan;{' '}
        </li>
        <li>Untuk memantau dan mengembangkan Layanan.</li>
      </ul>

      <Typography
        variant="body1"
        sx={{ py: '8px', pt: '16px' }}
        textAlign="justify"
        fontWeight={600}
      >
        1. INFORMASI YANG KAMI KUMPULKAN
      </Typography>

      <Typography variant="body1" textAlign="justify" fontStyle="italic">
        Singkatnya: Kami dapat mengumpulkan informasi yang Anda berikan kepada
        kami, informasi perangkat, dan informasi mengenai interaksi anda dengan
        Layanan.
      </Typography>

      <Typography variant="body1" textAlign="justify" sx={{ py: '8px' }}>
        Kami mengumpulkan informasi yang Anda berikan secara sukarela kepada
        kami saat Anda mendaftar di Layanan (terkecuali password). Kami
        mengumpulkan informasi tentang interaksi Anda dengan Layanan, pilihan
        yang Anda buat, serta produk dan fitur yang Anda gunakan. Informasi yang
        kami kumpulkan tidak selalu sama pada Situs dan Aplikasi, bergantung
        dengan kebutuhan dan kepentingan kami pada masing-masing Layanan.
        Informasi yang kami kumpulkan dapat mencakup hal-hal berikut:
      </Typography>

      <Typography variant="body1" textAlign="justify" sx={{ py: '8px' }}>
        <b>Data Login Menggunakan Media Sosial</b>. Kami dapat memberi Anda opsi
        untuk mendaftar dengan kami menggunakan data pada akun media sosial yang
        Anda miliki, seperti Facebook, Twitter, atau akun media sosial lainnya.
        Jika Anda memilih untuk mendaftar dengan cara ini, kami akan
        mengumpulkan informasi yang dijelaskan di bagian yang disebut {'"'}
        BAGAIMANA KAMI MENANGANI LOGIN MENGGUNAKAN MEDIA SOSIAL?
        {'"'}.
      </Typography>

      <Typography variant="body1" textAlign="justify" sx={{ py: '8px' }}>
        <b>Data-data yang Anda Input pada Layanan</b>. Kami mengumpulkan nama
        perusahaan; nomor telepon; alamat email; nama pengguna; dan informasi
        serupa lainnya. Semua informasi yang Anda berikan kepada kami harus
        benar, lengkap, dan akurat, dan apabila terdapat perubahan, Anda dapat
        mengubahnya.
      </Typography>

      <Typography variant="body1" textAlign="justify" sx={{ py: '8px' }}>
        <b>Data pada Curriculum Vitae (CV) atau Media Sosial</b>. Kami
        sewaktu-waktu dapat mengumpulkan informasi pribadi Anda yang tersedia
        pada akun media sosial Anda atau pun pada CV yang telah Anda sediakan
        kepada kami. Pengumpulan informasi pribadi Anda ini semata-mata
        dilakukan untuk mengonfirmasi kebenaran dan kekonsistenan informasi
        pribadi Anda serta pembuatan akun Layanan oleh kami untuk Anda atas
        persetujuan eksplisit Anda.
      </Typography>

      <Typography variant="body1" textAlign="justify" sx={{ py: '8px' }}>
        {' '}
        <b>Jawaban atas Pertanyaan Lamaran Kerja</b>. Saat melamar pekerjaan
        menggunakan Layanan, pelamar akan dihadapkan dengan beberapa pertanyaan
        yang bertujuan untuk mengetahui kompetensinya terhadap lowongan kerja
        tersebut. Jawaban atas pertanyaan-pertanyaan yang diajukan akan kami
        simpan agar Perusahaan dapat dengan mudah mengakses dan menilainya.
      </Typography>

      <Typography variant="body1" sx={{ py: '16px' }} textAlign="justify">
        Semua informasi pribadi yang Anda berikan kepada kami harus benar,
        lengkap, dan akurat, dan Anda harus memberi tahu kami jika ada perubahan
        pada informasi pribadi tersebut.
      </Typography>

      <Typography variant="body1" sx={{ py: '16px' }} textAlign="justify">
        Informasi yang dapat secara otomatis dikumpulkan
      </Typography>

      <Typography variant="body1" sx={{ py: '8px' }} textAlign="justify">
        Kami secara otomatis mengumpulkan informasi tertentu saat Anda
        mengunjungi, menggunakan, atau menavigasi Layanan. Informasi ini tidak
        mengungkapkan identitas spesifik Anda (seperti nama atau informasi
        kontak Anda) tetapi dapat mencakup informasi perangkat dan penggunaan,
        seperti alamat IP, browser dan karakteristik perangkat, sistem operasi,
        preferensi bahasa, URL rujukan, nama perangkat, negara, lokasi,
        informasi tentang siapa dan kapan Anda menggunakan Layanan kami dan
        informasi teknis lainnya. Informasi ini terutama diperlukan untuk
        menjaga keamanan dan pengoperasian Layanan kami, dan untuk tujuan
        analitik dan pelaporan internal kami.
      </Typography>

      <Typography variant="body1" sx={{ py: '8px' }} textAlign="justify">
        <b>Log dan Data Penggunaan.</b> Data log dan penggunaan terkait dengan
        layanan, penggunaan diagnostik, dan informasi kinerja yang dikumpulkan
        oleh server kami secara otomatis saat Anda mengakses atau menggunakan
        Layanan kami dan yang kami rekam dalam file log. Bergantung pada
        bagaimana Anda berinteraksi dengan Layanan kami, data log ini dapat
        mencakup alamat IP Anda, informasi perangkat, jenis dan pengaturan
        browser, dan informasi tentang aktivitas Anda di Layanan (seperti
        tanggal/waktu yang terkait dengan penggunaan Anda, halaman dan file yang
        dilihat, pencarian dan tindakan lain yang Anda lakukan seperti fitur
        yang Anda gunakan).
      </Typography>

      <Typography variant="body1" sx={{ py: '8px' }} textAlign="justify">
        <b>Data Perangkat.</b> Kami mengumpulkan data perangkat seperti
        informasi tentang komputer, ponsel, tablet, atau perangkat lain yang
        Anda gunakan untuk mengakses Layanan. Tergantung pada perangkat yang
        digunakan, data perangkat ini dapat mencakup informasi seperti nomor
        identifikasi perangkat, lokasi, model perangkat keras, penyedia layanan
        Internet dan/atau operator seluler, informasi konfigurasi sistem
        operasi.
      </Typography>

      <Typography variant="body1" sx={{ py: '8px' }} textAlign="justify">
        <b>Data Lokasi.</b> Kami mengumpulkan data informasi seperti informasi
        tentang lokasi perangkat Anda, yang dapat berupa tepat atau tidak tepat.
        Banyaknya informasi yang kami kumpulkan tergantung pada jenis pengaturan
        perangkat yang Anda gunakan untuk mengakses Layanan. Misalnya, kami
        dapat menggunakan GPS dan teknologi lain untuk mengumpulkan data
        geolokasi yang memberi tahu kami lokasi Anda saat ini (berdasarkan
        alamat IP Anda). Anda dapat memilih untuk tidak mengizinkan kami
        mengumpulkan informasi ini dengan menolak akses ke informasi tersebut
        atau dengan menonaktifkan pengaturan Lokasi di perangkat Anda. Namun
        jika Anda memilih untuk menolak akses tersebut, Anda mungkin tidak dapat
        menggunakan aspek-aspek tertentu dari Layanan.
      </Typography>

      <Typography variant="body1" sx={{ py: '16px' }} textAlign="justify">
        Informasi yang dikumpulkan dari sumber lain
      </Typography>

      <Typography variant="body1" sx={{ py: '8px' }} textAlign="justify">
        Untuk meningkatkan kemampuan kami dalam memberikan pemasaran, penawaran,
        dan layanan yang relevan kepada Anda dan memperbarui catatan kami, kami
        dapat memperoleh informasi tentang Anda dari sumber lain, seperti basis
        data publik, program afiliasi, penyedia data, platform media sosial,
        maupun dari pihak ketiga lainnya. Informasi ini termasuk namun tidak
        terbatas pada alamat surat, jabatan, alamat email, nomor telepon, profil
        media sosial, dan URL media sosial. Jika Anda berinteraksi dengan kami
        di platform media sosial menggunakan akun media sosial Anda (misalnya
        Facebook atau Twitter), kami menerima informasi pribadi tentang Anda
        seperti nama, alamat email, dan jenis kelamin Anda. Setiap informasi
        pribadi yang kami kumpulkan dari akun media sosial Anda bergantung pada
        pengaturan privasi akun media sosial Anda.
      </Typography>

      <Typography
        variant="body1"
        sx={{ py: '8px', pt: '16px' }}
        textAlign="justify"
        fontWeight={600}
      >
        2. BAGAIMANA KAMI MENGGUNAKAN INFORMASI PRIBADI ANDA?
      </Typography>

      <Typography variant="body1" textAlign="justify" fontStyle="italic">
        Secara singkat: Kami memproses informasi Anda untuk tujuan tertentu
        berdasarkan kepentingan bisnis yang sah, kepatuhan terhadap kewajiban
        hukum, dan/atau persetujuan Anda.
      </Typography>

      <Typography variant="body1" sx={{ py: '8px' }} textAlign="justify">
        Kami menggunakan informasi pribadi yang dikumpulkan melalui Layanan Kami
        untuk berbagai tujuan yang dijelaskan di bawah ini. Kami memproses
        informasi pribadi Anda untuk tujuan tersebut dengan mengandalkan
        kepentingan bisnis kami yang sah, untuk mengadakan atau melakukan
        kontrak dengan Anda, dengan persetujuan Anda, dan/atau untuk mematuhi
        kewajiban hukum kami.
      </Typography>

      <Typography variant="body1" textAlign="justify">
        Kami menggunakan informasi yang kami kumpulkan atau terima dengan
        tujuan:
      </Typography>

      <ul style={{ margin: 0, padding: '0 24px' }}>
        <li>
          Untuk memudahkan pembuatan akun dan proses masuk ke Layanan
          menggunakan akun pribadi. Jika Anda memilih untuk menautkan akun Anda
          dengan kami ke akun pihak ketiga (seperti akun Google), kami
          menggunakan informasi yang Anda izinkan untuk kami kumpulkan dari
          pihak ketiga tersebut untuk memfasilitasi pembuatan akun dan proses
          masuk untuk kinerja kontrak. Lihat bagian di bawah berjudul {'"'}
          BAGAIMANA KAMI MENANGANI LOGIN MENGGUNAKAN MEDIA SOSIAL?{'"'} untuk
          informasi lebih lanjut.
        </li>
        <li>
          Dalam kondisi tertentu, kami dapat membuatkan Anda akun pada Layanan
          untuk tujuan-tujuan tertentu, dengan catatan bahwa kami telah
          mendapatkan persetujuan secara eksplisit dari Anda. Kami menyatakan
          bahwa hanya akan mencantumkan informasi pribadi Anda sesuai dengan
          yang tersedia pada media sosial Anda (contoh: LinkedIn) atau CV yang
          telah Anda sediakan kepada kami.
        </li>
        <li>
          Untuk memposting testimonial. Kami memposting testimonial Aplikasi di
          media sosial kami yang mungkin berisi informasi pribadi. Jika Anda
          ingin memperbarui, atau menghapus testimonial Anda, silakan hubungi
          kami di kakkita@kitalulus.com dan pastikan untuk menyertakan nama
          Anda, nama Perusahaan, lokasi testimonial, dan informasi kontak.
        </li>
        <li>
          Untuk meminta umpan balik. Kami dapat menggunakan informasi Anda untuk
          meminta umpan balik dan menghubungi Anda tentang penggunaan Layanan
          kami oleh Anda.
        </li>
        <li>
          Mengelola pengundian hadiah dan kompetisi. Kami dapat menggunakan
          informasi Anda untuk mengelola pengundian hadiah dan kompetisi ketika
          Anda memilih untuk berpartisipasi dalam kompetisi kami.
        </li>
        <li>
          Memenuhi dan mengelola pesanan Anda. Kami dapat menggunakan informasi
          Anda untuk memenuhi dan mengelola pesanan, pembayaran, pengembalian,
          dan pertukaran Anda yang dilakukan melalui Layanan.
        </li>
        <li>
          Untuk menyampaikan dan memfasilitasi penyampaian layanan kepada
          pengguna. Kami dapat menggunakan informasi Anda untuk menyediakan
          layanan yang diminta terhadap Anda.
        </li>
        <li>
          Untuk menanggapi pertanyaan pengguna. Kami dapat menggunakan informasi
          Anda untuk menanggapi pertanyaan tertentu yang diajukan oleh pengguna
          Layanan dan memecahkan masalah yang mungkin Anda miliki dalam
          penggunaanLayanan kami.
        </li>
        <li>
          Untuk mengirimkan iklan yang bertarget kepada Anda. Kami dapat
          menggunakan informasi Anda untuk mengembangkan dan menampilkan konten
          dan iklan yang dipersonalisasi, yang disesuaikan dengan minat dan/atau
          lokasi Anda dan untuk mengukur efektivitasnya.
        </li>
      </ul>

      <Typography
        variant="body1"
        sx={{ py: '8px', pt: '16px' }}
        textAlign="justify"
        fontWeight={600}
      >
        3. DENGAN SIAPA INFORMASI ANDA DIBERIKAN
      </Typography>

      <Typography
        variant="body1"
        sx={{ pb: '16px' }}
        textAlign="justify"
        fontStyle="italic"
      >
        Secara singkat: Kami hanya membagikan informasi dengan persetujuan Anda,
        untuk mematuhi undang-undang, untuk menyediakan layanan kepada Anda,
        untuk melindungi hak Anda, atau untuk memenuhi kewajiban bisnis.
      </Typography>

      <Typography variant="body1" textAlign="justify">
        Kami dapat memproses atau membagikan data Anda yang kami miliki
        berdasarkan hal-hal berikut:
      </Typography>

      <ul style={{ margin: 0, padding: '0 24px' }}>
        <li>
          <b>Persetujuan:</b> Kami dapat memproses data Anda jika Anda telah
          memberi kami persetujuan khusus untuk menggunakan informasi pribadi
          Anda untuk tujuan tertentu.
        </li>
        <li>
          <b>Kepentingan yang Sah:</b> Kami dapat memproses data Anda jika
          diperlukan secara wajar untuk mencapai kepentingan bisnis kami yang
          sah.
        </li>
        <li>
          <b>Pelaksanaan Kontrak:</b> Jika kami telah menandatangani kontrak
          dengan Anda, kami dapat memproses informasi pribadi Anda untuk
          memenuhi persyaratan kontrak kami.
        </li>
        <li>
          <b>Kewajiban Hukum:</b> Kami dapat mengungkapkan informasi Anda di
          mana kami secara hukum diharuskan melakukannya untuk mematuhi hukum
          yang berlaku, permintaan pemerintah, proses peradilan, perintah
          pengadilan, atau proses hukum.
        </li>
        <li>
          <b>Kepentingan Vital:</b> Kami dapat mengungkapkan informasi Anda jika
          kami yakin perlu untuk menyelidiki, mencegah, atau mengambil tindakan
          terkait potensi pelanggaran terhadap kebijakan kami, dugaan penipuan,
          situasi yang melibatkan potensi ancaman terhadap keselamatan siapa pun
          dan aktivitas ilegal, atau sebagai bukti dalam litigasi di mana kita
          terlibat.
        </li>
      </ul>

      <Typography variant="body1" sx={{ pt: '16px' }} textAlign="justify">
        Lebih khusus lagi, kami mungkin perlu memproses data Anda atau
        membagikan informasi pribadi Anda dalam situasi berikut:
      </Typography>

      <ul style={{ margin: 0, padding: '0 24px' }}>
        <li>
          <b>Transfer Bisnis.</b> Kami dapat membagikan atau mentransfer
          informasi Anda sehubungan dengan, atau selama negosiasi, merger,
          penjualan aset perusahaan, pembiayaan, atau akuisisi semua atau
          sebagian dari bisnis kami ke perusahaan lain.
        </li>
        <li>
          <b>Vendor, Konsultan, dan Penyedia Layanan Pihak Ketiga Lainnya.</b>{' '}
          Kami dapat membagikan data Anda dengan vendor pihak ketiga, penyedia
          layanan, atau agen yang melakukan layanan untuk kami atau atas nama
          kami dan memerlukan akses ke informasi tersebut untuk melakukan
          pekerjaan itu. Contohnya meliputi: analisis data, pengiriman email,
          layanan hosting, dan upaya pemasaran. Kami dapat mengizinkan pihak
          ketiga yang dipilih untuk menggunakan teknologitracking pada Layanan,
          yang akan memungkinkan mereka untuk mengumpulkan data atas nama kami
          tentang bagaimana Anda berinteraksi dengan Layanan kami dari waktu ke
          waktu. Informasi ini dapat digunakan untuk, antara lain, menganalisis
          dan melacak data, menentukan popularitas konten, halaman, atau fitur
          tertentu, dan lebih memahami aktivitas Anda di dalam Layanan. Kecuali
          dijelaskan dalam pemberitahuan ini, kami tidak membagikan, menjual,
          menyewakan, atau memperdagangkan informasi Anda dengan pihak ketiga
          untuk tujuan promosi mereka. Layanan pihak ketiga yang kami maksud
          adalah:
          <Box sx={{ ul: { m: 0, p: '0 24px' }, li: { py: '8px' } }}>
            <ul>
              <li>
                Iklan Media Sosial
                <br /> Iklan Facebook dan Instagram
              </li>
              <li>
                Mobile Analytics
                <br /> Google Analytics for Firebase, Google Analytics dan
                Google Tag Manager
              </li>
              <li>
                Website Performance Monitoring
                <br /> Firebase Crash Reporting
              </li>
            </ul>
          </Box>
        </li>
        <li>
          <b>Afiliasi.</b> Kami dapat membagikan informasi Anda dengan afiliasi
          kami, dalam hal ini kami akan meminta afiliasi tersebut untuk
          menghormati pemberitahuan privasi ini. Afiliasi termasuk perusahaan
          induk kami dan anak perusahaan, mitra usaha patungan, atau perusahaan
          lain yang kami kendalikan atau yang berada di bawah kendali yang sama
          dengan kami.
        </li>
        <li>
          <b>Pengguna lain.</b> Saat Anda membagikan informasi pribadi atau
          berinteraksi dengan area publik Layanan, informasi pribadi tersebut
          dapat dilihat oleh semua pengguna dan dapat disediakan untuk publik di
          luar Layanan selamanya. Jika Anda berinteraksi dengan pengguna lain
          dari Layanan kami dan mendaftar untuk Layanan kami melalui jejaring
          sosial (seperti Facebook), kontak yang Anda miliki di media sosial
          akan melihat nama, foto profil, dan deskripsi aktivitas Anda. Demikian
          pula, pengguna lain akan dapat melihat deskripsi aktivitas Anda,
          berkomunikasi dengan Anda dalam Layanan kami, dan melihat profil Anda.
        </li>
      </ul>

      <Typography
        variant="body1"
        sx={{ py: '8px', pt: '16px' }}
        textAlign="justify"
        fontWeight={600}
      >
        4. PILIHAN DAN AKSES INFORMASI PRIBADI
      </Typography>

      <Typography variant="body1" textAlign="justify">
        Kami memiliki tujuan untuk untuk memperjelas informasi pribadi apa yang
        kami kumpulkan dan tetap memberikan Anda hak untuk membuat pilihan untuk
        mengubah atau menghapus informasi pribadi yang telah Anda berikan. Anda
        berhak untuk:
      </Typography>

      <Box sx={{ ol: { m: 0, p: '0 24px' } }}>
        <ol>
          <li>Mengakses informasi pribadi pada profil Anda;</li>
          <li>
            Mengubah/mengoreksi informasi pribadi yang terdapat pada profil
            Anda;
          </li>
          <li>Menghapus akun atau profil Anda;</li>
          <li>Menunda atau membatasi pemrosesan data pribadi.</li>
          <li>
            Memilih untuk tidak berlangganan materi pemasaran dari KitaLulus.
          </li>
        </ol>
      </Box>

      <Typography variant="body1" textAlign="justify" sx={{ py: '8px' }}>
        Anda dapat memilih untuk menghapus Akun dan segala hal yang terdapat
        pada profil Anda. Namun apabila sebelumnya Anda telah mendaftar pada
        suatu lowongan kerja pada Layanan, Perusahaan akan tetap memiliki
        informasi yang terdapat pada profil Anda.
      </Typography>

      <Typography
        variant="body1"
        sx={{ py: '8px', pt: '16px' }}
        textAlign="justify"
        fontWeight={600}
      >
        5. APAKAH KAMI MENGGUNAKAN COOKIES DAN TEKNOLOGI PELACAKAN LAINNYA?
      </Typography>

      <Typography variant="body1" textAlign="justify">
        Kami dapat menggunakan cookie dan teknologi pelacakan serupa untuk
        mengakses atau menyimpan informasi. Apabila anda merasa keberatan dengan
        adanya kebijakan ini, Anda dapat menolaknya pada pemberitahuan mengenai
        hal ini saat anda mengunjungi Layanan.
      </Typography>

      <Typography
        variant="body1"
        sx={{ py: '8px', pt: '16px' }}
        textAlign="justify"
        fontWeight={600}
      >
        6. BAGAIMANA KAMI MENANGANI LOGIN MENGGUNAKAN MEDIA SOSIAL?
      </Typography>

      <Typography variant="body1" textAlign="justify" fontStyle="italic">
        Secara singkat: Jika Anda memilih untuk mendaftar atau masuk ke layanan
        kami menggunakan akun media sosial, kami mungkin memiliki akses ke
        informasi tertentu tentang Anda.
      </Typography>

      <Typography variant="body1" sx={{ py: '8px' }} textAlign="justify">
        Layanan kami menawarkan Anda fitur untuk mendaftar dan login menggunakan
        detail akun media sosial pihak ketiga Anda (seperti login menggunakan
        akun Google). Jika Anda memilih untuk melakukan ini, kami akan menerima
        informasi profil tertentu tentang Anda dari penyedia media sosial Anda.
      </Typography>

      <Typography variant="body1" sx={{ py: '8px' }} textAlign="justify">
        Informasi profil yang kami terima bervariasi, tergantung pada penyedia
        media sosial yang bersangkutan, tetapi biasanya akan menyertakan nama,
        alamat email, dan nomor telepon Anda. Kami menggunakan Firebase
        Authentication untuklogin menggunakan media sosial dan mengacu pada
        kebijakan privasi{' '}
        <a
          target="_blank"
          href="https://firebase.google.com/support/privacy"
          rel="noreferrer"
        >
          berikut.
        </a>
      </Typography>

      <Typography variant="body1" sx={{ py: '8px' }} textAlign="justify">
        Kami akan menggunakan informasi yang kami terima hanya untuk tujuan yang
        dijelaskan dalam pemberitahuan privasi ini atau yang dijelaskan kepada
        Anda di Layanan terkait. Harap dicatat bahwa kami tidak mengontrol, dan
        tidak bertanggung jawab atas, penggunaan lain dari informasi pribadi
        Anda oleh penyedia media sosial pihak ketiga Anda. Kami menyarankan Anda
        meninjau pemberitahuan privasi mereka untuk memahami bagaimana mereka
        mengumpulkan, menggunakan, dan membagikan informasi pribadi Anda, dan
        bagaimana Anda dapat mengatur preferensi privasi Anda di situs dan
        aplikasi mereka.
      </Typography>

      <Typography
        variant="body1"
        sx={{ py: '8px', pt: '16px' }}
        textAlign="justify"
        fontWeight={600}
      >
        7. BERAPA LAMA KAMI MENYIMPAN INFORMASI ANDA?
      </Typography>

      <Typography variant="body1" textAlign="justify" fontStyle="italic">
        Secara singkat: Kami menyimpan informasi Anda selama diperlukan untuk
        memenuhi tujuan yang diuraikan dalam pemberitahuan privasi ini kecuali
        diwajibkan lain oleh hukum yang berlaku.
      </Typography>

      <Typography variant="body1" sx={{ py: '8px' }} textAlign="justify">
        Kami hanya akan menyimpan informasi pribadi Anda selama diperlukan untuk
        tujuan yang ditetapkan dalam kebijakan privasi ini, kecuali periode
        penyimpanan yang lebih lama diperlukan dan diizinkan oleh hukum yang
        berlaku.
      </Typography>

      <Typography variant="body1" sx={{ py: '8px' }} textAlign="justify">
        Ketika kami sudah tidak memiliki kebutuhan bisnis yang sah untuk
        memproses informasi pribadi Anda, kami akan menghapus atau menganonimkan
        informasi tersebut, atau, jika tidak memungkinkan (misalnya karena
        informasi pribadi Anda telah disimpan dalam arsip cadangan), kami akan
        dengan aman menyimpan informasi pribadi Anda dan mengisolasinya dari
        pemrosesan lebih lanjut hingga penghapusan dapat dilakukan.
      </Typography>

      <Typography
        variant="body1"
        sx={{ py: '8px', pt: '16px' }}
        textAlign="justify"
        fontWeight={600}
      >
        8. BAGAIMANA KAMI MENJAGA KEAMANAN INFORMASI ANDA?
      </Typography>

      <Typography variant="body1" textAlign="justify" fontStyle="italic">
        Kami bertujuan untuk melindungi informasi pribadi Anda melalui sistem
        keamanan teknis.
      </Typography>

      <Typography variant="body1" sx={{ py: '8px' }} textAlign="justify">
        Kami telah menerapkan langkah-langkah keamanan teknis yang dirancang
        untuk melindungi keamanan informasi pribadi apa pun yang kami proses.
        Namun, terlepas dari perlindungan dan upaya kami untuk mengamankan
        informasi Anda, tidak ada transmisi elektronik melalui Internet atau
        teknologi penyimpanan informasi yang dapat dijamin 100% aman. Kami tidak
        dapat menjanjikan atau menjamin bahwa peretas atau pihak ketiga tidak
        sah lainnya tidak akan mampu mengalahkan keamanan kami, dan
        mengumpulkan, mengakses, mencuri, atau mengubah informasi Anda secara
        tidak semestinya. Meskipun kami akan melakukan yang terbaik untuk
        melindungi informasi pribadi Anda, transmisi informasi pribadi ke dan
        dari Layanan kami adalah risiko Anda sendiri. Anda hanya boleh mengakses
        Layanan dalam lingkungan dan akses internet yang aman.
      </Typography>

      <Typography
        variant="body1"
        sx={{ py: '8px', pt: '16px' }}
        textAlign="justify"
        fontWeight={600}
      >
        9. APAKAH KAMI MENGUMPULKAN INFORMASI DARI ANAK DI BAWAH UMUR?
      </Typography>

      <Typography variant="body1" textAlign="justify" fontStyle="italic">
        Secara singkat: Kami tidak dengan sengaja mengumpulkan data dari
        anak-anak di bawah usia 18 tahun.
      </Typography>

      <Typography variant="body1" sx={{ py: '8px' }} textAlign="justify">
        Kami tidak dengan sengaja meminta data dari atau memasarkan Layanan
        kepada anak-anak di bawah usia 18 tahun. Dengan menggunakan Layanan,
        Anda menyatakan bahwa Anda setidaknya berusia 18 tahun atau bahwa Anda
        adalah orang tua atau wali dari anak di bawah umur tersebut dan
        menyetujuinya untuk menggunakan Layanan. Jika kami mengetahui bahwa
        informasi pribadi dari pengguna berusia kurang dari 18 tahun telah
        dikumpulkan, kami akan menonaktifkan akun dan mengambil tindakan yang
        wajar untuk segera menghapus data tersebut dari catatan kami. Jika Anda
        mengetahui data apa pun yang kami kumpulkan dari anak-anak di bawah usia
        18 tahun, silakan hubungi kami di cs@kitalulus.com atau 085880006500.
      </Typography>

      <Typography
        variant="body1"
        sx={{ py: '8px', pt: '16px' }}
        textAlign="justify"
        fontWeight={600}
      >
        10. APA HAK PRIVASI ANDA?
      </Typography>

      <Typography variant="body1" textAlign="justify" fontStyle="italic">
        Secara singkat: Anda dapat meninjau, mengubah, atau menghapus akun Anda
        kapan saja.
      </Typography>

      <Typography variant="body1" sx={{ py: '8px' }} textAlign="justify">
        Jika Anda sewaktu-waktu ingin meninjau atau mengubah informasi di akun
        Anda atau menghentikan akun Anda, Anda dapat:
      </Typography>

      <ul style={{ margin: 0, padding: '0 24px' }}>
        <li>Masuk ke pengaturan akun Anda dan perbarui informasi akun Anda.</li>
        <li>Hubungi kami menggunakan informasi kontak yang disediakan.</li>
      </ul>
      <Typography variant="body1" sx={{ py: '8px' }} textAlign="justify">
        Atas permintaan Anda untuk menghentikan akun Anda, kami akan
        menonaktifkan atau menghapus akun dan informasi Anda dari database aktif
        kami. Namun, kami dapat menyimpan beberapa informasi dalam file kami
        untuk mencegah penipuan, memecahkan masalah, membantu penyelidikan,
        menegakkan Syarat dan Ketentuan Penggunaan kami dan/atau mematuhi
        persyaratan hukum yang berlaku.
      </Typography>
      <Typography variant="body1" sx={{ py: '8px' }} textAlign="justify">
        Anda dapat berhenti berlangganan dari daftar email pemasaran kami kapan
        saja dengan mengklik tautan berhenti berlangganan di email yang kami
        kirim atau dengan menghubungi alamat email yang disediakan di bawah ini.
        Anda kemudian akan dihapus dari daftar email pemasaran – namun, kami
        mungkin masih berkomunikasi dengan Anda, misalnya, untuk mengirimi Anda
        email terkait layanan yang diperlukan untuk administrasi dan penggunaan
        akun Anda, untuk menanggapi permintaan layanan, atau untuk tujuan
        non-pemasaran lainnya.
      </Typography>

      <Typography
        variant="body1"
        sx={{ py: '8px', pt: '16px' }}
        textAlign="justify"
        fontWeight={600}
      >
        11. APAKAH KAMI AKAN MEMPERBARUI KEBIJAKAN PRIVASI?
      </Typography>

      <Typography variant="body1" textAlign="justify" fontStyle="italic">
        Singkatnya: Ya, kami akan memperbarui pemberitahuan ini seperlunya untuk
        mengakomodir perkembangan Layanan dengan tetap mematuhi undang-undang
        yang relevan.
      </Typography>

      <Typography variant="body1" sx={{ py: '16px' }} textAlign="justify">
        Kami dapat memperbarui Kebijakan Privasi ini dari waktu ke waktu. Versi
        yang diperbarui akan ditunjukkan dengan tanggal “Revisi” yang diperbarui
        dan versi yang diperbarui akan berlaku segera setelah dapat diakses.
        Jika kami membuat perubahan materi pada pemberitahuan Kebijakan Privasi,
        kami dapat memberitahu Anda dengan memposting pemberitahuan mengenai
        pembaruan tersebut melalui Layanan atau dengan langsung mengirimkan
        pemberitahuan kepada Anda. Kami menganjurkan Anda untuk meninjau
        pemberitahuan privasi secara berkala ataupun saat mendapatkan
        pemberitahuan agar Anda mengetahui tentang Kebijakan Privasi yang
        berlaku.
      </Typography>

      <Typography
        variant="body1"
        sx={{ py: '8px', pt: '16px' }}
        textAlign="justify"
        fontWeight={600}
      >
        12. KONTROL UNTUK FITUR JANGAN LACAK
      </Typography>

      <Typography variant="body1" textAlign="justify">
        Sebagian besar browser web dan beberapa sistem operasi seluler dan
        aplikasi seluler menyertakan fitur atau pengaturan Do Not Track (“DNT”)
        yang dapat Anda aktifkan untuk menandakan preferensi privasi Anda agar
        data tentang aktivitas penjelajahan online Anda dipantau dan
        dikumpulkan. Pada tahap ini, tidak ada standar teknologi yang seragam
        untuk mengenali dan mengimplementasikan sinyal DNT yang mutakhir.
        Karenanya, saat ini kami tidak menanggapi sinyal browser DNT atau
        mekanisme lain apa pun yang secara otomatis mengomunikasikan pilihan
        Anda untuk tidak dilacak secara online. Jika terdapat standar untuk
        pelacakan online yang harus kami ikuti di masa mendatang, kami akan
        memberitahu Anda tentang praktik tersebut dalam versi revisi dari
        Kebijakan Privasi ini.
      </Typography>

      <Typography
        variant="body1"
        sx={{ py: '8px', pt: '16px' }}
        textAlign="justify"
        fontWeight={600}
      >
        13. BAGAIMANA ANDA DAPAT MENGHUBUNGI KAMI TENTANG KEBIJAKAN PRIVASI INI?
      </Typography>

      <Typography variant="body1" sx={{ pb: '16px' }} textAlign="justify">
        Apabila Anda memiliki pertanyaan atau komentar tentang Kebijakan Privasi
        ini, Anda dapat mengirim email ke cs@kitalulus.com dan 085880006500 atau
        melalui pos ke:
      </Typography>

      <Typography variant="body1" textAlign="justify">
        Menara Mandiri II, Lt. 16, Jl. Jend. Sudirman, No Kav. 54-55
      </Typography>
      <Typography variant="body1" textAlign="justify">
        Senayan, Kec. Kebayoran Baru, Jakarta Selatan, DKI Jakarta 12190,
        Indonesia
      </Typography>
    </>
  );
};

export default PrivacyPolicy;
