import { DropdownOption } from '~/types';
import { useMultiQueryState } from '../use-query-state';
import { EpJobVacancyCandidateStatus } from '~/types/graphql/graphql';

export type CandidateRecommendationListTableParams = {
  name: string;
  gender?: string;
  age_min?: number;
  age_max?: number;
  educations?: string[];
  education_institutions?: DropdownOption[];
  salary_min?: number;
  salary_max?: number;
  locations: DropdownOption[];
  experience_year?: DropdownOption;
  job_functions: DropdownOption[];
  skills: DropdownOption[];
  limit: number;
  page: number;
  tab: EpJobVacancyCandidateStatus;
  invitation_statuses: string[];
};

export const useCandidateRecommendationPageQuery = () => {
  return useMultiQueryState<CandidateRecommendationListTableParams>({
    name: '',
    locations: [],
    job_functions: [],
    skills: [],
    gender: 'all',
    educations: [],
    education_institutions: [],
    limit: 10,
    page: 0,
    tab: EpJobVacancyCandidateStatus.Available,
    invitation_statuses: [],
  });
};
