import { SvgIcon, SvgIconProps } from '@kitalulus/web-ui-kit';

const CheckIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 18 18" {...props}>
      <path d="M6.74998 12.1277L4.14748 9.52523C3.85498 9.23273 3.38248 9.23273 3.08998 9.52523C2.79748 9.81773 2.79748 10.2902 3.08998 10.5827L6.22498 13.7177C6.51748 14.0102 6.98998 14.0102 7.28248 13.7177L15.2175 5.78273C15.51 5.49023 15.51 5.01773 15.2175 4.72523C14.925 4.43273 14.4525 4.43273 14.16 4.72523L6.74998 12.1277Z" />
    </SvgIcon>
  );
};

export default CheckIcon;
