import {
  Dialog,
  DialogTitle,
  Stack,
  Image,
  Typography,
  DialogContent,
  DialogActions,
  Button,
} from '@kitalulus/web-ui-kit';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ApplicantFeedbackDialogProps } from '~/components/GlobalDialogs/ApplicantFeedbackDialog/ApplicantFeedbackDialog.types';
import { useApplicantDetailMutate } from '~/hooks/applicant-detail';
import { clearDialog, setDialog } from '~/store/views/dialogs-slice';
import { EpJobApplicationEpStatusEnum } from '~/types/graphql/graphql';

const ApplicantFeedbackDialog = ({
  content,
  ...props
}: ApplicantFeedbackDialogProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { doHandleUpdateStatus } = useApplicantDetailMutate(
    content?.applicantId ?? '',
  );

  const handleSubmit = () => {
    if (!content) return;
    if (content.status === EpJobApplicationEpStatusEnum.OnProgress) {
      doHandleUpdateStatus(content.status, null);
    }
    if (content.status === EpJobApplicationEpStatusEnum.Rejected) {
      //open remarks dialog
      dispatch(
        setDialog({
          remarksDialog: { open: true, content: { status: content.status } },
        }),
      );
    }
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const handleCloseDialog = () => {
    dispatch(clearDialog('applicantFeedbackDialog'));
  };

  return (
    <Dialog {...props} fullWidth maxWidth="sm">
      <Stack>
        {/* container */}
        <DialogTitle>
          <Stack gap={2} alignItems="center" justifyContent="center">
            <Image
              sx={{
                width: 68,
              }}
              src={'/illustrations/character-12-illusatration.png'}
              alt={'applicant-bulk-illustration'}
            />
            <Typography variant="h5" fontWeight={700}>
              {content?.status === EpJobApplicationEpStatusEnum.OnProgress
                ? t('applicant-detail:feedbackDialog.interested.title')
                : t('applicant-detail:feedbackDialog.notInterested.title')}
            </Typography>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Stack gap={2}>
            <Typography variant="body1" textAlign="center">
              {content?.status === EpJobApplicationEpStatusEnum.OnProgress
                ? t('applicant-detail:feedbackDialog.interested.description')
                : t(
                    'applicant-detail:feedbackDialog.notInterested.description',
                  )}
            </Typography>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Stack
            width={1}
            direction="row"
            alignItems="center"
            justifyContent="center"
            gap={2}
          >
            <Button
              variant="outlined"
              color="inherit"
              sx={{ px: 6.5 }}
              onClick={() => handleCloseDialog()}
            >
              {t('applicant-detail:feedbackDialog.actions.cancel')}
            </Button>

            <Button
              variant="contained"
              color="primary"
              sx={{ px: 6.5 }}
              onClick={() => handleSubmit()}
            >
              {t('applicant-detail:feedbackDialog.actions.agree')}
            </Button>
          </Stack>
        </DialogActions>
      </Stack>
    </Dialog>
  );
};

export default ApplicantFeedbackDialog;
