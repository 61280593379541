import { fetchAndActivate } from 'firebase/remote-config';
import { useCallback, useEffect } from 'react';

import { remoteConfig } from '~/config/firebase';
import { useAppDispatch, useAppSelector } from '../use-store';
import { FETCH_STATUS } from '~/utils/constants';
import { setFirebaseRemoteConfig } from '~/store/helpers/firebase-remote-config-slice';

export const useFirebaseInit = () => {
  const dispatch = useAppDispatch();

  const firebaseRemoteConfig = useAppSelector(
    (state) => state.firebaseRemoteConfig,
  );

  const isLoading = [FETCH_STATUS.IDLE, FETCH_STATUS.PENDING].includes(
    firebaseRemoteConfig.fetchStatus,
  );

  const initializeRemoteConfig = useCallback(async () => {
    try {
      await fetchAndActivate(remoteConfig);
      dispatch(
        setFirebaseRemoteConfig({
          fetchStatus: FETCH_STATUS.RESOLVED,
        }),
      );
    } catch (err) {
      dispatch(
        setFirebaseRemoteConfig({
          fetchStatus: FETCH_STATUS.FAILED,
        }),
      );
    } finally {
      dispatch(
        setFirebaseRemoteConfig({
          fetchStatus: FETCH_STATUS.RESOLVED,
        }),
      );
    }
  }, []);

  useEffect(() => {
    dispatch(
      setFirebaseRemoteConfig({
        fetchStatus: FETCH_STATUS.PENDING,
      }),
    );
    initializeRemoteConfig();
  }, [initializeRemoteConfig]);

  return { isLoading };
};
