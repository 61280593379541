import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Stack,
  Typography,
} from '@kitalulus/web-ui-kit';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from '~/hooks/use-store';
import { useVacancyEthicCodeAlt } from '~/hooks/vacancy-ethic-code/use-vacancy-ethic-code-alt';
import { closeDialog } from '~/store/views/dialogs-slice';
import { VacancyEthicCodeDialogProps } from './VacancyEthicCodeDialog.types';

const VacancyEthicCodeDialog = (props: VacancyEthicCodeDialogProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { ethics } = useVacancyEthicCodeAlt();

  const handleClose = useCallback(() => {
    dispatch(closeDialog('vacancyEthicCodeDialog'));
  }, []);

  return (
    <Dialog
      {...props}
      sx={{
        '.MuiPaper-root': {
          width: '100%',
          maxWidth: '480px',
          p: { xs: 3, md: 4 },
          m: 2,
        },
        ...props.sx,
      }}
    >
      <DialogContent sx={{ p: 0 }}>
        <Stack spacing={2}>
          <Typography variant="h5">
            {t('vacancy-ethic-code:vacancyEthicCodeAApAlt')}
          </Typography>

          <Stack>
            {ethics.map((ethic, i) => (
              <Stack
                key={ethic.content}
                direction="row"
                spacing={0.75}
                fontSize="14px"
                lineHeight="28px"
              >
                <Box>{i + 1}.</Box>
                <Box>{ethic.content}</Box>
              </Stack>
            ))}
          </Stack>

          <Button variant="contained" size="large" onClick={handleClose}>
            {t('common:ok')}
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default VacancyEthicCodeDialog;
