export enum InterviewInvitationDialogActionKey {
  NEW_INVITATION = 'NEW_INVITATION',
  RESCHEDULE = 'RESCHEDULE',
}

export enum InterviewInvitationDialogSizeKey {
  SINGLE = 'SINGLE',
  BULK = 'BULK',
}

export enum InterviewInvitationDialogInvitationTypeKey {
  WITH_INVITATION = 'WITH_INVITATION',
  WITHOUT_INVITATION = 'WITHOUT_INVITATION',
}

export enum InterviewInvitationDialogVariantKey {
  SELECTING_TYPE_DIALOG = 'SELECTING_TYPE_DIALOG',
  DIRECT_CHANGE_STATUS_TO_INTERVIEW_DIALOG = 'DIRECT_CHANGE_STATUS_TO_INTERVIEW_DIALOG',
  INTERVIEW_INVITATION_SESSION_DIALOG = 'INTERVIEW_INVITATTION_SESSION_DIALOG',
  RESCHEDULE_SESSION_CONFIRMATION_DIALOG = 'RESCHEDULE_SESSION_CONFIRMATION_DIALOG',
}

export enum InterviewInvitationFormSessionSelectTypeKey {
  NEW_SESSION = 'NEW_SESSION',
  EXISTING_SESSION = 'EXISTING_SESSION',
}

export enum InterviewInvitationFormRecruiterMessageStatusKey {
  FILLED = 'FILLED',
  NOT_FILLED = 'NOT_FILLED',
}
