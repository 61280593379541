import {
  DialogActions,
  Paper,
  Stack,
  Button,
  Typography,
} from '@kitalulus/web-ui-kit';
import { useTranslation } from 'react-i18next';

import CancelIcon from '~/components/Icons/CancelIcon';
import ChevronRightIcon from '~/components/Icons/ChevronRightIcon';
import { useFeatureFlag } from '~/hooks/firebase/use-feature-flag';
import { useInvitationStatusColor } from '~/hooks/candidate-recommendation';
import { useAppDispatch, useAppSelector } from '~/hooks/use-store';
import { refresh } from '~/store/helpers/refresh-count-slice';
import { setDialog } from '~/store/views/dialogs-slice';
import { convertDateToISOString } from '~/utils/helper';
import * as mpe from '~/utils/mixpanel';

const CandidateRecommendationActions = ({
  showAcceptReject,
  handleCloseDialog,
  handleViewDetail,
  isShowResponseCandidateStatus,
}: {
  showAcceptReject: boolean;
  isShowResponseCandidateStatus?: boolean;
  handleCloseDialog: VoidFunction;
  handleViewDetail: VoidFunction;
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isCandidateRecommendationV2Active = useFeatureFlag(
    'candidate_recommendation_v2',
  );
  const { candidateRecommendationDetail, vacancyDetail: vacancy } =
    useAppSelector((state) => state);

  const { data: dataCandidateRecommendationDetail } =
    candidateRecommendationDetail.detail;

  const { getStatusDetails } = useInvitationStatusColor();

  const handleInvite = (templateStr: string) => {
    if (!templateStr) return;

    if (isCandidateRecommendationV2Active)
      return dispatch(
        setDialog({
          candidateRecommendationInviteDialogV2: {
            open: true,
            content: {
              triggerSource: 'preview',
              templateStr: templateStr,
              onSuccess: () => {
                dispatch(refresh('candidateRecommendationList'));
                dispatch(refresh('candidateRecommendationSummary'));
              },
            },
          },
        }),
      );

    dispatch(
      setDialog({
        candidateRecommendationInviteDialog: {
          open: true,
          content: {
            defaultTemplateStr: templateStr,
            onSuccess: () => {
              dispatch(refresh('candidateRecommendationList'));
              dispatch(refresh('candidateRecommendationSummary'));
            },
            triggerSource: 'preview',
          },
        },
      }),
    );
  };

  const handleCandidateDetailClick = () => {
    const { text: invitationStatusStr } = getStatusDetails(
      dataCandidateRecommendationDetail?.invitationStatus ?? undefined,
    );

    if (isShowResponseCandidateStatus)
      return mpe.candidateDetail({
        invitationStatus: invitationStatusStr ?? '',
        appliedStatus: !!dataCandidateRecommendationDetail?.jobApplicationId,
      });

    mpe.candidateDetail();
  };

  return (
    <DialogActions sx={{ padding: 0, flexDirection: 'column' }}>
      <Paper elevation={8} sx={{ width: 1, borderRadius: 0 }}>
        <Stack
          width={1}
          direction="row"
          alignItems="center"
          justifyContent={showAcceptReject ? 'space-between' : 'end'}
          padding={2}
          gap={2}
        >
          {showAcceptReject && (
            <Stack direction="row" gap={1}>
              <Button
                variant="text"
                color="error"
                onClick={() => {
                  if (!dataCandidateRecommendationDetail?.id) return;
                  mpe.candidateAction({
                    vacancyCode: vacancy?.code ?? '',
                    vacancyCreatedAt: convertDateToISOString(
                      vacancy?.createdAt,
                    ),
                    vacancyCloseDate: convertDateToISOString(
                      vacancy?.closeDate,
                    ),
                    candidateID: dataCandidateRecommendationDetail.id,
                    candidateName:
                      dataCandidateRecommendationDetail.userProfile?.name ?? '',
                    action: 'not interested',
                    actionPosition: 'preview',
                  });
                  handleCloseDialog();
                  dispatch(
                    setDialog({
                      candidateRecommendationRejectConfirmDialog: {
                        open: true,
                        content: {
                          onSuccess: () => {
                            dispatch(refresh('candidateRecommendationList'));
                            dispatch(refresh('candidateRecommendationSummary'));
                          },
                          triggerSource: 'preview',
                        },
                      },
                    }),
                  );
                }}
                startIcon={<CancelIcon color="error" />}
                sx={{ alignItems: 'center' }}
              >
                <Typography variant="caption" fontWeight={500}>
                  {t('candidate-recommendation-detail:notInterested')}
                </Typography>
              </Button>
              <Button
                variant="outlined"
                color="primary"
                sx={{ alignItems: 'center' }}
                onClick={async () => {
                  if (!dataCandidateRecommendationDetail?.id) return;
                  mpe.candidateAction({
                    vacancyCode: vacancy?.code ?? '',
                    vacancyCreatedAt: convertDateToISOString(
                      vacancy?.createdAt,
                    ),
                    vacancyCloseDate: convertDateToISOString(
                      vacancy?.closeDate,
                    ),
                    candidateID: dataCandidateRecommendationDetail.id,
                    candidateName:
                      dataCandidateRecommendationDetail.userProfile?.name ?? '',
                    action: 'invite',
                    actionPosition: 'preview',
                  });
                  handleCloseDialog();
                  dispatch(
                    setDialog({
                      candidateRecommendationVerificationDialog: {
                        open: true,
                        content: {
                          candidateId: dataCandidateRecommendationDetail.id,
                          onSuccess: (handleClose, templateStr) => {
                            handleInvite(templateStr);
                            handleClose();
                          },
                        },
                      },
                    }),
                  );
                }}
              >
                <Typography variant="caption" fontWeight={500}>
                  {t('candidate-recommendation:inviteCandidate')}
                </Typography>
              </Button>
            </Stack>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              handleCandidateDetailClick();
              handleCloseDialog();
              handleViewDetail();
            }}
            endIcon={<ChevronRightIcon sx={{ height: '12px' }} />}
            sx={{ alignItems: 'center', alignSelf: 'end' }}
          >
            <Typography variant="caption" fontWeight={500}>
              {t('applicant:viewDetail')}
            </Typography>
          </Button>
        </Stack>
      </Paper>
    </DialogActions>
  );
};

export default CandidateRecommendationActions;
