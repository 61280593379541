import { SvgIcon, SvgIconProps } from '@kitalulus/web-ui-kit';

const Dashboard = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path d="M3 13h8V3H3v10zm0 8h8v-6H3v6zm10 0h8V11h-8v10zm0-18v6h8V3h-8z" />
    </SvgIcon>
  );
};

export default Dashboard;
