import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@kitalulus/web-ui-kit';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import CloseIcon from '~/components/Icons/CloseIcon';
import { MixpanelManageInterviewApplicantReschedulePopUpProperties } from '~/types/mixpanel';
import { InterviewInvitationDialogVariantKey } from '~/utils/constants/interview-invitation';
import * as mpe from '~/utils/mixpanel';
import { RescheduleSessionConfirmationDialogProps } from './';

const RescheduleSessionConfirmationDialog = ({
  content: { sizeKey },
  onSwitchVariant,
  onClose,
  ...props
}: RescheduleSessionConfirmationDialogProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const triggerManageInterviewApplicantReschedulePopUp = useCallback(
    (
      buttonType: MixpanelManageInterviewApplicantReschedulePopUpProperties['buttonType'],
    ) => {
      mpe.manageInterviewApplicantReschedulePopUp({
        buttonType,
        selectionType: sizeKey,
      });
    },
    [sizeKey],
  );

  const handleContinue = useCallback(() => {
    triggerManageInterviewApplicantReschedulePopUp('continue');
    onSwitchVariant(
      InterviewInvitationDialogVariantKey.INTERVIEW_INVITATION_SESSION_DIALOG,
    );
  }, [onSwitchVariant, triggerManageInterviewApplicantReschedulePopUp]);

  const handleClose = useCallback(
    (
      buttonType: MixpanelManageInterviewApplicantReschedulePopUpProperties['buttonType'],
    ) => {
      triggerManageInterviewApplicantReschedulePopUp(buttonType);
      onClose();
    },
    [onClose, triggerManageInterviewApplicantReschedulePopUp],
  );

  return (
    <Dialog
      fullWidth
      onClose={() => handleClose('close')}
      PaperProps={{
        sx: {
          maxWidth: '520px',
          width: 'auto',
          mx: 2,
          p: 4,
          ...(isMobile && { p: 3 }),
        },
      }}
      {...props}
    >
      <Stack gap={3}>
        <DialogTitle component="div" sx={{ p: 0 }}>
          <Stack
            direction="row"
            alignItems="center"
            gap={1}
            justifyContent="space-between"
          >
            <Typography variant="h6" color="text.primary">
              {t(
                'interview-invitation:rescheduleSessionConfirmationDialog.title',
              )}
            </Typography>
            <IconButton aria-label="close" onClick={() => handleClose('close')}>
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>

        <DialogContent sx={{ p: 0 }}>
          <Stack gap={1}>
            <Typography variant="body1" color="text.secondary" fontWeight={600}>
              {t(
                'interview-invitation:rescheduleSessionConfirmationDialog.descriptionPrimary',
              )}
            </Typography>
            <Typography variant="body1" color="text.secondary">
              {t(
                'interview-invitation:rescheduleSessionConfirmationDialog.descriptionSecondary',
              )}
            </Typography>
          </Stack>
        </DialogContent>

        <DialogActions sx={{ p: 0, gap: 0.5 }}>
          <Button
            variant="outlined"
            color="inherit"
            onClick={() => handleClose('cancel')}
          >
            {t('common:cancelAlt')}
          </Button>
          <Button variant="contained" color="primary" onClick={handleContinue}>
            {t('common:yesNext')}
          </Button>
        </DialogActions>
      </Stack>
    </Dialog>
  );
};

export default RescheduleSessionConfirmationDialog;
