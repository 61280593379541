import ReactGA from 'react-ga4';
import { appConfig } from '~/config/app';

type EventType = {
  category: string;
  action: string;
  label?: string;
  value?: number;
  nonInteraction?: boolean;
  transport?: 'beacon' | 'xhr' | 'image';
};

const Analytics = {
  initialize: () => {
    ReactGA.initialize(appConfig.googleAnalyticsId);
  },
  sendEvent: (data: EventType) => {
    ReactGA.event(data);
  },
};

export const ANALYTICS_EVENTS = {
  ACCOUNT_LOGIN: { category: 'ACCOUNT', action: 'ACCOUNT_LOGIN' },
  ACCOUNT_REGISTER: { category: 'ACCOUNT', action: 'ACCOUNT_REGISTER' },
  APPLICANT_CONTACT: {
    category: 'APPLICANT',
    action: 'APPLICANT_CONTACT',
  },
  APPLICANT_ACCEPT_EMAIL: {
    category: 'APPLICANT',
    action: 'APPLICANT_ACCEPT_EMAIL',
  },
  APPLICANT_ACCEPT_WHATSAPP: {
    category: 'APPLICANT',
    action: 'APPLICANT_ACCEPT_WHATSAPP',
  },
  APPLICANT_ACCEPT_SKIP: {
    category: 'APPLICANT',
    action: 'APPLICANT_ACCEPT_SKIP',
  },
  APPLICANT_REJECT_EMAIL: {
    category: 'APPLICANT',
    action: 'APPLICANT_REJECT_EMAIL',
  },
  APPLICANT_REJECT_WHATSAPP: {
    category: 'APPLICANT',
    action: 'APPLICANT_REJECT_WHATSAPP',
  },
  APPLICANT_REJECT_SKIP: {
    category: 'APPLICANT',
    action: 'APPLICANT_REJECT_SKIP',
  },
  APPLICANT_CV: {
    category: 'APPLICANT',
    action: 'APPLICANT_CV',
  },
  APPLICANT_CONTACT_COPY: {
    category: 'APPLICANT',
    action: 'APPLICANT_CONTACT_COPY',
  },
  APPLICANT_DASHBOARD: {
    category: 'APPLICANT',
    action: 'APPLICANT_DASHBOARD',
  },
  APPLICANT_FILTER: {
    category: 'APPLICANT',
    action: 'APPLICANT_FILTER',
  },
  SETTING_NOTIFICATION: {
    category: 'NOTIFICATION',
    action: 'SETTING_NOTIFICATION',
  },
  VACANCY_CREATE_START: {
    category: 'VACANCY',
    action: 'VACANCY_CREATE_START',
  },
  VACANCY_CREATE_SECOND: {
    category: 'VACANCY',
    action: 'VACANCY_CREATE_SECOND',
  },
  VACANCY_CREATE_THIRD: {
    category: 'VACANCY',
    action: 'VACANCY_CREATE_THIRD',
  },
  VACANCY_CREATE_SUCCESS: {
    category: 'VACANCY',
    action: 'VACANCY_CREATE_SUCCESS',
  },
  OTHER_HELP: {
    category: 'OTHER',
    action: 'OTHER_HELP',
  },
};

export default Analytics;
