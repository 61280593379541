import { useMutation } from '@apollo/client';
import { useCallback, useState } from 'react';

import {
  MUTATION_REGISTER_COMPANY,
  MUTATION_REGISTER_COMPANY_V2,
} from '~/graphql/company';
import { useAppDispatch } from '~/hooks/use-store';
import { setSnackbar } from '~/store/views/snackbar-slice';
import { refresh } from '~/store/helpers/refresh-count-slice';
import { Nullable } from '~/types';
import {
  EpInputRegisterCompany,
  RegisterCompanyMutation,
  RegisterCompanyMutationVariables,
  RegisterCompanyV2Mutation,
  RegisterCompanyV2MutationVariables,
} from '~/types/graphql/graphql';
import { useUserOnboarding } from './use-user-onboarding';
import { useFeatureFlag } from './firebase/use-feature-flag';

export const useCompanyRegister = () => {
  const dispatch = useAppDispatch();
  const { handleNextOnboarding } = useUserOnboarding();
  const isPayPerPostActive = useFeatureFlag('pay_per_post');

  const [error, setError] = useState<Nullable<string>>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [register] = useMutation<
    RegisterCompanyMutation,
    RegisterCompanyMutationVariables
  >(MUTATION_REGISTER_COMPANY, {
    onCompleted: ({ registerCompany }) => {
      if (!registerCompany) return;
      const { isSuccess, error } = registerCompany;

      if (isSuccess) {
        dispatch(refresh('company'));
        handleNextOnboarding();
      }

      if (error) {
        setError(error);
        dispatch(setSnackbar({ severity: 'error', message: error }));
      }

      setIsLoading(false);
    },
  });

  const [registerV2] = useMutation<
    RegisterCompanyV2Mutation,
    RegisterCompanyV2MutationVariables
  >(MUTATION_REGISTER_COMPANY_V2, {
    onCompleted: ({ registerCompanyV2: res }) => {
      if (!res) return;

      if (res.__typename === 'EpRegisterCompanyV2Response') {
        dispatch(refresh('company'));
        handleNextOnboarding();
      }

      if (res.__typename === 'EpRegisterCompanyV2Error') {
        setError(res.message);
        dispatch(setSnackbar({ severity: 'error', message: res.message }));
      }

      setIsLoading(false);
    },
  });

  const handleRegister = useCallback(
    async (payload: EpInputRegisterCompany) => {
      setIsLoading(true);
      setError(null);
      (await isPayPerPostActive)
        ? registerV2({ variables: { payload: payload } })
        : register({ variables: { payload: payload } });
    },
    [],
  );

  return { handleRegister, isLoading, error };
};
