import { Route, Routes } from 'react-router-dom';

import { withAuthorized } from '~/hoc/with-auth';
import DashboardRoutes from './dashboard';
import { useMenus } from '~/hooks/use-menu';
import { useUserConfig } from '~/hooks/use-user-config';
import { useJobApplicationHistoryLabel } from '~/hooks/use-job-application-history-label';
import { useMixpanelUserProperties } from '~/hooks/use-mixpanel';
import { useApplicantStatusOptions } from '~/hooks/applicants';
import { useRealtimeData } from '~/hooks/firebase/use-realtime-data';

const AuthorizedRoutes = () => {
  useRealtimeData();
  useMenus();
  useUserConfig();
  useJobApplicationHistoryLabel();
  useMixpanelUserProperties();
  useApplicantStatusOptions();

  return (
    <Routes>
      <Route path="*" element={<DashboardRoutes />} />
    </Routes>
  );
};

export default withAuthorized(AuthorizedRoutes);
