import { Box } from '@kitalulus/web-ui-kit';
import { useCallback, useEffect, useMemo, useState } from 'react';

import Snackbar from '~/components/Snackbar';
import { useAppDispatch, useAppSelector } from '~/hooks/use-store';
import { setSnackbar } from '~/store/views/snackbar-slice';
import { GlobalSnackbarProps } from './GlobalSnakbars.types';

const GlobalSnackbars = ({ children, ...props }: GlobalSnackbarProps) => {
  const dispatch = useAppDispatch();
  const snackbar = useAppSelector((state) => state.snackbar);

  const [isOpen, setIsOpen] = useState(!!snackbar);
  useEffect(() => setIsOpen(!!snackbar), [snackbar]);

  const leftContentWidth: number = useMemo(() => {
    switch (snackbar?.layout) {
      case 'dashboard':
        // sidebar width
        return 280;
      case 'auth':
        // auth banner width
        return 466;
      default:
        return 0;
    }
  }, [snackbar?.layout]);

  const handleClose = useCallback(() => {
    if (!snackbar) return;
    setIsOpen(false);
    setTimeout(() => {
      dispatch(setSnackbar(null));
    }, 300);
  }, [snackbar]);

  return (
    <Box position="relative" {...props}>
      <Snackbar
        message=""
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        disableWindowBlurListener
        showIcon
        closeable
        autoHideDuration={+import.meta.env.VITE_SNACKBAR_DURATION}
        open={isOpen}
        {...snackbar}
        onClose={(_, reason) => {
          if (reason === 'clickaway') return;
          snackbar?.onClose ? snackbar.onClose(handleClose) : handleClose();
        }}
        sx={{
          mt: '0 !important',
          left: {
            xs: '8px',
            sm: '50%',
            lg: `calc(50% + ${leftContentWidth / 2}px) !important`,
          },
          '.MuiPaper-root': {
            maxWidth: { xs: '100%', lg: '450px' },
          },
          whiteSpace: 'pre-line',
          ...snackbar?.sx,
        }}
      />
      {children}
    </Box>
  );
};

export default GlobalSnackbars;
