import { SvgIcon, SvgIconProps } from '@kitalulus/web-ui-kit';

interface CustomSvgIconProps extends SvgIconProps {
  fillboxchecked?: string;
  fillchecked?: string;
}

export const EmailCheckIcon = (props: CustomSvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 20 20" {...props}>
      <path
        fill="#757575"
        fillRule="evenodd"
        d="M15.838 3.336H4.172a2.5 2.5 0 0 0-2.5 2.5v8.333a2.5 2.5 0 0 0 2.5 2.5h5.831L10.002 16v-2c0-1.886 0-2.828.586-3.414C11.174 10 12.116 10 14.002 10h2c1.027 0 1.774 0 2.337.095v-4.26a2.5 2.5 0 0 0-2.5-2.5Zm0 1.664-5.416 3.725a.833.833 0 0 1-.833 0L4.172 5h11.666Z"
        clipRule="evenodd"
      />
      <g clipPath="url(#a)">
        <rect
          width={8.333}
          height={8.333}
          x={10.834}
          y={10.836}
          fill={props.fillboxchecked ?? '#1E88E5'}
          rx={1.667}
        />
        <path
          fill={props.fillchecked ?? '#fff'}
          d="M14.42 17.916a.538.538 0 0 1-.43-.214l-1.383-1.826a.54.54 0 1 1 .86-.65l.91 1.2 2.287-3.672a.54.54 0 0 1 .916.57l-2.702 4.338a.537.537 0 0 1-.434.253l-.024.001Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M10 10h10v10H10z" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default EmailCheckIcon;
