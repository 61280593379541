import { FeatureFlag } from '~/types/firebase';
import { useRemoteConfigValue } from '~/hooks/firebase/use-remote-config';

export const useFeatureFlag = <T extends keyof FeatureFlag>(
  key: T,
): FeatureFlag[T] => {
  const featureFlag = useRemoteConfigValue('feature_flag');

  return featureFlag[key];
};
