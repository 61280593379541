import {
  Dialog,
  DialogContent,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@kitalulus/web-ui-kit';
import { useTranslation } from 'react-i18next';

import Loader3Dots from '~/components/Loaders/Loader3Dots';

const InterviewInvitationSessionLoaderDialog = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      open
      fullWidth
      PaperProps={{
        sx: {
          maxWidth: '500px',
          width: 'auto',
          mx: 2,
          p: 4,
          ...(isMobile && { p: 3 }),
        },
      }}
    >
      <DialogContent sx={{ p: 0, py: 2 }}>
        <Stack gap={4} alignItems="center">
          <Loader3Dots />
          <Typography variant="body1" whiteSpace="pre-wrap" textAlign="center">
            {t(
              'interview-invitation:interviewInvitationSessionLoaderDialog.description',
            )}
          </Typography>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default InterviewInvitationSessionLoaderDialog;
