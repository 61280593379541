import { SvgIcon, SvgIconProps } from '@kitalulus/web-ui-kit';

const CancelIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 21 21" width="21" height="21" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.15234 10.5796C2.15234 5.93046 5.92118 2.16162 10.5703 2.16162C12.8029 2.16162 14.944 3.04851 16.5227 4.62718C18.1013 6.20585 18.9882 8.34699 18.9882 10.5796C18.9882 15.2287 15.2194 18.9975 10.5703 18.9975C5.92118 18.9975 2.15234 15.2287 2.15234 10.5796ZM13.1021 12.2631C13.1021 12.0386 13.0125 11.8235 12.8531 11.6654L11.7588 10.5795L12.8531 9.49359C13.1832 9.16351 13.1832 8.62833 12.8531 8.29825C12.5231 7.96816 11.9879 7.96816 11.6578 8.29825L10.5719 9.39258L9.48596 8.29825C9.15587 7.96816 8.6207 7.96816 8.29061 8.29825C7.96053 8.62833 7.96053 9.16351 8.29061 9.49359L9.38494 10.5795L8.29061 11.6654C8.13125 11.8235 8.04161 12.0386 8.04161 12.2631C8.04161 12.4876 8.13125 12.7027 8.29061 12.8608C8.44867 13.0201 8.66383 13.1098 8.88829 13.1098C9.11274 13.1098 9.3279 13.0201 9.48596 12.8608L10.5719 11.7664L11.6578 12.8608C11.8158 13.0201 12.031 13.1098 12.2555 13.1098C12.4799 13.1098 12.6951 13.0201 12.8531 12.8608C13.0125 12.7027 13.1021 12.4876 13.1021 12.2631Z"
      />
    </SvgIcon>
  );
};

export default CancelIcon;
