import { SnackbarProps } from '@kitalulus/web-ui-kit';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Nullable } from '~/types';
import { createLocalStoragePersist } from '~/utils/redux-persist';

export type SnackbarState = Nullable<
  Partial<Omit<SnackbarProps, 'onClose'>> & {
    onClose?: (closeSnakbar: () => void) => void;
  }
>;

const SNACKBAR_PERSIST_KEY = 'snackbar-persist';

const snackbarPersistSlice = createSlice({
  name: SNACKBAR_PERSIST_KEY,
  initialState: null as SnackbarState,
  reducers: {
    setSnackbarPersist: (_, action: PayloadAction<SnackbarState>) =>
      action.payload,
    clearSnackbarPersist: () => null,
  },
});

export const { setSnackbarPersist, clearSnackbarPersist } =
  snackbarPersistSlice.actions;

export default createLocalStoragePersist(
  SNACKBAR_PERSIST_KEY,
  snackbarPersistSlice.reducer,
);
