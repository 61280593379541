import { MouseEvent } from 'react';
import { ConfirmDialogV2Props } from './ConfirmDialogV2.types';
import {
  Dialog,
  Typography,
  IconButton,
  Button,
  Stack,
  brandColors,
} from '@kitalulus/web-ui-kit';
import CloseIcon from '~/components/Icons/CloseIcon';
import LoadingButton from '~/components/LoadingButton/LoadingButton';
import { useAppDispatch } from '~/hooks/use-store';
import { closeDialog } from '~/store/views/dialogs-slice';

const ConfirmDialogV2 = ({ content, ...props }: ConfirmDialogV2Props) => {
  const dispatch = useAppDispatch();
  if (!content) return null;
  const {
    containerProps,
    title,
    titleProps,
    body,
    bodyProps,
    closeButtonProps,
    acceptProps,
    cancelProps,
  } = content;

  const handleClose = (e: MouseEvent<HTMLButtonElement>) => {
    dispatch(closeDialog('confirmDialogV2'));
    closeButtonProps?.onClick?.(e);
  };

  const titleIsString = typeof title === 'string';
  const bodyIsString = typeof body === 'string';

  return (
    <Dialog
      PaperProps={{
        ...containerProps,
        sx: {
          p: 3,
          maxWidth: 480,
          borderRadius: '16px',
          gap: 3,
          ...containerProps?.sx,
        },
      }}
      {...props}
    >
      <Stack gap={3}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          {titleIsString ? (
            <Typography variant="h6" component="div" {...titleProps}>
              {title}
            </Typography>
          ) : (
            title
          )}
          <IconButton
            size="small"
            onClick={handleClose}
            {...closeButtonProps}
            sx={{
              color: brandColors.grey[800],
              alignSelf: 'start',
              ...closeButtonProps?.sx,
            }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        {bodyIsString ? (
          <Typography
            variant="body1"
            component="div"
            color="text.secondary"
            {...bodyProps}
          >
            {body}
          </Typography>
        ) : (
          body
        )}
        <Stack direction="row" gap={1.5} justifyContent="end">
          {cancelProps?.children && (
            <Button
              autoFocus
              color="inherit"
              variant="outlined"
              onClick={handleClose}
              {...cancelProps}
              sx={{ minWidth: 86, ...cancelProps?.sx, fontWeight: 600 }}
            >
              {cancelProps.children}
            </Button>
          )}
          {acceptProps?.children && (
            <LoadingButton
              color="primary"
              variant="contained"
              {...acceptProps}
              sx={{ minWidth: 86, ...acceptProps?.sx, fontWeight: 600 }}
            >
              {acceptProps.children}
            </LoadingButton>
          )}
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default ConfirmDialogV2;
