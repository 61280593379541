import {
  DesktopDatePicker,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  TimePicker,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@kitalulus/web-ui-kit';
import { TimeStepOptions } from '@mui/x-date-pickers';
import { addDays, addMinutes } from 'date-fns';
import { useCallback, useContext, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import RequiredAsterisk from '~/components/RequiredAsterisk';
import {
  EpInterviewSessionSizeType,
  EpInterviewSessionType,
} from '~/types/graphql/graphql';
import {
  InterviewInvitationDialogSizeKey,
  InterviewInvitationFormSessionSelectTypeKey,
} from '~/utils/constants/interview-invitation';
import { removeFancyTextAndEmoji, sleep } from '~/utils/helper';
import {
  interviewInvitationOfflineAdressMaxLength,
  interviewInvitationOfflineLinkMaxLength,
  interviewInvitationOnlineLinkMaxLength,
  interviewInvitationOnlinePasswordMaxLength,
  interviewInvitationScheduleMinDaysAfterToday,
  urlPattern,
} from '~/utils/validation';
import { InterviewInvitationFormContext } from '../../';
import {
  InterviewInvitationFormSecondStepProps,
  InterviewInvitationFormSecondStepSizeTypeOption,
  InterviewInvitationFormSecondStepTypeOption,
} from './';

const scheduleMinDate = addDays(
  new Date(),
  interviewInvitationScheduleMinDaysAfterToday,
);
const scheduleTimeSteps: TimeStepOptions = { minutes: 5 };

const InterviewInvitationFormSecondStep = (
  props: InterviewInvitationFormSecondStepProps,
) => {
  const { t } = useTranslation();
  const { sizeKey, firstStepFormState, secondStepFormState } = useContext(
    InterviewInvitationFormContext,
  );
  const { control, setValue, trigger, unregister, watch } = secondStepFormState;

  // responsive states
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // form value watchers
  const sessionSelectType = firstStepFormState.watch('sessionSelectType');
  const scheduleStartTime = watch('scheduleStartTime');
  const scheduleEndTime = watch('scheduleEndTime');
  const isDisabled =
    sessionSelectType ===
    InterviewInvitationFormSessionSelectTypeKey.EXISTING_SESSION;

  // reducing 1 min to prevent selecting the same value as the end time one
  const scheduleStartTimeMax = scheduleEndTime
    ? addMinutes(scheduleEndTime, -1)
    : null;
  // adding 1 min to prevent selecting the same value as the start time one
  const scheduleEndTimeMin = scheduleStartTime
    ? addMinutes(scheduleStartTime, 1)
    : null;

  const sizeTypeOptions = useMemo<
    InterviewInvitationFormSecondStepSizeTypeOption[]
  >(() => {
    const isBulk = sizeKey === InterviewInvitationDialogSizeKey.BULK;

    return [
      {
        value: EpInterviewSessionSizeType.Personal,
        label: t('interview-invitation-form:sizeTypePersonalOptionLabel'),
        additional: {
          disableTooltip: isBulk
            ? t(
                'interview-invitation-form:sizeTypePersonalOptionDisableTooltip',
              )
            : undefined,
        },
      },
      {
        value: EpInterviewSessionSizeType.Group,
        label: t('interview-invitation-form:sizeTypeGroupOptionLabel'),
      },
    ];
  }, [sizeKey]);

  const typeOptions = useMemo<
    InterviewInvitationFormSecondStepTypeOption[]
  >(() => {
    return [
      {
        type: EpInterviewSessionType.Offline,
        title: t('interview-invitation-form:typeOfflineTitle'),
        subtitle: t('interview-invitation-form:typeOfflineSubtitle'),
        input: ({ isSelected }) => (
          <Stack gap={2.5}>
            <Controller
              control={control}
              name="offlineDetail.address"
              rules={{
                required: isSelected
                  ? {
                      value: true,
                      message: t(
                        'interview-invitation-form:typeOfflineAddressError.required',
                      ),
                    }
                  : undefined,
              }}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <TextField
                  disabled={isDisabled}
                  value={value}
                  onChange={({ target: { value } }) =>
                    onChange(removeFancyTextAndEmoji(value))
                  }
                  placeholder={t(
                    'interview-invitation-form:typeOfflineAddressPlaceholder',
                  )}
                  inputProps={{
                    maxLength: interviewInvitationOfflineAdressMaxLength,
                  }}
                  FormHelperTextProps={{ sx: { mx: 0 } }}
                  error={!!error}
                  helperText={error?.message}
                  multiline
                />
              )}
            />

            <Stack gap={0.25}>
              <Typography variant="body2">
                {t('interview-invitation-form:typeOfflineLinkTitle')}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {t('interview-invitation-form:typeOfflineLinkSubtitle')}
              </Typography>
            </Stack>

            <Controller
              control={control}
              name="offlineDetail.url"
              rules={{
                pattern: isSelected
                  ? {
                      value: urlPattern,
                      message: t(
                        'interview-invitation-form:typeOfflineLinkError.pattern',
                      ),
                    }
                  : undefined,
              }}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                const isValidURL = urlPattern.test(value);

                return (
                  <TextField
                    disabled={isDisabled}
                    value={value}
                    onChange={({ target: { value } }) =>
                      onChange(removeFancyTextAndEmoji(value))
                    }
                    placeholder={t(
                      'interview-invitation-form:typeOfflineLinkPlaceholder',
                    )}
                    inputProps={{
                      maxLength: interviewInvitationOfflineLinkMaxLength,
                    }}
                    InputProps={{
                      sx: {
                        color: isValidURL ? 'primary.main' : 'text.primary',
                      },
                    }}
                    FormHelperTextProps={{ sx: { mx: 0 } }}
                    error={!!error}
                    helperText={error?.message}
                  />
                );
              }}
            />
          </Stack>
        ),
      },
      {
        type: EpInterviewSessionType.Online,
        title: t('interview-invitation-form:typeOnlineTitle'),
        subtitle: t('interview-invitation-form:typeOnlineSubtitle'),
        input: ({ isSelected }) => (
          <Stack direction={isMobile ? 'column' : 'row'} gap={2} width="100%">
            <Controller
              control={control}
              name="onlineDetail.url"
              rules={{
                required: isSelected
                  ? {
                      value: true,
                      message: t(
                        'interview-invitation-form:typeOnlineLinkError.required',
                      ),
                    }
                  : undefined,
                pattern: isSelected
                  ? {
                      value: urlPattern,
                      message: t(
                        'interview-invitation-form:typeOnlineLinkError.pattern',
                      ),
                    }
                  : undefined,
              }}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                const isValidURL = urlPattern.test(value);

                return (
                  <TextField
                    disabled={isDisabled}
                    value={value}
                    onChange={({ target: { value } }) =>
                      onChange(removeFancyTextAndEmoji(value))
                    }
                    placeholder={t(
                      'interview-invitation-form:typeOnlineLinkPlaceholder',
                    )}
                    inputProps={{
                      maxLength: interviewInvitationOnlineLinkMaxLength,
                    }}
                    InputProps={{
                      sx: {
                        color: isValidURL ? 'primary.main' : 'text.primary',
                      },
                    }}
                    FormHelperTextProps={{ sx: { mx: 0 } }}
                    error={!!error}
                    helperText={error?.message}
                    sx={{ flex: 4 }}
                  />
                );
              }}
            />

            <Controller
              control={control}
              name="onlineDetail.password"
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <TextField
                  disabled={isDisabled}
                  value={value}
                  onChange={({ target: { value } }) =>
                    onChange(removeFancyTextAndEmoji(value))
                  }
                  label={t('interview-invitation-form:typeOnlinePasswordLabel')}
                  placeholder={t(
                    'interview-invitation-form:typeOnlinePasswordPlaceholder',
                  )}
                  inputProps={{
                    maxLength: interviewInvitationOnlinePasswordMaxLength,
                  }}
                  InputLabelProps={{ shrink: true }}
                  FormHelperTextProps={{ sx: { mx: 0 } }}
                  error={!!error}
                  helperText={error?.message}
                  sx={{ flex: 2 }}
                />
              )}
            />
          </Stack>
        ),
      },
    ];
  }, [isMobile, isDisabled]);

  const handleChangeSizeType = useCallback(
    async (newType: EpInterviewSessionType) => {
      setValue('type', newType);

      // to prevent race condition between set value and trigger validation
      await sleep(100);

      switch (newType) {
        case EpInterviewSessionType.Offline:
          // unregister other option's fields
          unregister('onlineDetail', {
            keepValue: true,
            keepDefaultValue: true,
          });
          // trigger validation to selected option
          trigger('offlineDetail');
          return;

        case EpInterviewSessionType.Online:
          // unregister other option's fields
          unregister('offlineDetail', {
            keepValue: true,
            keepDefaultValue: true,
          });
          // trigger validation to selected option
          trigger('onlineDetail');
          return;

        default:
          return;
      }
    },
    [],
  );

  return (
    <Stack gap={3} {...props}>
      {/* Schedule section */}
      <Stack gap={2.5}>
        <Stack gap={0.25}>
          <Typography variant="subtitle1">
            {t('interview-invitation-form:scheduleTitle')}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {t('interview-invitation-form:scheduleSubtitle')}
          </Typography>
        </Stack>

        <Stack
          direction={isMobile ? 'column' : 'row'}
          alignItems={isMobile ? 'stretch' : 'center'}
          gap={2}
          width="100%"
        >
          <Controller
            control={control}
            name="schedule"
            rules={{ required: true }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <DesktopDatePicker
                disabled={isDisabled}
                value={value}
                onChange={onChange}
                minDate={scheduleMinDate}
                format="EEEE, dd MMMM yyyy"
                slotProps={{
                  textField: {
                    label: (
                      <>
                        {t('interview-invitation-form:scheduleDateLabel')}
                        <RequiredAsterisk />
                      </>
                    ),
                    placeholder: t(
                      'interview-invitation-form:scheduleDatePlaceholder',
                    ),
                    InputLabelProps: { shrink: true },
                    inputProps: { readOnly: true },
                    error: !!error,
                  },
                }}
                sx={{ flex: 1 }}
              />
            )}
          />
          <Controller
            control={control}
            name="scheduleStartTime"
            rules={{
              required: true,
              validate: {
                maxDate: (v) =>
                  v && scheduleStartTimeMax ? v <= scheduleStartTimeMax : true,
              },
            }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <TimePicker
                disabled={isDisabled}
                value={value}
                onChange={async (d) => {
                  onChange(d);
                  await sleep(100);
                  trigger('scheduleEndTime');
                }}
                maxTime={scheduleStartTimeMax}
                ampm={false}
                timeSteps={scheduleTimeSteps}
                slotProps={{
                  textField: {
                    label: (
                      <>
                        {t('interview-invitation-form:scheduleStartTimeLabel')}
                        <RequiredAsterisk />
                      </>
                    ),
                    placeholder: t(
                      'interview-invitation-form:scheduleStartTimePlaceholder',
                    ),
                    InputLabelProps: { shrink: true },
                    inputProps: { readOnly: true },
                    error: !!error,
                  },
                }}
                sx={{ flex: 1 }}
              />
            )}
          />
          <Typography variant="body1" textAlign="center">
            {t('interview-invitation-form:scheduleStartEndDivider')}
          </Typography>
          <Controller
            control={control}
            name="scheduleEndTime"
            rules={{
              required: true,
              validate: {
                minDate: (v) =>
                  v && scheduleEndTimeMin ? v >= scheduleEndTimeMin : true,
              },
            }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <TimePicker
                disabled={isDisabled}
                value={value}
                onChange={async (d) => {
                  onChange(d);
                  await sleep(100);
                  trigger('scheduleStartTime');
                }}
                minTime={scheduleEndTimeMin}
                ampm={false}
                timeSteps={scheduleTimeSteps}
                slotProps={{
                  textField: {
                    label: (
                      <>
                        {t('interview-invitation-form:scheduleEndTimeLabel')}
                        <RequiredAsterisk />
                      </>
                    ),
                    placeholder: t(
                      'interview-invitation-form:scheduleEndTimePlaceholder',
                    ),
                    InputLabelProps: { shrink: true },
                    inputProps: { readOnly: true },
                    error: !!error,
                  },
                }}
                sx={{ flex: 1 }}
              />
            )}
          />
        </Stack>
      </Stack>

      {/* Size type section */}
      <Stack gap={1.5}>
        <Stack gap={0.25}>
          <Typography variant="subtitle1">
            {t('interview-invitation-form:sizeTypeTitle')}
            <RequiredAsterisk />
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {t('interview-invitation-form:sizeTypeSubtitle')}
          </Typography>
        </Stack>

        <Controller
          control={control}
          name="sizeType"
          rules={{ required: true }}
          render={({ field: { value, onChange } }) => (
            <Stack
              component={RadioGroup}
              direction={isMobile ? 'column' : 'row'}
              gap={isMobile ? 1 : 6}
              value={value}
              onChange={onChange}
            >
              {sizeTypeOptions.map((opt) => {
                const disableTooltip = opt.additional?.disableTooltip;

                return (
                  <Tooltip
                    key={opt.value}
                    title={!isDisabled && disableTooltip}
                    placement="bottom"
                    PopperProps={{
                      sx: {
                        '& .MuiTooltip-tooltip': {
                          maxWidth: '176px',
                          p: '8px',
                          textAlign: 'center',
                          fontSize: '12px',
                        },
                      },
                    }}
                    arrow
                  >
                    <FormControlLabel
                      control={<Radio />}
                      value={opt.value}
                      label={opt.label}
                      disabled={isDisabled || !!disableTooltip}
                    />
                  </Tooltip>
                );
              })}
            </Stack>
          )}
        />
      </Stack>

      {/* Type section */}
      <Stack gap={2.5}>
        <Stack gap={0.25}>
          <Typography variant="subtitle1">
            {t('interview-invitation-form:typeTitle')}
            <RequiredAsterisk />
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {t('interview-invitation-form:typeSubtitle')}
          </Typography>
        </Stack>

        <Controller
          control={control}
          name="type"
          rules={{ required: true }}
          render={({ field: { value } }) => (
            <Stack component={RadioGroup} gap={4}>
              {typeOptions.map((opt) => {
                const isSelected = value === opt.type;
                const onChangeDisabled = isDisabled || isSelected;

                return (
                  <Stack
                    key={opt.type}
                    direction="row"
                    alignItems="flex-start"
                    gap={0.5}
                    onClick={() =>
                      !onChangeDisabled && handleChangeSizeType(opt.type)
                    }
                  >
                    <Radio
                      value={opt.type}
                      edge="start"
                      checked={isSelected}
                      disabled={isDisabled}
                    />

                    <Stack gap={2.5} pt={1.1} width="100%">
                      <Stack gap={0.25}>
                        <Typography
                          variant="body1"
                          color={isDisabled ? 'text.disabled' : 'text.primary'}
                        >
                          {opt.title}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {opt.subtitle}
                        </Typography>
                      </Stack>

                      {opt.input({ isSelected })}
                    </Stack>
                  </Stack>
                );
              })}
            </Stack>
          )}
        />
      </Stack>
    </Stack>
  );
};

export default InterviewInvitationFormSecondStep;
