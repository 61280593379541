import {
  brandColors,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@kitalulus/web-ui-kit';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import AcceptRejectPreview from '~/components/Applicant/AcceptRejectPreview';
import CloseIcon from '~/components/Icons/CloseIcon';
import EmailIconOutlined from '~/components/Icons/EmailIconOutlined';
import LoadingButton from '~/components/LoadingButton';
import SnackbarIcon from '~/components/Snackbar/SnackbarIcon';
import SlideUpDialogTransition from '~/components/Transistions/SlideUpDialogTransition';
import { useAppDispatch } from '~/hooks/use-store';
import { closeDialog } from '~/store/views/dialogs-slice';
import { ApplicantAcceptDialogProps } from './ApplicantAcceptDialog.types';
import Header from './Header';
import useApplicantDialog from '~/hooks/applicants/use-applicant-dialog';

const ApplicantAcceptDialog = ({
  content,
  ...props
}: ApplicantAcceptDialogProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { checkIsLoading } = useApplicantDialog();

  const handleClose = useCallback(() => {
    dispatch(closeDialog('applicantAcceptDialog'));
  }, []);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isSingleUpdate = content?.applicants.length === 1;

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      fullScreen={isMobile}
      sx={{
        top: { xs: '10%', sm: 0 },
        borderRadius: '16px 16px 0px 0px',
      }}
      PaperProps={{ sx: { p: { xs: 0.3, sm: 2 } } }}
      scroll={'paper'}
      {...(isMobile && { TransitionComponent: SlideUpDialogTransition })}
      {...props}
    >
      <DialogTitle>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="start"
          ml={{ xs: 0, sm: 3 }}
        >
          <Stack direction="row" gap={1} sx={{ flexBasis: '90%' }}>
            <SnackbarIcon
              icon={'eva:checkmark-circle-2-fill'}
              color="success"
            />
            <Typography component="div" variant="h5" color="text.primary">
              {t('applicant:acceptDialog.title')}
            </Typography>
          </Stack>
          <Stack sx={{ flexBasis: '10%', alignItems: 'end' }}>
            <IconButton
              sx={{ top: -5, right: { xs: -10, sm: -25 } }}
              size="small"
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack gap={3} justifyContent="center" alignItems="center">
          <Header sx={{ width: '100%' }} />
          <Stack
            direction="row"
            gap={1}
            mx={{ xs: 0, sm: 3 }}
            alignSelf="start"
          >
            <EmailIconOutlined
              sx={{ width: 16, color: brandColors.neutral[700] }}
            />
            <Typography variant="body2" sx={{ color: brandColors.grey[600] }}>
              {t('applicant:acceptDialog.notice')}
            </Typography>
          </Stack>
          <AcceptRejectPreview text={t('applicant:acceptDialog.preview')} />
        </Stack>
      </DialogContent>
      <DialogActions
        sx={{
          boxShadow: theme.shadows[4],
          mb: { xs: -0.3, sm: -2 },
          px: 4,
          py: 2,
          mx: { xs: -0.3, sm: -2 },
          justifyContent: { xs: 'center', sm: 'end' },
        }}
      >
        <Button
          onClick={handleClose}
          variant="outlined"
          color="inherit"
          sx={{ width: 150 }}
        >
          {t('common:cancelAlt')}
        </Button>
        <LoadingButton
          onClick={content?.onSubmit}
          loading={checkIsLoading(isSingleUpdate)}
          variant="contained"
          sx={{ width: 150 }}
        >
          {t('common:send')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ApplicantAcceptDialog;
