import { Grid, Stack, Typography, brandColors } from '@kitalulus/web-ui-kit';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

import CheckIcon from '~/components/Icons/CheckIcon';
import CloseIcon from '~/components/Icons/CloseIcon';

import { ScreeningQuestionProps } from './ScreeningQuestionAnswers.types';

const ScreeningQuestionAnswers = ({
  screeningQuestion,
}: ScreeningQuestionProps) => {
  const { t } = useTranslation();

  const isRequirementExist = useMemo(
    () => screeningQuestion?.values?.some((value) => !!value?.requirement),
    [screeningQuestion],
  );

  return (
    <Stack
      spacing="12px"
      sx={{
        borderRadius: 2,
        border: '1px solid rgba(145, 158, 171, 0.24)',
        p: { xs: '6px 12px', sm: 3 },
      }}
    >
      <Grid container sx={{ justifyContent: { xs: 'flex-end', sm: 'unset' } }}>
        <Grid xs={12} sm={10}>
          <Typography variant="body2" fontWeight={600}>
            {screeningQuestion?.title}
          </Typography>
        </Grid>
        {isRequirementExist && (
          <Grid xs={4} sm={2} display={'flex'}>
            <Typography
              variant="body2"
              color={brandColors.grey[600]}
              fontWeight={600}
            >
              {t('applicant-detail:idealAnswers')}
            </Typography>
          </Grid>
        )}
      </Grid>
      {screeningQuestion?.values?.map((value) => {
        return (
          <Grid key={value?.id} container>
            <Grid
              item
              xs={isRequirementExist ? 4 : 6}
              sm={isRequirementExist ? 5 : 6}
              display="flex"
              alignItems="center"
              pr={0.5}
            >
              <Typography variant="body2" sx={{ wordBreak: 'break-word' }}>
                {value?.question}
              </Typography>
            </Grid>
            <Grid
              item
              xs={isRequirementExist ? 4 : 6}
              sm={isRequirementExist ? 5 : 6}
              display="flex"
              alignItems="center"
              pr={0.5}
            >
              <Stack
                direction="row"
                alignItems="center"
                spacing={{ sm: '12px' }}
              >
                {isRequirementExist &&
                  (value?.isMatch ? (
                    <CheckIcon color="success" />
                  ) : (
                    <CloseIcon color="error" />
                  ))}
                <Stack>
                  <Typography variant="body2" sx={{ wordBreak: 'break-word' }}>
                    {value?.answer}
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
            <Grid
              item
              xs={4}
              sm={2}
              display={isRequirementExist ? 'flex' : 'none'}
              alignItems="center"
              pr={0.5}
            >
              <Typography
                variant="body2"
                color={brandColors.grey[600]}
                sx={{ wordBreak: 'break-word' }}
              >
                {value?.requirement}
              </Typography>
            </Grid>
          </Grid>
        );
      })}
    </Stack>
  );
};
export default ScreeningQuestionAnswers;
