import { lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { withUnauthorized } from '~/hoc/with-auth';
import { useRemoteConfigValue } from '~/hooks/firebase/use-remote-config';

const AccountVerification = lazy(() => import('~/pages/AccountVerification'));
const ForgotPassword = lazy(() => import('~/pages/ForgotPassword'));
const PreSignUp = lazy(() => import('~/pages/SignUp/PreSignUp'));
const ResetPassword = lazy(() => import('~/pages/ResetPassword'));
const SelfSignUp = lazy(() => import('~/pages/SelfSignUp'));
const SignIn = lazy(() => import('~/pages/SignIn'));
const SignUpConfirmation = lazy(() => import('~/pages/SignUpConfirmation'));

const UnauthorizedRoutes = () => {
  const isSelfRegistrationActive = useRemoteConfigValue('ff_self_registration');

  return (
    <Routes>
      <Route path="signin" element={<SignIn />} />
      {isSelfRegistrationActive && (
        <>
          <Route path="signup" element={<SelfSignUp />} />
          <Route path="signup/confirm/:id" element={<SignUpConfirmation />} />
          <Route
            path="account-verification/:code"
            element={<AccountVerification />}
          />
        </>
      )}
      <Route path="signup/:code" element={<PreSignUp />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route path="reset-password/:code" element={<ResetPassword />} />
      <Route path="*" element={<Navigate to="/auth/signin" />} />
    </Routes>
  );
};

export default withUnauthorized(UnauthorizedRoutes);
