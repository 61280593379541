import { SvgIcon, SvgIconProps } from '@kitalulus/web-ui-kit';

const LinkedInSquareIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 32 32" {...props}>
      <path d="M0 5.00977C0 2.24834 2.23858 0.00976562 5 0.00976562H27C29.7614 0.00976562 32 2.24834 32 5.00977V27.0098C32 29.7712 29.7614 32.0098 27 32.0098H5C2.23858 32.0098 0 29.7712 0 27.0098V5.00977ZM8.2 13.3098V24.0098H11.6V13.3098H8.2ZM8 9.90977C8 11.0098 8.8 11.8098 9.9 11.8098C11 11.8098 11.8 11.0098 11.8 9.90977C11.8 8.80977 11 8.00977 9.9 8.00977C8.9 8.00977 8 8.80977 8 9.90977ZM20.6 24.0098H23.8V17.4098C23.8 14.1098 21.8 13.0098 19.9 13.0098C18.2 13.0098 17 14.1098 16.7 14.8098V13.3098H13.5V24.0098H16.9V18.3098C16.9 16.8098 17.9 16.0098 18.9 16.0098C19.9 16.0098 20.6 16.5098 20.6 18.2098V24.0098Z" />
    </SvgIcon>
  );
};

export default LinkedInSquareIcon;
