import { useLazyQuery, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';

import {
  QUERY_LOCATION_CITIES,
  QUERY_LOCATION_DISTRICTS,
  QUERY_LOCATION_PROVINCES,
} from '~/graphql/common';
import { DropdownOption } from '~/types';
import {
  LocationCitiesQuery,
  LocationCitiesQueryVariables,
  LocationDistricsQuery,
  LocationDistricsQueryVariables,
  LocationProvincesQuery,
  LocationProvincesQueryVariables,
} from '~/types/graphql/graphql';

export const useLocationOptions = (hasVacancy = true) => {
  const [provinceOptions, setProvinceOptions] = useState<DropdownOption[]>([]);
  const [cityOptions, setCityOptions] = useState<DropdownOption[]>([]);
  const [districtOptions, setDistrictOptions] = useState<DropdownOption[]>([]);
  // selected value
  const [provinceId, setProvinceId] = useState<string | null>(null);
  const [cityId, setCityId] = useState<string | null>(null);

  const { loading: isProvinceLoading } = useQuery<
    LocationProvincesQuery,
    LocationProvincesQueryVariables
  >(QUERY_LOCATION_PROVINCES, {
    variables: { hasVacancy },
    fetchPolicy: 'no-cache',
    onCompleted: ({ locationProvinces: provinces }) => {
      setProvinceOptions(
        (provinces ?? [])
          .map((d) => ({
            value: d?.id,
            label: d?.name,
          }))
          .sort((a, b) => {
            if (!a.label || !b.label) return 0;
            return a.label.localeCompare(b.label);
          }),
      );
    },
    onError: () => setProvinceOptions([]),
  });

  const [fetchCities, { loading: isCityLoading }] = useLazyQuery<
    LocationCitiesQuery,
    LocationCitiesQueryVariables
  >(QUERY_LOCATION_CITIES, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ locationCities: cities }) => {
      setCityOptions(
        (cities ?? [])
          .map((d) => ({
            value: d?.id,
            label: d?.name,
          }))
          .sort((a, b) => {
            if (!a.label || !b.label) return 0;
            return a.label.localeCompare(b.label);
          }),
      );
    },
    onError: () => setCityOptions([]),
  });

  const [fetchDistricts, { loading: isDistrictLoading }] = useLazyQuery<
    LocationDistricsQuery,
    LocationDistricsQueryVariables
  >(QUERY_LOCATION_DISTRICTS, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ locationDistrics: districts }) => {
      setDistrictOptions(
        (districts ?? [])
          .map((d) => ({
            value: d?.id,
            label: d?.name,
          }))
          .sort((a, b) => {
            if (!a.label || !b.label) return 0;
            return a.label.localeCompare(b.label);
          }),
      );
    },
    onError: () => setDistrictOptions([]),
  });

  useEffect(() => {
    if (!provinceId) return;
    fetchCities({ variables: { hasVacancy, parentId: provinceId } });
  }, [provinceId, fetchCities, hasVacancy]);

  useEffect(() => {
    if (!cityId) return;
    fetchDistricts({ variables: { hasVacancy, parentId: cityId } });
  }, [cityId, fetchDistricts, hasVacancy]);

  const isLoading = isProvinceLoading || isCityLoading || isDistrictLoading;

  return {
    provinceOptions,
    cityOptions,
    districtOptions,
    provinceId,
    setProvinceId,
    cityId,
    setCityId,
    isLoading,
    isProvinceLoading,
    isCityLoading,
    isDistrictLoading,
  };
};
