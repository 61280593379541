import { useTranslation } from 'react-i18next';
import { ApplicantInterviewDialogProps } from './ApplicantInterviewDialog.types';
import { clearDialog } from '~/store/views/dialogs-slice';
import { useDispatch } from 'react-redux';
import ErrorIcon from '~/components/Icons/ErrorIcon';
import ConfirmDialogV1 from '~/components/Dialogs/ConfirmDialogV1';
import useApplicantDialog from '~/hooks/applicants/use-applicant-dialog';

const ApplicantInterviewDialog = ({
  content,
  ...props
}: ApplicantInterviewDialogProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { checkIsLoading } = useApplicantDialog();

  const isSingleUpdate = content?.ids.length === 1;

  const handleClose = () => {
    dispatch(clearDialog('applicantInterviewDialog'));
  };

  return (
    <ConfirmDialogV1
      {...props}
      data-test-id="ctApplicantChangeToWawancara"
      titleIcon={<ErrorIcon color="primary" sx={{ m: '8px' }} />}
      title={t('applicant:interviewDialog.title')}
      body={t('applicant:interviewDialog.message')}
      acceptProps={{
        onClick: content?.handleAccept,
        children: t('applicant:interviewDialog.accept'),
        loading: checkIsLoading(isSingleUpdate),
      }}
      cancelProps={{ onClick: handleClose, children: t('common:cancelled') }}
    />
  );
};

export default ApplicantInterviewDialog;
