import {
  Box,
  brandColors,
  Button,
  Stack,
  Typography,
  useTheme,
} from '@kitalulus/web-ui-kit';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import CheckCircleOutlineIconV2 from '~/components/Icons/CheckCircleOutlineIconV2';
import ThumbUpIcon from '~/components/Icons/ThumbUpIcon';
import TruncateText from '~/components/TruncateText';
import { useAppDispatch } from '~/hooks/use-store';
import { setDialog } from '~/store/views/dialogs-slice';
import * as mpe from '~/utils/mixpanel';
import { SubscriptionPlanItemProps } from './SubscriptionPlanItem.types';
import { useCompanyAccManagerWhatsappLink } from '~/hooks/use-company';

const SubscriptionPlanItem = ({
  item,
  onPlanSelect,
  pageLocation,
  linkPosition,
  ...props
}: SubscriptionPlanItemProps) => {
  const waLink = useCompanyAccManagerWhatsappLink(
    'upgradePackage',
    item.packageName ?? '',
  );
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const onSelectClick = useCallback(() => {
    if (pageLocation === 'onBoarding' && item.packageName) {
      mpe.onBoardingPremiumSelect({
        packageOption: item.packageName,
      });
    }
    if (pageLocation === 'dialog' && item.packageName) {
      mpe.premiumOfferingSelect({
        packageOption: item.packageName,
        ...(linkPosition && { linkPosition }),
      });
    }

    dispatch(
      setDialog({
        subscriptionPlanConfirmationDialogV2: {
          open: true,
          content: {
            title: t(
              'subscription:subscriptionOfferV2.selectConfirmationTitle',
            ),
            body: t('subscription:subscriptionOfferV2.selectConfirmationBody'),
            onSubmit: onPlanSelect
              ? async (whatsapp, onClose) => {
                  const openWhatsapp = () => {
                    window.open(waLink, '_blank');
                    onClose();
                  };
                  await onPlanSelect(item, openWhatsapp, whatsapp);
                  if (pageLocation === 'dialog' && item.packageName) {
                    mpe.premiumOfferingSubmit({
                      packageOption: item.packageName,
                      ...(linkPosition && { linkPosition }),
                      contactedPhoneNumber: whatsapp,
                    });
                  }
                  if (pageLocation === 'onBoarding' && item.packageName) {
                    mpe.onBoardingPremiumSubmit({
                      packageOption: item.packageName,
                      contactedPhoneNumber: whatsapp,
                    });
                  }
                }
              : undefined,
            pageLocation: pageLocation,
          },
        },
      }),
    );
  }, [pageLocation, item, onPlanSelect, waLink]);

  return (
    <Stack position="relative" alignItems="center" height="100%" {...props}>
      {/* Recommended badge */}
      <Stack
        direction="row"
        bgcolor="#349C4B"
        height="32px"
        width="224px"
        borderRadius="4px 4px 0 0"
        justifyContent="center"
        alignContent="center"
        color="white"
        spacing={1}
        visibility={item.isPopular ? 'visible' : 'hidden'}
        data-test-id="ctSubscriptionPlanItemRecommend"
      >
        <Typography
          color="inherit"
          fontFamily="poppins"
          fontWeight={500}
          textAlign="center"
          sx={{ lineHeight: '30px' }}
        >
          {t('common:popular')}
        </Typography>
        <ThumbUpIcon
          sx={{ color: 'inherit', fontSize: '14px', height: '100%' }}
        />
      </Stack>

      <Box
        display="flex"
        flexDirection="column"
        boxShadow={theme.customShadows.z16}
        bgcolor={theme.palette.background.default}
        width="100%"
        height="100%"
        borderRadius={3}
        pb={3}
        minHeight="500px"
        position="relative"
        overflow="hidden"
        sx={{
          borderWidth: '1px',
          borderTopWidth: 0,
          borderStyle: 'solid',
          borderColor: theme.palette.divider,
        }}
        data-test-id="ctSubscriptionPlanItem"
      >
        {/* Top blue divider */}
        <Box
          bgcolor={brandColors.primary[600]}
          width="224px"
          height="6px"
          position="absolute"
          top="-1px"
          left={0}
          right={0}
          margin="auto"
          borderRadius="0 0 20px 20px"
        />

        <Stack>
          {/* Blue header section */}
          <Stack alignItems="center" bgcolor="#EDF5FD" px={3} pt={6} pb={4}>
            <Stack
              alignItems="center"
              textAlign="center"
              spacing={1}
              maxWidth="300px"
            >
              <Typography
                variant="h4"
                data-test-id="lbSubscriptionPlanItemName"
              >
                {item.packageName}
              </Typography>
              <Typography
                component="div"
                variant="body1"
                sx={{ height: '86px' }}
              >
                <TruncateText
                  noOfLines={3}
                  sx={{ wordBreak: 'break-word' }}
                  data-test-id="lbSubscriptionPlanItemDescription"
                >
                  {item.packageDescription}
                </TruncateText>
              </Typography>
              <Button
                variant="contained"
                color="primary"
                sx={{ width: '228px' }}
                onClick={onSelectClick}
                data-test-id="btnSubscriptionPlanItemCta"
              >
                {item.btnCopy}
              </Button>
            </Stack>
          </Stack>

          <Stack py={4} px={5} spacing={2}>
            <Typography
              fontWeight={600}
              fontSize="20px"
              data-test-id="lbSubscriptionPlanItemTitle"
            >
              {item.featureTitle ?? t('common:featureAndServices')}
            </Typography>
            <Stack spacing={2} data-test-id="lbSubscriptionPlanItemDescription">
              {item.featureItems.map((feat, i) => (
                <Stack
                  key={`subscriptionTypeItem${i + 1}`}
                  direction="row"
                  spacing={0.75}
                >
                  <CheckCircleOutlineIconV2
                    sx={{
                      color: 'white',
                      fontSize: '20px',
                      position: 'relative',
                      top: '2px',
                    }}
                  />
                  <Typography component="div">
                    <div dangerouslySetInnerHTML={{ __html: feat ?? '' }} />
                  </Typography>
                </Stack>
              ))}
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </Stack>
  );
};

export default SubscriptionPlanItem;
