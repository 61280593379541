import { Badge, Stack, Typography, brandColors } from '@kitalulus/web-ui-kit';
import { useTranslation } from 'react-i18next';

const Title = ({ showNewLabel = false }: { showNewLabel?: boolean }) => {
  const { t } = useTranslation();

  return (
    <Stack spacing="4px">
      <Stack>
        <Typography variant="h6" fontSize={18} fontWeight={700}>
          <Badge
            color="secondary"
            badgeContent={showNewLabel ? `${t('common:new')}!` : 0}
            sx={{
              '& .MuiBadge-badge': {
                textTransform: 'uppercase',
                top: 10,
                right: -30,
              },
            }}
          >
            {t('applicant-detail:screeningQuestionAnswersTitle')}
          </Badge>
        </Typography>
      </Stack>
      <Stack>
        <Typography variant="body2" color={brandColors.grey[600]}>
          {t('applicant-detail:screeningQuestionAnswersSubtitle')}
        </Typography>
      </Stack>
    </Stack>
  );
};
export default Title;
