import { gql } from '~/types/graphql/gql';

export const QUERY_JOB_VACANCIES = gql(`
  query jobVacancies(
    $filter: CommonFilter
    $name: String
    $state: EpJobVacancyState
    $cityId: ID
    $excludeVacancyId: ID
    $sort: String
  ) {
    jobVacancies: epJobVacancies(
      cityId: $cityId
      filter: $filter
      name: $name
      state: $state
      excludeVacancyId: $excludeVacancyId
      sort: $sort
    ) {
      page
      elements
      hasPrevPage
      hasNextPage
      list {
        id
        totalCandidateRecommendation
        createdAt
        updatedAt
        closeDate
        closeDateStr
        positionName
        code
        isPublished
        isHighlighted
        isReposted
        highlightedExpiredAt
        highlightedExpiredAtStr
        highlightedStatus
        webLink
        locationCity {
          id
          name
        }
        jobApplicationStatistic {
          totalAccepted
          totalInterview
          totalOnProgress
          totalPending
          totalRejected
        }
        state
        tierCategory {
          id
          name
        }
      }
    }
  }
`);

export const QUERY_JOB_VACANCIES_ALT = gql(`
  query jobVacancySearchAlt(
    $filter: CommonFilter
    $name: String
    $excludeVacancyId: ID
  ) {
    jobVacancies: epJobVacancies(
      filter: $filter
      name: $name
      excludeVacancyId: $excludeVacancyId
    ) {
      elements
      limit
      page
      size
      list {
        id
        positionName
        code
        createdAt
        closeDate
        locationCity {
          id
          name
        }
      }
    }
  }
`);

export const QUERY_JOB_VACANCY_SUMMARY = gql(`
  query jobVacancySummary($name: String, $cityId: ID) {
    jobVacancySummary: epJobVacancySummary(name: $name, cityId: $cityId) {
      total
      totalClosed
      totalOnHold
      totalOpen
    }
  }
`);

export const QUERY_JOB_VACANCY = gql(`
  query jobVacancy($id: ID!) {
    jobVacancy: epJobVacancyV2(id: $id) {
      ... on EpJobVacancy {
        id
        positionName
        code
        description
        formattedDescription
        benefits {
          key
          value
        }
        state
        status
        contactType
        contactDetail
        hrEmail
        vacancyCount
        closeDate
        webLink
        type
        typeStr
        jobLevel
        jobLevelStr
        locationSite
        locationSiteStr
        jobVacancyFunctionId
        workingDayStartStr
        workingDayStart
        workingDayEndStr
        workingDayEnd
        workingHourStartStr
        workingHourStart
        workingHourEndStr
        workingHourEnd
        salaryLowerBoundStr
        salaryLowerBound
        salaryUpperBoundStr
        salaryUpperBound
        educationLevel
        educationLevelStr
        minExperience
        maxAge
        minAge
        gender
        genderStr
        note
        createdAt
        isPublished
        isHighlighted
        isOpenForFreshGraduate
        isOpenForAllEducationPrograms
        educationProgramPreferences {
          id
          idx
          name
        }
        locationProvince {
          name
          id
        }
        locationCity {
          name
          id
        }
        locationDistrict {
          name
          id
        }
        jobFunction {
          displayName
          id
        }
        jobRole {
          id
          name
          displayName
        }
        jobSpecialization {
          id
          name
          displayName
        }
        skillsV2 {
          displayName
          id
          idx
          isVerified
          name
        }
        totalPending
        totalOnProgress
        totalAccepted
        totalRejected
        requirements {
          id
          jobVacancyReqCategorySubId
          isAdditional
          priority
          vacancyReqCategorySub {
            id
            displayName
            jobVacancyRequirementCategoryId
            name
          }
        }
        restrictions {
          city
          educationLevel
        }
        skillsV2 {
          id
          idx
          name
          displayName
        }
        tierCategory {
          id
          name
        }
      }
      ... on EpJobVacancyError {
        message
        typeError
      }
    }
  }
`);

export const QUERY_JOB_VACANCY_SCREENING_QUESTIONS = gql(`
  query jobVacancyScreeningQuestions($jobVacancyId: ID!) {
    jobVacancyScreeningQuestions: epJobVacancyScreeningQuestions(
      jobVacancyId: $jobVacancyId
    ) {
      list {
        id
        idx
        topic
        title
        description
        question
        answer
        value {
          id
          idx
          name
          displayName
        }
      }
    }
  }
`);

export const MUTATION_OPEN_JOB_VACANCY = gql(`
  mutation openJobVacancy($jobVacancyId: ID!) {
    openJobVacancy: epOpenJobVacancyV2(jobVacancyId: $jobVacancyId) {
      ... on EpJobVacancyGeneratedLink {
        closeDate
        code
        createdAt
        jobPublished {
          current
          hasExceeded
          max
        }
        positionName
        webLink
      }
      ... on EpOpenJobVacancyError {
        message
        typeError
      }
    }
  }
`);

export const MUTATION_OPEN_JOB_VACANCY_V3 = gql(`
  mutation openJobVacancyV3($id: ID!) {
    openJobVacancyV3: epOpenJobVacancyV3(jobVacancyId: $id) {
      ... on EpJobVacancyV3Response {
        closeDate
        code
        createdAt
        positionName
        webLink
      }
      ... on EpOpenJobVacancyV3Error {
        message
        typeError
      }
    }
  }
`);

export const MUTATION_ON_HOLD_JOB_VACANCY = gql(`
  mutation onHoldJobVacancy($jobVacancyId: ID!) {
    onHoldJobVacancy: epOnHoldJobVacancy(jobVacancyId: $jobVacancyId) {
      data
      error
      isSuccess
      warning
    }
  }
`);

export const MUTATION_ON_HOLD_JOB_VACANCY_V2 = gql(`
  mutation onHoldJobVacancyV2($id: ID!) {
    onHoldJobVacancyV2:epOnHoldJobVacancyV2(jobVacancyId: $id) {
      ... on EpJobVacancyV3IdResponse {
        id
      }
      ... on EpOnHoldJobVacancyV2Error {
        message
        typeError
      }
    }
  }
`);

export const MUTATION_CLOSED_JOB_VACANCY = gql(`
  mutation closeJobVacancy($jobVacancyId: ID!) {
    closeJobVacancy: epCloseJobVacancy(jobVacancyId: $jobVacancyId) {
      data
      error
      isSuccess
      warning
    }
  }
`);

export const MUTATION_CLOSED_JOB_VACANCY_V2 = gql(`
  mutation closeJobVacancyV2($id: ID!) {
    closeJobVacancyV2: epCloseJobVacancyV2(jobVacancyId: $id) {
      ... on EpCloseJobVacancyV2Error {
        message
        typeError
      }
      ... on EpJobVacancyV3IdResponse {
        id
      }
    }
  }
`);

export const MUTATION_CREATE_JOB_VACANCY = gql(`
  mutation createJobVacancy($data: EpJobVacancyCreateInput!) {
    createJobVacancy: epCreateJobVacancyV2(data: $data) {
      ... on EpJobVacancyGeneratedLink {
        closeDate
        code
        createdAt
        jobPublished {
          current
          hasExceeded
          max
        }
        positionName
        webLink
      }
      ... on EpCreateJobVacancyError {
        message
        typeError
      }
    }
  }
`);

export const MUTATION_CREATE_JOB_VACANCY_V3 = gql(`
  mutation createJobVacancyV3($data: EpJobVacancyCreateInput!) {
    createJobVacancyV3: epCreateJobVacancyV3(data: $data) {
      ... on EpCreateJobVacancyV3Error {
        message
        typeError
      }
      ... on EpJobVacancyV3Response {
        closeDate
        code
        createdAt
        positionName
        webLink
      }
    }
  }
`);

export const MUTATION_UPDATE_JOB_VACANCY = gql(`
  mutation updateJobVacancy($data: EpJobVacancyUpdateInput!) {
    updateJobVacancy: epUpdateJobVacancyV2(data: $data) {
      ... on EpJobVacancyGeneratedLink {
        closeDate
        code
        createdAt
        jobPublished {
          current
          hasExceeded
          max
        }
        positionName
        webLink
      }
      ... on EpUpdateJobVacancyError {
        message
        typeError
      }
    }
  }
`);

export const MUTATION_UPDATE_JOB_VACANCY_V3 = gql(`
  mutation updateJobVacancyV3($data: EpJobVacancyUpdateInput!) {
    updateJobVacancyV3: epUpdateJobVacancyV3(data: $data) {
      ... on EpJobVacancyV3Response {
        closeDate
        code
        createdAt
        positionName
        webLink
      }
      ... on EpUpdateJobVacancyV3Error {
        message
        typeError
      }
    }
  }
`);

export const MUTATION_REPOST_JOB_VACANCY = gql(`
  mutation repostJobVacancy($data: EpJobVacancyUpdateInput!) {
    repostJobVacancy: epRepostJobVacancy(data: $data) {
      ... on EpJobVacancyV3Response {
        closeDate
        code
        createdAt
        positionName
        webLink
      }
      ... on EpRepostJobVacancyError {
        message
        typeError
      }
    }
  }
`);

export const MUTATION_ACTIVATE_JOB_VACANCY_HIGHLIGHT = gql(`
  mutation activateJobVacancyHighlight($jobVacancyId: ID!) {
    activateJobVacancyHighlight: epActivateJobVacancyHighlight(
      jobVacancyId: $jobVacancyId
    ) {
      data
      error
      isSuccess
    }
  }
`);

export const MUTATION_ACTIVATE_JOB_VACANCY_HIGHLIGHT_V2 = gql(`
  mutation activateJobVacancyHighlightV2($id: ID!) {
    activateJobVacancyHighlightV2: epActivateJobVacancyHighlightV2(jobVacancyId: $id) {
      ... on EpActivateJobVacancyHighlightV2Error {
        message
        typeError
      }
      ... on EpJobVacancyV3IdResponse {
        id
      }
    }
  }
`);

export const MUTATION_DEACTIVATE_JOB_VACANCY_HIGHLIGHT = gql(`
  mutation deactivateJobVacancyHighlight($jobVacancyId: ID!) {
    deactivateJobVacancyHighlight: epDeactivateJobVacancyHighlight(
      jobVacancyId: $jobVacancyId
    ) {
      data
      error
      isSuccess
    }
  }
`);

export const MUTATION_DEACTIVATE_JOB_VACANCY_HIGHLIGHT_V2 = gql(`
  mutation deactivateJobVacancyHighlightV2($id: ID!) {
    deactivateJobVacancyHighlightV2: epDeactivateJobVacancyHighlightV2(jobVacancyId: $id) {
      ... on EpDeactivateJobVacancyHighlightV2Error {
        message
        typeError
      }
      ... on EpJobVacancyV3IdResponse {
        id
      }
    }
  }
`);

export const QUERY_VALIDATE_HIGHLIGHT_PROMPT = gql(`
  query validateHighlightPrompt {
    validateHighlightPrompt: epValidateHighlightPrompt
  }
`);

export const QUERY_JOB_VACANCY_DISTINCT_SEARCH = gql(`
  query jobVacancyDistinctSearch($filter: CommonFilter, $positionName: String) {
    jobVacancyDistinctSearch: epJobVacancyDistinctSearch(
      filter: $filter
      positionName: $positionName
    ) {
      elements
      limit
      page
      list
    }
  }
`);

export const QUERY_JOB_VACANCY_CLOSED_FEEDBACK_TEMPLATES = gql(`
  query jobVacancyClosedFeedbackTemplates {
    jobVacancyClosedFeedbackTemplates: epJobVacancyClosedFeedbackTemplates {
      list {
        id
        inputType
        content
      }
    }
  }
`);

export const MUTATION_CREATE_JOB_VACANCY_CLOSED_FEEDBACK = gql(`
  mutation createJobVacancyClosedFeedback(
    $payload: EpJobVacancyClosedFeedbackInput
  ) {
    createJobVacancyClosedFeedback: epCreateJobVacancyClosedFeedback(
      payload: $payload
    ) {
      isSuccess
    }
  }
`);

export const QUERY_SCREENING_QUESTIONS = gql(`
  query screeningQuestions {
    screeningQuestions: epScreeningQuestions {
      list {
        id
        idx
        topic
        title
        description
        question
        answer
        value {
          id
          idx
          name
          displayName
        }
      }
    }
  }
`);

export const QUERY_JOB_VACANCY_SCREENING_QUESTIONS_FILTER = gql(`
  query jobVacancyScreeningQuestionsFilter($jobVacancyId: ID!) {
    jobVacancyScreeningQuestionsFilter: epJobVacancyScreeningQuestionsFilter(
      jobVacancyId: $jobVacancyId
    ) {
      choices {
        key
        value
      }
      description
      entries {
        key
        value
      }
      id
      isRequired
      title
      topic
      uiType
    }
  }
`);

export const MUTATION_JOB_VACANCY_PUBLISHED_SYNC = gql(`
  mutation syncPublishedJobVacancy {
    syncPublishedJobVacancy: epSyncPublishedJobVacancy {
      ... on EpSyncPublishedJobVacancyError {
        message
        typeError
      }
      ... on EpSyncPublishedJobVacancyResponse {
        jobPublished {
          current
          hasExceeded
          max
        }
      }
    }
  }
`);

export const MUTATION_SUBMIT_CONTACTED_PHONE_NUMBER = gql(`
  mutation submitContactedPhoneNumber($packageId: ID!, $phoneNumber: String!) {
    submitContactedPhoneNumber:epSubmitContactedPhoneNumber(packageId: $packageId, phoneNumber: $phoneNumber) {
      ... on EpSubmitContactedPhoneNumberResponse {
        packageId
      }
      ... on EpSubmitContactedPhoneNumberResponseError {
        message
        typeError
      }
    }
  }
`);

export const QUERY_JOB_VACANCY_TIER_CATEGORY = gql(`
  query jobVacancyTierCategory($cityId: ID!, $specializationRoleId: ID!) {
    jobVacancyTierCategory: epJobVacancyTierCategory(cityId: $cityId, specializationRoleId: $specializationRoleId) {
      ... on EpJobVacancyTierCategoryError {
        message
        typeError
      }
      ... on EpJobVacancyTierCategoryResponse {
        id
        name
      }
    }
  }
`);
