import { SvgIcon, SvgIconProps } from '@kitalulus/web-ui-kit';

const ConsLocationIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 16 16" {...props}>
      <g>
        <path d="M7.99967 1.33301C5.41967 1.33301 3.33301 3.41967 3.33301 5.99967C3.33301 9.49967 7.99967 14.6663 7.99967 14.6663C7.99967 14.6663 12.6663 9.49967 12.6663 5.99967C12.6663 3.41967 10.5797 1.33301 7.99967 1.33301ZM4.66634 5.99967C4.66634 4.15967 6.15967 2.66634 7.99967 2.66634C9.83967 2.66634 11.333 4.15967 11.333 5.99967C11.333 7.91967 9.41301 10.793 7.99967 12.5863C6.61301 10.8063 4.66634 7.89967 4.66634 5.99967Z" />
        <path d="M7.99967 7.66634C8.92015 7.66634 9.66634 6.92015 9.66634 5.99967C9.66634 5.0792 8.92015 4.33301 7.99967 4.33301C7.0792 4.33301 6.33301 5.0792 6.33301 5.99967C6.33301 6.92015 7.0792 7.66634 7.99967 7.66634Z" />
      </g>
    </SvgIcon>
  );
};

export default ConsLocationIcon;
