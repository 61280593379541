import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  MUTATION_REQUEST_USER_SUBSCRIPTION_TYPE,
  QUERY_OFFERING_PACKAGES,
  QUERY_PACKAGE_FEATURES,
  QUERY_SUBSCRIPTION_PLAN_STATUS_DETAIL,
  QUERY_USER_SUBSCRIPTION_TYPES,
} from '~/graphql/user';
import { useAppDispatch, useAppSelector } from '~/hooks/use-store';
import { setPackageFeatures } from '~/store/features/user-subscription-slice';
import { setSnackbar, SnackbarLayoutType } from '~/store/views/snackbar-slice';
import { Nullable } from '~/types';
import {
  EpUserSubscriptionType,
  EpSubscriptionPlanStatusDetail,
  RequestUserSubscriptionTypeMutation,
  RequestUserSubscriptionTypeMutationVariables,
  SubscriptionPlanStatusDetailQuery,
  SubscriptionPlanStatusDetailQueryVariables,
  UserSubscriptionTypesQuery,
  UserSubscriptionTypesQueryVariables,
  PackageFeaturesQuery,
  PackageFeaturesQueryVariables,
  OfferingPackagesQueryVariables,
  OfferingPackagesQuery,
  EpOfferingPackage,
} from '~/types/graphql/graphql';
import { FETCH_STATUS } from '~/utils/constants';

export const useUserSubscriptionTypes = (companyId?: Nullable<string>) => {
  const [subscriptionTypes, setSubscriptionTypes] = useState<
    EpUserSubscriptionType[]
  >([]);

  const { loading } = useQuery<
    UserSubscriptionTypesQuery,
    UserSubscriptionTypesQueryVariables
  >(QUERY_USER_SUBSCRIPTION_TYPES, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: { companyId },
    onCompleted: ({ userSubscriptionTypes: types }) => {
      const items = types.list.filter(
        (item) => !!item,
      ) as typeof subscriptionTypes;
      setSubscriptionTypes(items);
    },
  });

  return { subscriptionTypes, isLoading: loading };
};

export const useOfferingPackages = () => {
  const [packages, setPackages] = useState<EpOfferingPackage[]>([]);

  const { loading } = useQuery<
    OfferingPackagesQuery,
    OfferingPackagesQueryVariables
  >(QUERY_OFFERING_PACKAGES, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ offeringPackages }) => {
      const items = offeringPackages?.list.filter((item) => !!item);
      setPackages(items ?? []);
    },
  });

  return { packages, isLoading: loading };
};

export const useUserSubscriptionPlanStatusDetail = () => {
  const companyId = useAppSelector((state) => state.company?.id);
  const [planDetails, setPlanDetails] = useState<
    EpSubscriptionPlanStatusDetail[]
  >([]);

  const [fetchPlanDetails, { loading }] = useLazyQuery<
    SubscriptionPlanStatusDetailQuery,
    SubscriptionPlanStatusDetailQueryVariables
  >(QUERY_SUBSCRIPTION_PLAN_STATUS_DETAIL, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ subscriptionPlanStatusDetail: details }) => {
      const items = details.filter((item) => !!item) as typeof planDetails;
      setPlanDetails(items);
    },
  });

  useEffect(() => {
    if (!companyId) return;
    fetchPlanDetails({ variables: { companyId } });
  }, [companyId]);

  return { planDetails, isLoading: loading };
};

export const useUserSubscriptionRequest = () => {
  const { t } = useTranslation();
  const companyId = useAppSelector((state) => state.company?.id);
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Nullable<string>>();

  const [requestSubscription] = useMutation<
    RequestUserSubscriptionTypeMutation,
    RequestUserSubscriptionTypeMutationVariables
  >(MUTATION_REQUEST_USER_SUBSCRIPTION_TYPE);

  const handleRequest = useCallback(
    async (
      subscriptionTypeId: string,
      successAlert?: {
        type?: 'from-api' | 'self-registration-success' | 'no-alert';
        layout?: SnackbarLayoutType;
      },
    ) => {
      try {
        if (!companyId) return;
        setIsLoading(true);
        setError(null);
        const res = await requestSubscription({
          variables: { companyId, subscriptionTypeId },
        });

        const data = res.data?.requestUserSubscriptionType;
        if (!data) return;
        if (!data.isSuccess) throw new Error(data.error ?? 'Unknown');

        // on success
        if (successAlert) {
          switch (successAlert.type) {
            case 'from-api':
              dispatch(
                setSnackbar({
                  layout: successAlert.layout,
                  severity: 'success',
                  message: data.data,
                }),
              );
              break;

            case 'self-registration-success':
              dispatch(
                setSnackbar({
                  layout: successAlert.layout,
                  severity: 'success',
                  message: t('company:companyRegistrationSuccess'),
                }),
              );
              break;

            case 'no-alert':
            default:
              break;
          }
        }

        return data;
      } catch (err: any) {
        setError(err.message);
        dispatch(
          setSnackbar({
            severity: 'error',
            message: err.message,
          }),
        );
      } finally {
        setIsLoading(false);
      }
    },
    [requestSubscription, companyId],
  );

  return { handleRequest, isLoading, error };
};

export const usePackageFeatures = () => {
  const dispatch = useAppDispatch();
  const packageFeatures = useAppSelector(
    (state) => state.userSubscription.packageFeatures,
  );

  const [fetch] = useLazyQuery<
    PackageFeaturesQuery,
    PackageFeaturesQueryVariables
  >(QUERY_PACKAGE_FEATURES, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ packageFeatures }) => {
      dispatch(
        setPackageFeatures({
          fetchStatus: FETCH_STATUS.RESOLVED,
          data: packageFeatures,
        }),
      );
    },
    onError: () => {
      dispatch(
        setPackageFeatures({
          fetchStatus: FETCH_STATUS.FAILED,
        }),
      );
    },
  });

  const fetchPackageFeatures = useCallback(() => {
    dispatch(
      setPackageFeatures({
        fetchStatus: FETCH_STATUS.PENDING,
      }),
    );
    fetch();
  }, []);

  return {
    fetchPackageFeatures,
    packageFeatures,
    isLoading:
      packageFeatures.fetchStatus !== FETCH_STATUS.RESOLVED &&
      packageFeatures.fetchStatus !== FETCH_STATUS.FAILED,
  };
};
