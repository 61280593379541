import { SvgIcon, SvgIconProps } from '@kitalulus/web-ui-kit';

const ArrowLeftIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 25 24" {...props}>
      <g clipPath="url(#clip0_348_47706)">
        <path d="M19 11L7.83 11L12.71 6.12C13.1 5.73 13.1 5.09 12.71 4.7C12.32 4.31 11.69 4.31 11.3 4.7L4.71 11.29C4.32 11.68 4.32 12.31 4.71 12.7L11.29 19.3C11.68 19.69 12.31 19.69 12.7 19.3C13.09 18.91 13.09 18.28 12.7 17.89L7.83 13L19 13C19.55 13 20 12.55 20 12C20 11.45 19.55 11 19 11Z" />
      </g>
      <defs>
        <clipPath id="clip0_348_47706">
          <rect
            width="24"
            height="24"
            transform="translate(24 24) rotate(180)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default ArrowLeftIcon;
