import { SvgIcon, SvgIconProps } from '@kitalulus/web-ui-kit';

const ChevronDownIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 12 7" {...props}>
      <path d="M5.99846 6.3333C5.80375 6.33368 5.61505 6.26586 5.46513 6.14163L0.465125 1.97496C0.110742 1.68041 0.0622398 1.15435 0.356792 0.799964C0.651344 0.445581 1.17741 0.397079 1.53179 0.691631L5.99846 4.42496L10.4651 0.824964C10.6373 0.685155 10.8581 0.61974 11.0786 0.643201C11.2991 0.666662 11.5012 0.777064 11.6401 0.949964C11.7944 1.12318 11.8695 1.35286 11.8474 1.58376C11.8252 1.81466 11.7078 2.02589 11.5235 2.16663L6.52346 6.19163C6.36922 6.29623 6.18438 6.34611 5.99846 6.3333Z" />
    </SvgIcon>
  );
};

export default ChevronDownIcon;
