import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Nullable } from '~/types';
import {
  EpJobVacancy,
  EpScreeningQuestionsFilter,
} from '~/types/graphql/graphql';
import { FETCH_STATUS } from '~/utils/constants';

export type TVacancyDetailScreeningQuestionFilter = Nullable<{
  data?: Nullable<EpScreeningQuestionsFilter[]>;
  fetchStatus?: FETCH_STATUS;
}>;

export type TVacancyDetailV2DetailErrorType = 'NOT_FOUND';

export type TVacancyDetailV2Detail = {
  data: Nullable<EpJobVacancy>;
  fetchStatus: FETCH_STATUS;
  errorType: Nullable<TVacancyDetailV2DetailErrorType>;
};

export type TVacancyDetailV2State = {
  screeningQuestionsFilter: TVacancyDetailScreeningQuestionFilter;
  detail: TVacancyDetailV2Detail;
};

const initialState: TVacancyDetailV2State = {
  screeningQuestionsFilter: null,
  detail: {
    data: null,
    fetchStatus: FETCH_STATUS.IDLE,
    errorType: null,
  },
};

export const vacancy2Slice = createSlice({
  name: 'vacancyDetailV2',
  initialState: initialState,
  reducers: {
    setVacancyDetailV2Details: (
      state,
      action: PayloadAction<Partial<TVacancyDetailV2Detail>>,
    ) => {
      state.detail = {
        ...state.detail,
        ...action.payload,
      };
    },
    setVacancyDetailScreeningQuestionsFilter: (
      state,
      action: PayloadAction<Partial<TVacancyDetailScreeningQuestionFilter>>,
    ) => {
      state.screeningQuestionsFilter = {
        ...state.screeningQuestionsFilter,
        ...action.payload,
      };
    },
    clearVacancyDetail: () => initialState,
  },
});

export const {
  setVacancyDetailV2Details,
  setVacancyDetailScreeningQuestionsFilter,
  clearVacancyDetail,
} = vacancy2Slice.actions;

export default vacancy2Slice.reducer;
