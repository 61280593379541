import { SnackbarProps } from '@kitalulus/web-ui-kit';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Nullable } from '~/types';

export type SnackbarLayoutType = 'auth' | 'dashboard' | 'default';

export type SnackbarState = Nullable<
  Partial<Omit<SnackbarProps, 'onClose'>> & {
    layout?: SnackbarLayoutType;
    onClose?: (closeSnakbar: () => void) => void;
    'data-test-id'?: string;
  }
>;

export const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState: null as SnackbarState,
  reducers: {
    setSnackbar: (_, action: PayloadAction<SnackbarState>) => action.payload,
    clearSnackbar: () => null,
  },
});

export const { setSnackbar, clearSnackbar } = snackbarSlice.actions;

export default snackbarSlice.reducer;
