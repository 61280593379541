import { Stack, Paper, useTheme, brandColors } from '@kitalulus/web-ui-kit';
import { Skeleton } from '@mui/material';

import Contents from '~/components/GlobalDialogs/ProfilePreviewDialog/Contents';
import { useAppSelector } from '~/hooks/use-store';
import { FETCH_STATUS } from '~/utils/constants';
import { PROFILE_VARIANTS } from '~/utils/constants/profile';

import { ProfilePreviewProps } from './ProfilePreviewDialog.types';

const ProfilePreview = ({
  isShowShimmer,
  profileLoading,
  content,
}: ProfilePreviewProps) => {
  const theme = useTheme();

  const { applicantDetail } = useAppSelector((state) => state);
  const { candidateRecommendationDetail } = useAppSelector((state) => state);

  const { data: dataApplicantDetail } = applicantDetail;
  const { data: dataCandidateRecommendationDetail } =
    candidateRecommendationDetail.detail;

  const lookUpData = {
    [PROFILE_VARIANTS.APPLICANT]: dataApplicantDetail,
    [PROFILE_VARIANTS.CANDIDATE_RECOMMENDATION]:
      dataCandidateRecommendationDetail,
  };
  const profileData = lookUpData[content.variant];

  return (
    <>
      {isShowShimmer && (
        <Paper
          sx={{
            borderRadius: theme.spacing(2),
            boxShadow: theme.customShadows['card'],
            border: `1px solid ${brandColors.neutral[400]}`,
            p: 3,
          }}
        >
          <Stack
            direction="column"
            sx={{
              minHeight: 100,
            }}
          >
            <Skeleton variant="text" width={100} />
            <Skeleton variant="text" width={'70%'} />
            <Skeleton variant="text" width={'70%'} />
            <Skeleton variant="text" width={'70%'} />
            <Skeleton variant="text" width={100} sx={{ marginTop: 3 }} />
            <Skeleton variant="text" width={'70%'} />
            <Skeleton variant="text" width={'70%'} />
            <Skeleton variant="text" width={'70%'} />
          </Stack>
        </Paper>
      )}
      {profileLoading === FETCH_STATUS.RESOLVED && (
        <Contents profileData={profileData} variant={content.variant} />
      )}
    </>
  );
};
export default ProfilePreview;
