import { SvgIcon, SvgIconProps } from '@kitalulus/web-ui-kit';

const StopCircleIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        d="M9 16H15C15.55 16 16 15.55 16 15V9C16 8.45 15.55 8 15 8H9C8.45 8 8 8.45 8 9V15C8 15.55 8.45 16 9 16ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2Z"
      />
    </SvgIcon>
  );
};

export default StopCircleIcon;
