import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DropdownOption, StateSetterFunc } from '~/types';
import {
  InterviewSessionApplicationsQuery,
  InterviewSessionByIdQuery,
  InterviewSessionTabsQuery,
} from '~/types/graphql/graphql';
import { FETCH_STATUS } from '~/utils/constants';

type TError = {
  message?: string;
  state: 'ERROR_QUERY';
} | null;

type TInterviewSessionsListDetail = {
  fetchStatus: FETCH_STATUS;
  data: Partial<InterviewSessionByIdQuery['interviewSessionById']>;
  error: TError;
};

type TInterviewSessionsListDetailListState = {
  fetchStatus: FETCH_STATUS;
  data: Partial<
    InterviewSessionApplicationsQuery['interviewSessionApplications']
  >;
  error: TError;
};

type TInterviewSessionsListDetailTabsState = {
  fetchStatus: FETCH_STATUS;
  data: Partial<InterviewSessionTabsQuery['interviewSessionTabs']>;
  error: TError;
  isFirstRequest: boolean;
};

type TInterviewSessionOptions = {
  fetchStatus: FETCH_STATUS;
  data: DropdownOption[];
  error: string | null;
  selected: DropdownOption | null;
};

type TInitialState = {
  detail: TInterviewSessionsListDetail;
  list: TInterviewSessionsListDetailListState;
  tabs: TInterviewSessionsListDetailTabsState;
  interviewSessionOptions: TInterviewSessionOptions;
  selectedRow: string[];
};

const initialState: TInitialState = {
  detail: {
    fetchStatus: FETCH_STATUS.IDLE,
    data: null,
    error: null,
  },
  list: {
    fetchStatus: FETCH_STATUS.IDLE,
    data: null,
    error: null,
  },
  tabs: {
    fetchStatus: FETCH_STATUS.IDLE,
    data: null,
    error: null,
    isFirstRequest: true,
  },
  interviewSessionOptions: {
    fetchStatus: FETCH_STATUS.IDLE,
    data: [],
    error: null,
    selected: {
      label: '',
      value: '',
    },
  },
  selectedRow: [],
};

export const interviewSessionsListSlice = createSlice({
  name: 'interviewSessionsListDetail',
  initialState: initialState,
  reducers: {
    setInterviewSessionsListDetail: (
      state,
      action: PayloadAction<
        StateSetterFunc<Partial<TInterviewSessionsListDetail>>
      >,
    ) => {
      return {
        ...state,
        detail: {
          ...state.detail,
          ...action.payload,
        },
      };
    },
    setInterviewSessionsListDetailList: (
      state,
      action: PayloadAction<
        StateSetterFunc<Partial<TInterviewSessionsListDetailListState>>
      >,
    ) => {
      return {
        ...state,
        list: {
          ...state.list,
          ...action.payload,
        },
      };
    },
    setInterviewSessionsListDetailListError: (
      state,
      action: PayloadAction<TError>,
    ) => {
      return {
        ...state,
        list: {
          ...state.list,
          fetchStatus: FETCH_STATUS.FAILED,
          error: action.payload,
        },
      };
    },
    setInterviewSessionsListDetailTabs: (
      state,
      action: PayloadAction<
        StateSetterFunc<Partial<TInterviewSessionsListDetailTabsState>>
      >,
    ) => {
      return {
        ...state,
        tabs: {
          ...state.tabs,
          ...action.payload,
        },
      };
    },
    setInterviewSessionOptions: (
      state,
      action: PayloadAction<DropdownOption>,
    ) => {
      const payloadFiltered = state.interviewSessionOptions.data.filter(
        ({ value }) => value !== action.payload.value,
      );

      return {
        ...state,
        interviewSessionOptions: {
          ...state.interviewSessionOptions,
          fetchStatus: FETCH_STATUS.RESOLVED,
          data: [action.payload, ...payloadFiltered],
        },
      };
    },
    setInterviewSessionOptionsMore: (
      state,
      action: PayloadAction<DropdownOption[]>,
    ) => {
      const payloadValue = action.payload.map((data) => data.value);
      const payloadFiltered = state.interviewSessionOptions.data.filter(
        ({ value }) => !payloadValue.includes(value),
      );

      return {
        ...state,
        interviewSessionOptions: {
          ...state.interviewSessionOptions,
          fetchStatus: FETCH_STATUS.RESOLVED,
          data: [...payloadFiltered, ...action.payload],
        },
      };
    },
    setInterviewSessionSelect: (
      state,
      action: PayloadAction<DropdownOption>,
    ) => {
      return {
        ...state,
        interviewSessionOptions: {
          ...state.interviewSessionOptions,
          selected: action.payload,
        },
      };
    },
    setInterviewSessionsListDetailSelectAllRows: (
      state,
      action: PayloadAction<string[]>,
    ) => {
      return {
        ...state,
        selectedRow: action.payload,
      };
    },
    setInterviewSessionsListDetailSelectRow: (
      state,
      action: PayloadAction<string>,
    ) => {
      const payload = action.payload;
      const index = state.selectedRow.indexOf(payload);

      if (index >= 0) {
        // Remove if exists
        state.selectedRow.splice(index, 1);
      } else {
        // Add if not exists
        state.selectedRow.push(payload);
      }
    },
    clearInterviewSessionsSelectedOption: (state) => {
      return {
        ...state,
        interviewSessionOptions: {
          ...state.interviewSessionOptions,
          selected: null,
        },
        // clear tab count
        tabs: {
          ...state.tabs,
          data: (state.tabs.data || []).map((tab) => ({
            ...tab,
            numberOfApplicants: 0,
          })),
        },
        // clear list for pagination
        list: {
          ...state.list,
          data: null,
        },
      };
    },
    clearInterviewSessionsListDetail: () => {
      return initialState;
    },
  },
});

export const {
  setInterviewSessionsListDetail,
  setInterviewSessionsListDetailList,
  setInterviewSessionsListDetailListError,
  setInterviewSessionsListDetailTabs,
  setInterviewSessionOptions,
  setInterviewSessionOptionsMore,
  setInterviewSessionSelect,
  setInterviewSessionsListDetailSelectRow,
  setInterviewSessionsListDetailSelectAllRows,
  clearInterviewSessionsSelectedOption,
  clearInterviewSessionsListDetail,
} = interviewSessionsListSlice.actions;

export default interviewSessionsListSlice.reducer;
