import {
  Box,
  brandColors,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@kitalulus/web-ui-kit';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import AuthLayoutCopyRight from '~/components/AuthLayoutV2/AuthLayoutCopyRight';
import CloseIcon from '~/components/Icons/CloseIcon';
import SubscriptionPlan from '~/components/SubscriptionPlan';
import { useAppDispatch, useAppSelector } from '~/hooks/use-store';
import {
  useUserSubscriptionRequest,
  useUserSubscriptionTypes,
} from '~/hooks/use-user-subscription';
import { clearDialog } from '~/store/views/dialogs-slice';
import {
  EpFreeTrialStatus,
  EpUserSubscriptionType,
} from '~/types/graphql/graphql';
import * as mpe from '~/utils/mixpanel';
import { SubscriptionPlanDialogProps } from './SubscriptionPlanDialog.types';
import { MIXPANEL_ACCOUNT_FAQ_LINK_POSITION } from '~/utils/constants/mixpanel';

const SubscriptionPlanDialog = ({
  content,
  ...props
}: SubscriptionPlanDialogProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { company, realtimeData } = useAppSelector((state) => state);
  const companyId = company?.id;

  const { subscriptionTypes, isLoading } = useUserSubscriptionTypes(companyId);
  const { handleRequest } = useUserSubscriptionRequest();

  const isOnFreeTrial = useMemo(() => {
    const realtimeDataCompany = realtimeData.company;

    const status = realtimeDataCompany?.data?.package?.freeTrialStatus;
    const isFreeTrial = !realtimeDataCompany?.data?.package?.isPremium;
    if (!status || !isFreeTrial) return false;
    return [
      EpFreeTrialStatus.FreeTrialValid,
      EpFreeTrialStatus.FreeTrialValidAndQuotaExceeded,
      EpFreeTrialStatus.QuotaExceeded,
    ].includes(status as EpFreeTrialStatus);
  }, [realtimeData]);

  const handleClose = useCallback(() => {
    dispatch(clearDialog('subscriptionPlanDialog'));
  }, []);

  const onSkip = useCallback(() => {
    mpe.premiumOfferingSelect({
      packageOption: 'Free Trial Continue',
      ...(content?.linkPosition && { linkPosition: content?.linkPosition }),
    });
    handleClose();
  }, [handleClose]);

  const onSelect = useCallback(
    async (plan: EpUserSubscriptionType, onCloseConfirmation: () => void) => {
      const res = await handleRequest(plan.id, {
        type: 'from-api',
        layout: 'dashboard',
      });
      if (!res?.isSuccess) return;
      // close small confirmation modal
      onCloseConfirmation();
      // close big subscription modal
      handleClose();
    },
    [handleRequest, handleClose],
  );

  return (
    <Dialog
      maxWidth="xl"
      fullWidth
      onClose={handleClose}
      {...props}
      sx={{ '.MuiPaper-root': { p: 0 } }}
    >
      <DialogTitle
        display="flex"
        justifyContent="flex-end"
        sx={{ p: { xs: 1, md: 2 } }}
      >
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ px: 0, py: 0 }}>
        <Stack spacing={6} px={0}>
          {/* Header section */}
          <Stack
            alignItems="center"
            textAlign="center"
            position="relative"
            spacing={1}
            overflow="hidden"
            px={{ xs: 4, md: 8 }}
            pb={4}
          >
            <Typography
              component="div"
              variant="h3"
              sx={{
                maxWidth: '500px',
                span: { color: brandColors.primary[700] },
              }}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: t('subscription:subscriptionModalTitle', {
                    highlighted: `<span>${t(
                      'subscription:subscriptionModalTitleHighlighted',
                    )}</span>`,
                  }),
                }}
              />
            </Typography>
            <Typography variant="body1" color="text.secondary">
              {t('subscription:subscriptionModalSubtitle')}
            </Typography>

            {/* Blobs */}
            <Box
              display={{ xs: 'none', lg: 'block' }}
              component="img"
              src="/blobs/small-24-dots.svg"
              position="absolute"
              left="100px"
              top="20px"
            />

            <Box
              display={{ xs: 'none', lg: 'block' }}
              component="img"
              src="/blobs/gradient-orange-dot.svg"
              position="absolute"
              right="-35px"
              bottom="0"
            />
          </Stack>

          {/* Subscription plan list */}
          <Box position="relative" pb={5} sx={{ overflowX: 'hidden' }}>
            <SubscriptionPlan
              plans={subscriptionTypes}
              isLoading={isLoading}
              onPlanSelect={onSelect}
              pageLocation="dialog"
              linkPosition={content?.linkPosition}
            />

            {/* Blobs */}
            <Box
              display={{ xs: 'none', lg: 'block' }}
              component="img"
              src="/blobs/gradient-blue-dot.svg"
              position="absolute"
              top="15px"
              left="0"
            />

            <Box
              display={{ xs: 'none', lg: 'block' }}
              component="img"
              src="/blobs/small-24-dots.svg"
              position="absolute"
              right="-70px"
              bottom="250px"
            />

            <Box
              display={{ xs: 'none', lg: 'block' }}
              component="img"
              src="/blobs/gradient-blue-dot.svg"
              position="absolute"
              bottom="30px"
              right="0"
            />
          </Box>

          {/* Footer section */}
          <Stack>
            {/* Free trial banner */}
            {isOnFreeTrial && (
              <Stack
                alignItems="center"
                spacing={2}
                bgcolor="#F8FCFF"
                px={{ xs: 4, md: 0 }}
                py={8}
              >
                <Stack textAlign="center" spacing={1}>
                  <Typography variant="h4">
                    {t('subscription:footer:modalFooterTitle')}
                  </Typography>
                  <Typography component="div" variant="body1">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: t('subscription:footer:modalFooterSubtitle', {
                          highlighted: `<b>${t('common:free')}</b>`,
                        }),
                      }}
                    />
                  </Typography>
                </Stack>
                <Button variant="contained" onClick={onSkip}>
                  {t('subscription:footer:modalFooterButton')}
                </Button>
              </Stack>
            )}

            {/* Copyright section */}
            <Box py={4} textAlign="center">
              <AuthLayoutCopyRight
                helpLink={{
                  linkPosition:
                    MIXPANEL_ACCOUNT_FAQ_LINK_POSITION.SUBSCRIPTION_PLAN_DIALOG,
                }}
                privacyPolicyLink={{
                  onClick: () => {
                    handleClose();
                    navigate('/privacy');
                  },
                }}
              />
            </Box>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default SubscriptionPlanDialog;
