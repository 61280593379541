import { MainContent, Box, Stack, BoxProps } from '@kitalulus/web-ui-kit';

import AuthLayoutCopyRight from '~/components/AuthLayoutV2/AuthLayoutCopyRight';
import useBanner from '~/hooks/use-banner';
import ErrorBoundary from '../ErrorBoundary';
import PotentialError from '../PotentialError';

type CustomMainContentProps = BoxProps & {
  showCopyright?: boolean;
};

const CustomMainContent = ({
  children,
  showCopyright,
  ...props
}: CustomMainContentProps) => {
  const { bannerHeight } = useBanner();
  return (
    <Box bgcolor={props.bgcolor}>
      <MainContent
        {...(props as typeof MainContent)}
        sx={{
          main: {
            display: 'flex',
            minHeight: 'inherit',
            flexDirection: 'column',
            gap: 4,
          },
          ...(props.sx as typeof MainContent),
        }}
      >
        <ErrorBoundary type="main">
          <PotentialError />

          <Stack>
            <Box height={bannerHeight} />
            {children}
          </Stack>
          {showCopyright && (
            <Stack alignItems="flex-end" pb={4}>
              <AuthLayoutCopyRight />
            </Stack>
          )}
        </ErrorBoundary>
      </MainContent>
    </Box>
  );
};

export default CustomMainContent;
