import {
  Stack,
  Typography,
  useTheme,
  brandColors,
  Link,
  Tooltip,
  IconButton,
} from '@kitalulus/web-ui-kit';
import { LinearProgress, linearProgressClasses } from '@mui/material';
import Help from '~/components/Icons/Help';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { kitalulusLink } from '~/utils/kitalulus-link';
import CloseIcon from '~/components/Icons/CloseIcon';
import { ProgressBarProps } from './ProgressBar.types';
import * as mpe from '~/utils/mixpanel';

const ProgressBar = ({
  title,
  used,
  max,
  helpText,
  onLearnMoreClicked,
}: ProgressBarProps) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const [openHelp, setOpenHelp] = useState(false);

  const noQuota = max === 0;
  const oneLeft = max - used === 1;

  const value = useMemo(() => {
    if (max === 0) return 0;
    return (used / max) * 100;
  }, [used, max]);

  const color = useMemo(() => {
    if (value >= 90 || oneLeft) return brandColors.danger[400];
    return brandColors.success[500];
  }, [value, oneLeft]);

  const helperText = useMemo(() => {
    if (max === 0) return t('dashboard:vacancyQuotaDetails.notAvailable');
    if (value === 100) return t('dashboard:vacancyQuotaDetails.reachedMax');
    if (value >= 90 || (oneLeft && max != 1))
      return t('dashboard:vacancyQuotaDetails.almostReachMax');
    return null;
  }, [value, max, oneLeft]);

  const toggleHelp = () => {
    setOpenHelp(!openHelp);
  };

  return (
    <Stack gap="6px">
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" gap="6px" alignItems="center">
          <Typography
            variant="body2"
            data-test-id={`lbDashboardVacancyQuotaDetailsItemTitle-${title}`}
          >
            {title}
          </Typography>
          <Tooltip
            open={openHelp}
            sx={{
              '& .MuiTooltip-popper': {
                zIndex: 1300,
              },
            }}
            placement="bottom-end"
            title={
              <Stack direction="row" alignItems="start" gap="10px">
                <Stack gap="10px">
                  <Typography
                    data-test-id={`lbDashboardVacancyQuotaDetailsHelpMessage-${title}`}
                    variant="body2"
                    sx={{
                      whiteSpace: 'pre-line',
                    }}
                    dangerouslySetInnerHTML={{
                      __html: helpText,
                    }}
                  />
                  <Link
                    data-test-id={`btnDashboardVacancyQuotaDetailsHelpLink-${title}`}
                    variant="body2"
                    fontWeight={600}
                    underline="always"
                    href={kitalulusLink.helpCenterJobCategory}
                    target="_blank"
                    rel="noopener"
                    onClick={() => {
                      toggleHelp();
                      onLearnMoreClicked();
                    }}
                  >
                    {t('common:learnMore2')}
                  </Link>
                </Stack>
                <IconButton
                  data-test-id={`btnDashboardVacancyQuotaDetailsHelpClose-${title}`}
                  sx={{ p: 0 }}
                  size="small"
                  onClick={toggleHelp}
                >
                  <CloseIcon />
                </IconButton>
              </Stack>
            }
            PopperProps={{
              sx: {
                '& .MuiTooltip-tooltip': {
                  color: 'text.primary',
                  p: '12px',
                  m: '0px !important',
                  bgcolor: theme.palette.common.white,
                  boxShadow: theme.customShadows.card,
                  maxWidth: 322,
                },
              },
            }}
          >
            <span>
              <Help
                data-test-id={`btnDashboardVacancyQuotaDetailsHelpTooltip-${title}`}
                onClick={() => {
                  mpe.vacancyQuotaCheck({ action: 'help' });
                  toggleHelp();
                }}
                sx={{ color: 'text.secondary', height: 12, width: 12 }}
              />
            </span>
          </Tooltip>
        </Stack>
        <Typography
          variant="body2"
          sx={{ fontWeight: 700 }}
          data-test-id={`lbDashboardVacancyQuotaDetailsItemQuota-${title}`}
        >
          {`${used}/${max}`}
        </Typography>
      </Stack>
      <LinearProgress
        data-test-id={`lbDashboardVacancyQuotaDetailsItemProgress-${title}`}
        variant="determinate"
        value={value}
        sx={{
          height: '8px',
          borderRadius: '12px',
          [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: brandColors.grey[50024],
          },
          [`& .${linearProgressClasses.bar}`]: {
            backgroundColor: color,
          },
        }}
      />
      {helperText && (
        <Typography
          data-test-id={`lbDashboardVacancyQuotaDetailsItemHelperText-${title}`}
          variant="caption"
          color={noQuota ? 'text.secondary' : 'error'}
        >
          {helperText}
        </Typography>
      )}
    </Stack>
  );
};

export default ProgressBar;
