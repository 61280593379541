import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const useVacancyEthicCodeAlt = () => {
  const { t } = useTranslation();

  const ethics = useMemo(() => {
    return [
      {
        content: t('vacancy-ethic-code:vacancyEthicCodeItemsAlt.1'),
      },
      {
        content: t('vacancy-ethic-code:vacancyEthicCodeItemsAlt.2'),
      },
      {
        content: t('vacancy-ethic-code:vacancyEthicCodeItemsAlt.3'),
      },
    ];
  }, []);

  return { ethics };
};
