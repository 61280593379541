import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Nullable } from '~/types';
import { CompanyQuery } from '~/types/graphql/graphql';

export type CompanyState = Nullable<CompanyQuery['company']>;

export const companySlice = createSlice({
  name: 'company',
  initialState: null as CompanyState,
  reducers: {
    setCompany: (_, action: PayloadAction<CompanyState>) => action.payload,
    clearCompany: () => null,
  },
});

export const { setCompany, clearCompany } = companySlice.actions;

export default companySlice.reducer;
