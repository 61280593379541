import {
  Avatar,
  Paper,
  Stack,
  Typography,
  brandColors,
} from '@kitalulus/web-ui-kit';
import { AvatarProps, Skeleton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import StarRatingIcon from '~/components/Icons/StarRatingIcon';
import { createArray } from '~/utils/array';
import { AVATAR_DEFAULT } from '~/utils/constants';

const AvatarContainer = ({
  imgUrl,
  displayName,
  matchingResult,
  showKitaMatch = false,
  scoreTestAttr,
  isMobile,
  showShimmer,
  ...props
}: Omit<AvatarProps, 'color'> & {
  imgUrl: string;
  displayName?: string;
  matchingResult?: number;
  showKitaMatch?: boolean;
  scoreTestAttr?: string;
  isMobile: boolean;
  showShimmer: boolean;
}) => {
  const { t } = useTranslation();

  const absolutePosition = {
    position: 'absolute',
    bottom: -10,
  };

  if (showShimmer)
    return (
      <Stack gap={1} sx={{ position: 'relative' }}>
        <Skeleton
          variant="circular"
          animation={isMobile ? 'wave' : 'pulse'}
          sx={{
            width: { xs: 52, sm: 128 },
            height: { xs: 52, sm: 128 },
            ...props?.sx,
            ...(isMobile && { bgcolor: 'grey.200' }),
          }}
        />
        {showKitaMatch && (
          <Stack alignItems="center" gap={0.5}>
            {!isMobile && (
              <Skeleton variant="rounded" height={24} width={100} />
            )}
            <Skeleton
              animation={isMobile ? 'wave' : 'pulse'}
              sx={{
                padding: 1,
                borderRadius: '14px',
                ...(isMobile && absolutePosition),
                ...(isMobile && { bgcolor: 'grey.200' }),
              }}
              variant="rounded"
              height={28}
              width={52}
            />
          </Stack>
        )}
      </Stack>
    );

  return (
    <Stack gap={1} sx={{ position: 'relative' }}>
      <Avatar
        src={imgUrl || AVATAR_DEFAULT}
        displayName={displayName ?? ''}
        sx={{
          width: { xs: 52, sm: 128 },
          height: { xs: 52, sm: 128 },
        }}
        imgProps={{ ...props.imgProps }}
        {...props}
      />
      {showKitaMatch && !!matchingResult && (
        <Stack alignItems="center" gap={0.5}>
          {!isMobile && (
            <Typography
              variant="caption"
              color={brandColors.grey[800]}
              fontWeight={600}
            >
              {t('KitaMatch Score')}
            </Typography>
          )}
          <Paper
            sx={{
              padding: 1,
              borderRadius: '14px',
              boxShadow: '1px 1px 10px rgba(0, 0, 0, 0.1)',
              ...(isMobile && absolutePosition),
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              data-test-id={scoreTestAttr}
            >
              {createArray(matchingResult).map((_rating) => (
                <StarRatingIcon
                  key={`rating-${_rating}`}
                  sx={{ fontSize: '12px', color: brandColors.warning[600] }}
                />
              ))}
            </Stack>
          </Paper>
        </Stack>
      )}
    </Stack>
  );
};

export default AvatarContainer;
