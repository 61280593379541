import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@kitalulus/web-ui-kit';
import { useTranslation } from 'react-i18next';

import { InterviewInvitationSessionCloseConfirmationDialogProps } from './';

const InterviewInvitationSessionCloseConfirmationDialog = ({
  onSubmit,
  onCancel,
}: InterviewInvitationSessionCloseConfirmationDialogProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      open
      fullWidth
      PaperProps={{
        sx: {
          maxWidth: '500px',
          width: 'auto',
          mx: 2,
          p: 4,
          ...(isMobile && { p: 3 }),
        },
      }}
    >
      <Stack gap={3}>
        <DialogTitle component="div" sx={{ p: 0 }}>
          <Typography
            variant="h6"
            color="text.primary"
            fontSize="18px !important"
          >
            {t(
              'interview-invitation:interviewInvitationSessionCloseConfirmationDialog.title',
            )}
          </Typography>
        </DialogTitle>

        <DialogContent sx={{ p: 0 }}>
          <Typography variant="body1" color="text.secondary">
            {t(
              'interview-invitation:interviewInvitationSessionCloseConfirmationDialog.description',
            )}
          </Typography>
        </DialogContent>

        <DialogActions sx={{ p: 0, gap: 0.5 }}>
          <Button variant="outlined" color="inherit" onClick={onCancel}>
            {t('common:cancelAlt')}
          </Button>
          <Button variant="contained" color="error" onClick={onSubmit}>
            {t('common:yesExit')}
          </Button>
        </DialogActions>
      </Stack>
    </Dialog>
  );
};

export default InterviewInvitationSessionCloseConfirmationDialog;
