import { useCallback, useMemo } from 'react';

import { clearOnboarding, setOnboarding } from '~/store/views/onboarding-slice';
import { ONBOARDING_STEPS } from '~/utils/constants';
import { useRemoteConfigValue } from './firebase/use-remote-config';
import { useAppDispatch, useAppSelector } from './use-store';

export const onboardingSteps = [
  ONBOARDING_STEPS.COMPANY_FORM,
  ONBOARDING_STEPS.SUBSCRIPTION_OFFER,
];

export const useUserOnboarding = () => {
  const dispatch = useAppDispatch();
  const onboarding = useAppSelector((state) => state.onboarding);
  const isMonetizationActive = useRemoteConfigValue('ff_monetization');

  const steps = useMemo(() => {
    const newOnboardingSteps: ONBOARDING_STEPS[] = [];
    for (const step of onboardingSteps) {
      // skip subscription offer step on monetization feature isn't active
      if (step === ONBOARDING_STEPS.SUBSCRIPTION_OFFER && !isMonetizationActive)
        continue;

      newOnboardingSteps.push(step);
    }
    return newOnboardingSteps;
  }, [isMonetizationActive]);

  const currentStepIndex = useMemo(() => {
    return steps.findIndex((step) => step === onboarding.currentStep);
  }, [steps, onboarding]);

  const handleSetStep = useCallback(
    (n: number) => {
      if (!n) return;
      const newStepIndex = currentStepIndex + n;
      if (newStepIndex >= steps.length) return dispatch(clearOnboarding());
      if (newStepIndex <= -1) return dispatch(clearOnboarding());
      dispatch(setOnboarding({ currentStep: steps[newStepIndex] }));
    },
    [currentStepIndex, steps],
  );

  const handleNextOnboarding = useCallback(() => {
    handleSetStep(1);
  }, [handleSetStep]);

  const handlePrevOnboarding = useCallback(() => {
    handleSetStep(-1);
  }, [handleSetStep]);

  return {
    onboarding,
    steps,
    handleNextOnboarding,
    handlePrevOnboarding,
  };
};
