import { brandColors, SvgIconProps } from '@kitalulus/web-ui-kit';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import FavoriteIcon from '~/components/Icons/FavoriteIcon';
import PaymentIcon from '~/components/Icons/PaymentIcon';
import PhoneIcon from '~/components/Icons/PhoneIcon';
import ShieldIcon from '~/components/Icons/ShieldIcon';
import StopCircleIcon from '~/components/Icons/StopCircleIcon';
import ThumbUpIcon from '~/components/Icons/ThumbUpIcon';

export const useVacancyEthicCode = () => {
  const { t } = useTranslation();

  const ethics = useMemo(() => {
    return [
      {
        icon: (props: SvgIconProps) => (
          <ShieldIcon
            {...props}
            sx={{ color: brandColors.warning[500], ...props.sx }}
          />
        ),
        content: t('vacancy-ethic-code:vacancyEthicCodeItems.1'),
      },
      {
        icon: (props: SvgIconProps) => (
          <ThumbUpIcon
            {...props}
            sx={{ color: brandColors.primary[400], ...props.sx }}
          />
        ),
        content: t('vacancy-ethic-code:vacancyEthicCodeItems.2'),
      },
      {
        icon: (props: SvgIconProps) => (
          <PhoneIcon
            {...props}
            sx={{ color: brandColors.success[400], ...props.sx }}
          />
        ),
        content: t('vacancy-ethic-code:vacancyEthicCodeItems.3'),
      },
      {
        icon: (props: SvgIconProps) => (
          <FavoriteIcon
            {...props}
            sx={{ color: brandColors.danger[300], ...props.sx }}
          />
        ),
        content: t('vacancy-ethic-code:vacancyEthicCodeItems.4'),
      },
      {
        icon: (props: SvgIconProps) => (
          <PaymentIcon
            {...props}
            sx={{ color: brandColors.success[800], ...props.sx }}
          />
        ),
        content: t('vacancy-ethic-code:vacancyEthicCodeItems.5'),
      },
      {
        icon: (props: SvgIconProps) => (
          <StopCircleIcon
            {...props}
            sx={{ color: brandColors.danger[600], ...props.sx }}
          />
        ),
        content: t('vacancy-ethic-code:vacancyEthicCodeItems.6'),
      },
    ];
  }, []);

  return { ethics };
};
