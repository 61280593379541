import { useMutation } from '@apollo/client';
import { useCallback, useState } from 'react';

import {
  MUTATION_CREATE_INTERVIEW_INVITATION,
  MUTATION_UPDATE_INTERVIEW_INVITATION,
} from '~/graphql/interview-invitation';
import { ActionFunctionResult } from '~/types';
import {
  EpCreateInterviewInvitationError,
  EpCreateInterviewInvitationMutation,
  EpCreateInterviewInvitationMutationVariables,
  EpCreateInterviewInvitationSuccess,
  EpUpdateInterviewInvitationError,
  EpUpdateInterviewInvitationMutation,
  EpUpdateInterviewInvitationMutationVariables,
  EpUpdateInterviewInvitationSuccess,
} from '~/types/graphql/graphql';

export const useMutationCreateInterviewInvitation = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [createInterviewInvitation] = useMutation<
    EpCreateInterviewInvitationMutation,
    EpCreateInterviewInvitationMutationVariables
  >(MUTATION_CREATE_INTERVIEW_INVITATION, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
  });

  const handleCreate = useCallback(
    async (
      variables: EpCreateInterviewInvitationMutationVariables,
    ): Promise<
      ActionFunctionResult<
        EpCreateInterviewInvitationSuccess,
        EpCreateInterviewInvitationError
      >
    > => {
      try {
        setIsLoading(true);
        const { data } = await createInterviewInvitation({ variables });

        const res = data?.createInterviewInvitation;
        if (!res) throw new Error('empty response');

        if (res.__typename === 'EpCreateInterviewInvitationError')
          return { success: false, error: res };
        if (res.__typename === 'EpCreateInterviewInvitationSuccess')
          return { success: true, data: res };

        throw new Error('invalid response type');
      } catch (err: any) {
        return { success: false, error: err };
      } finally {
        setIsLoading(false);
      }
    },
    [createInterviewInvitation],
  );

  return { handleCreate, isLoading };
};

export const useMutationUpdateInterviewInvitation = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [updateInterviewInvitation] = useMutation<
    EpUpdateInterviewInvitationMutation,
    EpUpdateInterviewInvitationMutationVariables
  >(MUTATION_UPDATE_INTERVIEW_INVITATION, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
  });

  const handleUpdate = useCallback(
    async (
      variables: EpUpdateInterviewInvitationMutationVariables,
    ): Promise<
      ActionFunctionResult<
        EpUpdateInterviewInvitationSuccess,
        EpUpdateInterviewInvitationError
      >
    > => {
      try {
        setIsLoading(true);
        const { data } = await updateInterviewInvitation({ variables });

        const res = data?.updateInterviewInvitation;
        if (!res) throw new Error('empty response');

        if (res.__typename === 'EpUpdateInterviewInvitationError')
          return { success: false, error: res };
        if (res.__typename === 'EpUpdateInterviewInvitationSuccess')
          return { success: true, data: res };

        throw new Error('invalid response type');
      } catch (err: any) {
        return { success: false, error: err };
      } finally {
        setIsLoading(false);
      }
    },
    [updateInterviewInvitation],
  );

  return { handleUpdate, isLoading };
};
