import { Typography, Box, Alert, brandColors } from '@kitalulus/web-ui-kit';
import debounce from 'lodash.debounce';
import { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useBanner from '~/hooks/use-banner';
import { useAppDispatch, useAppSelector } from '~/hooks/use-store';
import { setBanner } from '~/store/views/banner-slice';
import * as mpe from '~/utils/mixpanel';

const NearExpiredBanner = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const banner = useAppSelector((state) => state.banner);
  const { showBanner } = useBanner();

  const elementRef = useRef<HTMLDivElement>(null);

  const handleNavigateToPackageDetail = () => {
    mpe.stickyWarning({
      position: 'top navbar',
      action: 'check package detail',
      warningMessage: 'package near expired',
    });
    navigate('/account/package-detail');
  };

  const handleClose = () => {
    mpe.stickyWarning({
      position: 'top navbar',
      action: 'cancel',
      warningMessage: 'package near expired',
    });
    dispatch(
      setBanner({
        show: false,
      }),
    );
  };

  const updateBanner = useCallback(() => {
    dispatch(
      setBanner({
        height: elementRef.current?.clientHeight ?? 0,
      }),
    );
  }, [elementRef]);

  // debounce for better window resizing performance
  const updateBannerWithDebounce = useCallback(debounce(updateBanner, 100), [
    updateBanner,
  ]);

  // update banner size on toggle
  // using th original update banner (without debounce) for better load transition
  useEffect(() => {
    updateBanner();
  }, [banner.show, updateBanner]);

  // update banner size on window resize
  useEffect(() => {
    window.addEventListener('resize', updateBannerWithDebounce);
    return () => {
      window.removeEventListener('resize', updateBannerWithDebounce);
    };
  }, [updateBannerWithDebounce]);

  useEffect(() => {
    dispatch(setBanner({ show: showBanner }));
  }, [showBanner]);

  return (
    <Box
      position="fixed"
      zIndex={1300}
      width="100%"
      top={0}
      ref={elementRef}
      data-test-id="ctDashboardBanner"
    >
      {banner.show && (
        <Alert
          variant="standard"
          color="warning"
          severity="warning"
          sx={{
            alignItems: 'center',
            gap: 1.5,
            border: `1px solid ${brandColors.warning[300]}`,
            borderRadius: 0,
            '& .MuiAlert-message': {
              flex: 1,
              textAlign: { xs: 'left', lg: 'center' },
            },
            '& .MuiAlert-icon .MuiSvgIcon-root': {
              fontSize: { xs: '32px', lg: '24px' },
            },
          }}
          onClose={handleClose}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems={{ xs: 'flex-start', lg: 'center' }}
            gap={{ xs: '4px', lg: '10px' }}
            flexDirection={{ xs: 'column', lg: 'row' }}
          >
            <Typography variant="subtitle2">
              {t('subscription:banner.packageAboutAlmostExpired')}
              <Box
                component="span"
                sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                onClick={handleNavigateToPackageDetail}
                data-test-id="lbDashboardBannerCTA"
              >
                {t('subscription:banner.packageAboutAlmostExpiredCTA')}
              </Box>
              .
            </Typography>
          </Box>
        </Alert>
      )}
    </Box>
  );
};

export default NearExpiredBanner;
