import { RetryLink } from '@apollo/client/link/retry';

export type RetryLinkStatusCodes = RegExp | number | string;

// retry link to auto retry a request whenever it's getting error
// this will work if the HTTP code from request is error
// but this won't if the HTTP status code is 200, even the GQL response is error
// there are 2 params you can pass from GQL context.retry
// - maxAttempts (number) => max attempts before it's showing error
// - retryOnStatusCodes (regex, number, string, or array) => to match status code when you need to retry
const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: false,
  },
  attempts: (count, operation, error) => {
    // hide error alert on retry
    const errorCode = error.statusCode;

    // context data
    const retryContext = operation.getContext().retry ?? {};
    const maxAttempts = retryContext.maxAttempts ?? 0;
    const statusCodesToRetry: RetryLinkStatusCodes | RetryLinkStatusCodes[] =
      retryContext.retryOnStatusCodes;

    // clear retry context to show alert
    // before running last retry to catch errors when the last retry is error
    if (count > maxAttempts - 1) operation.setContext({ retry: null });
    // if attempts already reach the limit
    if (count > maxAttempts) return false;

    // convert to array
    let statusCodes: RetryLinkStatusCodes[] = [];
    statusCodes = Array.isArray(statusCodesToRetry)
      ? statusCodesToRetry
      : [statusCodesToRetry];
    // delete any empty data
    statusCodes = statusCodes.filter((code) => !!code);

    // no status codes provided
    if (statusCodes.length <= 0) return false;
    for (const statusCode of statusCodes) {
      // match by regex
      if (statusCode instanceof RegExp) {
        if (statusCode.test(errorCode)) return true;
        continue;
      }
      // match by string
      if (errorCode.toString() === statusCode.toString()) return true;
    }

    return false;
  },
});

export default retryLink;
