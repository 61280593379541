import { Box, Button, Stack, useTheme } from '@kitalulus/web-ui-kit';
import { Container } from '@mui/material';

import { NavbarProps } from './Navbar.types';

const Navbar = ({ buttons, ...props }: NavbarProps) => {
  const theme = useTheme();

  return (
    <Box
      height="64px"
      bgcolor={theme.palette.background.default}
      boxShadow={theme.customShadows.card}
      position="sticky"
      zIndex={9}
      top={0}
      flexShrink={0}
      {...props}
    >
      <Container maxWidth="xl" sx={{ height: '100%' }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          height="100%"
        >
          {/* Logo section */}
          <Box
            component="img"
            height="40px"
            src="/kitalulus-logo.svg"
            alt="KitaLulus Logo"
          />

          {/* Menu section */}
          <Stack direction="row" spacing={1}>
            {buttons?.map(({ key, ...btnProps }) => (
              <Button key={key} {...btnProps} />
            ))}
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
};

export default Navbar;
