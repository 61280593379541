import {
  Alert,
  Stack,
  Typography,
  useTheme,
  brandColors,
} from '@kitalulus/web-ui-kit';

import { InfoIcon } from '~/components/Icons/InfoIcon';

import { ResultAndAlertProps } from './ScreeningQuestionAnswers.types';

const ResultAndAlert = ({ resultStr, noticeStr }: ResultAndAlertProps) => {
  const theme = useTheme();

  if (!resultStr && !noticeStr) return null;
  return (
    <Stack spacing="12px">
      {resultStr && (
        <Stack>
          <Typography
            variant="subtitle2"
            color={brandColors.primary[500]}
            fontWeight={600}
          >
            {resultStr}
          </Typography>
        </Stack>
      )}
      {noticeStr && (
        <Stack>
          <Alert
            icon={
              <InfoIcon
                sx={{
                  fontSize: 24,
                  color: brandColors.secondary[500],
                }}
              />
            }
            sx={{
              backgroundColor: brandColors.secondary[100],
              color: theme.palette.common.black,
              borderRadius: 2,
              p: '12px',
              '.MuiAlert-icon': { p: 0, alignItems: 'center' },
              '.MuiAlert-message': { p: 0 },
            }}
          >
            {noticeStr}
          </Alert>
        </Stack>
      )}
    </Stack>
  );
};
export default ResultAndAlert;
