import { SvgIcon, SvgIconProps } from '@kitalulus/web-ui-kit';

const FiSsChartPieAlt = (props: SvgIconProps) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
      <g>
        <path d="M12.057 3.06044L12.0441 2.06397H11.0476C10.978 2.06331 10.9084 2.06331 10.8388 2.06397C4.80182 2.12162 -0.0454284 7.06234 0.0122278 13.0993C0.0698841 19.1364 5.0106 23.9836 11.0476 23.9259C17.0818 23.9193 21.9719 19.0296 21.9791 12.9955V11.999H12.0441L12.057 3.06044Z" />
        <path d="M14.0547 0.0742188V10.0082H23.9877C23.962 4.53287 19.53 0.100422 14.0547 0.0742188Z" />
      </g>
    </SvgIcon>
  );
};

export default FiSsChartPieAlt;
