import { SvgIcon, SvgIconProps } from '@kitalulus/web-ui-kit';

export const ErrorIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="24" height="25" viewBox="0 0 24 25" {...props}>
      <g>
        <path d="M24 12.0098C24 14.3831 23.2962 16.7032 21.9776 18.6766C20.6591 20.65 18.7849 22.1881 16.5922 23.0963C14.3995 24.0046 11.9867 24.2422 9.65892 23.7792C7.33115 23.3162 5.19295 22.1733 3.51472 20.495C1.83649 18.8168 0.693605 16.6786 0.230582 14.3509C-0.232441 12.0231 0.00519943 9.61028 0.913451 7.41757C1.8217 5.22485 3.35977 3.35071 5.33316 2.03213C7.30655 0.713553 9.62663 0.00976563 12 0.00976562C15.1815 0.0132067 18.2318 1.27859 20.4815 3.52829C22.7312 5.77798 23.9966 8.82823 24 12.0098ZM13 5.00977H11V15.0098H13V5.00977ZM13 17.0098H11V19.0098H13V17.0098Z" />
      </g>
    </SvgIcon>
  );
};

export default ErrorIcon;
