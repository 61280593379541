import { useLazyQuery } from '@apollo/client';
import { QUERY_JOB_VACANCY_SCREENING_QUESTIONS_FILTER } from '~/graphql/vacancy';
import {
  JobVacancyScreeningQuestionsFilterQuery,
  JobVacancyScreeningQuestionsFilterQueryVariables,
} from '~/types/graphql/graphql';
import { useAppDispatch } from '../use-store';
import { FETCH_STATUS } from '~/utils/constants';
import { setVacancyDetailScreeningQuestionsFilter } from '~/store/features/vacancy-detail-v2-slice';
import { useRemoteConfigValue } from '../firebase/use-remote-config';

const useVacancyScreeningQuestionsFilter = () => {
  const dispatch = useAppDispatch();
  const showScreeningQuestion = useRemoteConfigValue(
    'ff_vacancy_screening_question',
  );
  const [queryScreeningQuestionsFilter] = useLazyQuery<
    JobVacancyScreeningQuestionsFilterQuery,
    JobVacancyScreeningQuestionsFilterQueryVariables
  >(QUERY_JOB_VACANCY_SCREENING_QUESTIONS_FILTER, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ jobVacancyScreeningQuestionsFilter }) => {
      if (!jobVacancyScreeningQuestionsFilter) return;
      dispatch(
        setVacancyDetailScreeningQuestionsFilter({
          data: jobVacancyScreeningQuestionsFilter,
          fetchStatus: FETCH_STATUS.RESOLVED,
        }),
      );
    },
  });

  const getScreeningQuestionsFilter = (vacancyId: string) => {
    if (!showScreeningQuestion) return;
    dispatch(
      setVacancyDetailScreeningQuestionsFilter({
        fetchStatus: FETCH_STATUS.PENDING,
      }),
    );
    queryScreeningQuestionsFilter({ variables: { jobVacancyId: vacancyId } });
  };
  return { getScreeningQuestionsFilter };
};

export default useVacancyScreeningQuestionsFilter;
