import { SvgIcon, SvgIconProps } from '@kitalulus/web-ui-kit';

const ShieldIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path d="M12.0001 21.8498C11.6506 21.8553 11.3058 21.7691 11.0001 21.5998L10.7001 21.4298C5.94281 18.7383 3.00128 13.6957 3.00008 8.22978V8.08979C2.99363 7.36885 3.37569 6.70024 4.00008 6.33979L11.0001 2.39979C11.6189 2.04252 12.3813 2.04252 13.0001 2.39979L20.0001 6.33979C20.6245 6.70024 21.0065 7.36885 21.0001 8.08979V8.22978C20.9948 13.6998 18.0451 18.7434 13.2801 21.4298L12.9801 21.5998C12.6802 21.7657 12.3428 21.8518 12.0001 21.8498Z" />
    </SvgIcon>
  );
};

export default ShieldIcon;
