import { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import LoadingPage from '~/components/LoadingPage';
import { withDeviceStatus } from '~/hoc/with-device-status';
import { withMainData } from '~/hoc/with-main-data';
import { useAuthBroadcastChannel } from '~/hooks/use-broadcast-channel';
import AuthorizedRoutes from './authorized';
import InfoRoutes from './info';
import UnauthorizedRoutes from './unauthorized';

const IndexRoutes = () => {
  useAuthBroadcastChannel();

  return (
    <Suspense fallback={<LoadingPage variant="inline" />}>
      <Routes>
        <Route path="auth/*" element={<UnauthorizedRoutes />} />
        <Route path="info/*" element={<InfoRoutes />} />
        <Route path="*" element={<AuthorizedRoutes />} />
      </Routes>
    </Suspense>
  );
};

export default withMainData(withDeviceStatus(IndexRoutes));
