import { useQuery } from '@apollo/client';
import { useState } from 'react';

import { QUERY_COMPANY_SIZES } from '~/graphql/common';
import { DropdownOption } from '~/types';
import { CompanySizesQuery } from '~/types/graphql/graphql';

export const useCompanySizeOptions = () => {
  const [companySizes, setCompanySizes] = useState<DropdownOption[]>([]);

  const { loading } = useQuery<CompanySizesQuery>(QUERY_COMPANY_SIZES, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ companySizes: size }) => {
      if (!size) return;
      setCompanySizes(
        size.map((d) => ({
          value: d?.id,
          label: d?.size,
        })),
      );
    },
    onError: () => setCompanySizes([]),
  });

  return {
    companySizes,
    isLoading: loading,
  };
};
