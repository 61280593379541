import { useCallback, useMemo } from 'react';

import { useAppDispatch } from '~/hooks/use-store';
import { clearDialog, setDialog } from '~/store/views/dialogs-slice';
import { InterviewInvitationDialogVariantKey } from '~/utils/constants/interview-invitation';
import DirectChangeStatusToInterviewDialog from './variants/DirectChangeStatusToInterviewDialog';
import InterviewInvitationSessionDialog from './variants/InterviewInvitationSessionDialog';
import RescheduleSessionConfirmationDialog from './variants/RescheduleSessionConfirmationDialog';
import SelectingTypeDialog from './variants/SelectingTypeDialog';
import { InterviewInvitationDialogProps } from './';

const InterviewInvitationDialog = (props: InterviewInvitationDialogProps) => {
  const dispatch = useAppDispatch();
  const variantKey = props.content.variantKey;

  const handleSwitchVariant = useCallback(
    (variantKey: InterviewInvitationDialogVariantKey) => {
      dispatch(
        setDialog({
          interviewInvitationDialog: {
            ...props,
            content: {
              ...props.content,
              variantKey,
            },
          },
        }),
      );
    },
    [props],
  );

  const handleClose = useCallback(() => {
    dispatch(clearDialog('interviewInvitationDialog'));
  }, []);

  const renderContent = useMemo(() => {
    switch (variantKey) {
      case InterviewInvitationDialogVariantKey.SELECTING_TYPE_DIALOG:
        return (
          <SelectingTypeDialog
            {...props}
            onClose={handleClose}
            onSwitchVariant={handleSwitchVariant}
          />
        );

      case InterviewInvitationDialogVariantKey.DIRECT_CHANGE_STATUS_TO_INTERVIEW_DIALOG:
        return (
          <DirectChangeStatusToInterviewDialog
            {...props}
            onClose={handleClose}
            onSwitchVariant={handleSwitchVariant}
          />
        );

      case InterviewInvitationDialogVariantKey.INTERVIEW_INVITATION_SESSION_DIALOG:
        return (
          <InterviewInvitationSessionDialog
            {...props}
            onClose={handleClose}
            onSwitchVariant={handleSwitchVariant}
          />
        );

      case InterviewInvitationDialogVariantKey.RESCHEDULE_SESSION_CONFIRMATION_DIALOG:
        return (
          <RescheduleSessionConfirmationDialog
            {...props}
            onClose={handleClose}
            onSwitchVariant={handleSwitchVariant}
          />
        );

      default:
        return null;
    }
  }, [variantKey, handleSwitchVariant]);

  return renderContent;
};

export default InterviewInvitationDialog;
