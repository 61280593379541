import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import ConfirmDialogV1 from '~/components/Dialogs/ConfirmDialogV1';
import CancelIcon from '~/components/Icons/CancelIcon';
import { useDeactivateJobVacancyHighlight } from '~/hooks/use-job-vacancy-highlight';
import { clearDialog } from '~/store/views/dialogs-slice';
import { setSnackbar } from '~/store/views/snackbar-slice';
import { convertDateToISOString } from '~/utils/helper';
import * as mpe from '~/utils/mixpanel';

import { VacancyUnHighlightDialogProps } from './VacancyUnHighlightDialog.types';
import { useAppSelector } from '~/hooks/use-store';

const VacancyUnHighlightDialog = ({
  content,
  ...props
}: VacancyUnHighlightDialogProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { handleDeactivateVacancy, isLoading } =
    useDeactivateJobVacancyHighlight();

  const jobVacancyTierCategory = useAppSelector(
    (state) => state.commonOptions.jobVacancyTierCategory,
  );
  const handleClose = useCallback(
    (trackEvent: boolean) => {
      if (!content) return;
      const { vacancy } = content;

      if (trackEvent)
        mpe.vacancyUnhighlightCancel({
          vacancyCode: vacancy.code ?? '',
          vacancyCreatedAt: convertDateToISOString(vacancy.createdAt),
          vacancyCloseDate: convertDateToISOString(vacancy.closeDate),
        });

      dispatch(clearDialog('vacancyUnHighlightDialog'));
    },
    [content],
  );

  const handleDeactivate = useCallback(async () => {
    if (!content) return;
    const { vacancy, onCompleted } = content;
    const vacancyId = vacancy.id;
    if (!vacancyId) return;

    mpe.vacancyUnhighlightSubmit({
      vacancyCode: vacancy.code ?? '',
      vacancyCreatedAt: convertDateToISOString(vacancy.createdAt),
      vacancyCloseDate: convertDateToISOString(vacancy.closeDate),
      jobTierCategory: jobVacancyTierCategory?.data?.name ?? '',
    });

    const data = await handleDeactivateVacancy(vacancyId);
    if (!data?.isSuccess) return;
    dispatch(
      setSnackbar({
        layout: 'dashboard',
        severity: 'success',
        message: t('vacancy:deactivatePromoteSuccessMessage'),
      }),
    );
    if (onCompleted) onCompleted(() => handleClose(false), data);
  }, [content, handleClose, handleDeactivateVacancy]);

  return (
    <ConfirmDialogV1
      {...props}
      data-test-id="ctVacancyUnHighlightDialog"
      title={t('vacancy:deactivatePromoteConfirmModalTitle')}
      titleIcon={<CancelIcon color="error" sx={{ fontSize: '30px' }} />}
      body={t('vacancy:deactivatePromoteConfirmModalBody')}
      acceptProps={{
        onClick: handleDeactivate,
        color: 'error',
        children: t('common:yesStop'),
        loading: isLoading,
      }}
      cancelProps={{
        onClick: () => handleClose(true),
        children: t('common:cancelled'),
      }}
    />
  );
};

export default VacancyUnHighlightDialog;
