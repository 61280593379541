import { useAppDispatch, useAppSelector } from '../use-store';
import { setApplicantTotalActiveScreeningQuestion } from '~/store/features/applicants';
import useIDBApplicantScreeningQuestionFilter from '../index-db/use-idb-applicant-screening-question-filter';
import {
  EApplicantScreeningQuestionFilterDialogUiType,
  TApplicantScreeningQuestionFilterDialogData,
} from '~/pages/Applicants/ApplicantScreeningQuestionFilterDialog';
import { useCallback, useMemo } from 'react';
import {
  EpJobApplicationScreeningQuestionInput,
  JobApplicationScreeningTopic,
} from '~/types/graphql/graphql';

const useApplicantScreeningQuestionFilter = (vacancyID: string) => {
  const dispatch = useAppDispatch();
  const vacancyDetailV2 = useAppSelector((state) => state.vacancyDetailV2);
  const screeningQuestionFilters =
    vacancyDetailV2?.screeningQuestionsFilter?.data;

  const { getVacancyFilter, applicantScreeningQuestionFilter, loading } =
    useIDBApplicantScreeningQuestionFilter();

  const screeningQuestionPersistedFilter = useMemo(() => {
    return getVacancyFilter(vacancyID) ?? [];
  }, [JSON.stringify(applicantScreeningQuestionFilter), vacancyID]);

  const getVacancyScreeningQuestionConstructedFilterData = useCallback(() => {
    return (
      screeningQuestionFilters?.map((sq) => {
        const selectedByDefault =
          sq.uiType ===
          EApplicantScreeningQuestionFilterDialogUiType.horizontal_checkbox_with_right_label;
        return {
          id: sq.id,
          topic: sq.topic,
          uiType: sq.uiType,
          isRequired: sq.isRequired,
          title: sq.title,
          description: sq.description,
          choices: sq.choices?.map((choice) => {
            return {
              key: choice.key,
              value: choice.value,
              isSelected: selectedByDefault,
            };
          }),
          entries: sq.entries?.map((entry) => {
            return { key: entry.key, value: entry.value, choiceID: '' };
          }),
        } as TApplicantScreeningQuestionFilterDialogData;
      }) ?? []
    );
  }, [screeningQuestionFilters]);

  const handleApplyFilterCount = (
    sqf: Array<TApplicantScreeningQuestionFilterDialogData>,
  ) => {
    let totalCount = 0;
    sqf.forEach((sq) => {
      switch (sq.uiType) {
        case EApplicantScreeningQuestionFilterDialogUiType.horizontal_radio_with_left_label:
        case EApplicantScreeningQuestionFilterDialogUiType.horizontal_radio_with_top_label:
          if (sq.entries?.filter((entry) => entry.choiceID).length) {
            totalCount++;
          }
          break;
        case EApplicantScreeningQuestionFilterDialogUiType.horizontal_checkbox_with_right_label:
          if (sq.choices.filter((c) => c.isSelected).length) totalCount++;
          break;
        default:
      }
    });
    dispatch(setApplicantTotalActiveScreeningQuestion(totalCount));
  };

  const generateScreeningQuestionPayload = useCallback(
    (sqf: TApplicantScreeningQuestionFilterDialogData[]) => {
      return (
        sqf?.map((sqf) => {
          const defaultResponse = {
            id: sqf.id,
            topic: sqf.topic as JobApplicationScreeningTopic,
            values: sqf.entries
              ?.filter((entry) => entry.choiceID)
              .map((entry) => ({
                entry: entry.key,
                choice: entry.choiceID ?? '',
              })),
          };
          switch (sqf.uiType) {
            case EApplicantScreeningQuestionFilterDialogUiType.horizontal_radio_with_left_label:
            case EApplicantScreeningQuestionFilterDialogUiType.horizontal_radio_with_top_label:
              return defaultResponse;
            case EApplicantScreeningQuestionFilterDialogUiType.horizontal_checkbox_with_right_label:
              return {
                topic: sqf.topic,
                values: sqf.choices
                  .filter((choice) => choice.isSelected)
                  .map((choice) => ({ choice: choice.key })),
              } as EpJobApplicationScreeningQuestionInput;
            default:
              return defaultResponse;
          }
        }) ?? []
      );
    },
    [],
  );

  return {
    loading,
    screeningQuestionPersistedFilter,
    getVacancyScreeningQuestionConstructedFilterData,
    generateScreeningQuestionPayload,
    handleApplyFilterCount,
  };
};

export default useApplicantScreeningQuestionFilter;
