import {
  Alert,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@kitalulus/web-ui-kit';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CloseIcon from '~/components/Icons/CloseIcon';
import MailMarkIcon from '~/components/Icons/MailMarkIcon';
import InterviewInvitationForm from '~/components/InterviewInvitationForm';
import InterviewInvitationFormInfoAlert from '~/components/InterviewInvitationForm/InterviewInvitationFormInfoAlert';
import InterviewInvitationFormNavigation from '~/components/InterviewInvitationForm/InterviewInvitationFormNavigation';
import InterviewInvitationFormStepper from '~/components/InterviewInvitationForm/InterviewInvitationFormStepper';
import { useInterviewInvitationForm } from '~/hooks/interview-invitation-form/use-interview-invitation-form';
import {
  useMutationCreateInterviewInvitation,
  useMutationUpdateInterviewInvitation,
} from '~/hooks/interview-invitation-form/use-interview-invitation-action';
import { useNavigationBlockPrompt } from '~/hooks/use-navigation-block-prompt';
import { useAppDispatch, useAppSelector } from '~/hooks/use-store';
import { setSnackbar } from '~/store/views/snackbar-slice';
import {
  EpCreateInterviewInvitationInput,
  EpUpdateInterviewInvitationInput,
} from '~/types/graphql/graphql';
import {
  MixpanelApplicantInterviewFillDetailProperties,
  MixpanelInterviewDetailFillDetailCancelProperties,
} from '~/types/mixpanel';
import {
  InterviewInvitationDialogActionKey,
  InterviewInvitationFormRecruiterMessageStatusKey,
  InterviewInvitationFormSessionSelectTypeKey,
} from '~/utils/constants/interview-invitation';
import { generateDateHours } from '~/utils/date';
import { mapInterviewInvitationFormValueToData } from '~/utils/interview-invitation-form';
import * as mpe from '~/utils/mixpanel';
import InterviewInvitationSessionCloseConfirmationDialog from '../InterviewInvitationSessionCloseConfirmationDialog';
import InterviewInvitationSessionLoaderDialog from '../InterviewInvitationSessionLoaderDialog';
import InterviewInvitationSessionSuccessDialog, {
  InterviewInvitationSessionSuccessDialogProps,
} from '../InterviewInvitationSessionSuccessDialog';
import {
  InterviewInvitationSessionDialogCopywriting,
  InterviewInvitationSessionDialogProps,
} from './';

const InterviewInvitationSessionDialog = ({
  content: {
    vacancy,
    currentSessionId,
    actionKey,
    sizeKey,
    applicationIds,
    onRefresh,
    newInvitationSuccessDialog,
  },
  onSwitchVariant: _,
  onClose,
  ...props
}: InterviewInvitationSessionDialogProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const company = useAppSelector((state) => state.company);

  const [activeStep, setActiveStep] = useState(0);
  const { firstStepFormState, secondStepFormState, thirdStepFormState } =
    useInterviewInvitationForm(vacancy, sizeKey);
  const { handleCreate } = useMutationCreateInterviewInvitation();
  const { handleUpdate } = useMutationUpdateInterviewInvitation();

  // responsive state
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const generateMixpanelProperties = useCallback(() => {
    // first step state
    const sessionSelectType = firstStepFormState.getValues('sessionSelectType');
    const newSessionName = firstStepFormState.getValues('newSessionName') ?? '';
    const existingSessionName =
      firstStepFormState.getValues('selectedSession')?.additional?.name ?? '';
    const sessioNameByType: Record<
      InterviewInvitationFormSessionSelectTypeKey,
      string
    > = {
      [InterviewInvitationFormSessionSelectTypeKey.NEW_SESSION]: newSessionName,
      [InterviewInvitationFormSessionSelectTypeKey.EXISTING_SESSION]:
        existingSessionName,
    };

    // second step state
    const schedule = secondStepFormState.getValues('schedule');
    const scheduleStart = secondStepFormState.getValues('scheduleStartTime');
    const scheduleEnd = secondStepFormState.getValues('scheduleEndTime');
    const sizeType = secondStepFormState.getValues('sizeType');
    const type = secondStepFormState.getValues('type');

    // third step state
    const recruiterMessage = thirdStepFormState.getValues('recruiterMessage');

    // set schedule start and end to the same date as schedule
    const modifiedScheduleStart = scheduleStart
      ? generateDateHours(
          {
            hours: scheduleStart.getHours(),
            minutes: scheduleStart.getMinutes(),
            seconds: scheduleStart.getSeconds(),
            miliseconds: scheduleStart.getMilliseconds(),
          },
          schedule ?? undefined,
        )
      : undefined;
    const modifiedScheduleEnd = scheduleEnd
      ? generateDateHours(
          {
            hours: scheduleEnd.getHours(),
            minutes: scheduleEnd.getMinutes(),
            seconds: scheduleEnd.getSeconds(),
            miliseconds: scheduleEnd.getMilliseconds(),
          },
          schedule ?? undefined,
        )
      : undefined;

    return {
      step: `step${activeStep + 1}`,
      sessionCreationType: sessionSelectType ?? undefined,
      vacancyCode: vacancy.code,
      vacancyName: vacancy.positionName,
      companyName: company?.name ?? '',
      companyCode: company?.code ?? '',
      sessionName: sessionSelectType ? sessioNameByType[sessionSelectType] : '',
      interviewDate: schedule?.toISOString?.(),
      interviewSessionTimeStart: modifiedScheduleStart?.toISOString?.(),
      interviewSessionTimeEnd: modifiedScheduleEnd?.toISOString?.(),
      interviewGroup: sizeType ?? undefined,
      interviewType: type ?? undefined,
      invitationType: actionKey,
      additionalNote: recruiterMessage
        ? InterviewInvitationFormRecruiterMessageStatusKey.FILLED
        : InterviewInvitationFormRecruiterMessageStatusKey.NOT_FILLED,
      applicantNumber: applicationIds.length,
    };
  }, [vacancy, company, applicationIds, actionKey, activeStep]);

  const triggerApplicantInterviewFillDetail = useCallback(
    (
      buttonType: MixpanelApplicantInterviewFillDetailProperties['buttonType'],
    ) => {
      const mixpanelProps = generateMixpanelProperties();
      mpe.applicantInterviewFillDetail({
        buttonType,
        step: mixpanelProps.step,
        vacancyName: mixpanelProps.vacancyName,
        sessionCreationType: mixpanelProps.sessionCreationType,
        sessionName: mixpanelProps.sessionName,
        interviewDate: mixpanelProps.interviewDate,
        interviewGroup: mixpanelProps.interviewGroup,
        interviewType: mixpanelProps.interviewType,
        invitationType: mixpanelProps.invitationType,
      });
    },
    [generateMixpanelProperties],
  );

  const triggerInterviewDetailFillDetailCancel = useCallback(
    (
      buttonType: MixpanelInterviewDetailFillDetailCancelProperties['buttonType'],
    ) => {
      const mixpanelProps = generateMixpanelProperties();
      mpe.interviewDetailFillDetailCancel({
        buttonType,
        step: mixpanelProps.step,
        sessionCreationType: mixpanelProps.sessionCreationType,
        vacancyName: mixpanelProps.vacancyName,
        sessionName: mixpanelProps.sessionName,
        invitationType: mixpanelProps.invitationType,
      });
    },
    [generateMixpanelProperties],
  );

  const triggerApplicantInterviewDetailSubmit = useCallback(() => {
    const mixpanelProps = generateMixpanelProperties();
    mpe.applicantInterviewDetailSubmit({
      interviewType: mixpanelProps.interviewType,
      sessionName: mixpanelProps.sessionName,
      interviewGroup: mixpanelProps.interviewGroup,
      vacancyCode: mixpanelProps.vacancyCode,
      vacancyName: mixpanelProps.vacancyName,
      companyCode: mixpanelProps.companyCode,
      companyName: mixpanelProps.companyName,
      interviewSessionTimeStart: mixpanelProps.interviewSessionTimeStart,
      interviewSessionTimeEnd: mixpanelProps.interviewSessionTimeEnd,
      invitationType: mixpanelProps.invitationType,
      additionalNote: mixpanelProps.additionalNote,
      applicantNumber: mixpanelProps.applicantNumber,
      sessionCreationType: mixpanelProps.sessionCreationType,
    });
  }, [generateMixpanelProperties]);

  // dialog states
  const [showLoaderDialog, setShowLoaderDialog] = useState(false);
  const [successDialogProps, setSuccessDialogProps] =
    useState<InterviewInvitationSessionSuccessDialogProps | null>(null);
  const [showCloseConfirm, setShowCloseConfirm] = useState(false);

  // react router close confirmation
  const openLeavePrompt = useCallback(() => {
    triggerApplicantInterviewFillDetail('close');
    setShowCloseConfirm(true);
  }, [triggerApplicantInterviewFillDetail]);
  const closeLeavePrompt = useCallback(() => {
    triggerInterviewDetailFillDetailCancel('cancel');
    setShowCloseConfirm(false);
  }, [triggerInterviewDetailFillDetailCancel]);
  const { setIsBlocked } = useNavigationBlockPrompt(openLeavePrompt, true);

  const handleNext = useCallback(() => {
    triggerApplicantInterviewFillDetail('next');
    setActiveStep((c) => c + 1);
  }, [triggerApplicantInterviewFillDetail]);

  const handleBack = useCallback(() => {
    mpe.applicantInterviewFillDetailBack({ step: `step${activeStep + 1}` });
    setActiveStep((c) => c - 1);
  }, [activeStep]);

  const handleClose = useCallback(() => {
    triggerInterviewDetailFillDetailCancel('confirm');
    onClose();
    setIsBlocked(false);
  }, [onClose, triggerInterviewDetailFillDetailCancel]);

  const handleSubmitNewSchedule = useCallback(
    async (input: EpCreateInterviewInvitationInput): Promise<boolean> => {
      setShowLoaderDialog(true);
      const res = await handleCreate({ input });
      setShowLoaderDialog(false);

      if (res.success) {
        // allow router change or refresh on success
        setIsBlocked(false);

        // open success dialog
        setSuccessDialogProps({
          title: res.data.title ?? '',
          description: res.data.message ?? '',
          onCancel: () => {
            newInvitationSuccessDialog?.onCancel?.();
            // close modal
            onClose();
          },
          onContinue: () => {
            newInvitationSuccessDialog?.onContinue?.();
            // close modal
            onClose();
          },
          onClose,
        });
        return true;
      }

      // open error toast
      dispatch(
        setSnackbar({
          layout: 'dashboard',
          severity: 'error',
          closeable: true,
          message: res.error.message,
        }),
      );
      // close modal
      onClose();

      return false;
    },
    [handleCreate, onClose, newInvitationSuccessDialog],
  );

  const handleSubmitReschedule = useCallback(
    async (input: EpUpdateInterviewInvitationInput): Promise<boolean> => {
      setShowLoaderDialog(true);
      const res = await handleUpdate({ input });
      setShowLoaderDialog(false);

      if (res.success) {
        // allow router change or refresh on success
        setIsBlocked(false);

        // open success toast
        dispatch(
          setSnackbar({
            layout: 'dashboard',
            severity: 'success',
            closeable: true,
            message: res.data.message,
          }),
        );
        // close modal
        onClose();

        return true;
      }

      // open error toast
      dispatch(
        setSnackbar({
          layout: 'dashboard',
          severity: 'error',
          closeable: true,
          message: res.error.message,
        }),
      );
      // close modal
      onClose();

      return false;
    },
    [handleUpdate, onClose],
  );

  const handleSubmit = useCallback(async () => {
    const values = {
      ...firstStepFormState.getValues(),
      ...secondStepFormState.getValues(),
      ...thirdStepFormState.getValues(),
      vacancyId: vacancy.id,
      applicationIds,
    };
    const mapValues = mapInterviewInvitationFormValueToData(values);

    let isSuccess = false;
    switch (actionKey) {
      case InterviewInvitationDialogActionKey.NEW_INVITATION:
        isSuccess = await handleSubmitNewSchedule(mapValues);
        break;

      case InterviewInvitationDialogActionKey.RESCHEDULE:
        isSuccess = await handleSubmitReschedule(mapValues);
        break;

      default:
        break;
    }

    if (isSuccess) {
      triggerApplicantInterviewDetailSubmit();
      onRefresh?.();
    }
  }, [
    actionKey,
    vacancy,
    applicationIds,
    onRefresh,
    handleSubmitNewSchedule,
    handleSubmitReschedule,
    triggerApplicantInterviewDetailSubmit,
  ]);

  const copywritingByActionKey = useMemo<
    Record<
      InterviewInvitationDialogActionKey,
      InterviewInvitationSessionDialogCopywriting
    >
  >(() => {
    return {
      [InterviewInvitationDialogActionKey.NEW_INVITATION]: {
        title: t('interview-invitation:interviewInvitationSessionDialog.title'),
        alert: t(
          'interview-invitation:interviewInvitationSessionDialog.alert',
          {
            n: `<span class="highlight">(${applicationIds.length})</span>`,
          },
        ),
      },
      [InterviewInvitationDialogActionKey.RESCHEDULE]: {
        title: t(
          'interview-invitation:rescheduleInterviewInvitationSessionDialog.title',
        ),
        alert: t(
          'interview-invitation:rescheduleInterviewInvitationSessionDialog.alert',
          {
            n: `<span class="highlight">(${applicationIds.length})</span>`,
          },
        ),
      },
    };
  }, [applicationIds]);
  const copywriting = copywritingByActionKey[actionKey];

  if (showLoaderDialog) return <InterviewInvitationSessionLoaderDialog />;
  if (successDialogProps)
    return <InterviewInvitationSessionSuccessDialog {...successDialogProps} />;
  if (showCloseConfirm)
    return (
      <InterviewInvitationSessionCloseConfirmationDialog
        onSubmit={handleClose}
        onCancel={closeLeavePrompt}
      />
    );

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      fullScreen={isMobile}
      onClose={openLeavePrompt}
      sx={{
        '.MuiDialog-container': {
          alignItems: isMobile ? 'flex-end' : 'center',
        },
      }}
      PaperProps={{
        sx: {
          p: 0,
          ...(isMobile && {
            bottom: 0,
            height: 'auto',
            maxHeight: 'calc(100vh - 64px)',
            borderTopRightRadius: '8px',
            borderTopLeftRadius: '8px',
          }),
        },
      }}
      {...props}
    >
      <DialogTitle sx={{ px: isMobile ? 3 : 4, py: 3 }}>
        <Stack direction="row" gap={2} alignItems="center">
          <Typography variant="h6" flex={1}>
            {copywriting.title}
          </Typography>
          <IconButton aria-label="close" size="small" onClick={openLeavePrompt}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>

      <DialogContent
        sx={{
          px: isMobile ? 3 : 4,
          py: 3,
          borderBottom: `1px solid ${theme.palette.divider}`,
        }}
      >
        <Stack gap={4}>
          <Alert
            severity="info"
            icon={<MailMarkIcon />}
            sx={{
              fontWeight: '600',
              alignItems: 'center',
              '.highlight': {
                color: theme.palette.primary.main,
              },
            }}
          >
            <Box dangerouslySetInnerHTML={{ __html: copywriting.alert }} />
          </Alert>

          <InterviewInvitationFormStepper activeStep={activeStep} />

          <InterviewInvitationForm
            activeStep={activeStep}
            currentSessionId={currentSessionId}
            vacancy={vacancy}
            sizeKey={sizeKey}
            firstStepFormState={firstStepFormState}
            secondStepFormState={secondStepFormState}
            thirdStepFormState={thirdStepFormState}
          />
        </Stack>
      </DialogContent>

      <DialogActions sx={{ px: isMobile ? 3 : 4, py: 3 }}>
        <Stack gap={2} width="100%">
          {!isMobile && activeStep === 3 && (
            <InterviewInvitationFormInfoAlert />
          )}

          <InterviewInvitationFormNavigation
            activeStep={activeStep}
            navigationButtons={[
              {
                next: {
                  children: t('interview-invitation-form:navigation.next'),
                  disabled: !firstStepFormState.formState.isValid,
                  onClick: handleNext,
                },
              },
              {
                next: {
                  children: t('interview-invitation-form:navigation.next'),
                  disabled: !secondStepFormState.formState.isValid,
                  onClick: handleNext,
                },
                back: {
                  children: t('interview-invitation-form:navigation.back'),
                  onClick: handleBack,
                },
              },
              {
                next: {
                  children: t('interview-invitation-form:navigation.next'),
                  disabled: !thirdStepFormState.formState.isValid,
                  onClick: handleNext,
                },
                back: {
                  children: t('interview-invitation-form:navigation.back'),
                  onClick: handleBack,
                },
              },
              {
                next: {
                  children: t('interview-invitation-form:navigation.submit'),
                  onClick: handleSubmit,
                },
                back: {
                  children: t('interview-invitation-form:navigation.back'),
                  onClick: handleBack,
                },
              },
            ]}
          />
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default InterviewInvitationSessionDialog;
