import { useAppDispatch } from '~/hooks/use-store';
import { setSnackbar } from '~/store/views/snackbar-slice';

export const useCopyText = () => {
  const dispatch = useAppDispatch();

  const copy = (value: string, message: string) => {
    if (value) {
      navigator.clipboard.writeText(value);
      dispatch(
        setSnackbar({
          layout: 'dashboard',
          severity: 'success',
          message: message,
        }),
      );
    }
  };
  return { copy };
};
