import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  TextArea,
  Typography,
  brandColors,
  useMediaQuery,
  useTheme,
} from '@kitalulus/web-ui-kit';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import CandidateInviteEmailTemplate from '~/components/Candidate/CandidateInviteEmailTemplate';
import ConfirmDialogV1 from '~/components/Dialogs/ConfirmDialogV1';
import CloseIcon from '~/components/Icons/CloseIcon';
import LoadingButton, { LoadingButtonProps } from '~/components/LoadingButton';
import { useCandidateRecommendationInvite } from '~/hooks/candidate-recommendation';
import { useAppDispatch, useAppSelector } from '~/hooks/use-store';
import { closeDialog } from '~/store/views/dialogs-slice';
import {
  convertDateToISOString,
  removeFancyTextAndEmoji,
} from '~/utils/helper';
import * as mpe from '~/utils/mixpanel';
import { candidateInvitationTemplateMaxLength } from '~/utils/validation';
import {
  CandidateRecommendationInviteDialogForm,
  CandidateRecommendationInviteDialogProps,
} from './CandidateRecommendationInviteDialog.types';

const CandidateRecommendationInviteDialog = ({
  content,
  ...props
}: CandidateRecommendationInviteDialogProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const {
    candidateRecommendationDetail,
    vacancyDetail: vacancy,
    company,
  } = useAppSelector((state) => state);
  const [isEditMode, setIsEditMode] = useState(true);
  const [isConfirmClose, setIsConfirmClose] = useState(false);
  const { inviteCandidate, inviteLoading: isInviteLoading } =
    useCandidateRecommendationInvite();
  const {
    control,
    getValues,
    handleSubmit,
    reset,
    formState: { isDirty, isValid },
  } = useForm<CandidateRecommendationInviteDialogForm>({
    mode: 'all',
    defaultValues: { template: '' },
  });

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const candidate = candidateRecommendationDetail?.detail?.data ?? {};
  const defaultTemplate = content?.templateStr ?? '';

  const actionButtons = useMemo<LoadingButtonProps[]>(() => {
    return [
      {
        key: 'preview',
        'data-test-id': 'btnCandidateInvitePreview',
        hidden: !isEditMode,
        variant: 'outlined',
        disabled: !isValid,
        onClick: () => {
          mpe.candidateInvitationPreview({
            candidateID: candidate.id ?? '',
            candidateName: candidate.userProfile?.name ?? '',
            vacancyCode: vacancy?.code ?? '',
            vacancyCreatedAt: convertDateToISOString(vacancy?.createdAt),
            vacancyCloseDate: convertDateToISOString(vacancy?.closeDate),
          });
          setIsEditMode(false);
        },
        children: t(
          'candidate-recommendation-detail:inviteDialogV2.button.preview',
        ),
      },
      {
        key: 'edit',
        'data-test-id': 'btnCandidateInviteEdit',
        hidden: isEditMode,
        variant: 'outlined',
        onClick: () => setIsEditMode(true),
        children: t(
          'candidate-recommendation-detail:inviteDialogV2.button.edit',
        ),
      },
      {
        key: 'send',
        'data-test-id': 'btnCandidateInviteSend',
        type: 'submit',
        variant: 'contained',
        disabled: !isValid,
        loading: isInviteLoading,
        children: t(
          'candidate-recommendation-detail:inviteDialogV2.button.send',
        ),
      },
    ];
  }, [isEditMode, isValid, isInviteLoading, candidate, vacancy]);

  const contentToShow = useMemo(() => {
    if (isEditMode)
      return {
        title: t('candidate-recommendation-detail:inviteDialogV2.title', {
          name: candidate.userProfile?.name,
        }),
        content: (
          <Stack spacing={2}>
            <Typography
              data-test-id="lbCandidateInviteInstruction"
              variant="body1"
              sx={{ color: 'text.secondary' }}
            >
              {t('candidate-recommendation-detail:inviteDialogV2.instruction')}
            </Typography>
            <Controller
              control={control}
              name="template"
              rules={{
                required: {
                  value: true,
                  message: t('common:required'),
                },
              }}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <Stack spacing={1}>
                  <TextArea
                    label={null}
                    rows={14}
                    error={!!error}
                    value={value}
                    onChange={(e) =>
                      onChange(removeFancyTextAndEmoji(e.target.value))
                    }
                    onBlur={() => onChange(value.trim())}
                    inputProps={{
                      maxLength: candidateInvitationTemplateMaxLength,
                      'data-test-id': 'tfCandidateInviteTemplate',
                    }}
                    fullWidth
                  />
                  <Stack direction="row" justifyContent="space-between">
                    <Typography
                      data-test-id="lbCandidateInviteTemplateError"
                      variant="caption"
                      sx={{ color: brandColors.danger[500] }}
                    >
                      {error?.message}
                    </Typography>
                    <Typography
                      data-test-id="lbCandidateInviteTemplateCount"
                      variant="caption"
                      sx={{ color: 'text.secondary' }}
                    >
                      {value.length}/{candidateInvitationTemplateMaxLength}
                    </Typography>
                  </Stack>
                </Stack>
              )}
            />
          </Stack>
        ),
      };

    return {
      title: t('candidate-recommendation-detail:inviteDialogV2.previewTitle'),
      content: (
        <Stack alignItems="center" py={3}>
          <CandidateInviteEmailTemplate
            templateContent={getValues('template')}
            company={{
              name: company?.name ?? '',
              logoURL: company?.logo?.url,
            }}
            vacancy={{
              positionName: vacancy?.positionName ?? '',
              locationProvince: vacancy?.locationProvince?.name,
              locationCity: vacancy?.locationCity?.name,
              educationLevelStr: vacancy?.educationLevelStr,
              salaryLowerBound: vacancy?.salaryLowerBound,
              salaryUpperBound: vacancy?.salaryUpperBound,
            }}
          />
        </Stack>
      ),
    };
  }, [isEditMode, company, vacancy]);

  const handleClose = useCallback(() => {
    dispatch(closeDialog('candidateRecommendationInviteDialogV2'));
  }, []);

  const handlePreClose = useCallback(() => {
    isDirty ? setIsConfirmClose(true) : handleClose();
  }, [isDirty, handleClose]);

  const onSubmit: SubmitHandler<CandidateRecommendationInviteDialogForm> =
    useCallback(
      async (values) => {
        if (!candidate.id) return;
        if (!content) return;

        mpe.candidateActionConfirm({
          vacancyCode: vacancy?.code ?? '',
          vacancyCreatedAt: convertDateToISOString(vacancy?.createdAt),
          vacancyCloseDate: convertDateToISOString(vacancy?.closeDate),
          candidateID: candidate.id,
          candidateName: candidate.userProfile?.name ?? '',
          action: 'invite',
          actionPosition: content.triggerSource,
        });

        const { isSuccess } = await inviteCandidate(
          candidate.id,
          values.template,
        );
        if (!isSuccess) return;
        content?.onSuccess?.();
        handleClose();
      },
      [inviteCandidate, candidate, content, vacancy, company, handleClose],
    );

  useEffect(() => {
    if (defaultTemplate) reset({ template: defaultTemplate });
  }, [defaultTemplate]);

  if (isConfirmClose)
    return (
      <ConfirmDialogV1
        title={t(
          'candidate-recommendation-detail:inviteDialogV2.closeConfirm.title',
        )}
        body={t(
          'candidate-recommendation-detail:inviteDialogV2.closeConfirm.body',
        )}
        titleProps={{ 'data-test-id': 'lbCandidateInviteCloseTitle' }}
        bodyProps={{ 'data-test-id': 'lbCandidateInviteCloseBody' }}
        cancelProps={{
          'data-test-id': 'btnCandidateInviteCloseCancel',
          variant: 'outlined',
          children: t('common:cancelAlt'),
          onClick: () => setIsConfirmClose(false),
        }}
        acceptProps={{
          'data-test-id': 'btnCandidateInviteCloseConfirm',
          variant: 'contained',
          color: 'error',
          children: t('common:yesExit'),
          onClick: handleClose,
        }}
        dialogContentProps={{ sx: { px: 0, pt: 0, pb: 2 } }}
        PaperProps={{
          sx: {
            maxWidth: '500px',
            p: 4,
            pb: 3,
            m: 0,
            width: 'calc(100% - 32px)',
          },
        }}
        hideCloseButton
        {...props}
      />
    );

  return (
    <Dialog
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      fullWidth
      maxWidth="sm"
      fullScreen={isMobile}
      sx={{
        '.MuiDialog-container': {
          alignItems: isMobile ? 'flex-end' : 'center',
        },
      }}
      PaperProps={{
        sx: {
          p: 0,
          overflow: 'hidden',
          ...(isMobile && {
            p: 0.3,
            bottom: 0,
            height: 'auto',
            maxHeight: 'calc(100vh - 64px)',
            borderTopRightRadius: '8px',
            borderTopLeftRadius: '8px',
          }),
        },
      }}
      {...props}
    >
      <DialogTitle sx={{ px: isMobile ? 2 : 3 }}>
        <Stack spacing={1} direction="row" justifyContent="space-between">
          <Typography
            data-test-id="lbCandidateInviteTitle"
            variant="h6"
            alignSelf="center"
            color="#424242"
          >
            {contentToShow.title}
          </Typography>
          <div>
            <IconButton
              data-test-id="btnCandidateInviteClose"
              size="small"
              onClick={handlePreClose}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </Stack>
      </DialogTitle>

      <Divider />

      <DialogContent sx={{ px: isMobile ? 2 : 3 }}>
        {contentToShow.content}
      </DialogContent>

      <DialogActions
        sx={{
          px: isMobile ? 2 : 3,
          py: 2,
          boxShadow: theme.shadows[4],
          mx: { xs: -0.3, sm: -1 },
        }}
      >
        <Stack
          direction="row"
          spacing={2}
          width="100%"
          justifyContent="flex-end"
        >
          {actionButtons.map(
            ({ key, hidden, ...btnProps }) =>
              !hidden && (
                <LoadingButton
                  key={key}
                  {...btnProps}
                  sx={{
                    flex: isMobile ? 1 : 'initial',
                    px: isMobile ? 1 : 2,
                    height: '42px',
                    fontSize: isMobile ? '14px' : '13px',
                    ...btnProps.sx,
                  }}
                />
              ),
          )}
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default CandidateRecommendationInviteDialog;
