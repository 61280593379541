import { Box, Stack } from '@kitalulus/web-ui-kit';

import Navbar from '../Navbar';
import { AuthLayoutProps } from './AuthLayout.types';
import AuthLayoutBanner from './AuthLayoutBanner';
import AuthLayoutCopyRight from './AuthLayoutCopyRight';
import AuthLayoutHelp from './AuthLayoutHelp';

const AuthLayout = ({
  showHelpButton,
  showBanner,
  showNavbar,
  showCopyRightOnDesktop,
  contentBoxProps,
  navbarProps,
  bannerVariant,
  authLayoutCopyRight,
  children,
  ...props
}: AuthLayoutProps) => {
  return (
    <Box display="flex" flexDirection="column" minHeight="100vh" height="100%">
      {showNavbar && <Navbar {...navbarProps} />}

      <Box flex={1} display="flex" gap={2} {...props}>
        {/* Main auth content */}
        <Box flex={1} display="flex" padding={4} {...contentBoxProps}>
          <Stack height="100%" width="100%" spacing={8}>
            <Stack flex={1} width="100%" spacing={6}>
              {children}
            </Stack>

            {/* Copyright and help button */}
            <Stack spacing={2} alignSelf="center">
              {showHelpButton && <AuthLayoutHelp {...showHelpButton} />}

              <Box
                alignSelf="center"
                display={{
                  xs: 'block',
                  lg: showCopyRightOnDesktop ? 'block' : 'none',
                }}
              >
                <AuthLayoutCopyRight
                  textAlign="center"
                  {...authLayoutCopyRight}
                />
              </Box>
            </Stack>
          </Stack>
        </Box>

        {/* Auth banner */}
        {showBanner && (
          <AuthLayoutBanner
            bannerVariant={bannerVariant}
            authLayoutCopyRight={authLayoutCopyRight}
          />
        )}
      </Box>
    </Box>
  );
};

export default AuthLayout;
