import { useLazyQuery } from '@apollo/client';
import { useCallback, useEffect } from 'react';

import { QUERY_JOB_APPLICATION_HISTORY_LABELS } from '~/graphql/applicants';
import { setJobApplicantHistoryLabel } from '~/store/features/job-applicant-history-label-slice';
import {
  EpJobApplicationEpStatusEnum,
  EpJobApplicationHistoryLabel,
  JobApplicationHistoryLabelsQuery,
} from '~/types/graphql/graphql';
import { defaultGlobalDataContext } from '~/utils/apollo-request';
import { useAuthValue } from './use-auth';
import { useAppDispatch, useAppSelector } from './use-store';

export const useJobApplicationHistoryLabel = () => {
  const dispatch = useAppDispatch();
  const { isAuthenticated } = useAuthValue();
  const [getApplicationHistoryLabel, { loading }] =
    useLazyQuery<JobApplicationHistoryLabelsQuery>(
      QUERY_JOB_APPLICATION_HISTORY_LABELS,
      {
        fetchPolicy: 'no-cache',
        context: defaultGlobalDataContext,
        onCompleted: ({ jobApplicationHistoryLabels }) => {
          dispatch(
            setJobApplicantHistoryLabel(
              jobApplicationHistoryLabels?.filter(
                (d) => d,
              ) as EpJobApplicationHistoryLabel[],
            ),
          );
        },
      },
    );

  useEffect(() => {
    if (!isAuthenticated) return;
    getApplicationHistoryLabel();
  }, [isAuthenticated]);

  return { isLoading: loading };
};

export const useApplicantColorScheme = () => {
  const colors = useAppSelector((store) => store.jobApplicationHistoryLabels);

  const getColor = useCallback(
    (label: EpJobApplicationEpStatusEnum) =>
      colors.find((color) => color.label === label)?.textColor ??
      'text.secondary',
    [colors],
  );

  const getBackgroundColor = useCallback(
    (label: EpJobApplicationEpStatusEnum) =>
      colors.find((color) => color.label === label)?.bgColor ?? 'grey.300',
    [colors],
  );

  return { getColor, getBackgroundColor };
};
