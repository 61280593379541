import { useLazyQuery } from '@apollo/client';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import {
  QUERY_JOB_VACANCY,
  QUERY_JOB_VACANCY_SCREENING_QUESTIONS,
} from '~/graphql/vacancy';
import {
  setVacancyDetail,
  setVacancyDetailScreeningQuestions,
} from '~/store/features/vacancy-detail-slice';
import { setDialog } from '~/store/views/dialogs-slice';
import {
  EpJobVacancyState,
  EpScreeningQuestion,
  JobVacancyQuery,
  JobVacancyQueryVariables,
  JobVacancyScreeningQuestionsQuery,
  JobVacancyScreeningQuestionsQueryVariables,
} from '~/types/graphql/graphql';
import { useAppDispatch, useAppSelector } from './use-store';
import { FETCH_STATUS } from '~/utils/constants';
import { setVacancyDetailV2Details } from '~/store/features/vacancy-detail-v2-slice';
import { useRealtimeData } from './firebase/use-realtime-data';

export const useVacancyDetail = (triggerRealtimeVacancy = false) => {
  const params = useParams<{ id: string }>();
  const dispatch = useAppDispatch();
  const { vacancyDetail } = useAppSelector((state) => state);
  const isHighlighted = vacancyDetail?.isHighlighted ?? false;

  const { getVacancyRealtime } = useRealtimeData(false);

  const [getVacancyDetail, { loading }] = useLazyQuery<
    JobVacancyQuery,
    JobVacancyQueryVariables
  >(QUERY_JOB_VACANCY, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ jobVacancy }) => {
      if (jobVacancy?.__typename === 'EpJobVacancy') {
        dispatch(setVacancyDetail(jobVacancy));
        dispatch(
          setVacancyDetailV2Details({
            fetchStatus: FETCH_STATUS.RESOLVED,
            ...jobVacancy,
          }),
        );
        if (triggerRealtimeVacancy) getVacancyRealtime(jobVacancy?.code || '');
      }
    },
  });

  const doFetch = useCallback(() => {
    if (!params.id) return;
    dispatch(
      setVacancyDetailV2Details({
        fetchStatus: FETCH_STATUS.PENDING,
      }),
    );
    getVacancyDetail({ variables: { id: params.id } });
  }, []);

  // This function unused
  const handleChangeStatus = () => {
    if (!vacancyDetail?.id) return;
    dispatch(
      setDialog({
        vacancyChangeStateDialog: {
          open: true,
          content: {
            action:
              vacancyDetail.state === EpJobVacancyState.Open
                ? EpJobVacancyState.Closed
                : EpJobVacancyState.Open,
            vacancyHighlighted: isHighlighted,
            vacancyId: vacancyDetail.id,
            vacancyCode: vacancyDetail?.code ?? '',
            vacancyWebLink: vacancyDetail.webLink ?? undefined,
            vacancyName: vacancyDetail.positionName ?? '',
            vacancyLocation: vacancyDetail.locationCity?.name ?? '',
            onCompleted: (onClose, data) => {
              if (data) {
                onClose();
                doFetch();
              }
            },
          },
        },
      }),
    );
  };

  return {
    isLoading: loading,
    handleChangeStatus,
    getVacancyDetail,
  };
};

export const useVacancyDetailScreeningQuestions = () => {
  const dispatch = useAppDispatch();

  const [queryJobVacancyScreeningQuestions] = useLazyQuery<
    JobVacancyScreeningQuestionsQuery,
    JobVacancyScreeningQuestionsQueryVariables
  >(QUERY_JOB_VACANCY_SCREENING_QUESTIONS, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ jobVacancyScreeningQuestions }) =>
      dispatch(
        setVacancyDetailScreeningQuestions({
          screeningQuestions:
            jobVacancyScreeningQuestions?.list as EpScreeningQuestion[],
          screeningQuestionsFetchStatus: FETCH_STATUS.RESOLVED,
        }),
      ),
  });

  const getVacancyScreeningQuestions = (id: string) => {
    dispatch(
      setVacancyDetailScreeningQuestions({
        jobVacancyId: id,
        screeningQuestionsFetchStatus: FETCH_STATUS.PENDING,
      }),
    );
    queryJobVacancyScreeningQuestions({ variables: { jobVacancyId: id } });
  };

  return {
    getVacancyScreeningQuestions,
  };
};
