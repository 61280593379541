import { DropdownOption } from '~/types';
import {
  EpEducationLevelFilter,
  EpJobApplicationEpStatusEnum,
} from '~/types/graphql/graphql';
import { APPLICANT_TAB_PRIMARY } from '~/utils/constants';
import { useMultiQueryState } from '../use-query-state';

export type ApplicantPageQueryParams = {
  page_limit: number;
  page: number;
  active_tab_primary: APPLICANT_TAB_PRIMARY;
  active_tab_secondary: EpJobApplicationEpStatusEnum;
  vacancy_id: string;
  vacancy_label: string;
  name: string;
  sort: string;
  gender?: string;
  age_min?: number;
  age_max?: number;
  educations?: EpEducationLevelFilter[];
  education_institutions?: DropdownOption[];
  salary_min?: number;
  salary_max?: number;
  autoFilter?: boolean;
  location_values: DropdownOption[];
  experience_year?: DropdownOption;
  job_functions: DropdownOption[];
  skills: DropdownOption[];
  is_restrict: boolean;
};

export const useApplicantPageQuery = () => {
  return useMultiQueryState<ApplicantPageQueryParams>({
    page_limit: 25,
    page: 0,
    active_tab_primary: APPLICANT_TAB_PRIMARY.ALL,
    active_tab_secondary: EpJobApplicationEpStatusEnum.Pending,
    vacancy_id: '',
    vacancy_label: '',
    name: '',
    sort: 'DESC',
    gender: 'all',
    educations: [],
    location_values: [],
    job_functions: [],
    skills: [],
    is_restrict: false,
  });
};
