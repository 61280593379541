import { useLazyQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

import { QUERY_JOB_APPLICATION } from '~/graphql/applicants';
import { useAppDispatch, useAppSelector } from '~/hooks/use-store';
import { useApplicantPageQuery } from '~/hooks/page-query/use-applicant-page-query';
import { useApplicantScreeningQuestion } from '~/hooks/applicant-detail/use-applicant-screening-question';
import {
  setApplicantDetail,
  setApplicantDetailFetchStatus,
  setApplicantDetailError,
  setApplicantScreeningQuestion,
} from '~/store/features/applicant-detail-slice';

import { clearDialog } from '~/store/views/dialogs-slice';
import {
  JobApplicationQuery,
  JobApplicationQueryVariables,
} from '~/types/graphql/graphql';
import { FETCH_STATUS } from '~/utils/constants';
import { GRAPHQL_ERROR_CODE } from '~/utils/constants/graphql-error-code';
import { generateQueryString } from '~/utils/helper';
import useApplicantScreeningQuestionFilter from './use-applicant-screening-question-filter';

export const useApplicantDetail = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [urlParams] = useApplicantPageQuery();
  const { screeningQuestionPersistedFilter, generateScreeningQuestionPayload } =
    useApplicantScreeningQuestionFilter(urlParams.vacancy_id);
  const { fetchApplicantScreeningQuestion } = useApplicantScreeningQuestion();
  const { vacancyDetailV2 } = useAppSelector((state) => state);

  const haveScreeningQuestions =
    vacancyDetailV2?.screeningQuestionsFilter?.fetchStatus ===
      FETCH_STATUS.RESOLVED &&
    vacancyDetailV2?.screeningQuestionsFilter?.data?.length;

  const [getApplicantDetail, { loading, refetch: _refetchApplicationDetail }] =
    useLazyQuery<JobApplicationQuery, JobApplicationQueryVariables>(
      QUERY_JOB_APPLICATION,
      {
        fetchPolicy: 'no-cache',
        onCompleted: async ({ jobApplication }) => {
          dispatch(setApplicantDetailFetchStatus(FETCH_STATUS.RESOLVED));
          if (!jobApplication) return;
          dispatch(
            setApplicantDetail({
              ...jobApplication,
            }),
          );

          if (haveScreeningQuestions && jobApplication?.id) {
            dispatch(
              setApplicantScreeningQuestion({
                fetchStatus: FETCH_STATUS.PENDING,
              }),
            );

            fetchApplicantScreeningQuestion({
              variables: {
                jobApplicantId: jobApplication?.id,
                screeningQuestions: generateScreeningQuestionPayload(
                  screeningQuestionPersistedFilter,
                ),
              },
            });
          }
        },
      },
    );

  const queryApplicantDetail = async (id: string, setStatusPending = true) => {
    if (setStatusPending) {
      dispatch(setApplicantDetailFetchStatus(FETCH_STATUS.PENDING));
    }
    await getApplicantDetail({
      variables: {
        id: id,
      },
      onError: (error) => {
        const extensionCode = error.graphQLErrors.map(
          (error) => error.extensions?.code,
        );
        const isNotFound = extensionCode.includes(GRAPHQL_ERROR_CODE.NOT_FOUND);

        if (isNotFound) {
          dispatch(setApplicantDetailError(GRAPHQL_ERROR_CODE.NOT_FOUND));
          dispatch(clearDialog('profilePreviewDialog'));
          navigate(
            `/applicants/detail?${generateQueryString({
              id,
              ...urlParams,
            })}`,
            { replace: true },
          );
        }
      },
    });
  };

  return { queryApplicantDetail, loading };
};
