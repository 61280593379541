import { SvgIcon, SvgIconProps } from '@kitalulus/web-ui-kit';

const FiSsArrowRight = (props: SvgIconProps) => {
  return (
    <SvgIcon width="20" height="20" viewBox="0 0 20 20" {...props}>
      <g>
        <path d="M19.27 8.2328L15.4483 4.41113L14.27 5.58947L17.8217 9.14197L0 9.16697V10.8336L17.8717 10.8086L14.2692 14.4111L15.4475 15.5895L19.27 11.7678C19.7373 11.2982 19.9996 10.6627 19.9996 10.0003C19.9996 9.33786 19.7373 8.70237 19.27 8.2328Z" />
      </g>
    </SvgIcon>
  );
};

export default FiSsArrowRight;
