import {
  Box,
  Image,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@kitalulus/web-ui-kit';
import { format } from 'date-fns';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import ArrowLeftIcon from '~/components/Icons/ArrowLeftIcon';
import ConsLinkIcon from '~/components/ConsComponents/Icons/ConsLinkIcon';
import ConsLocationIcon from '~/components/ConsComponents/Icons/ConsLocationIcon';
import ConsTimeIcon from '~/components/ConsComponents/Icons/ConsTimeIcon';
import { EpInterviewSessionType } from '~/types/graphql/graphql';
import {
  InterviewInvitationFormPreviewProps,
  InterviewInvitationFormPreviewScheduleDetail,
} from '.';

const InterviewInvitationFormPreview = ({
  interviewInvitation,
  ...props
}: InterviewInvitationFormPreviewProps) => {
  const { t } = useTranslation();

  // responsive states
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    schedule,
    scheduleStartTime,
    scheduleEndTime,
    type,
    offlineDetail,
    recruiterMessage,
  } = interviewInvitation;

  const previewBoxTabs = useMemo(() => {
    return [
      {
        key: 'info',
        label: t('interview-invitation-form:previewBox.tabInformation'),
        isActive: true,
      },
      {
        key: 'detail',
        label: t('interview-invitation-form:previewBox.tabVacancyDetail'),
        isActive: false,
      },
    ];
  }, []);

  const previewBoxActionButtons = useMemo(() => {
    return [
      {
        key: 'reject',
        label: t('interview-invitation-form:previewBox.attendanceRejectButton'),
        isActive: false,
      },
      {
        key: 'confirm',
        label: t(
          'interview-invitation-form:previewBox.attendanceConfirmButton',
        ),
        isActive: true,
      },
    ];
  }, []);

  const scheduleTimeStr = useMemo(() => {
    const startTimeStr =
      scheduleStartTime && format(scheduleStartTime, 'HH.mm');
    const endTimeStr = scheduleEndTime && format(scheduleEndTime, 'HH.mm');

    const scheduleTimes: string[] = [];
    if (startTimeStr) scheduleTimes.push(startTimeStr);
    if (endTimeStr) scheduleTimes.push(endTimeStr);
    return scheduleTimes.join(' - ');
  }, [scheduleStartTime, scheduleEndTime]);

  const scheduleOfflineDetails = useMemo<
    InterviewInvitationFormPreviewScheduleDetail[]
  >(() => {
    return [
      {
        key: 'offline-time',
        icon: <ConsTimeIcon sx={{ fontSize: '16px', color: '#8f8f8f' }} />,
        content: (
          <Typography
            fontFamily="inherit"
            fontSize="14px"
            lineHeight="18px"
            color="#424242"
          >
            {scheduleTimeStr} WIB
          </Typography>
        ),
      },
      {
        key: 'offline-location',
        icon: <ConsLocationIcon sx={{ fontSize: '16px', color: '#8f8f8f' }} />,
        content: (
          <Stack>
            <Typography
              fontFamily="inherit"
              fontSize="14px"
              lineHeight="18px"
              color="#424242"
              sx={{ wordBreak: 'break-all' }}
            >
              {offlineDetail.address}
            </Typography>
            <Typography
              fontFamily="inherit"
              fontSize="14px"
              fontWeight={600}
              lineHeight="18px"
              color="#1E88E5"
              sx={{ wordBreak: 'break-all' }}
            >
              {offlineDetail.url}
            </Typography>
          </Stack>
        ),
      },
    ];
  }, [scheduleTimeStr, offlineDetail.address, offlineDetail.url]);

  const scheduleOnlineDetails = useMemo<
    InterviewInvitationFormPreviewScheduleDetail[]
  >(() => {
    return [
      {
        key: 'online-time',
        icon: <ConsTimeIcon sx={{ fontSize: '16px', color: '#8f8f8f' }} />,
        content: (
          <Typography
            fontFamily="inherit"
            fontSize="14px"
            lineHeight="18px"
            color="#424242"
          >
            {scheduleTimeStr} WIB
          </Typography>
        ),
      },
      {
        key: 'online-link',
        icon: <ConsLinkIcon sx={{ fontSize: '16px', color: '#8f8f8f' }} />,
        content: (
          <Stack>
            <Typography
              fontFamily="inherit"
              fontSize="14px"
              lineHeight="18px"
              color="#424242"
            >
              {t(
                'interview-invitation-form:previewBox.scheduleOnlineDetailPrimary',
              )}
            </Typography>
            <Typography
              fontFamily="inherit"
              fontSize="14px"
              lineHeight="18px"
              color="#424242"
            >
              {t(
                'interview-invitation-form:previewBox.scheduleOnlineDetailSecondary',
              )}
            </Typography>
          </Stack>
        ),
      },
    ];
  }, [scheduleTimeStr]);

  const scheduleDetails = useMemo<
    InterviewInvitationFormPreviewScheduleDetail[]
  >(() => {
    switch (type) {
      case EpInterviewSessionType.Offline:
        return scheduleOfflineDetails;
      case EpInterviewSessionType.Online:
        return scheduleOnlineDetails;
      default:
        return [];
    }
  }, [type, scheduleOfflineDetails, scheduleOnlineDetails]);

  return (
    <Stack gap={2} {...props} overflow="hidden">
      {/* Top bar section */}
      <Stack direction="row" gap={2} p={isMobile ? 1.5 : 2} pb={1}>
        <ArrowLeftIcon />
        <Typography
          fontSize="18px"
          color="#424242"
          fontWeight={600}
          fontFamily="inherit"
        >
          {t('interview-invitation-form:previewBox.topBarTitle')}
        </Typography>
      </Stack>

      {/* Tab */}
      <Stack>
        <Stack direction="row">
          {previewBoxTabs.map((tab) => (
            <Box key={tab.key} flex={1} textAlign="center">
              <Typography
                fontFamily="inherit"
                fontWeight={600}
                fontSize="14px"
                color={tab.isActive ? '#1E88E5' : '#a8a8a8'}
                pb={1.5}
                sx={{
                  ...(tab.isActive && {
                    borderWidth: '0px',
                    borderBottomWidth: '4px',
                    borderStyle: 'solid',
                    borderColor: '#1E88E5',
                  }),
                }}
              >
                {tab.label}
              </Typography>
            </Box>
          ))}
        </Stack>
        <Box bgcolor="#eeeeee" height="5px" width="100%" />
      </Stack>

      {/* Tab content */}
      <Stack px={isMobile ? 1.5 : 2} gap={2}>
        <Typography
          fontFamily="inherit"
          fontSize="14px"
          fontWeight={600}
          color="#424242"
        >
          {t('interview-invitation-form:previewBox.scheduleTitle')}
        </Typography>

        {/* Schedule box */}
        <Stack
          gap={1.5}
          bgcolor="#f8f8f8"
          p={isMobile ? 1 : 1.5}
          borderRadius="10px"
        >
          <Stack gap={1}>
            {schedule && (
              <Typography
                fontFamily="inherit"
                fontSize="14px"
                fontWeight={600}
                color="#424242"
              >
                {format(schedule, 'EEEE, dd MMMM yyyy')}
              </Typography>
            )}

            <Stack gap={1}>
              {scheduleDetails.map((sch) => (
                <Stack
                  key={sch.key}
                  direction="row"
                  gap={1}
                  alignItems="center"
                >
                  {sch.icon}
                  {sch.content}
                </Stack>
              ))}
            </Stack>
          </Stack>

          <Box height="1px" width="100%" bgcolor="#c2c2c2" />

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography fontFamily="inherit" fontSize="14px" color="#424242">
              {t('interview-invitation-form:previewBox.rescheduleLabel')}
            </Typography>

            <Stack
              direction="row"
              gap={1}
              bgcolor="#ffffff"
              px="8px"
              py="4px"
              sx={{
                borderWidth: '1px',
                borderStyle: 'solid',
                borderColor: '#919EAB',
                borderRadius: '4px',
              }}
            >
              <Image
                src="/icon/whatsapp-icon.png"
                alt="Whatsapp icon"
                sx={{ width: '16px', height: '16px' }}
              />
              <Typography
                fontFamily="inherit"
                fontSize="12px"
                fontWeight={600}
                color="#919EAB"
              >
                {t('interview-invitation-form:previewBox.rescheduleButton')}
              </Typography>
            </Stack>
          </Stack>
        </Stack>

        {/* Recruiter message */}
        <Stack gap={0.5}>
          <Typography
            fontFamily="inherit"
            fontSize="14px"
            fontWeight={600}
            color="#424242"
          >
            {t('interview-invitation-form:previewBox.recruiterMessageTitle')}
          </Typography>
          <Typography
            fontFamily="inherit"
            fontSize="14px"
            fontWeight={400}
            fontStyle={recruiterMessage ? 'normal' : 'italic'}
            color="#757575"
            whiteSpace="pre-wrap"
            sx={{ wordBreak: 'break-all' }}
          >
            {recruiterMessage ||
              t('interview-invitation-form:previewBox.recruiterMessageEmpty')}
          </Typography>
        </Stack>
      </Stack>

      {/* Footer */}
      <Stack
        p={isMobile ? 1.5 : 2}
        gap={1.5}
        sx={{ boxShadow: '-4px 0px 8px 0px #00000026' }}
      >
        <Stack>
          <Typography
            fontFamily="inherit"
            fontSize="14px"
            fontWeight={600}
            color="#424242"
          >
            {t('interview-invitation-form:previewBox.attendanceTitle')}
          </Typography>
          <Typography
            fontFamily="inherit"
            fontSize="12px"
            fontWeight={400}
            color="#424242"
          >
            {t('interview-invitation-form:previewBox.attendanceSubtitle')}
          </Typography>
        </Stack>

        <Stack direction="row" gap={1.5}>
          {previewBoxActionButtons.map((btn) => (
            <Box
              key={btn.key}
              flex={1}
              bgcolor={btn.isActive ? '#757575' : '#fdfdfd'}
              p={1}
              sx={{
                borderColor: '#757575',
                borderRadius: '6px',
                borderStyle: 'solid',
                borderWidth: '1px',
              }}
            >
              <Typography
                fontFamily="inherit"
                fontSize="16px"
                fontWeight={600}
                textAlign="center"
                color={btn.isActive ? '#fdfdfd' : '#757575'}
              >
                {btn.label}
              </Typography>
            </Box>
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default InterviewInvitationFormPreview;
