import { SvgIcon, SvgIconProps } from '@kitalulus/web-ui-kit';

const ChevronRightIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 7 14" {...props}>
      <path d="M1.1667 13.4167C0.952515 13.4171 0.744949 13.3425 0.580029 13.2058C0.392564 13.0504 0.274644 12.8268 0.252287 12.5843C0.22993 12.3418 0.304975 12.1004 0.460863 11.9133L4.56753 6.99999L0.607529 2.07749C0.45374 1.88811 0.381783 1.64524 0.40759 1.40266C0.433397 1.16007 0.55484 0.937774 0.745029 0.78499C0.936765 0.616288 1.19019 0.535221 1.44424 0.561322C1.69829 0.587423 1.92993 0.718325 2.08336 0.92249L6.51086 6.42249C6.7889 6.76074 6.7889 7.24841 6.51086 7.58666L1.92753 13.0867C1.74102 13.3117 1.45841 13.4342 1.1667 13.4167Z" />
    </SvgIcon>
  );
};

export default ChevronRightIcon;
