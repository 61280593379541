import { SvgIcon, SvgIconProps } from '@kitalulus/web-ui-kit';

const ConsLinkIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="17" height="16" viewBox="0 0 17 16" {...props}>
      <path
        d="M3.1026 8.0013C3.1026 6.8613 4.02927 5.93464 5.16927 5.93464H7.83594V4.66797H5.16927C3.32927 4.66797 1.83594 6.1613 1.83594 8.0013C1.83594 9.8413 3.32927 11.3346 5.16927 11.3346H7.83594V10.068H5.16927C4.02927 10.068 3.1026 9.1413 3.1026 8.0013ZM5.83594 8.66797H11.1693V7.33464H5.83594V8.66797ZM11.8359 4.66797H9.16927V5.93464H11.8359C12.9759 5.93464 13.9026 6.8613 13.9026 8.0013C13.9026 9.1413 12.9759 10.068 11.8359 10.068H9.16927V11.3346H11.8359C13.6759 11.3346 15.1693 9.8413 15.1693 8.0013C15.1693 6.1613 13.6759 4.66797 11.8359 4.66797Z"
        fill="#8F8F8F"
      />
    </SvgIcon>
  );
};

export default ConsLinkIcon;
