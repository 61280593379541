import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

const Fraud = lazy(() => import('~/pages/Fraud'));
const InfoPrivacy = lazy(() => import('~/pages/Privacy/InfoPrivacy'));

const InfoRoutes = () => {
  return (
    <Routes>
      <Route path="fraud" element={<Fraud />} />
      <Route path="privacy" element={<InfoPrivacy />} />
    </Routes>
  );
};

export default InfoRoutes;
