import {
  Alert,
  List,
  ListItem,
  Stack,
  Typography,
} from '@kitalulus/web-ui-kit';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import InfoIcon from '~/components/Icons/InfoIcon';
import { InterviewInvitationFormInfoAlertProps } from './';

const InterviewInvitationFormInfoAlert = (
  props: InterviewInvitationFormInfoAlertProps,
) => {
  const { t } = useTranslation();

  const infos = useMemo(() => {
    return [
      {
        key: '1',
        label: t('interview-invitation-form:previewAlertList.1'),
      },
      {
        key: '2',
        label: t('interview-invitation-form:previewAlertList.2'),
      },
    ];
  }, []);

  return (
    <Alert icon={false} severity="info" {...props}>
      <Stack gap={1.5}>
        <Stack direction="row" gap={1.5} alignItems="center">
          <InfoIcon sx={{ color: 'info.main', fontSize: '24px' }} />
          <Typography variant="subtitle1" color="text.primary">
            {t('interview-invitation-form:previewAlertTitle')}
          </Typography>
        </Stack>

        <List sx={{ py: 0, pl: 3.25, listStyleType: 'disc' }}>
          {infos.map((info) => (
            <ListItem
              key={info.key}
              sx={{ display: 'list-item', py: 0, pl: 1.2 }}
            >
              <Typography variant="body1" color="text.primary">
                {info.label}
              </Typography>
            </ListItem>
          ))}
        </List>
      </Stack>
    </Alert>
  );
};

export default InterviewInvitationFormInfoAlert;
