import { SvgIcon, SvgIconProps } from '@kitalulus/web-ui-kit';

const FileCopyOutlinedIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 18 18" {...props}>
      <path d="M11.25 0.75H3C2.175 0.75 1.5 1.425 1.5 2.25V12C1.5 12.4125 1.8375 12.75 2.25 12.75C2.6625 12.75 3 12.4125 3 12V3C3 2.5875 3.3375 2.25 3.75 2.25H11.25C11.6625 2.25 12 1.9125 12 1.5C12 1.0875 11.6625 0.75 11.25 0.75ZM11.6925 4.1925L15.315 7.815C15.5925 8.0925 15.75 8.475 15.75 8.8725V15.75C15.75 16.575 15.075 17.25 14.25 17.25H5.9925C5.1675 17.25 4.5 16.575 4.5 15.75L4.5075 5.25C4.5075 4.425 5.175 3.75 6 3.75H10.6275C11.025 3.75 11.4075 3.9075 11.6925 4.1925ZM11.25 9H14.625L10.5 4.875V8.25C10.5 8.6625 10.8375 9 11.25 9Z" />
    </SvgIcon>
  );
};

export default FileCopyOutlinedIcon;
