import { HTMLAttributes, UIEvent } from 'react';
import { DropdownOption } from '~/types';
import { SCROLL_THRESHOLD_PERCENTAGE } from './constants';
import { Box, Typography } from '@kitalulus/web-ui-kit';

export const autocompleteDropdownSchemaProps = {
  isOptionEqualToValue: (opt: DropdownOption, value: DropdownOption) =>
    opt.value === value.value,
  renderOption: (
    props: HTMLAttributes<HTMLLIElement>,
    option: DropdownOption,
  ) => {
    return (
      <Box
        {...props}
        sx={{
          '&.MuiAutocomplete-option:hover': {
            background: 'rgba(145, 158, 171, 0.08)',
          },
        }}
        component="li"
      >
        <Typography variant="body1">{option?.label}</Typography>
      </Box>
    );
  },
};

export const onScrollReachBottom =
  (reachBottom: () => void) =>
  (e: UIEvent<HTMLUListElement, globalThis.UIEvent>) => {
    const listboxNode = e.currentTarget;

    const scrollPosition = listboxNode.scrollTop + listboxNode.clientHeight;
    const threshold = listboxNode.scrollHeight * SCROLL_THRESHOLD_PERCENTAGE; // 90% scroll threshold

    if (scrollPosition >= threshold) {
      reachBottom();
    }
  };
