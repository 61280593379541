import { useMemo } from 'react';
import { Stack, Typography } from '@kitalulus/web-ui-kit';
import { useAppDispatch, useAppSelector } from '../use-store';
import { closeDialog, setDialog } from '~/store/views/dialogs-slice';
import { useTranslation } from 'react-i18next';
import * as mpe from '~/utils/mixpanel';
import { FETCH_STATUS } from '~/utils/constants';
import { markAsViewed } from '~/store/features/applicant-detail-freeplan-persist-slice';
import usePayPerPost from '../use-pay-per-post';

export const useApplicantFreePlan = () => {
  const {
    realtimeData,
    vacancyDetail,
    company,
    applicantDetailFreeplanPersist,
  } = useAppSelector((state) => state);
  const { isFreePlan } = usePayPerPost();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const vacancyLimit = useMemo(() => {
    if (!vacancyDetail?.code) return null;
    return realtimeData.vacancyByCompanyId.data[vacancyDetail.code];
  }, [realtimeData.vacancyByCompanyId.data, vacancyDetail?.code]);

  const isOnQuota = useMemo(() => {
    if (!isFreePlan) return null;
    if (!vacancyLimit) return false;
    return !vacancyLimit.hasExceeded;
  }, [vacancyLimit, isFreePlan]);

  const current = useMemo(() => {
    if (!vacancyLimit) return 0;
    return vacancyLimit?.current ?? 0;
  }, [vacancyLimit]);

  const max = useMemo(() => {
    if (!vacancyLimit) return 0;
    return vacancyLimit?.max ?? 0;
  }, [vacancyDetail, vacancyLimit]);

  const isLoading =
    !max ||
    realtimeData.vacancyByCompanyId.fetchStatus !== FETCH_STATUS.RESOLVED;

  const handleOnExplorePackages = () => {
    mpe.premiumOffering({
      linkPosition: 'limitBasicApplicantView',
    });
    dispatch(
      setDialog({
        subscriptionPlanDialog: {
          open: true,
          content: {
            linkPosition: 'limitBasicApplicantView',
          },
        },
      }),
    );
    dispatch(closeDialog('confirmDialogV2'));
  };

  const showApplicantFreePlanLimitDialog = ({
    variant,
  }: {
    variant: 'reached' | 'exceed';
  }) => {
    const isExceed = variant === 'exceed';

    // checker to trigger only once
    if (isExceed) {
      const persistedCompany = applicantDetailFreeplanPersist.companies.find(
        (comp) => comp.companyID === company?.id,
      );
      const shouldTrigger = !persistedCompany?.vacancies.find(
        (v) => v === vacancyDetail?.code,
      );
      if (shouldTrigger) {
        dispatch(
          markAsViewed({
            companyID: company?.id ?? '',
            vacancyCode: vacancyDetail?.code ?? '',
          }),
        );
      } else {
        return;
      }
    }

    dispatch(
      setDialog({
        confirmDialogV2: {
          open: true,
          content: {
            containerProps: {
              'data-test-id': 'ctApplicantFreePlanLimitDialogContainer',
            },
            title: t('applicant:freePlanLimitDialog.title'),
            titleProps: {
              'data-test-id': 'lbApplicantFreePlanLimitDialogTitle',
            },
            body: (
              <Stack>
                <img
                  alt="freePlanLimitImage"
                  data-test-id="imgApplicantFreePlanLimitDialog"
                  src="/illustrations/applicant-free-plan.webp"
                  width={430}
                  style={{ maxWidth: '100%' }}
                />
                <Typography
                  mt={3}
                  data-test-id="lbApplicantFreePlanLimitDialogDescription"
                  sx={{
                    '& ul': { listStyle: 'none', mt: 0, pl: 1 },
                    '& ul li::before': {
                      content: '"•"',
                      fontSize: 14,
                      mr: 1,
                    },
                  }}
                  dangerouslySetInnerHTML={{
                    __html: isExceed
                      ? t('applicant:freePlanLimitDialog.message21st', { max })
                      : t('applicant:freePlanLimitDialog.message20th', { max }),
                  }}
                />
              </Stack>
            ),
            cancelProps: {
              children: t('common:later'),
              'data-test-id': 'btnApplicantFreePlanLimitDialogCancel',
            },
            acceptProps: {
              children: t('common:explorePackages'),
              'data-test-id': 'btnApplicantFreePlanLimitDialogConfirm',
              onClick: handleOnExplorePackages,
            },
          },
        },
      }),
    );
  };

  return {
    isLoading,
    current,
    max,
    isOnQuota,
    isFreePlan,
    vacancyLimit,
    handleOnExplorePackages,
    showApplicantFreePlanLimitDialog,
  };
};
