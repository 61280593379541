import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type BannerFreeTrialState = {
  height: number;
};

export const initialState: BannerFreeTrialState = {
  height: 0,
};

export const bannerFreeTrialSlice = createSlice({
  name: 'bannerFreeTrial',
  initialState: initialState,
  reducers: {
    setBannerFreeTrial: (
      state,
      action: PayloadAction<Partial<BannerFreeTrialState>>,
    ) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const { setBannerFreeTrial } = bannerFreeTrialSlice.actions;

export default bannerFreeTrialSlice.reducer;
