import {
  Grid,
  Stack,
  TextField,
  Typography,
  Image,
  Autocomplete,
  useMediaQuery,
  useTheme,
  Divider,
  Tooltip,
  Box,
  brandColors,
} from '@kitalulus/web-ui-kit';
import { useCallback, useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import AuthLayout from '~/components/AuthLayoutV2';
import LoadingButton from '~/components/LoadingButton';
import { useCompanyIndustryOptions } from '~/hooks/use-company-industries';
import { useCompanyRegister } from '~/hooks/use-company-register';
import { useCompanySizeOptions } from '~/hooks/use-company-size';
import { useLocationOptions } from '~/hooks/use-location';
import { useAppSelector } from '~/hooks/use-store';
import { DropdownOption } from '~/types';
import { autocompleteDropdownSchemaProps } from '~/utils/components';
import { EpInputRegisterCompany } from '~/types/graphql/graphql';
import * as mpe from '~/utils/mixpanel';
import {
  emojiPattern,
  signupNameMaxLength,
  tiktokPattern,
} from '~/utils/validation';
import { isValidURL } from '~/utils/helper';
import { InfoIcon } from '~/components/Icons/InfoIcon';
import RequiredAsterisk from '~/components/RequiredAsterisk';
import {
  MIXPANEL_ACCOUNT_FAQ_LINK_POSITION,
  MIXPANEL_ACCOUNT_HELP_LINK_POSITION,
} from '~/utils/constants/mixpanel';

export interface ICompanyField extends EpInputRegisterCompany {
  jobCompanyIndustry?: DropdownOption;
  jobCompanySize?: DropdownOption;
  locationProvince?: DropdownOption;
  locationCity?: DropdownOption;
  locationDistrict?: DropdownOption;
  website: string;
}

const Company = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    provinceOptions,
    isProvinceLoading,
    cityOptions,
    isCityLoading,
    districtOptions,
    isDistrictLoading,
    provinceId,
    setProvinceId,
    cityId,
    setCityId,
  } = useLocationOptions();
  const { companySizes } = useCompanySizeOptions();
  const { companyIndustries } = useCompanyIndustryOptions();
  const {
    handleSubmit,
    setValue,
    control,
    formState: { isValid },
    getValues,
  } = useForm<ICompanyField>({
    mode: 'all',
    defaultValues: {
      name: '',
      description: '',
      locationId: '',
      website: '',
    },
  });

  const { handleRegister, isLoading } = useCompanyRegister();

  const { user } = useAppSelector((state) => state);

  const onSubmit: SubmitHandler<ICompanyField> = useCallback((data) => {
    mpe.onboardingSelfRegistrationData();

    const payload: EpInputRegisterCompany = {
      name: data.name,
      description: data.description,
      jobCompanySizeId: data.jobCompanySize?.value,
      jobCompanyIndustryId: data.jobCompanyIndustry?.value,
      locationId: data.locationDistrict?.value,
      contactWeblink: data.website ? `https://${data.website}` : '',
    };
    handleRegister(payload);
  }, []);

  useEffect(() => {
    setValue('locationCity', undefined);
    setValue('locationDistrict', undefined);
  }, [provinceId]);

  useEffect(() => {
    setValue('locationDistrict', undefined);
  }, [cityId]);

  useEffect(() => {
    if (user?.companyName) setValue('name', user?.companyName);
  }, [user?.companyName]);

  return (
    <AuthLayout
      showHelpButton={{
        linkPosition: MIXPANEL_ACCOUNT_HELP_LINK_POSITION.COMPANY_DATA_PAGE,
      }}
      contentBoxProps={{ px: '20px' }}
      showCopyRightOnDesktop
      authLayoutCopyRight={{
        helpLink: {
          linkPosition: MIXPANEL_ACCOUNT_FAQ_LINK_POSITION.COMPANY_DATA_PAGE,
        },
      }}
    >
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ minHeight: '100vh', maxWidth: '100%' }}
      >
        <Stack
          spacing={3}
          justifyContent="center"
          alignItems="center"
          width="100%"
        >
          <Image
            src={'/illustrations/company-seo-illustration.webp'}
            sx={{
              width: '228.15px',
              pt: { xs: '48px', sm: '103px' },
              pb: { xs: '0', sm: '54px' },
            }}
          />
          {/* Form section */}
          <Stack sx={{ width: '100%' }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack
                px={isMobile ? 0 : 4}
                spacing={3}
                sx={{ width: isMobile ? '100%' : 567, margin: '0 auto' }}
              >
                <Stack spacing="10px" pb={{ xs: 0, sm: 3 }}>
                  <Typography variant="subtitle2" color="text.primary">
                    {`${t('common:hello')}, ${user?.displayName ?? ''}!`}
                  </Typography>
                  <Typography
                    variant="body2"
                    fontWeight={600}
                    color="text.primary"
                  >
                    {t('company:companyDescription')}
                  </Typography>
                </Stack>

                {/* Company name input */}
                <Stack gap={1.5}>
                  <Stack direction="row">
                    <Typography variant="body2" color="text.secondary">
                      {t('company:form.name.label')}
                    </Typography>
                  </Stack>
                  <Controller
                    control={control}
                    name="name"
                    rules={{
                      required: {
                        value: true,
                        message: t('common:required'),
                      },
                    }}
                    render={({
                      field: { value = '', onChange },
                      fieldState: { error },
                    }) => (
                      <TextField
                        placeholder={t('company:form.name.placeholder')}
                        inputProps={{
                          maxLength: signupNameMaxLength,
                        }}
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        required
                        value={value}
                        onChange={(e) =>
                          onChange(e.target.value.replace(emojiPattern, ''))
                        }
                        error={!!error}
                        helperText={error?.message}
                        disabled={!!user?.companyName}
                      />
                    )}
                  />
                </Stack>

                {/* Industry input */}
                <Stack gap={1.5}>
                  <Stack direction="row">
                    <Typography variant="body2" color="text.secondary">
                      {t('company:form.jobCompanyIndustry.label')}
                    </Typography>
                    <RequiredAsterisk />
                  </Stack>
                  <Controller
                    control={control}
                    name="jobCompanyIndustry"
                    rules={{
                      required: {
                        value: true,
                        message: t('common:required'),
                      },
                    }}
                    render={({
                      field: { value = null, onChange },
                      fieldState: { error },
                    }) => (
                      <Autocomplete
                        {...autocompleteDropdownSchemaProps}
                        sx={{ flex: 1 }}
                        options={companyIndustries}
                        renderInput={(params) => (
                          <TextField
                            placeholder={t(
                              'company:form.jobCompanyIndustry.placeholder',
                            )}
                            required
                            {...params}
                            InputLabelProps={{
                              shrink: true,
                              ...params.InputLabelProps,
                            }}
                            inputProps={{
                              ...params.inputProps,
                            }}
                            error={!!error}
                            helperText={error?.message}
                          />
                        )}
                        value={value}
                        onChange={(_, val) => {
                          onChange(val);
                        }}
                      />
                    )}
                  />
                </Stack>

                {/* Company Size input */}
                <Stack gap={1.5}>
                  <Stack direction="row">
                    <Typography variant="body2" color="text.secondary">
                      {t('company:form.jobCompanySize.label')}
                    </Typography>
                    <RequiredAsterisk />
                  </Stack>
                  <Controller
                    control={control}
                    name="jobCompanySize"
                    rules={{
                      required: {
                        value: true,
                        message: t('common:required'),
                      },
                    }}
                    render={({
                      field: { value = null, onChange },
                      fieldState: { error },
                    }) => (
                      <Autocomplete
                        {...autocompleteDropdownSchemaProps}
                        sx={{ flex: 1 }}
                        options={companySizes}
                        renderInput={(params) => (
                          <TextField
                            placeholder={t(
                              'company:form.jobCompanySize.placeholder',
                            )}
                            required
                            {...params}
                            InputLabelProps={{
                              shrink: true,
                              ...params.InputLabelProps,
                            }}
                            inputProps={{
                              ...params.inputProps,
                            }}
                            error={!!error}
                            helperText={error?.message}
                          />
                        )}
                        value={value}
                        onChange={(_, val) => {
                          onChange(val);
                        }}
                      />
                    )}
                  />
                </Stack>

                {/* Company Location input */}
                <Stack spacing={1.5}>
                  <Typography variant="body2" color="text.secondary">
                    {t('company:form.location.label')}
                  </Typography>

                  <Stack spacing="12px" width="100%">
                    <Controller
                      control={control}
                      name="locationProvince"
                      rules={{
                        required: {
                          value: true,
                          message: t('common:required'),
                        },
                      }}
                      render={({
                        field: { value = null, onChange },
                        fieldState: { error },
                      }) => (
                        <Autocomplete
                          {...autocompleteDropdownSchemaProps}
                          sx={{ flex: 1 }}
                          loading={isProvinceLoading}
                          options={provinceOptions}
                          renderInput={(params) => (
                            <TextField
                              label={
                                <span>
                                  {t('common:province')}
                                  <RequiredAsterisk />
                                </span>
                              }
                              placeholder={t(
                                'company:form.location.placeholder.province',
                              )}
                              {...params}
                              InputLabelProps={{
                                shrink: true,
                                ...params.InputLabelProps,
                              }}
                              inputProps={{
                                ...params.inputProps,
                              }}
                              error={!!error}
                              helperText={error?.message}
                            />
                          )}
                          value={value}
                          onChange={(_, val) => {
                            onChange(val);
                            setProvinceId(val?.value);
                          }}
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name="locationCity"
                      rules={{
                        required: {
                          value: true,
                          message: t('common:required'),
                        },
                      }}
                      render={({
                        field: { value = null, onChange },
                        fieldState: { error },
                      }) => (
                        <Autocomplete
                          {...autocompleteDropdownSchemaProps}
                          sx={{ flex: 1 }}
                          loading={isCityLoading}
                          options={cityOptions}
                          renderInput={(params) => (
                            <TextField
                              label={
                                <span>
                                  {t('common:city')}
                                  <RequiredAsterisk />
                                </span>
                              }
                              placeholder={t(
                                'company:form.location.placeholder.city',
                              )}
                              {...params}
                              InputLabelProps={{
                                shrink: true,
                                ...params.InputLabelProps,
                              }}
                              inputProps={{
                                ...params.inputProps,
                              }}
                              error={!!error}
                              helperText={error?.message}
                            />
                          )}
                          value={value}
                          onChange={(_, val) => {
                            onChange(val);
                            setCityId(val?.value);
                          }}
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name="locationDistrict"
                      rules={{
                        required: {
                          value: true,
                          message: t('common:required'),
                        },
                      }}
                      render={({
                        field: { value = null, onChange },
                        fieldState: { error },
                      }) => (
                        <Autocomplete
                          {...autocompleteDropdownSchemaProps}
                          sx={{ flex: 1 }}
                          loading={isDistrictLoading}
                          options={districtOptions}
                          renderInput={(params) => (
                            <TextField
                              label={
                                <span>
                                  {t('common:district')}
                                  <RequiredAsterisk />
                                </span>
                              }
                              placeholder={t(
                                'company:form.location.placeholder.district',
                              )}
                              {...params}
                              InputLabelProps={{
                                shrink: true,
                                ...params.InputLabelProps,
                              }}
                              inputProps={{
                                ...params.inputProps,
                              }}
                              error={!!error}
                              helperText={error?.message}
                            />
                          )}
                          value={value}
                          onChange={(_, val) => onChange(val)}
                        />
                      )}
                    />
                  </Stack>
                </Stack>

                {/* Description input */}
                <Stack gap={1.5}>
                  <Stack direction="row">
                    <Typography variant="body2" color="text.secondary">
                      {t('company:form.description.label')}
                    </Typography>
                    <RequiredAsterisk />
                  </Stack>
                  <Controller
                    control={control}
                    name="description"
                    rules={{
                      required: {
                        value: true,
                        message: t('common:required'),
                      },
                    }}
                    render={({
                      field: { value = '', onChange },
                      fieldState: { error },
                    }) => (
                      <TextField
                        placeholder={t('company:form.description.placeholder')}
                        inputProps={{
                          maxLength: 1000,
                        }}
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        required
                        multiline
                        minRows={6}
                        value={value}
                        onChange={(e) =>
                          onChange(e.target.value.replace(emojiPattern, ''))
                        }
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                </Stack>

                {/* Website input */}
                <Stack gap={1.5}>
                  <Stack direction="row" spacing="12px">
                    <Typography variant="body2" color="text.secondary">
                      {t('company:form.website.label')}
                    </Typography>
                    <Tooltip
                      title={t('company:form.website.tooltip')}
                      enterTouchDelay={0}
                      arrow
                      placement="top"
                      PopperProps={{
                        sx: {
                          whiteSpace: 'pre-line',
                          '.MuiTooltip-tooltip': {
                            minWidth: '273px',
                            p: '12px',
                            mb: '6px !important',
                            textAlign: 'center',
                            borderRadius: '4px',
                            fontSize: '14px',
                            fontWeight: '400',
                          },
                        },
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <InfoIcon
                          sx={{
                            fontSize: '20px',
                            color: brandColors.grey[600],
                          }}
                        />
                      </Box>
                    </Tooltip>
                  </Stack>
                  <Controller
                    control={control}
                    name="website"
                    rules={{
                      validate: () => {
                        const value = getValues('website');
                        if (value === '') return true;
                        const isTiktokUserlink = tiktokPattern.test(value);
                        if (isTiktokUserlink) return isTiktokUserlink;
                        const isValidWebsite = isValidURL(value);
                        return (
                          isValidWebsite || t('company:form.website.error')
                        );
                      },
                    }}
                    render={({
                      field: { value = '', onChange },
                      fieldState: { error },
                    }) => (
                      <TextField
                        inputProps={{
                          'data-test-id': 'tfCompanyFormSocialMedia',
                        }}
                        InputProps={{
                          startAdornment: (
                            <Typography
                              variant="body1"
                              color="primary.main"
                              sx={{ mr: 1 }}
                            >
                              https://
                            </Typography>
                          ),
                        }}
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        value={value}
                        onChange={(e) =>
                          onChange(e.target.value.replace(emojiPattern, ''))
                        }
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                </Stack>
              </Stack>
              {/* Submit button */}
              <Stack
                pt={{ xs: 7, sm: 4 }}
                pb={{ xs: 3 }}
                spacing={3}
                alignItems="center"
                justifyItems="center"
              >
                <Divider
                  sx={{ width: '100%', display: { xs: 'none', sm: 'block' } }}
                />
                <LoadingButton
                  data-test-id="btnCompanyFormSubmit"
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  disabled={!isValid}
                  loading={isLoading}
                  sx={{ width: 168 }}
                >
                  {t('company:save')}
                </LoadingButton>
              </Stack>
            </form>
          </Stack>
        </Stack>
      </Grid>
    </AuthLayout>
  );
};

export default Company;
