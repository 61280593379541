import { ApolloLink, Observable, ObservableSubscription } from '@apollo/client';
import { GraphQLError } from 'graphql';
import * as Sentry from '@sentry/react';

const loggerLink = new ApolloLink((operation, forward) => {
  const saveLogToNewRelic = (errors?: ReadonlyArray<GraphQLError>) => {
    const status = operation.getContext()?.response?.status;

    if (status) {
      Sentry.captureException(errors);
    }
  };

  return new Observable((observer) => {
    let sub: ObservableSubscription;
    try {
      sub = forward(operation).subscribe({
        next: (response) => {
          saveLogToNewRelic(response?.errors);
          observer.next(response);
        },
        error: (err) => {
          saveLogToNewRelic(err);
          observer.error(err);
        },
        complete: () => {
          observer.complete();
        },
      });
    } catch (err) {
      observer.error(err);
    }

    return () => {
      if (sub) sub.unsubscribe();
    };
  });
});

export default loggerLink;
