import { gql } from '~/types/graphql/gql';

export const QUERY_JOB_APPLICATION_COUNT = gql(`
  query jobApplicationCount($filter: EpJobApplicantFilterInput) {
    jobApplicationCount: epJobApplicationCount(filter: $filter) {
      menuId
      countStr
      count
    }
  }
`);

export const QUERY_JOB_APPLICATION_SUMMARY = gql(`
  query jobApplicationSummary(
    $id: ID!
    $tab: String
    $epStatus: EpJobApplicationEpStatusEnum
    $lastEducationLevels: [EpEducationLevelFilter!]
    $educationInstitutionIds: [ID!]
    $domicile: ID
    $gender: String
    $ageMin: Int
    $ageMax: Int
    $name: String
    $sort: String
    $locations: [EpLocationAreaInput!]
    $minSalary: Int
    $maxSalary: Int
    $experienceYear: String
    $jobSpecializationRoles: [ID!]
    $skillsV2: [ID!]
  ) {
    jobApplicationSummary: epJobApplicationSummary(
      id: $id
      tab: $tab
      epStatus: $epStatus
      lastEducationLevels: $lastEducationLevels
      educationInstitutionIds: $educationInstitutionIds
      domicile: $domicile
      gender: $gender
      ageMin: $ageMin
      ageMax: $ageMax
      name: $name
      sort: $sort
      locations: $locations
      minSalary: $minSalary
      maxSalary: $maxSalary
      experienceYear: $experienceYear
      jobSpecializationRoles: $jobSpecializationRoles
      skillsV2: $skillsV2
    ) {
      total
      accepted
      saved
      withdrawn
      rejected
      onProgress
      pending
      interview
    }
  }
`);

export const QUERY_JOB_APPLICATIONS = gql(`
  query jobApplications(
    $filter: CommonFilter
    $epStatus: EpJobApplicationEpStatusEnum
    $jobVacancyId: ID!
    $lastEducationLevels: [EpEducationLevelFilter!]
    $educationInstitutionIds: [ID!]
    $domicile: ID
    $gender: String
    $ageMin: Int
    $ageMax: Int
    $name: String
    $sort: String
    $minSalary: Int
    $maxSalary: Int
    $experienceYear: String
    $jobSpecializationRoles: [ID!]
    $skillsV2: [ID!]
    $tab: String
    $locations: [EpLocationAreaInput!]
    $isRestrict: Boolean
    $screeningQuestions: [EpJobApplicationScreeningQuestionInput!]
  ) {
    jobApplications: epJobApplications(
      filter: $filter
      epStatus: $epStatus
      jobVacancyId: $jobVacancyId
      lastEducationLevels: $lastEducationLevels
      educationInstitutionIds: $educationInstitutionIds
      domicile: $domicile
      gender: $gender
      ageMin: $ageMin
      ageMax: $ageMax
      name: $name
      sort: $sort
      minSalary: $minSalary
      maxSalary: $maxSalary
      experienceYear: $experienceYear
      jobSpecializationRoles: $jobSpecializationRoles
      skillsV2: $skillsV2
      locations: $locations
      tab: $tab
      isRestrict: $isRestrict
      screeningQuestions: $screeningQuestions
    ) {
      elements
      limit
      page
      size
      list {
        id
        userProfileId
        isInvited
        jobVacancyId
        matchingResult
        name
        gender
        phoneNumber
        locationId
        status
        companyStatus
        userLastEducation {
          periodStr
          educationLevel
          educationInstitution {
            id
            name
          }
          educationProgram {
            id
            name
          }
        }
        lastEducationLevel
        startEducationYear
        endEducationYear
        lastJobDescription
        lastJobDescription
        startLastJobYear
        endLastJobYear
        cityName
        provinceName
        districtName
        createdAt
        userProfile {
          imageUrl
          age
          experiencesYearStr
          companyNote
          lastExperience {
            name
            companyName
            employmentType {
              value
            }
            periodStr
          }
        }
        isInvited
        isRead
        expectedSalaryStr
        haveScreeningQuestionAnswers
        screeningQuestionResultStr
        isFullScoreScreeningQuestion
      }
      state
    }
  }
`);

export const QUERY_JOB_APPLICATION = gql(`
  query jobApplication($id: ID!) {
    jobApplication: epJobApplication(id: $id) {
      id
      userProfileId
      jobVacancyId
      name
      gender
      phoneNumber
      locationId
      status
      companyNote
      companyStatus
      userLastEducation {
        educationLevel
        educationInstitution {
          id
          name
        }
        educationProgram {
          id
          name
        }
      }
      lastEducationLevel
      startEducationYear
      endEducationYear
      lastJobDescription
      lastJobDescription
      startLastJobYear
      endLastJobYear
      cityName
      provinceName
      createdAt
      isMasked
      isInvited
      userProfile {
        id
        name
        age
        experiencesYearStr
        userEmail
        phoneNumber
        isContactRestricted
        nickname
        gender
        genderStr
        imageUrl
        about
        isPrivate
        birthdate
        locationId
        companyStatus
        companyNote
        updatedAt
        updatedAtStr
        educations {
          id
          userProfileId
          educationLevel
          educationInstitution {
            id
            name
          }
          educationProgram {
            id
            name
          }
          description
          startYear
          endYear
          startMonth
          endMonth
          periodStr
        }
        experiences {
          periodStr
          name
          skills
          userProfileId
          startYear
          jobFunction
          endYear
          id
          employmentTypeStr
          description
          companyName
          jvSpecializationRoleId
          jvRole
        }
        skills {
          displayName
          id
          name
        }
        links {
          id
          userProfileId
          name
          link
          supportLinkCategory {
            id
            name
            displayName
            prefix
            asset {
              id
              imageUrl
            }
          }
        }
        district {
          id
          parentId
          name
          level
        }
        city {
          id
          parentId
          name
          level
        }
        province {
          id
          parentId
          name
          level
        }
        cv {
          url
        }
      }
      isRead
      isInvited
      expectedSalaryStr
      matchingResult
    }
  }
`);

export const MUTATION_UPDATE_BULK_JOB_APPLICATION_COMPANY_STATUS = gql(`
  mutation updateBulkJobApplicationCompanyStatus(
    $data: EpUpdateBulkJobApplicationCompanyStatusInput
  ) {
    updateBulkJobApplicationCompanyStatus: epUpdateBulkJobApplicationCompanyStatus(
      data: $data
    ) {
      isSuccess
      error
      warning
      data
    }
  }
`);

export const MUTATION_UPDATE_BULK_JOB_APPLICATION_COMPANY_STATUS_V2 = gql(`
  mutation updateBulkJobApplicationCompanyStatusV2(
    $data: EpUpdateBulkJobApplicationCompanyStatusInput
  ) {
    updateBulkJobApplicationCompanyStatusV2: epUpdateBulkJobApplicationCompanyStatusV2(
      data: $data
    ) {
      isSuccess
      error
      warning
      data {
        message
        countSuccess
        countFailed
        result {
          id
          status
        }
      }
    }
  }
`);

export const QUERY_JOB_APPLICATION_SORT_DROPDOWN = gql(`
  query jobApplicationSortDropdown {
    jobApplicationSortDropdown: epJobApplicationSortDropdown {
      key
      value
    }
  }
`);

export const MUTATION_UPDATE_JOB_APPLICATION_NOTE = gql(`
  mutation updateJobApplicationNote($data: EpJobApplicationNoteInput) {
    updateJobApplicationNote: epUpdateJobApplicationNote(data: $data) {
      isSuccess
      error
      data
    }
  }
`);

export const QUERY_JOB_APPLICATION_EP_STATUS_DROPDOWN = gql(`
  query jobApplicationEpStatusDropdown {
    jobApplicationEpStatusDropdown: epJobApplicationEpStatusDropdown {
      key
      value
    }
  }
`);

export const MUTATION_UPDATE_JOB_APPLICATION_STATUS_AND_REMARKS = gql(`
  mutation updateJobApplicationStatusAndRemarks(
    $data: EpJobApplicationStatusAndRemarksUpdateInput!
  ) {
    updateJobApplicationStatusAndRemarks: epUpdateJobApplicationStatusAndRemarks(
      data: $data
    ) {
      isSuccess
      error
      data {
        note
      }
    }
  }
`);

export const QUERY_JOB_APPLICATION_HISTORY_LABELS = gql(`
  query jobApplicationHistoryLabels {
    jobApplicationHistoryLabels: epJobApplicationHistoryLabels {
      label
      displayName
      textColor
      bgColor
    }
  }
`);

export const QUERY_JOB_APPLICATION_PREV_NEXT = gql(`
  query jobApplicationPrevNext(
    $id: ID
    $jobVacancyId: ID!
    $epStatus: EpJobApplicationEpStatusEnum
    $tab: String
    $lastEducationLevels: [EpEducationLevelFilter!]
    $educationInstitutionIds: [ID!]
    $gender: String
    $name: String
    $sort: String
    $locations: [EpLocationAreaInput!]
    $jobSpecializationRoles: [ID!]
    $skillsV2: [ID!]
    $domicile: ID
    $ageMin: Int
    $ageMax: Int
    $minSalary: Int
    $maxSalary: Int
    $experienceYear: String
    $screeningQuestions: [EpJobApplicationScreeningQuestionInput!]
  ) {
    jobApplicationPrevNext: epJobApplicationPrevNext(
      id: $id
      epStatus: $epStatus
      jobVacancyId: $jobVacancyId
      lastEducationLevels: $lastEducationLevels
      educationInstitutionIds: $educationInstitutionIds
      domicile: $domicile
      gender: $gender
      ageMin: $ageMin
      ageMax: $ageMax
      name: $name
      sort: $sort
      locations: $locations
      minSalary: $minSalary
      maxSalary: $maxSalary
      experienceYear: $experienceYear
      jobSpecializationRoles: $jobSpecializationRoles
      skillsV2: $skillsV2
      tab: $tab
      screeningQuestions: $screeningQuestions
    ) {
      prevId
      id
      nextId
    }
  }
`);

export const QUERY_JOB_APPLICATION_REJECTED_REASON_CATEGORIES = gql(`
  query jobApplicationRejectedReasonCategories {
    jobApplicationRejectedReasonCategories: epJobApplicationRejectedReasonCategories {
      key
      value
      disabledKeys
      reasonTitle
      reasonDescription
      reasonType
    }
  }
`);

export const QUERY_JOB_APPLICATION_REJECTED_REASONS = gql(`
  query jobApplicationRejectedReasons($id: ID!) {
    jobApplicationRejectedReasons: epJobApplicationRejectedReason(id: $id)
  }
`);

export const QUERY_USER_CV = gql(`
  query userCv($userProfileId: ID!, $cvFileNameForDownload: String) {
    userCv: epUserCv(
      userProfileId: $userProfileId
      cvFileNameForDownload: $cvFileNameForDownload
    ) {
      id
      userProfileIdStr
      cv {
        url
      }
      cvStatus
      updatedAtStr
    }
  }
`);

export const MUTATION_UPDATE_JOB_APPLICATION_IS_READ = gql(`
  mutation updateJobApplicationIsRead($data: EpUpdateJobApplicationIsRead) {
    updateJobApplicationIsRead: epUpdateJobApplicationIsRead(data: $data) {
      isSuccess
      error
      warning
      data
    }
  }
`);

export const QUERY_JOB_APPLICATION_SCREENING = gql(`
  query jobApplicationScreening(
    $jobApplicantId: ID!
    $screeningQuestions: [EpJobApplicationScreeningQuestionInput]
  ) {
    jobApplicationScreening: epJobApplicationScreening(
      jobApplicantId: $jobApplicantId
      screeningQuestions: $screeningQuestions
    ) {
      data {
        id
        title
        topic
        values {
          answer
          id
          isMatch
          question
          requirement
        }
      }
      noticeStr
      resultStr
      isFullScore
    }
  }
`);
