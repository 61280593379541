import { SvgIcon, SvgIconProps } from '@kitalulus/web-ui-kit';

const ChevronLeftIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 7 14" {...props}>
      <path d="M6.83004 15C6.52745 15.001 6.24067 14.865 6.05004 14.63L1.22004 8.62999C0.916724 8.26099 0.916724 7.729 1.22004 7.35999L6.22004 1.35999C6.5735 0.934734 7.20478 0.876531 7.63004 1.22999C8.0553 1.58346 8.1135 2.21473 7.76004 2.63999L3.29004 7.99999L7.61004 13.36C7.85973 13.6597 7.91234 14.0773 7.74479 14.4296C7.57724 14.7819 7.22012 15.0046 6.83004 15Z" />
    </SvgIcon>
  );
};

export default ChevronLeftIcon;
