import { Stack, SxProps, Typography } from '@kitalulus/web-ui-kit';
import { ReactNode } from 'react';

export type ConsChipProps = {
  label: string;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  sx?: SxProps;
  oneLine?: boolean;
};

const ConsChip = ({
  label,
  startIcon,
  endIcon,
  sx,
  oneLine = true,
}: ConsChipProps) => {
  return (
    <Stack
      direction="row"
      sx={{ px: 1, py: 0.3, borderRadius: '5px', ...sx, overflow: 'hidden' }}
      bgcolor="#EDF5FD"
    >
      {startIcon}
      <Typography
        color="primary"
        variant="subtitle2"
        sx={{
          ...(oneLine && {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }),
        }}
      >
        {label}
      </Typography>
      {endIcon}
    </Stack>
  );
};

export default ConsChip;
