import { Stack, TextArea, Typography } from '@kitalulus/web-ui-kit';
import { useContext } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import WhatssappIcon from '~/components/Icons/WhatsappIcon';
import InputAdornmentWithBorder from '~/components/InputAdornmentWithBorder';
import NumberTextField from '~/components/NumberTextField';
import RequiredAsterisk from '~/components/RequiredAsterisk';
import { InterviewInvitationFormSessionSelectTypeKey } from '~/utils/constants/interview-invitation';
import { removeFancyTextAndEmoji } from '~/utils/helper';
import {
  interviewInvitationRecruiterMessageMaxLength,
  phoneNumberMaxLength,
  phoneNumberMinLength,
  phoneNumberWithoutLeadingZeroPattern,
} from '~/utils/validation';
import { InterviewInvitationFormContext } from '../../';
import { InterviewInvitationFormThirdStepProps } from './';

const InterviewInvitationFormThirdStep = (
  props: InterviewInvitationFormThirdStepProps,
) => {
  const { t } = useTranslation();
  const { firstStepFormState, thirdStepFormState } = useContext(
    InterviewInvitationFormContext,
  );
  const { control } = thirdStepFormState;

  // form value watchers
  const sessionSelectType = firstStepFormState.watch('sessionSelectType');
  const isDisabled =
    sessionSelectType ===
    InterviewInvitationFormSessionSelectTypeKey.EXISTING_SESSION;

  return (
    <Stack gap={4} {...props}>
      {/* Recruiter phone number section */}
      <Stack gap={1.5}>
        <Stack gap={0.25}>
          <Typography variant="subtitle1">
            {t('interview-invitation-form:recruiterPhoneNumberTitle')}
            <RequiredAsterisk />
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {t('interview-invitation-form:recruiterPhoneNumberSubtitle')}
          </Typography>
        </Stack>

        <Controller
          control={control}
          name="recruiterPhoneNumber"
          rules={{
            required: {
              value: true,
              message: t(
                'interview-invitation-form:recruiterPhoneNumberError.required',
              ),
            },
            pattern: {
              value: phoneNumberWithoutLeadingZeroPattern,
              message: t(
                'interview-invitation-form:recruiterPhoneNumberError.pattern',
              ),
            },
            minLength: {
              value: phoneNumberMinLength,
              message: t(
                'interview-invitation-form:recruiterPhoneNumberError.minLength',
                {
                  min: phoneNumberMinLength,
                  max: phoneNumberMaxLength,
                },
              ),
            },
          }}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <Stack
              direction="row"
              gap={1.5}
              alignItems="flex-start"
              width="100%"
            >
              <WhatssappIcon
                sx={{
                  color: isDisabled ? 'text.disabled' : '#00AB55',
                  fontSize: '24px',
                  mt: '16px',
                }}
              />
              <NumberTextField
                disabled={isDisabled}
                value={value}
                onChange={onChange}
                onlyPositive
                placeholder={t(
                  'interview-invitation-form:recruiterPhoneNumberPlaceholder',
                )}
                inputProps={{
                  maxLength: phoneNumberMaxLength,
                  inputMode: 'numeric',
                }}
                FormHelperTextProps={{ sx: { mx: 0 } }}
                helperText={error?.message}
                error={!!error}
                InputProps={{
                  startAdornment: (
                    <InputAdornmentWithBorder position="start">
                      <Typography
                        variant="body1"
                        color={isDisabled ? 'text.disabled' : 'success.main'}
                      >
                        +62
                      </Typography>
                    </InputAdornmentWithBorder>
                  ),
                }}
                sx={{ flex: 1, maxWidth: '500px' }}
              />
            </Stack>
          )}
        />
      </Stack>

      {/* Recruiter message section */}
      <Stack gap={1.5}>
        <Stack gap={0.25}>
          <Typography variant="subtitle1">
            {t('interview-invitation-form:recruiterMessageTitle')}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {t('interview-invitation-form:recruiterMessageSubtitle')}
          </Typography>
        </Stack>

        <Controller
          control={control}
          name="recruiterMessage"
          render={({ field: { value, onChange } }) => (
            <TextArea
              disabled={isDisabled}
              label={null}
              value={value}
              onChange={({ target: { value } }) =>
                onChange(removeFancyTextAndEmoji(value))
              }
              onBlur={() => onChange(value.trim())}
              rows={8}
              placeholder={t(
                'interview-invitation-form:recruiterMessagePlaceholder',
              )}
              inputProps={{
                maxLength: interviewInvitationRecruiterMessageMaxLength,
              }}
              FormHelperTextProps={{ component: 'div', sx: { mx: 0 } }}
              helperText={
                <Stack direction="row" justifyContent="flex-end">
                  <Typography variant="caption" color="text.secondary">
                    {value.length}/
                    {interviewInvitationRecruiterMessageMaxLength}
                  </Typography>
                </Stack>
              }
              fullWidth
            />
          )}
        />
      </Stack>
    </Stack>
  );
};

export default InterviewInvitationFormThirdStep;
