import { AnyAction, Reducer } from '@reduxjs/toolkit';
import persistReducer from 'redux-persist/es/persistReducer';

import { localStorage, cookies } from './storage';

export function createLocalStoragePersist<S, A extends AnyAction>(
  key: string,
  reducer: Reducer<S, A>,
) {
  return persistReducer({ key, storage: localStorage }, reducer);
}

export function createCookiesPersist<S, A extends AnyAction>(
  key: string,
  reducer: Reducer<S, A>,
) {
  return persistReducer({ key, storage: cookies }, reducer);
}
