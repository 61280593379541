import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DropdownOption } from '~/types';
import { FETCH_STATUS } from '~/utils/constants';

type TStatusOptions = {
  items: DropdownOption[];
  fetchStatus: FETCH_STATUS;
};

export type TApplicantOptions = {
  status: TStatusOptions;
};

const initialState: TApplicantOptions = {
  status: {
    items: [],
    fetchStatus: FETCH_STATUS.IDLE,
  },
};

export const applicationOptionsSlice = createSlice({
  name: 'applicationOptions',
  initialState,
  reducers: {
    setApplicationOptionsStatus: (
      state,
      action: PayloadAction<Partial<TStatusOptions>>,
    ) => {
      state.status = { ...state.status, ...action.payload };
    },
    clearApplicationOptions: () => initialState,
  },
});

export const { setApplicationOptionsStatus, clearApplicationOptions } =
  applicationOptionsSlice.actions;

export default applicationOptionsSlice.reducer;
