import { useTranslation } from 'react-i18next';
import { EpInvitationStatus } from '~/types/graphql/graphql';

interface InvitationStatusColorProps {
  color: string;
  text: string;
  tooltipStr?: string;
}

export const useInvitationStatusColor = () => {
  const { t } = useTranslation();

  const getStatusDetails = (
    status?: EpInvitationStatus,
  ): InvitationStatusColorProps => {
    switch (status) {
      case EpInvitationStatus.Confirmed:
        return {
          color: 'success.main',
          text: t('candidate-recommendation:responseCandidate.confirmed'),
          tooltipStr: t(
            'candidate-recommendation:responseCandidate.appliedTooltipConfirmed',
          ),
        };
      case EpInvitationStatus.Declined:
        return {
          color: 'error.main',
          text: t('candidate-recommendation:responseCandidate.declined'),
          tooltipStr: t(
            'candidate-recommendation:responseCandidate.appliedTooltipDeclined',
          ),
        };
      case EpInvitationStatus.Pending:
        return {
          color: 'text.secondary',
          text: t('candidate-recommendation:responseCandidate.pending'),
          tooltipStr: t(
            'candidate-recommendation:responseCandidate.appliedTooltipPending',
          ),
        };
      default:
        return {
          color: 'text.secondary',
          text: '',
        };
    }
  };

  return { getStatusDetails };
};
