type ClonePropsProps<T> = {
  children: (props: T) => JSX.Element;
};

const CloneProps = <T extends Record<string, any>>(
  props: ClonePropsProps<Omit<T, 'children'>> & T,
) => {
  const { children, ...other } = props;
  if (children) return children(other as unknown as T);
  return null;
};

export default CloneProps;
