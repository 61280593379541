import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { DropdownOption } from '~/types';

export const useWorkingDayOptions = () => {
  const { t } = useTranslation();

  const workingDays = useMemo<DropdownOption[]>(() => {
    return [
      {
        label: t('common:monday'),
        value: 2,
      },
      {
        label: t('common:tuesday'),
        value: 3,
      },
      {
        label: t('common:wednesday'),
        value: 4,
      },
      {
        label: t('common:thursday'),
        value: 5,
      },
      {
        label: t('common:friday'),
        value: 6,
      },
      {
        label: t('common:saturday'),
        value: 7,
      },
      {
        label: t('common:sunday'),
        value: 1,
      },
    ];
  }, []);

  const workingDaysObj: { [key: number]: DropdownOption } = useMemo(() => {
    return workingDays.reduce((acc, val) => ({ ...acc, [val.value]: val }), {});
  }, [workingDays]);

  return { workingDays, workingDaysObj };
};
