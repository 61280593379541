import {
  Box,
  Stack,
  Tab,
  TabProps,
  Tabs,
  Typography,
  brandColors,
  useTheme,
} from '@kitalulus/web-ui-kit';
import { ReactNode, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import PrivacyPolicy from '../PrivacyPolicy';
import TermCondition from '../TermCondition';
import { FiSsShieldCheck } from '~/components/Icons/FiSsShieldCheck';
import { FiSsDocument } from '~/components/Icons/FiSsDocument';

export type CustomTabProps = Omit<TabProps, 'content' | 'href'> & {
  content?: ReactNode;
  dataTestId: string;
};

const PrivacyMain = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const tabs: CustomTabProps[] = useMemo(() => {
    return [
      {
        value: 'privacy-policy',
        label: t('privacy:privacyPolicy'),
        icon: <FiSsShieldCheck sx={{ fontSize: '20px' }} />,
        content: <PrivacyPolicy />,
        dataTestId: 'btnPrivacyPolicyTab',
      },
      {
        value: 'terms-condition',
        label: t('privacy:termsCondition'),
        icon: <FiSsDocument sx={{ fontSize: '20px' }} />,
        content: <TermCondition />,
        dataTestId: 'btnPrivacyTermsConditionsTab',
      },
    ];
  }, []);

  const [activeTabValue, setActiveTabValue] = useState(tabs[0].value);

  const activeTab = useMemo(() => {
    return tabs.find(({ value }) => value === activeTabValue);
  }, [activeTabValue, tabs]);

  return (
    <Stack spacing={3}>
      <Typography variant="h6">{t('privacy:title')}</Typography>

      <Box
        boxShadow={theme.customShadows.card}
        bgcolor={brandColors.neutral[200]}
        borderRadius="16px"
        overflow="hidden"
      >
        <Tabs
          variant="scrollable"
          allowScrollButtonsMobile
          value={activeTabValue}
          onChange={(_, val) => setActiveTabValue(val)}
          sx={{
            height: 60,
            '& .MuiTabs-flexContainer': {
              height: 60,
            },
            '& .MuiTab-root.Mui-selected': {
              bgcolor: brandColors.primary[100],
              color: brandColors.primary[500],
            },
            '& .MuiTab-root:hover': {
              bgcolor: brandColors.neutral[300],
            },
          }}
        >
          {tabs.map((tab) => (
            <Tab
              key={tab.value}
              iconPosition="start"
              sx={{
                textTransform: 'none',
                fontWeight: 600,
                fontSize: 14,
                px: '18px',
              }}
              value={tab.value}
              label={tab.label}
              icon={tab.icon}
              {...{
                'data-test-id': tab.dataTestId,
              }}
            />
          ))}
        </Tabs>

        <Box
          py={{ xs: '12px', lg: '24px' }}
          bgcolor={theme.palette.background.default}
        >
          <Box
            key={activeTabValue}
            sx={{
              px: { xs: '12px', lg: '24px' },
              maxHeight: '410px',
              overflow: 'auto',
            }}
          >
            {activeTab?.content}
          </Box>
        </Box>
      </Box>
    </Stack>
  );
};

export default PrivacyMain;
