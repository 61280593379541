import { DialogProps } from '@kitalulus/web-ui-kit';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ApplicantAcceptDialogProps } from '~/components/GlobalDialogs/ApplicantAcceptDialog';
import { ApplicantRejectDialogProps } from '~/components/GlobalDialogs/ApplicantRejectDialog';
import { ApplicantFeedbackDialogProps } from '~/components/GlobalDialogs/ApplicantFeedbackDialog';
import { CandidateRecommendationRejectConfirmDialogProps } from '~/components/GlobalDialogs/CandidateRecommendationRejectConfirmDialog';
import { CandidateRecommendationVerificationDialogProps } from '~/components/GlobalDialogs/CandidateRecommendationVerificationDialog';
import { ProfilePreviewDialogProps } from '~/components/GlobalDialogs/ProfilePreviewDialog/ProfilePreviewDialog.types';
import { RemarksDialogProps } from '~/components/GlobalDialogs/RemarksDialog/RemarksDialog.types';
import { SubscriptionPlanConfirmationDialogProps } from '~/components/GlobalDialogs/SubscriptionPlanConfirmationDialog';
import { SubscriptionPlanDialogProps } from '~/components/GlobalDialogs/SubscriptionPlanDialog';
import { VacancyEthicCodeDialogProps } from '~/components/GlobalDialogs/VacancyEthicCodeDialog';
import { VacancyHighlightDialogProps } from '~/components/GlobalDialogs/VacancyHighlightDialog';
import { VacancyUnHighlightDialogProps } from '~/components/GlobalDialogs/VacancyUnHighlightDialog';
import { VacancyHighlightQuotaExceededDialogProps } from '~/components/GlobalDialogs/VacancyHighlightQuotaExceededDialog';
import { VacancyOnReviewDialogProps } from '~/components/GlobalDialogs/VacancyOnReviewDialog';
import { VacancyPreviewDialogProps } from '~/components/GlobalDialogs/VacancyPreviewDialog';
import { VacancyChangeStateDialogProps } from '~/components/GlobalDialogs/VacancyChangeStateDialog';
import { ApplicantBulkDialogProps } from '~/pages/Applicants/DialogConfirmationBulk';
import { CandidateRecommendationInviteDialogProps } from '~/components/GlobalDialogs/CandidateRecommendationInviteDialog';
import { CandidateRecommendationInviteDialogProps as CandidateRecommendationInviteDialogPropsV2 } from '~/components/GlobalDialogs/CandidateRecommendationInviteDialogV2';
import { Nullable } from '~/types';
import { ApplicantInterviewDialogProps } from '~/components/GlobalDialogs/ApplicantInterviewDialog';
import { LogoutConfirmationDialogProps } from '~/components/GlobalDialogs/LogoutConfirmationDialog';
import { VacancyClosedSurveyDialogProps } from '~/components/GlobalDialogs/VacancyClosedSurveyDialog';
import { VacancyRepostDialogProps } from '~/components/GlobalDialogs/VacancyRepostDialog';
import { ApplicantScreeningQuestionFilterDialogProps } from '~/pages/Applicants/ApplicantScreeningQuestionFilterDialog';
import { VacancyShareDialogProps } from '~/components/GlobalDialogs/VacancyShareDialog';
import { ConfirmDialogV2Props } from '~/components/Dialogs/ConfirmDialogV2/ConfirmDialogV2.types';
import { VacancyEthicCodeSubmitDialogProps } from '~/components/GlobalDialogs/VacancyEthicCodeSubmitDialog';
import { SubscriptionPlanConfirmationDialogV2Props } from '~/components/GlobalDialogs/SubscriptionPlanConfirmationDialogV2';
import { InterviewInvitationDialogProps } from '~/components/GlobalDialogs/InterviewInvitationDialog';

export type DialogState = {
  applicantAcceptDialog?: Nullable<
    Pick<ApplicantAcceptDialogProps, 'open' | 'content'>
  >;
  applicantInterviewDialog?: Nullable<
    Pick<ApplicantInterviewDialogProps, 'open' | 'content'>
  >;
  applicantRejectDialog?: Nullable<
    Pick<ApplicantRejectDialogProps, 'open' | 'content'>
  >;
  applicantStatusLegendDialog?: Nullable<Pick<DialogProps, 'open'>>;
  applicantBulkDialog?: Nullable<
    Pick<ApplicantBulkDialogProps, 'open' | 'content'>
  >;
  applicantFeedbackDialog?: Nullable<
    Pick<ApplicantFeedbackDialogProps, 'open' | 'content'>
  >;
  applicantScreeningQuestionFilterDialog?: Nullable<
    Pick<ApplicantScreeningQuestionFilterDialogProps, 'open'>
  >;
  candidateRecommendationEmailPreviewDialog?: Nullable<
    Pick<DialogProps, 'open'>
  >;
  candidateRecommendationInviteDialog?: Nullable<
    Pick<CandidateRecommendationInviteDialogProps, 'open' | 'content'>
  >;
  candidateRecommendationInviteDialogV2?: Nullable<
    Pick<CandidateRecommendationInviteDialogPropsV2, 'open' | 'content'>
  >;
  candidateRecommendationRejectConfirmDialog?: Nullable<
    Pick<CandidateRecommendationRejectConfirmDialogProps, 'open' | 'content'>
  >;
  candidateRecommendationVerificationDialog?: Nullable<
    Pick<CandidateRecommendationVerificationDialogProps, 'open' | 'content'>
  >;
  logoutConfirmationDialog?: Nullable<
    Pick<LogoutConfirmationDialogProps, 'open' | 'content'>
  >;
  profilePreviewDialog?: Nullable<
    Pick<ProfilePreviewDialogProps, 'open' | 'content'>
  >;
  remarksDialog?: Nullable<Pick<RemarksDialogProps, 'open' | 'content'>>;
  subscriptionPlanConfirmationDialog?: Nullable<
    Pick<SubscriptionPlanConfirmationDialogProps, 'open' | 'content'>
  >;
  subscriptionPlanConfirmationDialogV2?: Nullable<
    Pick<SubscriptionPlanConfirmationDialogV2Props, 'open' | 'content'>
  >;
  subscriptionPlanDialog?: Nullable<
    Pick<SubscriptionPlanDialogProps, 'open' | 'content'>
  >;
  vacancyEthicCodeDialog?: Nullable<Pick<VacancyEthicCodeDialogProps, 'open'>>;
  vacancyEthicCodeSubmitDialog?: Nullable<
    Pick<VacancyEthicCodeSubmitDialogProps, 'open' | 'content'>
  >;
  vacancyHighlightDialog?: Nullable<
    Pick<VacancyHighlightDialogProps, 'open' | 'content'>
  >;
  vacancyUnHighlightDialog?: Nullable<
    Pick<VacancyUnHighlightDialogProps, 'open' | 'content'>
  >;
  vacancyHighlightQuotaExceededDialog?: Nullable<
    Pick<VacancyHighlightQuotaExceededDialogProps, 'open'>
  >;
  vacancyOnReviewDialog?: Nullable<
    Pick<VacancyOnReviewDialogProps, 'open' | 'onConfirm'>
  >;
  vacancyPreviewDialog?: Nullable<
    Pick<VacancyPreviewDialogProps, 'open' | 'content'>
  >;
  vacancyChangeStateDialog?: Nullable<
    Pick<VacancyChangeStateDialogProps, 'open' | 'content'>
  >;
  vacancyClosedSurveyDialog?: Nullable<
    Pick<VacancyClosedSurveyDialogProps, 'open' | 'content'>
  >;
  vacancyRepostDialog?: Nullable<
    Pick<VacancyRepostDialogProps, 'open' | 'content'>
  >;
  vacancyShareDialog?: Nullable<
    Pick<VacancyShareDialogProps, 'open' | 'content' | 'onClose'>
  >;
  confirmDialogV2?: Nullable<Pick<ConfirmDialogV2Props, 'open' | 'content'>>;
  interviewInvitationDialog: Nullable<
    Pick<InterviewInvitationDialogProps, 'open' | 'content'>
  >;
};

export const dialogsSlice = createSlice({
  name: 'dialogs',
  initialState: {} as DialogState,
  reducers: {
    openDialog: (state, action: PayloadAction<keyof DialogState>) => {
      const dialogState = state[action.payload];
      if (dialogState) dialogState.open = true;
    },
    closeDialog: (state, action: PayloadAction<keyof DialogState>) => {
      const dialogState = state[action.payload];
      if (dialogState) dialogState.open = false;
    },
    setDialog: (state, action: PayloadAction<Partial<DialogState>>) => {
      return { ...state, ...action.payload };
    },
    clearDialog: (state, action: PayloadAction<keyof DialogState>) => {
      state[action.payload] = null;
    },
  },
});

export const { openDialog, closeDialog, setDialog, clearDialog } =
  dialogsSlice.actions;

export default dialogsSlice.reducer;
