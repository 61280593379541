import { Stack, Image, Typography } from '@kitalulus/web-ui-kit';
import ConsClipboardIcon from '~/components/ConsComponents/Icons/ConsClipboardIcon';
import ConsGajiIcon from '~/components/ConsComponents/Icons/ConsGajiIcon';
import ConsLocationIcon from '~/components/ConsComponents/Icons/ConsLocationIcon';
import { useAppSelector } from '~/hooks/use-store';
import { VacancyPreviewDialogContent } from '../VacancyPreviewDialog.types';

const BoxOne = ({ content }: { content: VacancyPreviewDialogContent }) => {
  const company = useAppSelector((state) => state.company);

  const showSalaryBounds =
    !!content?.salaryLowerBound && !!content?.salaryUpperBound;

  return (
    <Stack sx={{ bgcolor: '#FFF', py: 4, px: 2 }} gap={1}>
      <Stack direction="row" gap={2}>
        <Image
          src={
            company?.logo?.url || company?.logoUrl || '/icon/cons-company.svg'
          }
          sx={{ width: 48, height: 48, borderRadius: '50%' }}
        />
        <Stack>
          <Typography
            variant="h5"
            sx={{
              fontWeight: 600,
            }}
          >
            {content.positionName}
          </Typography>
          <Typography variant="body1">{company?.name}</Typography>
        </Stack>
      </Stack>
      <Stack direction="row" mt={2} gap={2}>
        <ConsLocationIcon color="primary" />
        <Typography variant="body2">{content.location}</Typography>
      </Stack>
      <Stack direction="row" gap={2}>
        <ConsClipboardIcon color="primary" />
        <Typography variant="body2">{content.workType.join(' ● ')}</Typography>
      </Stack>
      {showSalaryBounds && (
        <Stack direction="row" gap={2}>
          <ConsGajiIcon color="primary" />
          <Typography variant="body2">
            {`Rp${content.salaryLowerBound} - Rp${content.salaryUpperBound} `}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};

export default BoxOne;
