import { ApolloLink } from '@apollo/client';

import { store } from '~/store';
import { setSnackbar } from '~/store/views/snackbar-slice';
import { unionResponseErrorTypes } from '~/utils/business-error';

export const businessErrorLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    if (response?.data) {
      const { operationName } = operation;
      const operationResponse = response?.data?.[operationName];

      if (
        operationResponse &&
        unionResponseErrorTypes.includes(operationResponse?.__typename)
      ) {
        const { typeError, message } = operationResponse;

        if (typeError === 'UNKNOWN_ERROR')
          store.dispatch(
            setSnackbar({
              severity: 'error',
              message,
            }),
          );
      }
    }

    return response;
  });
});

export default businessErrorLink;
