import { getValue } from 'firebase/remote-config';
import { useMemo } from 'react';

import { remoteConfig } from '~/config/firebase';
import { FirebaseRemoteConfigSchema } from '~/types/firebase';
import { parseValue } from '~/utils/helper';

export const useRemoteConfigValue = <
  T extends keyof FirebaseRemoteConfigSchema,
>(
  key: T,
): FirebaseRemoteConfigSchema[T] => {
  const value = getValue(remoteConfig, key);

  const parsedValue = useMemo<FirebaseRemoteConfigSchema[T]>(() => {
    return parseValue(value.asString());
  }, [value]);

  return parsedValue;
};
