import {
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useMediaQuery,
  useTheme,
} from '@kitalulus/web-ui-kit';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingButton from '~/components/LoadingButton';
import { useDeleteCandidateRecommendation } from '~/hooks/candidate-recommendation';
import { useAppDispatch, useAppSelector } from '~/hooks/use-store';
import { closeDialog } from '~/store/views/dialogs-slice';
import { CandidateRecommendationRejectConfirmDialogProps } from './CandidateRecommendationRejectConfirmDialog.types';
import * as mpe from '~/utils/mixpanel';
import { convertDateToISOString } from '~/utils/helper';

const CandidateRecommendationRejectConfirmDialog = ({
  content,
  ...props
}: CandidateRecommendationRejectConfirmDialogProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const { candidateRecommendationDetail, vacancyDetail: vacancy } =
    useAppSelector((state) => state);
  const candidateDetailData = candidateRecommendationDetail?.detail?.data;
  const { deleteCandidate, loading } = useDeleteCandidateRecommendation();
  const handleClose = useCallback(() => {
    dispatch(closeDialog('candidateRecommendationRejectConfirmDialog'));
  }, []);

  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  if (!content) return null;
  const { onSuccess, triggerSource } = content;
  return (
    <Dialog
      maxWidth="sm"
      {...props}
      sx={{
        textAlign: 'center',
      }}
    >
      <Box
        {...(!isMobile && {
          sx: {
            py: 2,
            px: 7,
          },
        })}
      >
        <DialogTitle>
          <Typography component="div" variant="h5" color="text.primary">
            {t('candidate-recommendation-detail:rejectDialog.title')}
          </Typography>
        </DialogTitle>
        <DialogContent>
          {t('candidate-recommendation-detail:rejectDialog.content')}
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: 'center',
          }}
        >
          <LoadingButton
            size="medium"
            variant="contained"
            sx={{ width: 150 }}
            onClick={async () => {
              if (!candidateDetailData?.id) return;
              mpe.candidateActionConfirm({
                vacancyCode: vacancy?.code ?? '',
                vacancyCreatedAt: convertDateToISOString(vacancy?.createdAt),
                vacancyCloseDate: convertDateToISOString(vacancy?.closeDate),
                candidateID: candidateDetailData.id,
                candidateName:
                  candidateRecommendationDetail?.detail?.data?.userProfile
                    ?.name ?? '',
                action: 'not interested',
                actionPosition: triggerSource,
              });
              const { isSuccess } = await deleteCandidate(
                candidateDetailData.id,
              );
              handleClose();
              if (isSuccess && onSuccess) onSuccess();
            }}
            color="error"
            loading={loading}
          >
            {t('candidate-recommendation-detail:rejectDialog.accept')}
          </LoadingButton>
          <Button
            size="medium"
            variant="outlined"
            sx={{ width: 150 }}
            onClick={handleClose}
            autoFocus
            color="inherit"
          >
            {t('candidate-recommendation-detail:rejectDialog.cancel')}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default CandidateRecommendationRejectConfirmDialog;
