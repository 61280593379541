import { SvgIcon, SvgIconProps } from '@kitalulus/web-ui-kit';

const CopyIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 20 21" {...props}>
      <path
        opacity="0.48"
        d="M8.15944 16.2549C6.53234 16.2549 5.20801 14.9306 5.20801 13.3035V6.18552H3.99277C2.93928 6.18552 2.08301 7.04167 2.08301 8.09516V17.4702C2.08301 18.5237 2.93928 19.3799 3.99277 19.3799H12.6732C13.7267 19.3799 14.583 18.5237 14.583 17.4702V16.2549H8.15944Z"
      />
      <path d="M17.3611 3.91701C17.3611 2.79179 16.5062 1.87993 15.4514 1.87993H8.15976C7.10487 1.87993 6.25 2.79179 6.25 3.91701V13.1762C6.25 14.3014 7.10487 15.2133 8.15976 15.2133H15.4514C16.5062 15.2133 17.3611 14.3014 17.3611 13.1762V3.91701Z" />
    </SvgIcon>
  );
};

export default CopyIcon;
