import { Grid, Button, Image, Stack, Typography } from '@kitalulus/web-ui-kit';
import { useTranslation } from 'react-i18next';
import { useCompanyAccManagerWhatsappLink } from '~/hooks/use-company';
import { MIXPANEL_ACCOUNT_HELP_LINK_POSITION } from '~/utils/constants/mixpanel';
import { accountHelp } from '~/utils/mixpanel';

const ErrorScreen = () => {
  const { t } = useTranslation();
  const waLink = useCompanyAccManagerWhatsappLink('errorBoundaryIssue');

  const handleContact = () => {
    accountHelp({
      linkPosition: MIXPANEL_ACCOUNT_HELP_LINK_POSITION.TECHNICAL_ERROR,
    });
    window.open(waLink, '_blank');
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{ minHeight: '100vh' }}
      p={4}
    >
      <Grid container item xs={12} spacing={3}>
        <Grid item xs={12}>
          <Stack spacing={3} alignItems="center">
            <Typography
              variant="h3"
              component="div"
              sx={{ fontWeight: 700, textAlign: 'center' }}
            >
              {t('error:screen.title')}
            </Typography>
            <Typography
              variant="body1"
              component="div"
              color="grey.600"
              sx={{
                textAlign: 'center',
                maxWidth: '480px',
                whiteSpace: 'pre-line',
              }}
              dangerouslySetInnerHTML={{
                __html: t('error:screen.subtitle'),
              }}
            />
            <Image
              src="/illustrations/maintenance-illustration.webp"
              alt="maintenance illustration"
              my={3}
              sx={{ maxWidth: '355px', width: '100%' }}
            />
          </Stack>
        </Grid>
        <Grid item container xs={12} justifyContent="center">
          <Button size="large" variant="contained" onClick={handleContact}>
            {t('error:screen.buttonText')}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ErrorScreen;
