import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { MUTATION_DELETE_JOB_VACANCY_CANDIDATE } from '~/graphql/candidate-recommendation';
import { setSnackbar } from '~/store/views/snackbar-slice';
import { useAppDispatch } from '~/hooks/use-store';
import {
  DeleteJobVacancyCandidateMutation,
  DeleteJobVacancyCandidateMutationVariables,
} from '~/types/graphql/graphql';

export const useDeleteCandidateRecommendation = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [handleDelete, { loading }] = useMutation<
    DeleteJobVacancyCandidateMutation,
    DeleteJobVacancyCandidateMutationVariables
  >(MUTATION_DELETE_JOB_VACANCY_CANDIDATE, {
    fetchPolicy: 'no-cache',
  });

  const deleteCandidate = async (id: string) => {
    const res = await handleDelete({
      variables: {
        id: id,
      },
    });
    const data = res?.data?.deleteJobVacancyCandidate;
    if (!data) throw new Error('Unknown error');
    const { isSuccess } = data;
    if (isSuccess) {
      dispatch(
        setSnackbar({
          layout: 'dashboard',
          severity: 'success',
          message: t(
            'candidate-recommendation-detail:snackbarMesseges.successDelete',
          ),
          closeable: true,
        }),
      );
    }
    return data;
  };
  return { deleteCandidate, loading };
};
