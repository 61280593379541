import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@kitalulus/web-ui-kit';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import CloseIcon from '~/components/Icons/CloseIcon';
import LoadingButton from '~/components/LoadingButton';
import { useMutationUpdateBulkJobApplicationCompanyStatus } from '~/hooks/applicants/use-applicant-status-v2';
import { useAppDispatch, useAppSelector } from '~/hooks/use-store';
import { setSnackbar } from '~/store/views/snackbar-slice';
import { EpJobApplicationEpStatusEnum } from '~/types/graphql/graphql';
import { MixpanelApplicantProceedOnlyChangeStatusPopupProperties } from '~/types/mixpanel';
import * as mpe from '~/utils/mixpanel';
import { DirectChangeStatusToInterviewDialogProps } from './';

const DirectChangeStatusToInterviewDialog = ({
  content: { applicationIds, onRefresh, vacancy },
  onSwitchVariant: _,
  onClose,
  ...props
}: DirectChangeStatusToInterviewDialogProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const company = useAppSelector((state) => state.company);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { handleUpdateStatus, isLoading } =
    useMutationUpdateBulkJobApplicationCompanyStatus();

  const handleSubmit = useCallback(async () => {
    mpe.applicantProceedOnlyChangeStatusPopup({
      buttonType: 'change status',
      vacancyCode: vacancy.code,
      vacancyName: vacancy.positionName,
      companyCode: company?.code ?? '',
      companyName: company?.name ?? '',
      applicantNumber: applicationIds.length,
    });

    const res = await handleUpdateStatus({
      ids: applicationIds,
      companyStatus: EpJobApplicationEpStatusEnum.Interview,
    });

    if (res.success) {
      // on success
      dispatch(
        setSnackbar({
          severity: 'success',
          message: res.data?.data?.message,
        }),
      );

      onRefresh();
      onClose();
    } else {
      // on error
      dispatch(
        setSnackbar({
          severity: 'error',
          message:
            res.error instanceof Error
              ? res.error.message
              : res.error?.error || res.error?.data?.message,
        }),
      );

      onClose();
    }
  }, [handleUpdateStatus, onClose, applicationIds, vacancy, company]);

  const handleClose = useCallback(
    (
      buttonType: MixpanelApplicantProceedOnlyChangeStatusPopupProperties['buttonType'],
    ) => {
      mpe.applicantProceedOnlyChangeStatusPopup({
        buttonType,
        vacancyCode: vacancy.code,
        vacancyName: vacancy.positionName,
        companyCode: company?.code ?? '',
        companyName: company?.name ?? '',
        applicantNumber: applicationIds.length,
      });
      onClose();
    },
    [onClose, applicationIds, vacancy, company],
  );

  return (
    <Dialog
      fullWidth
      onClose={() => handleClose('close')}
      PaperProps={{
        sx: {
          maxWidth: '500px',
          width: 'auto',
          mx: 2,
          p: 4,
          ...(isMobile && { p: 3 }),
        },
      }}
      {...props}
    >
      <Stack gap={3}>
        <DialogTitle component="div" sx={{ p: 0 }}>
          <Stack direction="row" alignItems="center" gap={2}>
            <Typography variant="h6" color="text.primary">
              {t(
                'interview-invitation:directChangeStatusToInterviewDialog.title',
              )}
            </Typography>
            <IconButton
              aria-label="close"
              onClick={() => handleClose('close')}
              sx={{ alignSelf: 'flex-start', mt: '-12px', mr: '-12px' }}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>

        <DialogContent sx={{ p: 0 }}>
          <Typography variant="body1" color="text.secondary">
            {t(
              'interview-invitation:directChangeStatusToInterviewDialog.description',
            )}
          </Typography>
        </DialogContent>

        <DialogActions sx={{ p: 0, gap: 0.5 }}>
          <Button
            variant="outlined"
            color="inherit"
            onClick={() => handleClose('cancel')}
          >
            {t('common:cancelAlt')}
          </Button>
          <LoadingButton
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            loading={isLoading}
          >
            {t(
              'interview-invitation:directChangeStatusToInterviewDialog.submit',
            )}
          </LoadingButton>
        </DialogActions>
      </Stack>
    </Dialog>
  );
};

export default DirectChangeStatusToInterviewDialog;
