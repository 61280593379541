import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { EpMenu } from '~/types/graphql/graphql';

export type MenusInitialState = EpMenu[];

export const menuSlice = createSlice({
  name: 'menus',
  initialState: [] as MenusInitialState,
  reducers: {
    setMenus: (_, action: PayloadAction<MenusInitialState>) => action.payload,
    clearMenus: () => [],
  },
});

export const { setMenus, clearMenus } = menuSlice.actions;

export default menuSlice.reducer;
