import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
  brandColors,
  useMediaQuery,
  useTheme,
} from '@kitalulus/web-ui-kit';
import { useTranslation } from 'react-i18next';
import CloseIcon from '~/components/Icons/CloseIcon';
import SlideUpDialogTransition from '~/components/Transistions/SlideUpDialogTransition';
import { useAppDispatch } from '~/hooks/use-store';
import { clearDialog } from '~/store/views/dialogs-slice';
import BoxFour from './BoxFour';
import BoxOne from './BoxOne/BoxOne';
import BoxThree from './BoxThree/BoxThree';
import BoxTwo from './BoxTwo/BoxTwo';
import { VacancyPreviewDialogProps } from './VacancyPreviewDialog.types';

const VacancyPreviewDialog = ({
  content,
  ...props
}: VacancyPreviewDialogProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleCloseDialog = () => {
    dispatch(clearDialog('vacancyPreviewDialog'));
  };

  if (!content) return null;

  return (
    <Dialog
      {...props}
      maxWidth="lg"
      fullWidth
      fullScreen={isMobile}
      sx={{ top: { xs: '10%', sm: 0 } }}
      PaperProps={{
        sx: {
          bgcolor: 'background.neutral',
          maxWidth: 1045,
        },
      }}
      scroll={'paper'}
      {...(isMobile && { TransitionComponent: SlideUpDialogTransition })}
    >
      <DialogTitle sx={{ p: { xs: 0, sm: 2 } }}>
        <Stack direction="row" justifyContent="space-between">
          <Typography component="div" variant="h6" color="text.primary">
            {t('vacancy-form:preview.dialogTitle')}
          </Typography>
          <IconButton sx={{ top: -10, right: -10 }} onClick={handleCloseDialog}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent
        sx={{
          display: 'flex',
          justifyContent: 'center',
          px: { xs: 0, sm: 2 },
          py: '24px !important',
        }}
      >
        <Stack
          maxWidth={450}
          width="100%"
          gap={2}
          sx={{ bgcolor: brandColors.neutral[300] }}
        >
          <BoxOne content={content} />
          <BoxTwo content={content} />
          <BoxThree content={content} />
          <BoxFour />
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default VacancyPreviewDialog;
