import {
  Autocomplete,
  Box,
  CircularProgress,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@kitalulus/web-ui-kit';
import { format } from 'date-fns';
import { debounce } from 'lodash';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import RequiredAsterisk from '~/components/RequiredAsterisk';
import TruncateText from '~/components/TruncateText';
import { useInterviewInvitationSessionNameValidation } from '~/hooks/interview-invitation-form/use-interview-invitation-sessio-name';
import { useInterviewSessionOption } from '~/hooks/options/use-interview-session-option';
import {
  EpInterviewSessionType,
  EpValidateInterviewSessionNameCode,
} from '~/types/graphql/graphql';
import { autocompleteDropdownSchemaProps } from '~/utils/components';
import { InterviewInvitationFormSessionSelectTypeKey } from '~/utils/constants/interview-invitation';
import { removeFancyTextAndEmoji, sleep } from '~/utils/helper';
import {
  interviewInvitationSessionNameMaxLength,
  interviewInvitationSessionNameMinLength,
} from '~/utils/validation';
import { InterviewInvitationFormContext } from '../../';
import {
  InterviewInvitationFirstStepSessionOption,
  InterviewInvitationFormFirstStepProps,
} from './';

const InterviewInvitationFormFirstStep = (
  props: InterviewInvitationFormFirstStepProps,
) => {
  const { t } = useTranslation();
  const { vacancy, currentSessionId, firstStepFormState } = useContext(
    InterviewInvitationFormContext,
  );
  const {
    control,
    getFieldState,
    getValues,
    resetField,
    setError,
    setValue,
    trigger,
    unregister,
    watch,
    formState: { defaultValues },
  } = firstStepFormState;

  const {
    handleFetchOptions,
    options,
    isLoading: isOptionLoading,
  } = useInterviewSessionOption(false, { vacancyId: vacancy.id });
  const { handleValidateSessionName, isLoading: isValidating } =
    useInterviewInvitationSessionNameValidation(vacancy.id);
  const [hasSessions, setHasSessions] = useState(false);

  // responsive states
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const newSessionName = watch('newSessionName');
  const selectSessiontype = watch('sessionSelectType');
  const newSessionNameDefaultValue = defaultValues?.newSessionName ?? '';
  const isNewSession =
    selectSessiontype ===
    InterviewInvitationFormSessionSelectTypeKey.NEW_SESSION;

  const handleChangeSessionSelectType = useCallback(
    async (newType: InterviewInvitationFormSessionSelectTypeKey) => {
      setValue('sessionSelectType', newType);

      // to prevent race condition between set value and trigger validation
      await sleep(100);

      switch (newType) {
        case InterviewInvitationFormSessionSelectTypeKey.NEW_SESSION: {
          // set newSessionName to its default value is the value is empty
          const newSessionName = getValues('newSessionName');
          if (!newSessionName)
            setValue('newSessionName', newSessionNameDefaultValue);

          // unregister other option's fields
          unregister('selectedSession', {
            keepValue: true,
            keepDefaultValue: true,
          });
          // trigger validation to selected option
          trigger(['newSessionName', 'isNewSessionNameValid']);
          return;
        }

        case InterviewInvitationFormSessionSelectTypeKey.EXISTING_SESSION: {
          // unregister other option's fields
          unregister(['newSessionName', 'isNewSessionNameValid'], {
            keepValue: true,
            keepDefaultValue: true,
          });
          // trigger validation to selected option
          trigger('selectedSession');
          return;
        }

        default:
          return;
      }
    },
    [newSessionNameDefaultValue],
  );

  const handleValidateNewSessionName = useCallback(
    async (name: string) => {
      try {
        // skip server validation if the client one isn't passed
        const newSessionNameState = getFieldState('newSessionName');
        if (newSessionNameState.invalid) return;

        // start running server validation
        const res = await handleValidateSessionName({ name });
        // request or internal error
        if (!res.success) throw new Error(res?.error.message ?? '');
        // expected business error
        if (res.data.code !== EpValidateInterviewSessionNameCode.Valid)
          throw new Error(res.data.message ?? '');

        // on input valid
        setValue('isNewSessionNameValid', true, { shouldValidate: true });
      } catch (err: any) {
        setValue('isNewSessionNameValid', false, { shouldValidate: true });

        const sessionSelectType = getValues('sessionSelectType');
        const isNewSession =
          sessionSelectType ===
          InterviewInvitationFormSessionSelectTypeKey.NEW_SESSION;
        // only set error if new session selected
        isNewSession && setError('newSessionName', { message: err.message });
      }
    },
    [handleValidateSessionName],
  );

  const handleFetchSessionOptions = useCallback(
    async (keyword: string) => {
      return await handleFetchOptions({
        keyword,
        excludeIds: currentSessionId ? [currentSessionId] : [],
      });
    },
    [handleFetchOptions],
  );

  const debouncedHandleValidateNewSessionName = useCallback(
    debounce(handleValidateNewSessionName, 1000),
    [handleValidateNewSessionName],
  );

  const debouncedHandleFetchSessionOptions = useCallback(
    debounce(handleFetchSessionOptions, 500),
    [handleFetchSessionOptions],
  );

  const sessionTypeLabel = useMemo<
    Record<EpInterviewSessionType, string>
  >(() => {
    return {
      [EpInterviewSessionType.Offline]: t(
        'interview-invitation:interviewOffline',
      ),
      [EpInterviewSessionType.Online]: t(
        'interview-invitation:interviewOnline',
      ),
    };
  }, []);

  const sessionOptions = useMemo<
    InterviewInvitationFirstStepSessionOption[]
  >(() => {
    const existingSessionDisableTooltip = !hasSessions
      ? t('interview-invitation-form:existingSessionDiableTooltip')
      : null;

    return [
      {
        type: InterviewInvitationFormSessionSelectTypeKey.NEW_SESSION,
        title: t('interview-invitation-form:newSessionTitle'),
        subtitle: t('interview-invitation-form:newSessionSubtitle'),
        input: ({ isSelected }) => (
          <>
            <Controller
              control={control}
              name="newSessionName"
              rules={{
                required: isSelected
                  ? {
                      value: true,
                      message: t(
                        'interview-invitation-form:newSessionError.required',
                      ),
                    }
                  : undefined,
                minLength: isSelected
                  ? {
                      value: interviewInvitationSessionNameMinLength,
                      message: t(
                        'interview-invitation-form:newSessionError.minLength',
                      ),
                    }
                  : undefined,
              }}
              render={({
                field: { value = '', onChange },
                fieldState: { error },
              }) => (
                <TextField
                  inputProps={{
                    maxLength: interviewInvitationSessionNameMaxLength,
                  }}
                  placeholder={t(
                    'interview-invitation-form:newSessionPlaceholder',
                  )}
                  FormHelperTextProps={{ component: 'div', sx: { mx: 0 } }}
                  helperText={
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      gap={2}
                    >
                      <Typography variant="caption" color="inherit">
                        {error?.message ??
                          t('interview-invitation-form:newSessionHelper')}
                      </Typography>
                      <Typography variant="caption" color="text.secondary">
                        {value.length}/{interviewInvitationSessionNameMaxLength}
                      </Typography>
                    </Stack>
                  }
                  value={value}
                  onChange={({ target: { value } }) => {
                    onChange(removeFancyTextAndEmoji(value));
                    // reset new session valid status whenever users change the input
                    resetField('isNewSessionNameValid');
                  }}
                  error={!!error}
                  InputProps={{
                    endAdornment: isValidating && (
                      <Stack pl={2} pr={0.5}>
                        <CircularProgress size={20} color="inherit" />
                      </Stack>
                    ),
                  }}
                />
              )}
            />

            {/* Hidden input for new session name valid status */}
            <Controller
              control={control}
              name="isNewSessionNameValid"
              rules={{ validate: isSelected ? (v) => !!v : undefined }}
              render={() => <></>}
            />
          </>
        ),
      },
      {
        type: InterviewInvitationFormSessionSelectTypeKey.EXISTING_SESSION,
        title: t('interview-invitation-form:existingSessionTitle'),
        subtitle: t('interview-invitation-form:existingSessionSubtitle'),
        disableTooltip: existingSessionDisableTooltip,
        input: ({ isSelected }) => (
          <Controller
            control={control}
            name="selectedSession"
            rules={{ required: isSelected ? true : undefined }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Autocomplete
                {...autocompleteDropdownSchemaProps}
                disabled={!!existingSessionDisableTooltip}
                options={options}
                ListboxProps={{ sx: { maxHeight: '478px !important' } }}
                value={value}
                onChange={(_, val) => onChange(val)}
                onInputChange={(_, val) =>
                  debouncedHandleFetchSessionOptions(val)
                }
                renderInput={(params) => (
                  <TextField
                    error={!!error}
                    placeholder={t(
                      'interview-invitation-form:existingSessionPlaceholder',
                    )}
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {isOptionLoading && (
                            <CircularProgress color="inherit" size={20} />
                          )}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
                renderOption={(props, { additional: session }) => {
                  if (!session) return;
                  const scheduleDate = new Date(session.schedule);

                  return (
                    <Stack
                      component="li"
                      gap={0.15}
                      sx={{ '*': { width: '100%' } }}
                      borderRadius="4px !important"
                      px="16px !important"
                      py="12px !important"
                      {...props}
                    >
                      <TruncateText noOfLines={1}>
                        <Typography variant="subtitle1">
                          {session.name}
                        </Typography>
                      </TruncateText>
                      <Typography variant="body2">
                        {sessionTypeLabel[session.type]}
                      </Typography>
                      <Typography variant="caption">
                        {format(scheduleDate, 'EEEE, dd/MM/yyyy')}・
                        {session.startTimeStr} - {session.endTimeStr} WIB
                      </Typography>
                      <Typography variant="caption" color="text.secondary">
                        {t(
                          'interview-invitation-form:numberOfInvitedApplicants',
                          { n: session.numberOfApplicants },
                        )}
                      </Typography>
                    </Stack>
                  );
                }}
              />
            )}
          />
        ),
      },
    ];
  }, [
    hasSessions,
    isValidating,
    isOptionLoading,
    handleValidateNewSessionName,
    sessionTypeLabel,
  ]);

  // validate new session name onChange
  useEffect(() => {
    if (!isNewSession) return;
    debouncedHandleValidateNewSessionName(newSessionName);
  }, [newSessionName, debouncedHandleValidateNewSessionName, isNewSession]);

  // initial option data fetching and check has session
  useEffect(() => {
    (async () => {
      const options = (await handleFetchSessionOptions('')) ?? [];
      setHasSessions(options.length > 0);
    })();
  }, [handleFetchSessionOptions]);

  return (
    <Stack gap={2} {...props}>
      <Typography variant="subtitle1">
        {t('interview-invitation-form:sessionTitle')}
        <RequiredAsterisk />
      </Typography>

      <Controller
        control={control}
        name="sessionSelectType"
        rules={{ required: true }}
        render={({ field: { value } }) => (
          <Stack component={RadioGroup} gap={2}>
            {sessionOptions.map((s) => {
              const isSelected = value === s.type;
              const isDisabled = !!s.disableTooltip;

              return (
                <Stack
                  key={s.type}
                  direction="row"
                  alignItems="flex-start"
                  gap={0.5}
                  onClick={() =>
                    !isDisabled &&
                    !isSelected &&
                    handleChangeSessionSelectType(s.type)
                  }
                >
                  <Radio
                    value={s.type}
                    edge="start"
                    disabled={!!isDisabled}
                    checked={isSelected}
                  />
                  <Stack gap={2} pt={1.1} width="100%">
                    <Stack gap={0.5}>
                      <Box>
                        <Tooltip
                          title={s.disableTooltip}
                          placement={isMobile ? 'bottom' : 'right'}
                          PopperProps={{
                            sx: {
                              '& .MuiTooltip-tooltip': {
                                maxWidth: '176px',
                                p: '8px',
                                textAlign: 'center',
                                fontSize: '12px',
                              },
                            },
                          }}
                          arrow
                        >
                          <Typography
                            variant="body1"
                            display="inline"
                            color={
                              isDisabled ? 'text.disabled' : 'text.primary'
                            }
                          >
                            {s.title}
                          </Typography>
                        </Tooltip>
                      </Box>
                      <Typography
                        variant="body2"
                        color={isDisabled ? 'text.disabled' : 'text.secondary'}
                      >
                        {s.subtitle}
                      </Typography>
                    </Stack>

                    {s.input({ isSelected })}
                  </Stack>
                </Stack>
              );
            })}
          </Stack>
        )}
      />
    </Stack>
  );
};

export default InterviewInvitationFormFirstStep;
