import { useLazyQuery } from '@apollo/client';

import { useAppDispatch, useAppSelector } from '~/hooks/use-store';
import { QUERY_JOB_APPLICATION_SCREENING } from '~/graphql/applicants';
import {
  JobApplicationScreeningQuery,
  JobApplicationScreeningQueryVariables,
} from '~/types/graphql/graphql';
import { setApplicantScreeningQuestion } from '~/store/features/applicant-detail-slice';
import { FETCH_STATUS } from '~/utils/constants';

export const useApplicantScreeningQuestion = () => {
  const dispatch = useAppDispatch();
  const { applicantScreeningQuestion } = useAppSelector(
    (state) => state.applicantDetail,
  );

  const [fetchApplicantScreeningQuestion] = useLazyQuery<
    JobApplicationScreeningQuery,
    JobApplicationScreeningQueryVariables
  >(QUERY_JOB_APPLICATION_SCREENING, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ jobApplicationScreening }) => {
      if (!jobApplicationScreening) return;

      dispatch(
        setApplicantScreeningQuestion({
          fetchStatus: FETCH_STATUS.RESOLVED,
          data: jobApplicationScreening,
        }),
      );
    },
    onError: () => {
      dispatch(
        setApplicantScreeningQuestion({
          fetchStatus: FETCH_STATUS.FAILED,
        }),
      );
    },
  });

  return {
    fetchApplicantScreeningQuestion,
    isLoadingApplicantScreeningQuestion:
      applicantScreeningQuestion?.fetchStatus !== FETCH_STATUS.RESOLVED &&
      applicantScreeningQuestion?.fetchStatus !== FETCH_STATUS.FAILED,
    applicantScreeningQuestion,
  };
};
