import { useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';

import { QUERY_JOB_APPLICATION_COUNT } from '~/graphql/applicants';
import { QUERY_MENUS } from '~/graphql/menus';
import { setApplicantCount } from '~/store/features/applicant-count-slice';
import { setMenus } from '~/store/features/menus-slice';
import {
  EpMenu,
  JobApplicationCountQuery,
  MenusQuery,
} from '~/types/graphql/graphql';
import { defaultGlobalDataContext } from '~/utils/apollo-request';
import { useAuthValue } from './use-auth';
import { useAppDispatch } from './use-store';

export const useMenus = () => {
  const { isAuthenticated } = useAuthValue();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const [getMenu, { loading: _menuLoading }] = useLazyQuery<MenusQuery>(
    QUERY_MENUS,
    {
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
      context: defaultGlobalDataContext,
      onCompleted: ({ menus }) => {
        if (!menus) return;
        dispatch(setMenus(menus.filter((menu) => !!menu) as EpMenu[]));
      },
    },
  );

  const [getApplicantCount, { loading: _applicantCountLoading }] =
    useLazyQuery<JobApplicationCountQuery>(QUERY_JOB_APPLICATION_COUNT, {
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
      onCompleted: ({ jobApplicationCount }) => {
        dispatch(setApplicantCount(jobApplicationCount));
      },
    });

  useEffect(() => {
    if (!isAuthenticated) return;
    setIsLoading(true);
    const fetchData = async () => {
      await getMenu();
      await getApplicantCount();
    };

    fetchData().then(() => {
      setIsLoading(false);
    });
  }, [isAuthenticated]);

  return {
    isLoading: isLoading, // use to wait for all query to be done.
    // use single loading to wait specific query to be done.
    _menuLoading,
    _applicantCountLoading,
  };
};
