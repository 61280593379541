import { SvgIcon, SvgIconProps } from '@kitalulus/web-ui-kit';

const ConsClipboardIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 16 16" {...props}>
      <path d="M10.5083 8.63492C8.8435 8.63492 7.49239 9.98603 7.49239 11.6508C7.49239 13.3156 8.8435 14.6667 10.5083 14.6667C12.173 14.6667 13.5241 13.3156 13.5241 11.6508C13.5241 9.98603 12.173 8.63492 10.5083 8.63492ZM11.5035 13.0683L10.2067 11.7714V9.84127H10.8098V11.5241L11.9257 12.64L11.5035 13.0683ZM11.1114 3.20635H9.19334C8.94001 2.50667 8.27652 2 7.49239 2C6.70826 2 6.04477 2.50667 5.79144 3.20635H3.87334C3.20985 3.20635 2.66699 3.74921 2.66699 4.4127V13.4603C2.66699 14.1238 3.20985 14.6667 3.87334 14.6667H7.55874C7.20286 14.3229 6.91334 13.9127 6.70223 13.4603H3.87334V4.4127H5.07969V6.22222H9.90509V4.4127H11.1114V7.47683C11.5397 7.53714 11.9438 7.66381 12.3178 7.83873V4.4127C12.3178 3.74921 11.7749 3.20635 11.1114 3.20635ZM7.49239 4.4127C7.16064 4.4127 6.88921 4.14127 6.88921 3.80952C6.88921 3.47778 7.16064 3.20635 7.49239 3.20635C7.82413 3.20635 8.09556 3.47778 8.09556 3.80952C8.09556 4.14127 7.82413 4.4127 7.49239 4.4127Z" />
    </SvgIcon>
  );
};

export default ConsClipboardIcon;
