import { SvgIcon, SvgIconProps } from '@kitalulus/web-ui-kit';

const ThumbUpIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 14 13" {...props}>
      <path d="M7.65203 1.20173L4.42036 4.4334C4.20453 4.64923 4.08203 4.94673 4.08203 5.2559V11.0834C4.08203 11.7251 4.60703 12.2501 5.2487 12.2501H10.4987C10.9654 12.2501 11.3854 11.9701 11.572 11.5442L13.4737 7.10506C13.9637 5.95006 13.1179 4.66673 11.8637 4.66673H8.56786L9.12203 1.99506C9.18036 1.7034 9.09286 1.4059 8.88286 1.1959C8.5387 0.857563 7.99036 0.857563 7.65203 1.20173ZM1.7487 12.2501C2.39036 12.2501 2.91536 11.7251 2.91536 11.0834V6.41673C2.91536 5.77506 2.39036 5.25006 1.7487 5.25006C1.10703 5.25006 0.582031 5.77506 0.582031 6.41673V11.0834C0.582031 11.7251 1.10703 12.2501 1.7487 12.2501Z" />
    </SvgIcon>
  );
};

export default ThumbUpIcon;
