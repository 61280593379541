import { DialogProps } from '@kitalulus/web-ui-kit';

export type ApplicantScreeningQuestionFilterDialogProps = DialogProps & {
  handleSubmit: (d: Array<TApplicantScreeningQuestionFilterDialogData>) => void;
  handleReset: () => void;
  data: Array<TApplicantScreeningQuestionFilterDialogData>;
};

export type TApplicantScreeningQuestionFilterDialogData = {
  id: string;
  topic: string;
  uiType: EApplicantScreeningQuestionFilterDialogUiType;
  isRequired: boolean;
  title: string;
  description: string;
  choices: Array<TApplicantScreeningQuestionFilterDialogDataChoices>;
  entries?: Array<TApplicantScreeningQuestionFilterDialogDataEntry>;
};

export type TApplicantScreeningQuestionFilterDialogDataEntry = {
  key: string;
  value: string;
  choiceID?: string;
};

export type TApplicantScreeningQuestionFilterDialogDataChoices = {
  key: string;
  value: string;
  isSelected?: boolean;
};

export enum EApplicantScreeningQuestionFilterDialogUiType {
  horizontal_radio_with_left_label = 'horizontal_radio_with_left_label',
  horizontal_radio_with_top_label = 'horizontal_radio_with_top_label',
  horizontal_checkbox_with_right_label = 'horizontal_checkbox_with_right_label',
}

export type TMixpanelScreeningQuestionFilterSkillAndIndustry = {
  [key: string]: string;
};
