import {
  Box,
  CircularProgress,
  Divider,
  IconButton,
  Stack,
  TextArea,
  Typography,
  useTheme,
} from '@kitalulus/web-ui-kit';
import { ChangeEvent } from 'react';
import { Controller } from 'react-hook-form';

import CheckCircleIcon from '~/components/Icons/CheckCircleIcon';
import FileCopyOutlinedIcon from '~/components/Icons/FileCopyOutlinedIcon';
import TrashIcon from '~/components/Icons/TrashIcon';

interface ITakeNotesArea {
  disabled?: boolean;
  isLoading?: boolean;
  isSuccess?: boolean;
  handleCopy: () => void;
  control: any;
  resetField: any;
  name: string;
  defaultValue: string;
  placeholder?: string;
  successStatusStr?: string;
  maximumLength?: number;
}

const TakeNotesArea = ({
  disabled,
  isLoading,
  isSuccess,
  handleCopy,
  control,
  resetField,
  name,
  defaultValue,
  placeholder,
  successStatusStr,
  maximumLength,
}: ITakeNotesArea) => {
  const theme = useTheme();

  return (
    <>
      <Stack
        sx={{
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: theme.spacing(2),
          background: disabled ? theme.palette.grey['200'] : 'none',
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          gap={1}
          sx={{
            padding: theme.spacing(1),
            px: theme.spacing(2),
          }}
        >
          <Stack direction="row" alignItems="center" gap={1}>
            {isLoading && <CircularProgress color="inherit" size={16} />}
            {isSuccess && (
              <>
                <CheckCircleIcon fontSize="small" color="success" />
                <Typography variant="body2">{successStatusStr}</Typography>
              </>
            )}
          </Stack>
          <Stack direction="row" alignItems="center" gap={1}>
            <IconButton
              data-test-id="btnApplicantStatusChangeNoteCopy"
              size="small"
              onClick={handleCopy}
            >
              <FileCopyOutlinedIcon
                fontSize="small"
                sx={{ color: 'text.primary' }}
              />
            </IconButton>
            <IconButton
              data-test-id="btnApplicantStatusChangeNoteRemove"
              size="small"
              onClick={() => resetField(name, { defaultValue: '' })}
            >
              <TrashIcon fontSize="small" sx={{ color: 'text.primary' }} />
            </IconButton>
          </Stack>
        </Stack>
        <Divider />
        <Box sx={{ minHeight: '437px', padding: theme.spacing(1.5) }}>
          <Controller
            name={name}
            control={control}
            defaultValue={defaultValue || ''}
            render={({ field }) => {
              const { ref, ...others } = field;
              return (
                <TextArea
                  fullWidth
                  label=""
                  variant="standard"
                  hiddenLabel
                  placeholder={placeholder}
                  disabled={disabled}
                  inputProps={{
                    'data-test-id': 'tfApplicantStatusChangeNote',
                    maxLength: maximumLength ?? 2000,
                  }}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  rows={17}
                  {...others}
                  inputRef={ref}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    field.onChange(event);
                  }}
                />
              );
            }}
          />
        </Box>
      </Stack>
    </>
  );
};

export default TakeNotesArea;
