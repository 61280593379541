import { Step, StepButton, Stepper } from '@kitalulus/web-ui-kit';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { InterviewInvitationFormStepperProps } from './';

const InterviewInvitationFormStepper = (
  props: InterviewInvitationFormStepperProps,
) => {
  const { t } = useTranslation();

  const steps = useMemo(() => {
    return [
      {
        key: 'first',
        label: t('interview-invitation-form:steps.first'),
      },
      {
        key: 'second',
        label: t('interview-invitation-form:steps.second'),
      },
      {
        key: 'third',
        label: t('interview-invitation-form:steps.third'),
      },
      {
        key: 'fourth',
        label: t('interview-invitation-form:steps.fourth'),
      },
    ];
  }, []);

  return (
    <Stepper alternativeLabel {...props}>
      {steps.map((step) => (
        <Step key={step.key}>
          <StepButton disabled>{step.label}</StepButton>
        </Step>
      ))}
    </Stepper>
  );
};

export default InterviewInvitationFormStepper;
