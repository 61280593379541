import InterviewInvitationFormFirstStep from './steps/InterviewInvitationFormFirstStep';
import InterviewInvitationFormFourthStep from './steps/InterviewInvitationFormFourthStep';
import InterviewInvitationFormSecondStep from './steps/InterviewInvitationFormSecondStep';
import InterviewInvitationFormThirdStep from './steps/InterviewInvitationFormThirdStep';
import {
  InterviewInvitationFormContext,
  InterviewInvitationFormProps,
} from './';

const steps = [
  {
    key: 'first',
    component: InterviewInvitationFormFirstStep,
  },
  {
    key: 'second',
    component: InterviewInvitationFormSecondStep,
  },
  {
    key: 'third',
    component: InterviewInvitationFormThirdStep,
  },
  {
    key: 'fourth',
    component: InterviewInvitationFormFourthStep,
  },
];

const InterviewInvitationForm = (props: InterviewInvitationFormProps) => {
  return (
    <InterviewInvitationFormContext.Provider value={props}>
      {steps.map((step, i) => (
        <step.component
          key={step.key}
          display={props.activeStep === i ? 'flex' : 'none'}
        />
      ))}
    </InterviewInvitationFormContext.Provider>
  );
};

export default InterviewInvitationForm;
