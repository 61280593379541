import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Nullable } from '~/types';
import { ONBOARDING_STEPS } from '~/utils/constants';
import { createLocalStoragePersist } from '~/utils/redux-persist';

export type OnboardingState = Nullable<{ currentStep: ONBOARDING_STEPS }>;

export const ONBOARDING_KEY = 'onboarding';

export const onboardingSlice = createSlice({
  name: ONBOARDING_KEY,
  initialState: null as OnboardingState,
  reducers: {
    setOnboarding: (_, action: PayloadAction<OnboardingState>) =>
      action.payload,
    clearOnboarding: () => null,
  },
});

export const { setOnboarding, clearOnboarding } = onboardingSlice.actions;

export default createLocalStoragePersist(
  ONBOARDING_KEY,
  onboardingSlice.reducer,
);
