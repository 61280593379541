import { Typography } from '@kitalulus/web-ui-kit';

import { RequiredAsteriskProps } from './RequiredAsterisk.types';

const RequiredAsterisk = (props: RequiredAsteriskProps) => {
  return (
    <Typography
      color="error"
      display="inline"
      fontSize="inherit"
      children="*"
      {...props}
    />
  );
};

export default RequiredAsterisk;
