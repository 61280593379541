import { Stack, Image, Typography, brandColors } from '@kitalulus/web-ui-kit';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '~/hooks/use-store';

const BoxFour = () => {
  const { t } = useTranslation();
  const company = useAppSelector((state) => state.company);

  return (
    <Stack sx={{ bgcolor: '#FFF', py: 4, px: 2 }} gap={1}>
      <Typography variant="caption" color={brandColors.neutral[700]}>
        {t('vacancy-form:preview.aboutCompany')}
      </Typography>
      <Stack direction="row" gap={2} mt={1}>
        <Image
          src={
            company?.logo?.url || company?.logoUrl || '/icon/cons-company.svg'
          }
          sx={{ width: 48, height: 48, borderRadius: '50%' }}
        />
        <Stack>
          <Typography variant="subtitle2">{company?.name}</Typography>
          <Typography variant="caption">
            {company?.jobCompanyIndustry?.name}
          </Typography>
        </Stack>
      </Stack>
      <Typography variant="body2">{company?.description}</Typography>
      <Typography
        color={brandColors.neutral[600]}
        component="div"
        fontWeight={600}
        fontSize={13}
      >
        {t('vacancy-form:preview.viewProfileDetails')}
      </Typography>
    </Stack>
  );
};

export default BoxFour;
