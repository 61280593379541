import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Nullable } from '~/types';
import {
  EpJobApplication,
  EpJobApplicationEpStatusEnum,
  EpJobApplicationPrevNext,
  EpJobApplicationScreening,
  EpUserProfile,
  JobApplicationQuery,
  UserCvQuery,
} from '~/types/graphql/graphql';
import { MixpanelProfileDetailTabKeys } from '~/types/mixpanel';
import { FETCH_STATUS } from '~/utils/constants';
type TApplication = JobApplicationQuery['jobApplication'] & {
  pages?: number | null;
};

export type TApplicantDetailState = Nullable<TApplication>;

type TypePrevNext = Partial<EpJobApplicationPrevNext>;

interface TypeRejectReasons {
  fetchStatus: FETCH_STATUS;
  reasons: string[] | null;
}

interface TypeCv {
  fetchStatus: FETCH_STATUS;
  cvDownloadStatus: FETCH_STATUS;
  data: UserCvQuery['userCv'] | null;
}

type TypeApplicantScreeningQuestion = {
  fetchStatus: FETCH_STATUS;
  data: EpJobApplicationScreening | null;
};

interface TypeApplication {
  activeTab: MixpanelProfileDetailTabKeys;
  fetchStatusDetail: FETCH_STATUS;
  fetchStatusPrevNext: FETCH_STATUS;
  isUpdatingStatus: boolean;
  data: TApplicantDetailState;
  prevNext: TypePrevNext;
  rejectReasons: TypeRejectReasons;
  cv: TypeCv;
  error: string | null;
  applicantScreeningQuestion: TypeApplicantScreeningQuestion;
}

const initialState: TypeApplication = {
  activeTab: 'profile',
  fetchStatusDetail: FETCH_STATUS.IDLE,
  fetchStatusPrevNext: FETCH_STATUS.IDLE,
  isUpdatingStatus: false,
  data: null,
  error: null,
  prevNext: {
    nextId: null,
    prevId: null,
    id: null,
  },
  rejectReasons: {
    fetchStatus: FETCH_STATUS.IDLE,
    reasons: null,
  },
  cv: {
    fetchStatus: FETCH_STATUS.IDLE,
    cvDownloadStatus: FETCH_STATUS.IDLE,
    data: null,
  },
  applicantScreeningQuestion: {
    fetchStatus: FETCH_STATUS.IDLE,
    data: null,
  },
};

export const ApplicantDetailSlice = createSlice({
  name: 'applicantDetail',
  initialState,
  reducers: {
    setApplicantDetailActiveTab: (
      state,
      action: PayloadAction<MixpanelProfileDetailTabKeys>,
    ) => {
      state.activeTab = action.payload;
    },
    setApplicantDetail: (
      state,
      action: PayloadAction<TApplicantDetailState>,
    ) => {
      state.fetchStatusDetail = FETCH_STATUS.RESOLVED;
      state.data = action.payload;
    },
    setApplicantDetailFetchStatus: (
      state,
      action: PayloadAction<FETCH_STATUS>,
    ) => {
      state.fetchStatusDetail = action.payload;
    },
    setApplicantPrevNext: (state, action: PayloadAction<TypePrevNext>) => {
      return {
        ...state,
        fetchStatusPrevNext: FETCH_STATUS.RESOLVED,
        prevNext: action.payload,
      };
    },
    setApplicantDetailIsUpdatingStatus: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.isUpdatingStatus = action.payload;
    },
    setApplicantFetchStatusPrevNext: (
      state,
      action: PayloadAction<FETCH_STATUS>,
    ) => {
      return {
        ...state,
        fetchStatusPrevNext: action.payload,
      };
    },
    setApplicantDetailStatus: (
      state,
      action: PayloadAction<EpJobApplicationEpStatusEnum>,
    ) => {
      return {
        ...state,
        data: {
          ...(state.data as EpJobApplication),
          userProfile: {
            ...(state.data?.userProfile as EpUserProfile),
            companyStatus: action.payload,
          },
        },
      };
    },
    setApplicantRejectReasonsStatus: (
      state,
      action: PayloadAction<FETCH_STATUS>,
    ) => {
      state.rejectReasons.fetchStatus = action.payload;
    },
    setApplicantRejectReasons: (
      state,
      action: PayloadAction<string[] | null>,
    ) => {
      state.rejectReasons.fetchStatus = FETCH_STATUS.RESOLVED;
      state.rejectReasons.reasons = action.payload;
    },
    setApplicantDetailCv: (state, action: PayloadAction<Partial<TypeCv>>) => {
      state.cv = {
        ...state.cv,
        ...action.payload,
      };
    },
    setApplicantDetailError: (state, action: PayloadAction<string>) => {
      state.fetchStatusDetail = FETCH_STATUS.FAILED;
      state.error = action.payload;
    },
    setApplicantScreeningQuestion: (
      state,
      action: PayloadAction<Partial<TypeApplicantScreeningQuestion>>,
    ) => {
      return {
        ...state,
        applicantScreeningQuestion: {
          ...state.applicantScreeningQuestion,
          ...action.payload,
        },
      };
    },
    clearApplicantDetail: () => initialState,
  },
});

export const {
  setApplicantDetailActiveTab,
  setApplicantDetail,
  setApplicantDetailFetchStatus,
  setApplicantDetailError,
  setApplicantPrevNext,
  setApplicantFetchStatusPrevNext,
  setApplicantDetailStatus,
  setApplicantDetailIsUpdatingStatus,
  setApplicantRejectReasonsStatus,
  setApplicantRejectReasons,
  setApplicantDetailCv,
  setApplicantScreeningQuestion,
  clearApplicantDetail,
} = ApplicantDetailSlice.actions;

export default ApplicantDetailSlice.reducer;
