import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Nullable } from '~/types';
import { JobVacancyCandidateQuery } from '~/types/graphql/graphql';
import { FETCH_STATUS } from '~/utils/constants';

type TCandidateRecommendation =
  JobVacancyCandidateQuery['jobVacancyCandidate'] & {
    pages?: number | null;
  };

export type TCandidateRecommendationDetailState =
  Nullable<TCandidateRecommendation>;

interface TypePrevNext {
  fetchStatus: FETCH_STATUS;
  nextId: string | null;
  prevId: string | null;
}

type TypeRecommendedCandidate = {
  detail: {
    fetchStatus: FETCH_STATUS;
    data: TCandidateRecommendationDetailState;
    error: string | null;
  };
  prevNext: TypePrevNext;
};

const initialState: TypeRecommendedCandidate = {
  detail: {
    fetchStatus: FETCH_STATUS.IDLE,
    data: null,
    error: null,
  },
  prevNext: {
    fetchStatus: FETCH_STATUS.IDLE,
    nextId: null,
    prevId: null,
  },
};

export const CandidateRecommendationDetailSlice = createSlice({
  name: 'candidateRecommendation',
  initialState,
  reducers: {
    setCandidateRecommendationDetail: (
      state,
      action: PayloadAction<TCandidateRecommendationDetailState>,
    ) => {
      return {
        ...state,
        detail: {
          ...state.detail,
          fetchStatus: FETCH_STATUS.RESOLVED,
          data: action.payload,
        },
      };
    },
    setCandidateRecommendationPrevNext: (
      state,
      action: PayloadAction<Partial<TypePrevNext>>,
    ) => {
      return {
        ...state,
        prevNext: {
          ...state.prevNext,
          ...action.payload,
        },
      };
    },
    setCandidateRecommendationFetchStatusPrevNext: (
      state,
      action: PayloadAction<FETCH_STATUS>,
    ) => {
      return {
        ...state,
        prevNext: {
          ...state.prevNext,
          fetchStatus: action.payload,
        },
      };
    },
    setCandidateRecomedationDetailFetchStatus: (
      state,
      action: PayloadAction<FETCH_STATUS>,
    ) => {
      return {
        ...state,
        detail: {
          ...state.detail,
          fetchStatus: action.payload,
        },
      };
    },
    setCandidateRecommendationDetailError: (
      state,
      action: PayloadAction<string | null>,
    ) => {
      return {
        ...state,
        detail: {
          ...state.detail,
          fetchStatus: FETCH_STATUS.FAILED,
          error: action.payload,
        },
      };
    },
    clearCandidateRecommendationDetail: () => initialState,
  },
});

export const {
  setCandidateRecommendationDetail,
  setCandidateRecommendationPrevNext,
  setCandidateRecommendationFetchStatusPrevNext,
  setCandidateRecomedationDetailFetchStatus,
  setCandidateRecommendationDetailError,
  clearCandidateRecommendationDetail,
} = CandidateRecommendationDetailSlice.actions;

export default CandidateRecommendationDetailSlice.reducer;
