import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getRemoteConfig } from 'firebase/remote-config';
import { getFirestore } from 'firebase/firestore';

// firebase initialization
const app = initializeApp({
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSENGER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
});

// firebase - remote config
const remoteConfig = getRemoteConfig();
const firebaseAuth = getAuth(app);
const firestore = getFirestore(app);

remoteConfig.settings.minimumFetchIntervalMillis = 0;

export { remoteConfig, firebaseAuth, firestore };
