import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Nullable } from '~/types';
import { EpJobCompanyAccManager } from '~/types/graphql/graphql';

export type CompanyAccManagerState = Nullable<EpJobCompanyAccManager>;

export const companySlice = createSlice({
  name: 'company-acc-manager',
  initialState: null as CompanyAccManagerState,
  reducers: {
    setCompanyAccManager: (_, action: PayloadAction<CompanyAccManagerState>) =>
      action.payload,
    clearCompanyAccManager: () => null,
  },
});

export const { setCompanyAccManager, clearCompanyAccManager } =
  companySlice.actions;

export default companySlice.reducer;
