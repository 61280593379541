import { gql } from '~/types/graphql/gql';

export const QUERY_VALIDATE_INTERVIEW_SESSION_NAME = gql(`
  query validateInterviewSessionName($name: String!, $vacancyId: ID!) {
    validateInterviewSessionName: epValidateInterviewSessionName(
      name: $name,
      vacancyId: $vacancyId
    ) {
      code
      message
    }
  }
`);

export const MUTATION_CREATE_INTERVIEW_INVITATION = gql(`
  mutation EpCreateInterviewInvitation($input: EpCreateInterviewInvitationInput!) {
  createInterviewInvitation: epCreateInterviewInvitation(input: $input) {
    ... on EpCreateInterviewInvitationError {
      message
      typeError
    }
    ... on EpCreateInterviewInvitationSuccess {
      message
      title
    }
  }
}
`);

export const MUTATION_UPDATE_INTERVIEW_INVITATION =
  gql(`mutation EpUpdateInterviewInvitation($input: EpUpdateInterviewInvitationInput!) {
  updateInterviewInvitation: epUpdateInterviewInvitation(input: $input) {
    ... on EpUpdateInterviewInvitationError {
      message
      typeError
    }
    ... on EpUpdateInterviewInvitationSuccess {
      message
      title
    }
  }
}`);
