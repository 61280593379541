import { Button } from '@kitalulus/web-ui-kit';
import { useState } from 'react';
import { appConfig } from '~/config/app';
import { useMultiQueryState } from '~/hooks/use-query-state';

const PotentialError = () => {
  const [hasError, setHasError] = useState(false);
  const [urlParams] = useMultiQueryState<{ trigger_error: number }>({
    trigger_error: 0,
  });

  const handleClick = () => {
    setHasError(true);
  };

  if (hasError) {
    throw new Error('Error di component PotentialError');
  }

  if (urlParams.trigger_error !== 1 || appConfig.mode === 'production')
    return null;

  return (
    <Button onClick={handleClick} variant="contained" color="error">
      Trigger Error
    </Button>
  );
};

export default PotentialError;
