import { useEffect } from 'react';

import {
  PreloadedImagesState,
  setPreloadImage,
} from '~/store/helpers/preloaded-images-slice';
import { convertImageToBase64 } from '~/utils/image-converter';
import { useAppDispatch } from './use-store';

const usePreloadedImages = (key: keyof PreloadedImagesState, asset: string) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    convertImageToBase64(asset)
      .then((res) => {
        if (!res) return;
        dispatch(setPreloadImage({ key, value: res }));
      })
      .catch(console.error);
  }, [key, asset]);
};

export default usePreloadedImages;
