import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Nullable } from '~/types';
import { EpJobVacancy, EpScreeningQuestion } from '~/types/graphql/graphql';
import { FETCH_STATUS } from '~/utils/constants';

export type TVacancyDetailScreeningQuestion = {
  jobVacancyId: string;
  screeningQuestions: EpScreeningQuestion[];
  screeningQuestionsFetchStatus: FETCH_STATUS;
};
export type TVacancyState = Nullable<
  EpJobVacancy & TVacancyDetailScreeningQuestion
>;

export const vacancySlice = createSlice({
  name: 'vacancyDetail',
  initialState: null as TVacancyState,
  reducers: {
    setVacancyDetail: (_, action: PayloadAction<Nullable<EpJobVacancy>>) => {
      if (!action.payload) return;
      return {
        ...action.payload,
        jobVacancyId: '',
        screeningQuestions: [],
        screeningQuestionsFetchStatus: FETCH_STATUS.RESOLVED,
        screeningQuestionsFilter: null,
      };
    },
    setVacancyDetailScreeningQuestions: (
      state,
      action: PayloadAction<Partial<TVacancyDetailScreeningQuestion>>,
    ) => {
      if (!state) return;
      return { ...state, ...action.payload };
    },
    clearVacancyDetail: () => null,
  },
});

export const {
  setVacancyDetail,
  setVacancyDetailScreeningQuestions,
  clearVacancyDetail,
} = vacancySlice.actions;

export default vacancySlice.reducer;
