import { useMutation } from '@apollo/client';
import { useCallback, useState } from 'react';

import { MUTATION_UPDATE_BULK_JOB_APPLICATION_COMPANY_STATUS_V2 } from '~/graphql/applicants';
import { ActionFunctionResult } from '~/types';
import {
  UpdateBulkJobApplicationCompanyStatusV2Mutation,
  UpdateBulkJobApplicationCompanyStatusV2MutationVariables,
} from '~/types/graphql/graphql';

export const useMutationUpdateBulkJobApplicationCompanyStatus = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [updateBulkJobApplicationCompanyStatusV2] = useMutation<
    UpdateBulkJobApplicationCompanyStatusV2Mutation,
    UpdateBulkJobApplicationCompanyStatusV2MutationVariables
  >(MUTATION_UPDATE_BULK_JOB_APPLICATION_COMPANY_STATUS_V2);

  const handleUpdateStatus = useCallback(
    async (
      payload: UpdateBulkJobApplicationCompanyStatusV2MutationVariables['data'],
    ): Promise<
      ActionFunctionResult<
        UpdateBulkJobApplicationCompanyStatusV2Mutation['updateBulkJobApplicationCompanyStatusV2'],
        UpdateBulkJobApplicationCompanyStatusV2Mutation['updateBulkJobApplicationCompanyStatusV2']
      >
    > => {
      try {
        setIsLoading(true);
        const { data } = await updateBulkJobApplicationCompanyStatusV2({
          variables: { data: payload },
        });

        const res = data?.updateBulkJobApplicationCompanyStatusV2;
        if (!res) throw new Error('empty response');
        if (!res.isSuccess) return { success: false, error: res };
        return { success: true, data: res };
      } catch (err: any) {
        return { success: false, error: err };
      } finally {
        setIsLoading(false);
      }
    },
    [updateBulkJobApplicationCompanyStatusV2],
  );

  return { handleUpdateStatus, isLoading };
};
