import { TextField } from '@kitalulus/web-ui-kit';
import { forwardRef } from 'react';

import {
  onlyNumberPattern,
  onlyPositiveNumberPattern,
} from '~/utils/validation';
import { NumberTextFieldProps } from './NumberTextField.types';

const NumberTextField = forwardRef<HTMLInputElement, NumberTextFieldProps>(
  ({ onlyPositive, ...props }, ref) => {
    const pattern = onlyPositive
      ? onlyPositiveNumberPattern
      : onlyNumberPattern;

    return (
      <TextField
        ref={ref}
        {...props}
        onChange={(e) => {
          const value = e.target.value;
          if (pattern.test(value) && props.onChange) return props.onChange(e);
          e.preventDefault();
        }}
      />
    );
  },
);

export default NumberTextField;
