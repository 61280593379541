import { useAppSelector } from '~/hooks/use-store';
import LoadingPage3DotsDialog from './LoadingPage3DotsDialog';

const AuthenticatedDialogs = () => {
  const { loadingPage3DotsDialog } = useAppSelector(
    (state) => state.authenticatedDialogs,
  );
  return (
    <>
      {!!loadingPage3DotsDialog?.open && (
        <LoadingPage3DotsDialog
          {...loadingPage3DotsDialog}
          open={!!loadingPage3DotsDialog?.open}
        />
      )}
    </>
  );
};

export default AuthenticatedDialogs;
