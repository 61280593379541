import CustomMainContent from '~/components/CustomMainContent';
import PrivacyMain from '../PrivacyMain';

const DashboardPrivacy = () => {
  return (
    <CustomMainContent>
      <PrivacyMain />
    </CustomMainContent>
  );
};

export default DashboardPrivacy;
