import { useTheme, Dialog, Stack } from '@kitalulus/web-ui-kit';

import Loader3Dots from '~/components/Loaders/Loader3Dots';
import { LoadingPage3DotsProps } from './LoadingPage3DotsDialog.types';

const LoadingPage3Dots = ({ content, ...props }: LoadingPage3DotsProps) => {
  const theme = useTheme();
  return (
    <Dialog
      fullWidth
      PaperProps={{
        sx: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
          textAlign: 'center',
          maxWidth: 508,
        },
      }}
      {...props}
    >
      <Stack
        alignItems="center"
        alignContent="center"
        sx={{
          backgroundColor: theme.palette.common.white,
          padding: '54px 24px',
          borderRadius: '16px',
        }}
      >
        <Loader3Dots sx={{ ...(!!content?.body && { mb: 3 }) }} />
        {content?.body}
      </Stack>
    </Dialog>
  );
};

export default LoadingPage3Dots;
