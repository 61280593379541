import { SvgIcon, SvgIconProps } from '@kitalulus/web-ui-kit';

const WatchLaterIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 18 18" {...props}>
      <path d="M8.5 0.0100098C6.81886 0.0100098 5.17547 0.508526 3.77766 1.44252C2.37984 2.37651 1.29037 3.70403 0.647028 5.2572C0.00368293 6.81037 -0.164645 8.51944 0.163329 10.1683C0.491303 11.8171 1.30085 13.3317 2.4896 14.5204C3.67834 15.7092 5.1929 16.5187 6.84174 16.8467C8.49057 17.1747 10.1996 17.0063 11.7528 16.363C13.306 15.7196 14.6335 14.6302 15.5675 13.2324C16.5015 11.8345 17 10.1912 17 8.51001C17 6.25567 16.1045 4.09366 14.5104 2.4996C12.9164 0.905543 10.7543 0.0100098 8.5 0.0100098V0.0100098ZM9.20834 8.81672L5.62913 11.06L4.87759 9.85584L7.79167 8.03189V4.26001H9.20834V8.81672Z" />
    </SvgIcon>
  );
};

export default WatchLaterIcon;
