import { SvgIcon, SvgIconProps } from '@kitalulus/web-ui-kit';

const ConsCheckIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 16 16" {...props}>
      <path d="M6.70746 12.9058C6.31693 13.2963 5.68377 13.2963 5.29324 12.9058L2.56746 10.18C2.17693 9.78951 2.17693 9.15635 2.56746 8.76582L3.03887 8.29441C3.4298 7.90348 4.06377 7.90395 4.45412 8.29546L5.29289 9.13671C5.68338 9.52836 6.31763 9.52866 6.7085 9.13739L11.8799 3.96075C12.2704 3.56989 12.9038 3.56973 13.2945 3.96039L13.7666 4.43249C14.1571 4.82301 14.1571 5.45618 13.7666 5.8467L6.70746 12.9058Z" />
    </SvgIcon>
  );
};

export default ConsCheckIcon;
