import type { History, Transition } from 'history';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';

export type NavigationBlockPromptCallback = (tx: Transition) => void;

export const useNavigationBlockPrompt = (
  // this callback is invoked when the block status is true
  // and user tries to navigate to the other pages
  // we can pass either global or local state to control prompt state
  cb?: NavigationBlockPromptCallback,
  defaultIsBlocked = false,
) => {
  const [isBlocked, setIsBlocked] = useState(defaultIsBlocked);

  const navigation = useContext(NavigationContext);
  const navigator = navigation.navigator as History;

  const transition = useRef<Transition>();
  const unblock = useRef<VoidFunction>();

  const next = useCallback(() => {
    unblock.current?.();
    transition.current?.retry();
  }, []);

  useEffect(() => {
    if (!isBlocked) return;
    unblock.current = navigator.block((tx) => {
      transition.current = tx;
      cb?.(tx);
    });

    return next;
  }, [isBlocked, cb, next]);

  return { isBlocked, setIsBlocked };
};
