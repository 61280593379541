import { ApolloProvider } from '@apollo/client';
import {
  KitaLulusThemeProvider,
  LocalizationProvider,
  AdapterDateFns,
} from '@kitalulus/web-ui-kit';
import '@kitalulus/web-ui-kit/src/theme/palette';
import '@kitalulus/web-ui-kit/src/theme/shadows';
import '@kitalulus/web-ui-kit/src/theme/typography';
import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import StrictMode from './components/StrictMode';
import IndexRoutes from './routes';
import { store, persisor } from './store';
import './theme/fonts';
import Analytics from './utils/analytics';
import { client } from './utils/apollo-client';
import './utils/i18n';
import * as mixpanel from './utils/mixpanel';
import Sentry from './utils/sentry';

import '@kitalulus/web-ui-kit/dist/index.css';
import './config/date';
import './theme/styles.css';
import { AuthProvider } from './provider/AuthProvider';
import CustomDateLocalization from './components/CustomDateLocalization';

Sentry.initialize();
Analytics.initialize();
mixpanel.init();

ReactDOM.createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persisor}>
        <ApolloProvider client={client}>
          <Suspense>
            <KitaLulusThemeProvider>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <CustomDateLocalization>
                  <BrowserRouter>
                    <AuthProvider>
                      <IndexRoutes />
                    </AuthProvider>
                  </BrowserRouter>
                </CustomDateLocalization>
              </LocalizationProvider>
            </KitaLulusThemeProvider>
          </Suspense>
        </ApolloProvider>
      </PersistGate>
    </Provider>
  </StrictMode>,
);
