import { SvgIcon, SvgIconProps } from '@kitalulus/web-ui-kit';

export const EmailIconOutlined = (props: SvgIconProps) => {
  return (
    <SvgIcon width="18" height="17" viewBox="0 0 18 17" {...props}>
      <path d="M15.75 0H2.25C1.65326 0 1.08097 0.237053 0.65901 0.65901C0.237053 1.08097 0 1.65326 0 2.25L0 16.5H18V2.25C18 1.65326 17.7629 1.08097 17.341 0.65901C16.919 0.237053 16.3467 0 15.75 0ZM2.25 1.5H15.75C15.9489 1.5 16.1397 1.57902 16.2803 1.71967C16.421 1.86032 16.5 2.05109 16.5 2.25V2.75025L10.5915 8.6595C10.1688 9.08049 9.59656 9.31685 9 9.31685C8.40344 9.31685 7.83118 9.08049 7.4085 8.6595L1.5 2.75025V2.25C1.5 2.05109 1.57902 1.86032 1.71967 1.71967C1.86032 1.57902 2.05109 1.5 2.25 1.5ZM1.5 15V4.875L6.348 9.72C7.05197 10.4222 8.00569 10.8165 9 10.8165C9.99431 10.8165 10.948 10.4222 11.652 9.72L16.5 4.875V15H1.5Z" />
    </SvgIcon>
  );
};

export default EmailIconOutlined;
