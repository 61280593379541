import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
  IconButton,
} from '@kitalulus/web-ui-kit';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import CloseIcon from '~/components/Icons/CloseIcon';

import { useSignOut } from '~/hooks/use-auth';
import { useAppDispatch } from '~/hooks/use-store';
import { clearDialog } from '~/store/views/dialogs-slice';

const LogoutConfirmationDialog = (props: DialogProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { handleSignOut } = useSignOut();

  const handleClose = useCallback(() => {
    dispatch(clearDialog('logoutConfirmationDialog'));
  }, []);

  return (
    <Dialog
      maxWidth="xs"
      sx={{
        '.MuiDialog-paper': { maxWidth: 479, p: 3, gap: 3 },
        '.MuiDialogTitle-root, .MuiDialogContent-root, .MuiDialogActions-root':
          { p: 0 },
        '.MuiDialogActions-root > :not(:first-of-type)': { ml: 0 },
      }}
      {...props}
      data-test-id="ctLogoutAccountPopup"
    >
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 11,
          top: 19,
          color: 'text.primary',
        }}
        data-test-id="btnLogoutConfirmationDialogClose"
      >
        <CloseIcon sx={{ fontSize: 22 }} />
      </IconButton>
      <DialogTitle
        variant="h6"
        color="text.primary"
        data-test-id="lbLogoutAccountPopupTitle"
      >
        {t('common:logout')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{t('common:logoutNotice')}</DialogContentText>
      </DialogContent>
      <DialogActions sx={{ gap: '12px' }}>
        <Button
          variant="outlined"
          color="inherit"
          onClick={handleClose}
          autoFocus
          sx={{ height: 36, width: 86, fontWeight: 600 }}
          data-test-id="btnCancelLogoutAccountPopup"
        >
          {t('common:no')}
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={() => {
            handleSignOut();
            handleClose();
          }}
          sx={{ height: 36, width: 99, fontWeight: 600, ml: 0 }}
          data-test-id="btnConfirmLogoutAccountPopup"
        >
          {t('common:yesExit')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LogoutConfirmationDialog;
