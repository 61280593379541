import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  Typography,
} from '@kitalulus/web-ui-kit';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { appConfig } from '~/config/app';
import { useAppDispatch } from '~/hooks/use-store';
import { clearDialog } from '~/store/views/dialogs-slice';
import { VacancyHighlightQuotaExceededDialogProps } from './VacancyHighlightQuotaExceededDialog.types';

const VacancyHighlightQuotaExceededDialog = (
  props: VacancyHighlightQuotaExceededDialogProps,
) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleClose = useCallback(() => {
    dispatch(clearDialog('vacancyHighlightQuotaExceededDialog'));
  }, []);

  return (
    <Dialog
      {...props}
      sx={{
        '.MuiPaper-root': { maxWidth: '470px', p: { xs: 3, md: 5 } },
        ...props.sx,
      }}
    >
      <DialogContent sx={{ px: 1 }}>
        <Stack spacing={4} alignItems="center">
          <Box
            component="img"
            src="/illustrations/highlight-vacancy-quota-illustration.webp"
            alt="Vacancy On Review Illustration"
            maxWidth="205px"
            width="100%"
            height="auto"
          />
          <Stack spacing={2}>
            <Typography variant="h4" color="text.primary" textAlign="center">
              {t('vacancy:vacancyHighlightQuotaExceededModalTitle')}
            </Typography>
            <Typography
              variant="body1"
              textAlign="justify"
              color="text.primary"
            >
              {t('vacancy:vacancyHighlightQuotaExceededModalBody', {
                appName: appConfig.appName,
              })}
            </Typography>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={handleClose}
          fullWidth
        >
          {t('common:okayGotItAlt')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default VacancyHighlightQuotaExceededDialog;
