import { SvgIcon, SvgIconProps } from '@kitalulus/web-ui-kit';

const StarRatingIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 9 10" {...props}>
      <path d="M7.30031 9.24365L4.50019 7.18565L1.70006 9.24365L2.77519 5.92002L-0.0234375 3.87515H3.43181L4.50019 0.545898L5.56856 3.87515H9.02344L6.22519 5.92002L7.30031 9.24365Z" />
    </SvgIcon>
  );
};

export default StarRatingIcon;
