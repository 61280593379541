import { SvgIcon, SvgIconProps } from '@kitalulus/web-ui-kit';

const CheckCircleOutlineIconV2 = (props: SvgIconProps) => {
  return (
    <SvgIcon width="20" height="20" viewBox="0 0 20 20" {...props}>
      <g>
        <path
          d="M9.9974 18.3337C14.5999 18.3337 18.3307 14.6028 18.3307 10.0003C18.3307 5.39783 14.5999 1.66699 9.9974 1.66699C5.3949 1.66699 1.66406 5.39783 1.66406 10.0003C1.66406 14.6028 5.3949 18.3337 9.9974 18.3337Z"
          stroke="#1E88E5"
          strokeWidth="2"
        />
        <path
          d="M7.5 9.99967L9.16667 11.6663L12.5 8.33301"
          stroke="#1E88E5"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </g>
    </SvgIcon>
  );
};

export default CheckCircleOutlineIconV2;
