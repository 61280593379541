import { ComponentType, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import DisconnectedPage from '~/components/DisconnectedPage';
import { useAppDispatch } from '~/hooks/use-store';
import { setSnackbar } from '~/store/views/snackbar-slice';

export function withDeviceStatus<T extends JSX.IntrinsicAttributes>(
  Content: ComponentType<T>,
): ComponentType<T> {
  return function OfflineNetworkContent(props) {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [isOnline, setIsOnline] = useState(true);
    const [isSecure, setIsSecure] = useState(true);

    const updateNetwork = useCallback(() => {
      setIsOnline(window.navigator.onLine);
    }, []);

    // device off/on line status checking
    useEffect(() => {
      window.addEventListener('offline', updateNetwork);
      window.addEventListener('online', updateNetwork);
      return () => {
        window.removeEventListener('offline', updateNetwork);
        window.removeEventListener('online', updateNetwork);
      };
    }, [updateNetwork]);

    // SSL secure checking
    useEffect(() => {
      setIsSecure(window.isSecureContext);
    }, []);

    // on SS not secure
    useEffect(() => {
      if (!isSecure) {
        dispatch(
          setSnackbar({
            severity: 'error',
            message: t('common:sslErrorTitle'),
            description: t('common:sslErrorDescription'),
            autoHideDuration: undefined,
            StackProps: { alignItems: 'flex-start' },
            sx: {
              width: '410px',
              '.SnackbarIcon': { pt: 1.5 },
            },
            closeable: false,
          }),
        );
      }
    }, [isSecure]);

    if (!isOnline) return <DisconnectedPage />;

    return <Content {...props} />;
  };
}
