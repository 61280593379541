import React from 'react';

import { appConfig } from '~/config/app';
import { StrictModeProps } from './StrictMode.types';

const StrictMode = (props: StrictModeProps) => {
  if (!appConfig.isDevelopment) return <>{props.children}</>;

  return <React.StrictMode>{props.children}</React.StrictMode>;
};

export default StrictMode;
