import { PopoverDialogV1Props } from './PopoverDialogV1.types';
import {
  Button,
  IconButton,
  MenuPopover,
  Stack,
  Typography,
  brandColors,
  useTheme,
} from '@kitalulus/web-ui-kit';
import LoadingButton from '~/components/LoadingButton/LoadingButton';
import { Trans } from 'react-i18next';
import CloseIcon from '~/components/Icons/CloseIcon';

const PopoverDialogV1 = ({
  triggerElem,
  setTriggerElem,
  popuptitle,
  popupBody,
  menuPopoverProps,
  StackContainerProps,
  btnCancelProps,
  onClose,
  btnAcceptProps,
  ...props
}: PopoverDialogV1Props) => {
  const theme = useTheme();
  const closePopover = () => {
    setTriggerElem(null);
    onClose?.();
  };

  return (
    <MenuPopover
      {...menuPopoverProps}
      open={!!triggerElem}
      anchorEl={triggerElem}
      onClose={closePopover}
      PaperProps={{
        sx: {
          width: 500,
          bgcolor: brandColors.grey[700],
          color: theme.palette.common.white,
          ...menuPopoverProps?.PaperProps?.sx,
        },
      }}
      arrow="none"
    >
      <Stack p={3} gap={1} {...StackContainerProps}>
        <Stack m={-1} mb={-2} p={0}>
          <IconButton
            sx={{
              alignSelf: 'end',
              color: theme.palette.common.white,
            }}
            onClick={closePopover}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        <Typography variant="h6">{popuptitle}</Typography>
        <Typography variant="body1">{popupBody}</Typography>
        {props.children}
        <Stack direction="row" gap={2} mt={2}>
          <Button
            onClick={closePopover}
            variant="outlined"
            color="inherit"
            sx={{ width: '100%' }}
            {...btnCancelProps}
          >
            {btnCancelProps?.children ?? <Trans i18nKey="common:cancelled" />}
          </Button>
          <LoadingButton
            onClick={btnAcceptProps.onClick}
            loading={btnAcceptProps.loading}
            variant="contained"
            sx={{ width: '100%' }}
            {...btnAcceptProps}
          >
            {btnAcceptProps.children ?? (
              <Trans i18nKey="applicant-detail:changeToSelected" />
            )}
          </LoadingButton>
        </Stack>
      </Stack>
    </MenuPopover>
  );
};

export default PopoverDialogV1;
