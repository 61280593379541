import { configureStore } from '@reduxjs/toolkit';
import { persistStore } from 'redux-persist';

import realtimeDataMiddleware from '~/store/middleware/realtimeData';

import applicantsReducer from './features/applicants';
import applicantCountReducer from './features/applicant-count-slice';
import applicantDetailFreeplanPersistReducer from './features/applicant-detail-freeplan-persist-slice';
import applicantDetailReducer from './features/applicant-detail-slice';
import authReducer from './features/auth-slice';
import candidateRecommendationDetailReducer from './features/candidate-recommendation-detail-slice';
import candidateRecommendationListReducer from './features/candidate-recommendation-list-slice';
import candidateRecommendationVacancyReducer from './features/candidate-recommendation-vacancy-slice';
import companyAccManagerReducer from './features/company-acc-manager-slice';
import companyReducer from './features/company-slice';
import dashboardReducer from './features/dashboard-slice';
import firebaseRemoteConfigReducer from './helpers/firebase-remote-config-slice';
import jobApplicationHistoryLabelsReducer from './features/job-applicant-history-label-slice';
import menusReducer from './features/menus-slice';
import optionReducer from './features/option-slice';
import userConfigReducer from './features/user-config-slice';
import userReducer from './features/user-slice';
import userSubscriptionReducer from './features/user-subscription-slice';
import vacancyDetailReducer from './features/vacancy-detail-slice';
import vacancyDetailV2Reducer from './features/vacancy-detail-v2-slice';
import vacancyReducer from './features/vacancy-slice';
import preloadedImagesReducer from './helpers/preloaded-images-slice';
import refreshCountReducer from './helpers/refresh-count-slice';
import coachMarkReducer from './views/coach-mark-slice';
import dialogsReducer from './views/dialogs-slice';
import authenticatedDialogsReducer from './views/authenticated-dialog-slice';
import onboardingReducer from './views/onboarding-slice';
import sideMenuReducer from './views/side-menu-slice';
import snackbarReducer from './views/snackbar-slice';
import snackbarPersistReducer from './views/snackbar-persist-slice';
import visibilityReducer from './views/visibility-slice';
import bannerFreeTrialReducer from './views/banner-free-trial-slice';
import bannerReducer from './views/banner-slice';
import vacancyOptionsReducer from './options/vacancy-options-slice';
import applicationOptionsReducer from './options/application-options-slice';
import commonOptionsReducer from './options/common-options-slice';
import screeningFeatureFlagReducer from './views/screening-feature-flag-slice';
import realtimeDataReducer from './features/realtime-data-slice';
import interviewSessionsReducer from './features/interview-sessions-list-slice';
import interviewSessionListDetailReducer from './features/interview-sessions-list-detail-slice';

export const store = configureStore({
  reducer: {
    applicants: applicantsReducer,
    applicantCount: applicantCountReducer,
    applicantDetailFreeplanPersist: applicantDetailFreeplanPersistReducer,
    applicantDetail: applicantDetailReducer,
    auth: authReducer,
    banner: bannerReducer,
    bannerFreeTrial: bannerFreeTrialReducer,
    candidateRecommendationDetail: candidateRecommendationDetailReducer,
    candidateRecommendationVacancy: candidateRecommendationVacancyReducer,
    candidateRecommendationList: candidateRecommendationListReducer,
    coachMark: coachMarkReducer,
    company: companyReducer,
    companyAccManager: companyAccManagerReducer,
    dashboard: dashboardReducer,
    dialogs: dialogsReducer,
    authenticatedDialogs: authenticatedDialogsReducer,
    jobApplicationHistoryLabels: jobApplicationHistoryLabelsReducer,
    menus: menusReducer,
    onboarding: onboardingReducer,
    option: optionReducer,
    preloadedImages: preloadedImagesReducer,
    refreshCount: refreshCountReducer,
    user: userReducer,
    userConfig: userConfigReducer,
    userSubscription: userSubscriptionReducer,
    sideMenu: sideMenuReducer,
    snackbar: snackbarReducer,
    snackbarPersist: snackbarPersistReducer,
    vacancy: vacancyReducer,
    vacancyDetail: vacancyDetailReducer,
    vacancyDetailV2: vacancyDetailV2Reducer,
    visibility: visibilityReducer,
    vacancyOptions: vacancyOptionsReducer,
    applicationOptions: applicationOptionsReducer,
    commonOptions: commonOptionsReducer,
    firebaseRemoteConfig: firebaseRemoteConfigReducer,
    screeningFeatureFlag: screeningFeatureFlagReducer,
    realtimeData: realtimeDataReducer,
    interviewSessions: interviewSessionsReducer,
    interviewSessionListDetail: interviewSessionListDetailReducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false,
    }).concat(realtimeDataMiddleware);
  },
});

export const persisor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type AppState = ReturnType<typeof store.getState>;
