import { useLazyQuery } from '@apollo/client';
import { useCallback, useEffect } from 'react';

import { QUERY_INTERVIEW_SESSION_DROPDOWN } from '~/graphql/common';
import { useAppDispatch, useAppSelector } from '~/hooks/use-store';
import {
  setInterviewSessions,
  TCommonOptions,
} from '~/store/options/common-options-slice';
import {
  EpInterviewSessionDropdown,
  InterviewSessionDropdownQuery,
  InterviewSessionDropdownQueryVariables,
} from '~/types/graphql/graphql';
import { FETCH_STATUS } from '~/utils/constants';

export const defaultPagination: InterviewSessionDropdownQueryVariables['pagination'] =
  {
    page: 0,
    limit: 10,
  };

export const useInterviewSessionOption = (
  prefetch: boolean,
  defaultVariables: InterviewSessionDropdownQueryVariables,
) => {
  const dispatch = useAppDispatch();
  const { interviewSessions } = useAppSelector((state) => state.commonOptions);

  const [fetchOptions] = useLazyQuery<
    InterviewSessionDropdownQuery,
    InterviewSessionDropdownQueryVariables
  >(QUERY_INTERVIEW_SESSION_DROPDOWN, {
    fetchPolicy: 'no-cache',
  });

  const handleFetchOptions = useCallback(
    async (variables?: Partial<InterviewSessionDropdownQueryVariables>) => {
      try {
        dispatch(setInterviewSessions({ fetchStatus: FETCH_STATUS.PENDING }));

        const { data } = await fetchOptions({
          variables: {
            pagination: defaultPagination,
            ...defaultVariables,
            ...variables,
          },
        });

        // map response to dropdown option type
        const options = data?.interviewSessionDropdown?.list ?? [];
        // remove undefined or null from the list
        const filteredOptions = options.filter(
          (opt) => !!opt,
        ) as EpInterviewSessionDropdown[];
        const mapOptions: TCommonOptions['interviewSessions']['data'] =
          filteredOptions.map((opt) => ({
            value: opt.id,
            label: opt.name,
            additional: opt,
          }));

        dispatch(
          setInterviewSessions({
            fetchStatus: FETCH_STATUS.RESOLVED,
            data: mapOptions,
          }),
        );

        return mapOptions;
      } catch (err) {
        dispatch(setInterviewSessions({ fetchStatus: FETCH_STATUS.FAILED }));
      }
    },
    [JSON.stringify(defaultVariables)],
  );

  useEffect(() => {
    prefetch &&
      handleFetchOptions({
        pagination: defaultPagination,
        ...defaultVariables,
      });
  }, [prefetch, JSON.stringify(defaultVariables)]);

  return {
    options: interviewSessions.data,
    handleFetchOptions,
    isLoading:
      interviewSessions.fetchStatus !== FETCH_STATUS.RESOLVED &&
      interviewSessions.fetchStatus !== FETCH_STATUS.FAILED,
  };
};
