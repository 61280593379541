import { AdapterDateFns, LocalizationProvider } from '@kitalulus/web-ui-kit';
import id from 'date-fns/locale/id';

import { CustomDateLocalizationProps } from './';

const CustomDateLocalization = (props: CustomDateLocalizationProps) => {
  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={id}
      {...props}
    />
  );
};

export default CustomDateLocalization;
