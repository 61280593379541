import {
  Box,
  brandColors,
  Image,
  Stack,
  Typography,
} from '@kitalulus/web-ui-kit';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Instagram from '~/components/Icons/Instagram';
import TikTok from '~/components/Icons/TikTok';
import X from '~/components/Icons/X';
import LinkedIn from '~/components/Icons/LinkedIn';
import { kitalulusLink } from '~/utils/kitalulus-link';
import AuthLayoutCopyRight from '~/components/AuthLayoutV2/AuthLayoutCopyRight';
import {
  AuthLayoutBannerProps,
  AuthLayoutBannerContact,
} from './AuthLayoutBanner.types';

const contacts: AuthLayoutBannerContact[] = [
  {
    key: 'instagram',
    logo: Instagram,
    link: kitalulusLink.instagram,
    dataTestId: 'lnAuthBannerInstagram',
  },
  {
    key: 'tikTok',
    logo: TikTok,
    link: kitalulusLink.tikTok,
    dataTestId: 'lnAuthBannerTikTok',
  },
  {
    key: 'x',
    logo: X,
    link: kitalulusLink.x,
    dataTestId: 'lnAuthBannerX',
  },
  {
    key: 'linkedIn',
    logo: LinkedIn,
    link: kitalulusLink.linkedIn,
    dataTestId: 'lnAuthBannerLinkedIn',
  },
];

const AuthLayoutBanner = ({
  bannerVariant = 'default',
  authLayoutCopyRight,
  ...props
}: AuthLayoutBannerProps) => {
  const { t } = useTranslation();

  const bannerTitle = useMemo(() => {
    if (bannerVariant === 'signup') {
      return (
        <Stack textAlign="center">
          <Typography
            width="100%"
            color={brandColors.primary[800]}
            fontWeight={600}
            fontSize="32px"
          >
            {t('auth:bannerTitle.signUp')}
          </Typography>
          <Typography
            width="100%"
            color={brandColors.primary[800]}
            fontWeight={600}
            fontSize="24px"
          >
            <div
              dangerouslySetInnerHTML={{
                __html: t('auth:bannerTitle.signUpSubtitle'),
              }}
            />
          </Typography>
        </Stack>
      );
    }
    return (
      <Typography
        textAlign="center"
        width="100%"
        color={brandColors.primary[800]}
        fontWeight={600}
        fontSize="32px"
      >
        {t('auth:bannerTitle.default')}
      </Typography>
    );
  }, [bannerVariant]);

  return (
    <Box
      data-test-id="ctAuthBanner"
      display={{ xs: 'none', lg: 'flex' }}
      alignItems="center"
      justifyContent="center"
      bgcolor="grey.100"
      width="50%"
      {...props}
    >
      <Stack spacing={4} alignItems="center" width="525px">
        {bannerTitle}

        <Image
          data-test-id="imgAuthBannerIllustration"
          src="/illustrations/auth-2-illustration.webp"
          alt="Illustration"
          sx={{ width: '440px', alignSelf: 'flex-end' }}
        />

        <Stack spacing={1} alignItems="flex-end" width="100%">
          {/* Contact list */}
          <Typography variant="subtitle2">{t('auth:findUsOn')}:</Typography>
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            color={brandColors.grey[700]}
          >
            {contacts.map((c) => (
              <a
                key={c.key}
                data-test-id={c.dataTestId}
                href={c.link}
                target="_blank"
                rel="noreferrer"
                style={{
                  color: 'inherit',
                  height: 24,
                  width: 24,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <c.logo
                  color="inherit"
                  sx={{ height: 16, width: 16, color: 'text.primary' }}
                />
              </a>
            ))}
          </Stack>
        </Stack>

        <Box width="100%" alignItems="flex-end" textAlign="right">
          <AuthLayoutCopyRight {...authLayoutCopyRight} />
        </Box>
      </Stack>
    </Box>
  );
};

export default AuthLayoutBanner;
