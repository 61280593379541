import { useLazyQuery, useMutation } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';

import { MUTATION_UPDATE_USER, QUERY_USER } from '~/graphql/user';
import { useAuthValue } from '~/hooks/use-auth';
import { UserState, setUser } from '~/store/features/user-slice';
import { refresh } from '~/store/helpers/refresh-count-slice';
import { setSnackbar } from '~/store/views/snackbar-slice';
import { Nullable } from '~/types';
import {
  EpUserRegistrationStatus,
  EpUserUpdateInput,
  UpdateUserMutation,
  UpdateUserMutationVariables,
  UserQuery,
} from '~/types/graphql/graphql';
import { defaultGlobalDataContext } from '~/utils/apollo-request';
import { useAppDispatch, useAppSelector } from './use-store';
import { useUserOnboarding } from './use-user-onboarding';
import { FETCH_STATUS } from '~/utils/constants';

export const useUser = () => {
  const { isAuthenticated } = useAuthValue();

  const dispatch = useAppDispatch();
  const userRefreshCount = useAppSelector((state) => state.refreshCount.user);
  const { onboarding, handleNextOnboarding } = useUserOnboarding();

  const [getUser, { loading }] = useLazyQuery<UserQuery>(QUERY_USER, {
    fetchPolicy: 'no-cache',
    context: defaultGlobalDataContext,
    onCompleted: ({ user }) => {
      const data = {
        ...user,
        fetchStatus: FETCH_STATUS.RESOLVED,
      } as UserState;

      dispatch(setUser(data));

      // start company form onboarding if user's registration status is not complete
      if (data?.registrationStatus !== EpUserRegistrationStatus.NotComplete)
        return;
      // skip start or next onboarding on there's already current step
      // to prevent resetting onboarding step every page refreshed
      if (onboarding.currentStep) return;
      handleNextOnboarding();
    },
  });

  useEffect(() => {
    if (!isAuthenticated) return;
    dispatch(setUser({ fetchStatus: FETCH_STATUS.PENDING } as UserState));
    getUser();
  }, [isAuthenticated, userRefreshCount]);

  return { isLoading: loading };
};

export const useUpdateUser = () => {
  const dispatch = useAppDispatch();
  const [error, setError] = useState<Nullable<string>>();
  const [update, { loading }] = useMutation<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >(MUTATION_UPDATE_USER, {
    onCompleted: ({ updateUser }) => {
      if (!updateUser) return;
      const { isSuccess, error, data } = updateUser;

      if (isSuccess) {
        dispatch(refresh('user'));
        dispatch(
          setSnackbar({
            layout: 'dashboard',
            severity: 'success',
            message: data,
          }),
        );
      }

      if (error) {
        setError(error);
        dispatch(
          setSnackbar({
            layout: 'dashboard',
            severity: 'error',
            message: error,
          }),
        );
      }
    },
  });

  const handleUpdate = useCallback((payload: EpUserUpdateInput) => {
    setError(null);
    update({ variables: { data: payload } });
  }, []);

  return {
    handleUpdate,
    isLoading: loading,
    error,
  };
};
