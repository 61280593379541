export type GenerateDateHoursTime = {
  hours?: number;
  minutes?: number;
  seconds?: number;
  miliseconds?: number;
};

export const generateDateHours = (
  time: GenerateDateHoursTime,
  d = new Date(),
) => {
  const date = new Date(d);
  date.setHours(time.hours ?? 0);
  date.setMinutes(time.minutes ?? 0);
  date.setSeconds(time.seconds ?? 0);
  date.setMilliseconds(time.miliseconds ?? 0);
  return date;
};
