import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Nullable } from '~/types';
import { EpJobApplicationCountData } from '~/types/graphql/graphql';

export type ApplicantCountSlice = Nullable<EpJobApplicationCountData>;

export const applicantCountSlice = createSlice({
  name: 'applicantCount',
  initialState: null as ApplicantCountSlice,
  reducers: {
    setApplicantCount: (_, action: PayloadAction<ApplicantCountSlice>) =>
      action.payload,
    clearApplicantCount: () => null,
  },
});

export const { setApplicantCount, clearApplicantCount } =
  applicantCountSlice.actions;

export default applicantCountSlice.reducer;
