import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Nullable } from '~/types';
import { EpDashboardVacancySummaryResponse } from '~/types/graphql/graphql';
import { FETCH_STATUS } from '~/utils/constants';

export type TypeDashboardState = {
  summaryCount: {
    fetchStatus: FETCH_STATUS;
    totalNew: number;
    totalPending: number;
  };
  summaryList: {
    fetchStatus: FETCH_STATUS;
    data: Nullable<EpDashboardVacancySummaryResponse>;
  };
};

const initialState: TypeDashboardState = {
  summaryCount: {
    fetchStatus: FETCH_STATUS.IDLE,
    totalNew: 0,
    totalPending: 0,
  },
  summaryList: {
    fetchStatus: FETCH_STATUS.IDLE,
    data: null,
  },
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setDashboardSummaryCount: (
      state,
      action: PayloadAction<Partial<TypeDashboardState['summaryCount']>>,
    ) => {
      return {
        ...state,
        summaryCount: {
          ...state.summaryCount,
          ...action.payload,
        },
      };
    },
    setDashboardSummaryList: (
      state,
      action: PayloadAction<Partial<TypeDashboardState['summaryList']>>,
    ) => {
      state.summaryList = {
        ...state.summaryList,
        ...action.payload,
      };
    },
    clearDashboard: () => initialState,
  },
});

export const {
  setDashboardSummaryCount,
  setDashboardSummaryList,
  clearDashboard,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
