import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Label,
  Paper,
  Stack,
  Typography,
  useTheme,
} from '@kitalulus/web-ui-kit';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CheckCircleIcon from '~/components/Icons/CheckCircleIcon';
import EmailIcon from '~/components/Icons/EmailIcon';
import LocationIcon from '~/components/Icons/LocationIcon';
import { SpamIcon } from '~/components/Icons/SpamIcon';
import WhatssappIcon from '~/components/Icons/WhatsappIcon';
import LoadingButton from '~/components/LoadingButton';
import TakeNotesArea from '~/components/TakeNotesArea';
import TruncateText from '~/components/TruncateText';
import { useApplicantDetailMutate } from '~/hooks/applicant-detail';
import { useAppDispatch, useAppSelector } from '~/hooks/use-store';
import { clearDialog } from '~/store/views/dialogs-slice';
import { EpJobApplicationEpStatusEnum } from '~/types/graphql/graphql';
import Analytics, { ANALYTICS_EVENTS } from '~/utils/analytics';
import { truncateText } from '~/utils/truncateText';

import { RemarksDialogProps } from './RemarksDialog.types';

const RemarksDialog = ({ content, ...props }: RemarksDialogProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { applicantDetail, vacancyDetail } = useAppSelector((state) => state);
  const { data: dataApplicantDetail } = applicantDetail;

  const { isLoading, doHandleUpdateRemarks, remarksState, handleCopy } =
    useApplicantDetailMutate(dataApplicantDetail?.id ?? '');
  const { control, resetField, getValues, watch } = remarksState;

  const handleClose = useCallback(() => {
    dispatch(clearDialog('remarksDialog'));
  }, []);

  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (getValues('remarksNote')?.length) return setDisabled(false);
    return setDisabled(true);
  }, [watch('remarksNote')]);

  return (
    <Dialog {...props} fullWidth maxWidth="md">
      <Grid container>
        <Grid item xs={12}>
          <DialogTitle>
            <Stack direction="row" alignItems="center" gap={1}>
              {content?.status === EpJobApplicationEpStatusEnum.Accepted && (
                <CheckCircleIcon sx={{ m: '8px' }} color="success" />
              )}
              {content?.status === EpJobApplicationEpStatusEnum.Rejected && (
                <SpamIcon sx={{ m: '8px' }} color="error" />
              )}
              <Typography variant="h6" component="span">
                {t(
                  `applicant-detail:remarks.${
                    content?.status === EpJobApplicationEpStatusEnum.Accepted
                      ? 'accept'
                      : 'reject'
                  }.title`,
                )}
              </Typography>
            </Stack>
          </DialogTitle>
          <DialogContent>
            <Stack gap={5}>
              <Paper
                sx={{
                  padding: theme.spacing(3),
                  backgroundColor: 'grey.200',
                  boxShadow: theme.customShadows.card,
                }}
              >
                <Stack
                  sx={{
                    flexDirection: {
                      xs: 'column',
                      sm: 'row',
                    },
                    alignItems: {
                      xs: 'flex-start',
                      sm: 'center',
                    },
                  }}
                  justifyContent="space-between"
                  gap={2}
                >
                  <Stack direction="row" alignItems="center" gap={2}>
                    <Avatar
                      data-test-id="imgApplicantChangeStatusAvatar"
                      sx={{
                        width: theme.spacing(6),
                        height: theme.spacing(6),
                      }}
                      src={dataApplicantDetail?.userProfile?.imageUrl ?? ''}
                      displayName={'test'}
                    />
                    <Stack>
                      <TruncateText noOfLines={1}>
                        <Typography
                          data-test-id="lbApplicantChangeStatusModalName"
                          variant="subtitle2"
                          pl={0.65}
                        >
                          {dataApplicantDetail?.userProfile?.name ?? '-'}
                        </Typography>
                      </TruncateText>
                      <Stack direction="row" alignItems="center" gap={1}>
                        <LocationIcon
                          sx={{ color: 'text.secondary', fontSize: '18px' }}
                        />
                        <Typography
                          data-test-id="lbApplicantChangeStatusModalLocation"
                          variant="body2"
                          color="text.secondary"
                        >
                          {dataApplicantDetail?.userProfile?.city &&
                          dataApplicantDetail?.userProfile?.province
                            ? `${dataApplicantDetail?.userProfile?.city?.name}, ${dataApplicantDetail?.userProfile?.province?.name}`
                            : '-'}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                  <Stack
                    sx={{
                      flexDirection: {
                        xs: 'column',
                        sm: 'row',
                      },
                      alignItems: {
                        xs: 'center',
                      },
                      width: {
                        xs: '100%',
                        sm: 'unset',
                      },
                    }}
                    gap={2}
                  >
                    <Typography variant="body2">Untuk posisi</Typography>

                    <Label
                      data-test-id="lbApplicantChangeStatusModalPosition"
                      sx={{
                        padding: `${theme.spacing(2)} ${theme.spacing(1.75)}`,
                      }}
                      variant="outlined"
                      color="info"
                    >
                      {truncateText(vacancyDetail?.positionName ?? '', 20)}
                    </Label>
                  </Stack>
                </Stack>
              </Paper>
              <TakeNotesArea
                name="remarksNote"
                control={control}
                resetField={resetField}
                defaultValue={''}
                placeholder={t('applicant-detail:remarksPlaceholder')}
                handleCopy={() => handleCopy(getValues('remarksNote'))}
                maximumLength={1000}
              />
            </Stack>
          </DialogContent>
          <DialogActions
            sx={{
              justifyContent: 'center',
            }}
          >
            <Stack justifyContent={'center'} gap={2.25}>
              <LoadingButton
                data-test-id="btnApplicationStatusChangeEmailNotification"
                color={'primary'}
                variant="contained"
                startIcon={<EmailIcon sx={{ width: '15px' }} />}
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  textTransform: 'none',
                }}
                onClick={() => {
                  if (content?.status === EpJobApplicationEpStatusEnum.Accepted)
                    Analytics.sendEvent(
                      ANALYTICS_EVENTS.APPLICANT_ACCEPT_EMAIL,
                    );
                  if (content?.status === EpJobApplicationEpStatusEnum.Rejected)
                    Analytics.sendEvent(
                      ANALYTICS_EVENTS.APPLICANT_REJECT_EMAIL,
                    );
                  doHandleUpdateRemarks('email', content?.status);
                }}
                disabled={disabled || isLoading}
                loading={isLoading}
              >
                {t(
                  `applicant-detail:remarks.${
                    content?.status === EpJobApplicationEpStatusEnum.Accepted
                      ? 'accept'
                      : 'reject'
                  }.emailButton`,
                )}
              </LoadingButton>
              <LoadingButton
                data-test-id="btnApplicationStatusChangeWhatsappNotification"
                color={'success'}
                variant="contained"
                startIcon={<WhatssappIcon sx={{ width: '15px' }} />}
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  textTransform: 'none',
                }}
                onClick={() => {
                  if (content?.status === EpJobApplicationEpStatusEnum.Accepted)
                    Analytics.sendEvent(
                      ANALYTICS_EVENTS.APPLICANT_ACCEPT_WHATSAPP,
                    );
                  if (content?.status === EpJobApplicationEpStatusEnum.Rejected)
                    Analytics.sendEvent(
                      ANALYTICS_EVENTS.APPLICANT_REJECT_WHATSAPP,
                    );
                  doHandleUpdateRemarks('wa', content?.status);
                }}
                disabled={disabled || isLoading}
                loading={isLoading}
              >
                {t(
                  `applicant-detail:remarks.${
                    content?.status === EpJobApplicationEpStatusEnum.Accepted
                      ? 'accept'
                      : 'reject'
                  }.whatsAppButton`,
                )}
              </LoadingButton>
              <LoadingButton
                data-test-id="btnApplicationStatusChangeNoNotification"
                variant="contained"
                startIcon={<CheckCircleIcon sx={{ width: '15px' }} />}
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  backgroundColor: 'primary.semilight',
                  textTransform: 'none',
                }}
                onClick={() => {
                  if (content?.status === EpJobApplicationEpStatusEnum.Accepted)
                    Analytics.sendEvent(ANALYTICS_EVENTS.APPLICANT_ACCEPT_SKIP);
                  if (content?.status === EpJobApplicationEpStatusEnum.Rejected)
                    Analytics.sendEvent(ANALYTICS_EVENTS.APPLICANT_REJECT_SKIP);
                  doHandleUpdateRemarks('skip', content?.status);
                }}
                disabled={isLoading}
                loading={isLoading}
              >
                {t(
                  `applicant-detail:remarks.${
                    content?.status === EpJobApplicationEpStatusEnum.Accepted
                      ? 'accept'
                      : 'reject'
                  }.nowButton`,
                )}
              </LoadingButton>
              <Button
                data-test-id="btnApplicationStatusChangeCancel"
                autoFocus
                color="inherit"
                onClick={handleClose}
                variant="outlined"
                sx={{
                  textTransform: 'none',
                }}
              >
                {t(
                  `applicant-detail:remarks.${
                    content?.status === EpJobApplicationEpStatusEnum.Accepted
                      ? 'accept'
                      : 'reject'
                  }.cancelButton`,
                )}
              </Button>
            </Stack>
          </DialogActions>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default RemarksDialog;
