import {
  Box,
  BoxProps,
  Label,
  Paper,
  PaperProps,
  Stack,
  Typography,
  TypographyProps,
  useTheme,
} from '@kitalulus/web-ui-kit';
import { ReactNode } from 'react';

export type CardSkillProps = {
  paperProps?: PaperProps;
  title: string;
  boxTitleProps?: BoxProps;
  skills: string[];
  skillProps?: TypographyProps;
  emptyState?: string | ReactNode;
};

const CardSkill = ({
  paperProps,
  title,
  boxTitleProps,
  skills,
  skillProps,
  emptyState,
}: CardSkillProps) => {
  const theme = useTheme();

  const renderSkills = () => {
    if (!skills.length) {
      if (emptyState) {
        return (
          <Typography variant="body2" color="text.secondary">
            {emptyState}
          </Typography>
        );
      }
      return '-';
    }

    return skills.map((skill) => {
      return (
        <Label
          key={skill}
          sx={{
            borderRadius: '100px',
            p: '15px',
            whiteSpace: 'pre-wrap',
            height: 'auto',
            paddingY: '4px',
          }}
        >
          <Typography variant="body2" color="text.primary" {...skillProps}>
            {skill}
          </Typography>
        </Label>
      );
    });
  };

  return (
    <Paper
      sx={{
        borderRadius: theme.spacing(2),
        padding: theme.spacing(3),
        overflowWrap: 'anywhere',
        boxShadow: theme.customShadows['card'],
      }}
      {...paperProps}
    >
      <Box
        sx={{
          borderTopLeftRadius: theme.spacing(2),
          borderTopRightRadius: theme.spacing(2),
          ...boxTitleProps?.sx,
        }}
      >
        <Typography variant="h6">{title}</Typography>
      </Box>
      <Stack
        paddingTop={theme.spacing(4)}
        direction="row"
        alignItems="center"
        gap={1}
        flexWrap="wrap"
      >
        {renderSkills()}
      </Stack>
    </Paper>
  );
};

export default CardSkill;
