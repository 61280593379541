import { SvgIcon, SvgIconProps } from '@kitalulus/web-ui-kit';

const ConsFaceUnlockIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 16 16" {...props}>
      <g>
        <path d="M6.83301 8.66634C6.83301 9.12634 6.45967 9.49967 5.99967 9.49967C5.53967 9.49967 5.16634 9.12634 5.16634 8.66634C5.16634 8.20634 5.53967 7.83301 5.99967 7.83301C6.45967 7.83301 6.83301 8.20634 6.83301 8.66634ZM9.99967 7.83301C9.53967 7.83301 9.16634 8.20634 9.16634 8.66634C9.16634 9.12634 9.53967 9.49967 9.99967 9.49967C10.4597 9.49967 10.833 9.12634 10.833 8.66634C10.833 8.20634 10.4597 7.83301 9.99967 7.83301ZM14.6663 7.99967C14.6663 11.6797 11.6797 14.6663 7.99967 14.6663C4.31967 14.6663 1.33301 11.6797 1.33301 7.99967C1.33301 4.31967 4.31967 1.33301 7.99967 1.33301C11.6797 1.33301 14.6663 4.31967 14.6663 7.99967ZM7.10634 2.74634C8.03967 4.29301 9.73301 5.33301 11.6663 5.33301C11.973 5.33301 12.273 5.29967 12.5597 5.25301C11.6263 3.70634 9.93301 2.66634 7.99967 2.66634C7.69301 2.66634 7.39301 2.69967 7.10634 2.74634ZM2.94634 6.31301C4.08634 5.66634 4.96634 4.61301 5.38634 3.35301C4.24634 3.99967 3.36634 5.05301 2.94634 6.31301ZM13.333 7.99967C13.333 7.47967 13.253 6.97967 13.113 6.50634C12.6463 6.60634 12.1663 6.66634 11.6663 6.66634C9.57967 6.66634 7.71967 5.70634 6.49301 4.20634C5.79301 5.91301 4.39967 7.25301 2.66634 7.90634C2.67301 7.93301 2.66634 7.96634 2.66634 7.99967C2.66634 10.9397 5.05967 13.333 7.99967 13.333C10.9397 13.333 13.333 10.9397 13.333 7.99967Z" />
      </g>
    </SvgIcon>
  );
};

export default ConsFaceUnlockIcon;
