import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StateSetterFunc } from '~/types';

import { EpJobVacancy } from '~/types/graphql/graphql';
import { FETCH_STATUS } from '~/utils/constants';

export type CandidateRecommendationVacancyState = {
  vacancyList: {
    fetchStatus: FETCH_STATUS;
    items: EpJobVacancy[];
    noOfPages: number;
    hasNext: boolean;
    hasPrev: boolean;
  };
};

const initialState: CandidateRecommendationVacancyState = {
  vacancyList: {
    fetchStatus: FETCH_STATUS.IDLE,
    items: [],
    noOfPages: 0,
    hasNext: false,
    hasPrev: false,
  },
};

export const candidateRecommendationVacancySlice = createSlice({
  name: 'candidateRecommendationVacancy',
  initialState: initialState,
  reducers: {
    setCandidateRecommendationVacancyList: (
      state,
      action: PayloadAction<
        StateSetterFunc<
          Partial<CandidateRecommendationVacancyState['vacancyList']>
        >
      >,
    ) => {
      const newData =
        typeof action.payload === 'function'
          ? action.payload(state.vacancyList)
          : action.payload;
      state.vacancyList = {
        ...state.vacancyList,
        ...newData,
      };
    },
    clearCandidateRecommendationVacancy: () => initialState,
  },
});

export const {
  setCandidateRecommendationVacancyList,
  clearCandidateRecommendationVacancy,
} = candidateRecommendationVacancySlice.actions;

export default candidateRecommendationVacancySlice.reducer;
