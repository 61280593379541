import {
  Stack,
  Image,
  Box,
  brandColors,
  Typography,
  BoxProps,
} from '@kitalulus/web-ui-kit';
import { appConfig } from '~/config/app';

const AcceptRejectPreview = ({
  text,
  ...props
}: { text: string } & BoxProps) => {
  return (
    <Box
      {...props}
      sx={{
        bgcolor: brandColors.grey[50016],
        p: 2.5,
        maxWidth: 400,
        borderRadius: '16px',
        ...props.sx,
      }}
    >
      <Stack sx={{ bgcolor: '#fff', p: 2, borderRadius: '8px' }}>
        <Image
          src="/kitalulus-logo.svg"
          alt={appConfig.appName}
          sx={{ width: '130px', mb: 3 }}
        />
        <Typography
          component="div"
          variant="body2"
          sx={{ whiteSpace: 'pre-wrap' }}
        >
          <div dangerouslySetInnerHTML={{ __html: text }} />
        </Typography>
      </Stack>
    </Box>
  );
};

export default AcceptRejectPreview;
