import { useLazyQuery } from '@apollo/client';
import { useEffect, useCallback } from 'react';
import {
  QUERY_JOB_APPLICATIONS,
  QUERY_JOB_APPLICATION_SUMMARY,
} from '~/graphql/applicants';
import { DropdownOption } from '~/types';
import {
  EpJobApplication,
  JobApplicationSummaryQuery,
  JobApplicationSummaryQueryVariables,
  JobApplicationsQuery,
  JobApplicationsQueryVariables,
  JobVacanciesQuery,
  JobVacanciesQueryVariables,
} from '~/types/graphql/graphql';
import { useApplicantPageQuery } from './page-query/use-applicant-page-query';
import { useAppDispatch, useAppSelector } from './use-store';
import {
  setApplicantsData,
  setApplicantsDisplayData,
  setApplicantsFetchStatusDataTable,
  setApplicantsFilters,
  setApplicantsIsShowModalFilter,
  setApplicantsSummaryCount,
  setAutocompleteVacancyOptions,
  setAutocompleteVacancyOptionsMore,
  setAutocompleteVacancySelected,
  setApplicantisNoVacancy,
  TypeDisplayData,
  clearApplicantsVacancy,
  setApplicantsError,
  setApplicantFilterTooltip,
} from '~/store/features/applicants';
import debounce from 'lodash.debounce';
import { APPLICANT_TAB_PRIMARY, FETCH_STATUS } from '~/utils/constants';
import { useVacancyDetail } from './use-vacancy-detail';
import { setVacancyDetail } from '~/store/features/vacancy-detail-slice';
import { QUERY_JOB_VACANCIES_ALT } from '~/graphql/vacancy';
import { GRAPHQL_ERROR_CODE } from '~/utils/constants/graphql-error-code';
import useVacancyScreeningQuestionsFilter from './vacancy/use-vacancy-screening-questions-filter';
import useApplicantScreeningQuestionFilter from './applicants/use-applicant-screening-question-filter';
import { TApplicantScreeningQuestionFilterDialogData } from '~/pages/Applicants/ApplicantScreeningQuestionFilterDialog';
import { useRemoteConfigValue } from './firebase/use-remote-config';
import { useRealtimeData } from './firebase/use-realtime-data';
import * as mpe from '~/utils/mixpanel';

export type displayType =
  | (EpJobApplication & {
      isChecked: boolean;
    })
  | null;

const useApplicants = () => {
  const showScreeningQuestion = useRemoteConfigValue(
    'ff_vacancy_screening_question',
  );
  const [urlParams, setUrlParams] = useApplicantPageQuery();
  const { getVacancyDetail } = useVacancyDetail();
  const { getVacancyRealtime } = useRealtimeData(false);
  const { getScreeningQuestionsFilter } = useVacancyScreeningQuestionsFilter();
  const { generateScreeningQuestionPayload, handleApplyFilterCount } =
    useApplicantScreeningQuestionFilter(urlParams.vacancy_id);

  const dispatch = useAppDispatch();

  const { applicants, vacancyDetail } = useAppSelector((state) => state);

  const {
    filterTooltip: { listAutoFilterTooltipInitialCheck },
  } = applicants;

  const isInRecommendation =
    urlParams.active_tab_primary === APPLICANT_TAB_PRIMARY.AI_RECOMMENDATION;

  const baseVariable = {
    epStatus: urlParams.active_tab_secondary,
    tab: urlParams.active_tab_primary,
    lastEducationLevels: urlParams.educations,
    educationInstitutionIds: urlParams.education_institutions?.map(
      (educInstitutions) => educInstitutions.value,
    ),
    gender: urlParams.gender,
    ageMin: urlParams.age_min,
    ageMax: urlParams.age_max,
    name: urlParams.name.toString(),
    sort: urlParams.sort,
    locations: urlParams.location_values.map((loc) => ({
      areaType: loc.additional,
      name: loc.value,
    })),
    minSalary: urlParams.salary_min,
    maxSalary: urlParams.salary_max,
    experienceYear: urlParams.experience_year?.value,
    jobSpecializationRoles: urlParams.job_functions?.map((f) => f.value),
    skillsV2: urlParams.skills?.map((skill) => skill.value),
    isRestrict: urlParams.is_restrict,
  };

  const variableApplicants = {
    ...baseVariable,
    filter: {
      page: Number(urlParams.page),
      limit: Number(urlParams.page_limit),
    },
    jobVacancyId: urlParams.vacancy_id,
    ...(showScreeningQuestion && {
      screeningQuestions: [],
    }),
  };

  const [getVacancySearch] = useLazyQuery<
    JobVacanciesQuery,
    JobVacanciesQueryVariables
  >(QUERY_JOB_VACANCIES_ALT, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ jobVacancies }) => {
      if (!jobVacancies?.list) return;
      const payload = jobVacancies.list.map((item) => {
        return {
          value: item?.id,
          label: item?.positionName,
          additional: {
            code: item?.code,
            createdAt: item?.createdAt,
            closeDate: item?.closeDate,
          },
        };
      });

      dispatch(setAutocompleteVacancyOptionsMore(payload));
    },
  });

  const [queryApplicantsSummary] = useLazyQuery<
    JobApplicationSummaryQuery,
    JobApplicationSummaryQueryVariables
  >(QUERY_JOB_APPLICATION_SUMMARY, {
    fetchPolicy: 'no-cache',
  });

  const getApplicantsSummary = async () => {
    const { data } = await queryApplicantsSummary({
      variables: { ...baseVariable, id: urlParams.vacancy_id },
    });

    const res = data?.jobApplicationSummary;
    if (!res) return;
    dispatch(setApplicantsSummaryCount(res));
  };

  const [queryApplicants] = useLazyQuery<
    JobApplicationsQuery,
    JobApplicationsQueryVariables
  >(QUERY_JOB_APPLICATIONS, {
    fetchPolicy: 'no-cache',
    onError: (error) => {
      const extensionCode = error.graphQLErrors.map(
        (error) => error.extensions?.code,
      );
      const isNotFound = extensionCode.includes(GRAPHQL_ERROR_CODE.NOT_FOUND);
      const errorCode = isNotFound ? GRAPHQL_ERROR_CODE.NOT_FOUND : '';
      dispatch(setApplicantsError(errorCode));
    },
  });

  const getApplicants = async (
    newVars?: Partial<JobApplicationsQueryVariables>,
  ) => {
    dispatch(setApplicantsFetchStatusDataTable(FETCH_STATUS.PENDING));
    const res = await queryApplicants({
      variables: { ...variableApplicants, ...newVars },
    });

    const applicants = res.data?.jobApplications;

    if (!applicants) return;
    const payload = applicants?.list?.map((applicant) => ({
      ...applicant,
      isChecked: false,
    }));
    dispatch(setApplicantsData(applicants));
    dispatch(setApplicantsDisplayData(payload as TypeDisplayData[]));
  };

  const handleParamsUpdate = (
    sqf: TApplicantScreeningQuestionFilterDialogData[],
  ) => {
    if (!urlParams.vacancy_id) return;

    // Don't refetch vacancy if still the same
    if (urlParams.vacancy_id !== vacancyDetail?.id) {
      getScreeningQuestionsFilter(urlParams.vacancy_id);
      getVacancyDetail({
        variables: { id: urlParams.vacancy_id },
        onCompleted: ({ jobVacancy }) => {
          if (jobVacancy?.__typename === 'EpJobVacancy') {
            getVacancyRealtime(jobVacancy?.code || '');
            mpe.applicantChangeVacancy({ vacancyCode: jobVacancy.code ?? '' });

            const payload = {
              value: jobVacancy?.id,
              label: jobVacancy?.positionName,
              additional: {
                code: jobVacancy?.code,
                createdAt: jobVacancy?.createdAt,
                closeDate: jobVacancy?.closeDate,
              },
            };
            dispatch(setVacancyDetail(jobVacancy));
            dispatch(setAutocompleteVacancyOptions(payload));

            const haveRestriction =
              jobVacancy?.restrictions?.city ||
              jobVacancy?.restrictions?.educationLevel;

            // Auto show tooltip
            if (
              !!haveRestriction &&
              listAutoFilterTooltipInitialCheck === false
            ) {
              dispatch(
                setApplicantFilterTooltip({
                  showListAutoFilterTooltip: true,
                }),
              );
            } else {
              dispatch(
                setApplicantFilterTooltip({
                  listAutoFilterTooltipInitialCheck: true,
                }),
              );
            }
          }
        },
      });

      getVacancySearch({
        variables: {
          filter: { limit: 9, page: 0 },
          excludeVacancyId: urlParams.vacancy_id,
        },
      });
    }

    // to remove screening questions form request if toggled off
    if (showScreeningQuestion && !isInRecommendation) {
      handleApplyFilterCount(sqf);
      getApplicants({
        screeningQuestions: generateScreeningQuestionPayload(sqf),
      });
    } else {
      getApplicants();
    }
    getApplicantsSummary();
  };

  const handleInitialLoad = () => {
    if (!urlParams.vacancy_id) {
      // Override on complete
      getVacancySearch({
        variables: {
          filter: { limit: 10, page: 0 },
        },
        onCompleted: ({ jobVacancies }) => {
          if (!jobVacancies?.list) return;
          const payload = jobVacancies.list.map((item) => {
            return {
              value: item?.id,
              label: item?.positionName,
              additional: {
                code: item?.code,
                createdAt: item?.createdAt,
                closeDate: item?.closeDate,
              },
            };
          });

          if (jobVacancies.elements) {
            setUrlParams(
              {
                vacancy_id: payload[0].value ?? '',
              },
              { replace: true },
            );
            dispatch(setAutocompleteVacancyOptionsMore(payload));
          } else {
            // no vacancy
            dispatch(setApplicantisNoVacancy(true));
          }
        },
      });
    } else {
      getVacancyDetail({
        variables: { id: urlParams.vacancy_id },
        onCompleted: ({ jobVacancy }) => {
          if (jobVacancy?.__typename === 'EpJobVacancy') {
            const payload = {
              value: jobVacancy?.id,
              label: jobVacancy?.positionName,
              additional: {
                code: jobVacancy?.code,
                createdAt: jobVacancy?.createdAt,
                closeDate: jobVacancy?.closeDate,
              },
            };
            if (
              jobVacancy?.restrictions?.city ||
              jobVacancy?.restrictions?.educationLevel
            ) {
              dispatch(
                setApplicantFilterTooltip({
                  showListAutoFilterTooltip: true,
                }),
              );
            }
            dispatch(setVacancyDetail(jobVacancy));
            dispatch(setAutocompleteVacancyOptions(payload));

            getVacancyRealtime(jobVacancy?.code || '');
          }
        },
      });
      getApplicants();
      getApplicantsSummary();
    }
  };

  useEffect(() => {
    dispatch(setApplicantsFilters({ name: urlParams.name }));
  }, [urlParams.name]);

  const doCloseModalFilter = useCallback(() => {
    dispatch(setApplicantsIsShowModalFilter(false));
  }, []);

  const doSelectedVacancy = (payload: DropdownOption) => {
    dispatch(setAutocompleteVacancySelected(payload));
  };

  const doSearchVacancy = useCallback(
    debounce((name: string) => {
      getVacancySearch({
        variables: {
          filter: { limit: 9, page: 0 },
          excludeVacancyId: urlParams.vacancy_id,
          name,
        },
      });
    }, 500),
    [],
  );

  const doCheckUnCheckRowApplicant = useCallback(
    (payload: TypeDisplayData[]) => {
      dispatch(setApplicantsDisplayData(payload));
    },
    [],
  );

  const handleCheckUnCheckRow = (
    event: React.FormEvent<EventTarget>,
    applicantId: string,
  ) => {
    event.stopPropagation();
    const payload = applicants.displayData.map((data) =>
      data.id === applicantId ? { ...data, isChecked: !data.isChecked } : data,
    );

    doCheckUnCheckRowApplicant(payload);
  };

  const doClearApplicantVacancy = () => {
    setUrlParams({ name: '' });
    dispatch(clearApplicantsVacancy());
  };

  return {
    applicants,
    getApplicants,
    getApplicantsSummary,
    doCloseModalFilter,
    doCheckUnCheckRowApplicant,
    handleCheckUnCheckRow,
    handleInitialLoad,
    handleParamsUpdate,
    doSearchVacancy,
    doSelectedVacancy,
    doClearApplicantVacancy,
    vacancyDetail,
  };
};

export default useApplicants;
