import { useCallback } from 'react';
import { useAppSelector } from '../use-store';
import { FETCH_STATUS } from '~/utils/constants';
import { useRemoteConfigValue } from '../firebase/use-remote-config';

const useApplicantDialog = () => {
  const { applicants, applicantDetail } = useAppSelector((state) => state);
  const isUseApplicantUpdateStatusV2Active = useRemoteConfigValue(
    'ff_use_applicant_update_status_v2',
  );

  const isApplicantMutating =
    applicants.mutationStatusUpdateApplicantsStatus === FETCH_STATUS.PENDING;

  const checkIsLoading = useCallback(
    (isSingleUpdate: boolean) => {
      if (isUseApplicantUpdateStatusV2Active) {
        return (
          (isApplicantMutating && isSingleUpdate) ||
          applicantDetail.isUpdatingStatus
        );
      }
      return isApplicantMutating || applicantDetail.isUpdatingStatus;
    },
    [isApplicantMutating, applicantDetail.isUpdatingStatus],
  );
  return { checkIsLoading };
};

export default useApplicantDialog;
