import { onError } from '@apollo/client/link/error';

import { store } from '~/store';
import { setSnackbar } from '~/store/views/snackbar-slice';
import { logout } from '~/utils/auth/helper';
import i18n from '~/utils/i18n';
import { GRAPHQL_ERROR_CODE } from '~/utils/constants/graphql-error-code';
import * as mpe from '~/utils/mixpanel';
import { setSnackbarPersist } from '~/store/views/snackbar-persist-slice';

const errorLink = onError(({ graphQLErrors, operation }) => {
  if (!graphQLErrors) return;

  const accessToken = store.getState().auth.accessToken;

  // get context data
  const errorContext = operation.getContext().error ?? {};
  const retryContext = operation.getContext().retry;

  const isQueryRefreshToken = operation.operationName === 'refreshTokenV2';
  const isQueryJobSpecializationRoles =
    operation.operationName === 'jobSpecializationRoles';

  const extensionCode = graphQLErrors.map((error) => error.extensions.code);
  const hiddenErrorAlertSpecialCase =
    extensionCode.includes(GRAPHQL_ERROR_CODE.NOT_FOUND) ||
    isQueryRefreshToken ||
    isQueryJobSpecializationRoles;

  // if the context has retry
  // pending the alert till the retry context is empty (no attempts left)
  const specifiedShowErrorAlert = errorContext.showErrorAlert ?? true;
  const showErrorAlert = retryContext ? false : specifiedShowErrorAlert;

  if (isQueryRefreshToken) {
    store.dispatch(
      setSnackbarPersist({
        severity: 'info',
        message: i18n.t('common:refreshTokenError').toString(),
      }),
    );
  }

  // optionally show error alert
  if (showErrorAlert && !hiddenErrorAlertSpecialCase) {
    store.dispatch(
      setSnackbar({
        severity: 'error',
        message:
          graphQLErrors[0].message ||
          i18n.t('common:technicalProblem').toString(),
      }),
    );
  }

  if (!accessToken) return;
  // if server return 403 on authorized user, force logout the user
  for (const err of graphQLErrors) {
    if (
      err.extensions.httpStatusCode === 403 ||
      err.extensions.response?.status === 403 ||
      err.extensions.code === GRAPHQL_ERROR_CODE.FORBIDDEN
    ) {
      mpe.logout({
        logoutType: 'refresh token',
        logoutLocation: null,
      });
      logout();
      window.location.reload();
    }
  }
});

export default errorLink;
