import {
  Box,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@kitalulus/web-ui-kit';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import InterviewInvitationFormInfoAlert from '../../InterviewInvitationFormInfoAlert';
import InterviewInvitationFormPreview from '../../InterviewInvitationFormPreview';
import { InterviewInvitationFormContext } from '../../';
import { InterviewInvitationFormFourthStepProps } from '.';

const InterviewInvitationFormFourthStep = (
  props: InterviewInvitationFormFourthStepProps,
) => {
  const { t } = useTranslation();
  const { secondStepFormState, thirdStepFormState } = useContext(
    InterviewInvitationFormContext,
  );

  // responsive states
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // form value states
  const schedule = secondStepFormState.watch('schedule');
  const scheduleStartTime = secondStepFormState.watch('scheduleStartTime');
  const scheduleEndTime = secondStepFormState.watch('scheduleEndTime');
  const type = secondStepFormState.watch('type');
  const offlineDetail = secondStepFormState.watch('offlineDetail');
  const recruiterMessage = thirdStepFormState.watch('recruiterMessage');

  return (
    <Stack gap={3} width="100%" {...props}>
      {isMobile && <InterviewInvitationFormInfoAlert />}

      <Stack gap={0.25}>
        <Typography variant="subtitle1">
          {t('interview-invitation-form:previewTitle')}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {t('interview-invitation-form:previewSubtitle')}
        </Typography>
      </Stack>

      {/* Preview box */}
      <Box
        fontFamily="Source Sans Pro"
        p={isMobile ? 1.5 : 3}
        alignSelf="center"
        sx={{
          width: '100%',
          maxWidth: '420px',
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: 'divider',
          borderRadius: '16px',
        }}
      >
        <InterviewInvitationFormPreview
          interviewInvitation={{
            schedule,
            scheduleStartTime,
            scheduleEndTime,
            type,
            offlineDetail,
            recruiterMessage,
          }}
          sx={{
            boxShadow: '0px 12px 40px 0px rgba(0, 0, 0, 0.15)',
            borderRadius: '16px',
          }}
        />
      </Box>
    </Stack>
  );
};

export default InterviewInvitationFormFourthStep;
