import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type BannerState = {
  show: boolean;
  height: number;
};

export const initialState: BannerState = {
  show: false,
  height: 0,
};

export const bannerSlice = createSlice({
  name: 'banner',
  initialState: initialState,
  reducers: {
    setBanner: (state, action: PayloadAction<Partial<BannerState>>) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const { setBanner } = bannerSlice.actions;

export default bannerSlice.reducer;
