import { Box } from '@kitalulus/web-ui-kit';
import { useCallback, useEffect, useState } from 'react';

import Snackbar from '~/components/Snackbar';
import { useAppDispatch, useAppSelector } from '~/hooks/use-store';
import { SnackbarPersistProps } from './SnackbarPersist.types';
import { clearSnackbarPersist } from '~/store/views/snackbar-persist-slice';

const SnackbarPersist = ({ children, ...props }: SnackbarPersistProps) => {
  const dispatch = useAppDispatch();
  const snackbarPersist = useAppSelector((state) => state.snackbarPersist);

  const [isOpen, setIsOpen] = useState(!!snackbarPersist?.message);
  useEffect(
    () => setIsOpen(!!snackbarPersist?.message),
    [snackbarPersist?.message],
  );

  const handleClose = useCallback(() => {
    if (!snackbarPersist) return;
    setIsOpen(false);
    setTimeout(() => {
      dispatch(clearSnackbarPersist());
    }, 300);
  }, [snackbarPersist]);

  return (
    <Box position="relative" {...props}>
      <Snackbar
        message=""
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        disableWindowBlurListener
        showIcon
        closeable
        autoHideDuration={5000}
        open={isOpen}
        {...snackbarPersist}
        onClose={(_, reason) => {
          if (reason === 'clickaway') return;
          snackbarPersist?.onClose
            ? snackbarPersist.onClose(handleClose)
            : handleClose();
        }}
        sx={{
          mt: '0 !important',
          left: {
            xs: '8px',
            sm: '50%',
            lg: `calc(50%) !important`,
          },
          '.MuiPaper-root': {
            maxWidth: { xs: '100%', lg: '450px' },
          },
          whiteSpace: 'pre-line',
          ...snackbarPersist?.sx,
        }}
      />
      {children}
    </Box>
  );
};

export default SnackbarPersist;
