import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DropdownOption } from '~/types';
import { FETCH_STATUS } from '~/utils/constants';

export type OptionState = {
  jobVacancyDistinctSearch: {
    fetchStatus: FETCH_STATUS;
    items: DropdownOption[];
  };
};

const initialState: OptionState = {
  jobVacancyDistinctSearch: {
    fetchStatus: FETCH_STATUS.IDLE,
    items: [],
  },
};

export const optionSlice = createSlice({
  name: 'option',
  initialState,
  reducers: {
    setOption: <T extends keyof OptionState>(
      state: OptionState,
      action: PayloadAction<{ key: T; value: Partial<OptionState[T]> }>,
    ) => {
      state[action.payload.key] = {
        ...state[action.payload.key],
        ...action.payload.value,
      };
    },
    clearOption: () => initialState,
  },
});

export const { setOption, clearOption } = optionSlice.actions;

export default optionSlice.reducer;
