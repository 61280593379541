import {
  Dialog,
  DialogContent,
  IconButton,
  Stack,
  Typography,
  DialogActions,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  useTheme,
  TextField,
} from '@kitalulus/web-ui-kit';
import CloseIcon from '~/components/Icons/CloseIcon';
import LoadingButton from '~/components/LoadingButton';
import { VacancyClosedSurveyDialogProps } from './VacancyClosedSurveyDialog.types';
import { clearDialog } from '~/store/views/dialogs-slice';
import { useAppDispatch } from '~/hooks/use-store';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/client';
import {
  MUTATION_CREATE_JOB_VACANCY_CLOSED_FEEDBACK,
  QUERY_JOB_VACANCY_CLOSED_FEEDBACK_TEMPLATES,
} from '~/graphql/vacancy';
import {
  CreateJobVacancyClosedFeedbackMutation,
  CreateJobVacancyClosedFeedbackMutationVariables,
  JobVacancyClosedFeedbackTemplate,
  JobVacancyClosedFeedbackTemplateEnum,
  JobVacancyClosedFeedbackTemplatesQuery,
} from '~/types/graphql/graphql';
import { useEffect, useState } from 'react';
import { Nullable } from '~/types';
import * as mpe from '~/utils/mixpanel';

const MAX_TEXTFIELD_LENGTH = 500;

const VacancyClosedSurveyDialog = ({
  content,
  ...props
}: VacancyClosedSurveyDialogProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const [message, setMessage] = useState('');
  const [choice, setChoice] =
    useState<Nullable<JobVacancyClosedFeedbackTemplate>>();
  const [options, setOptions] = useState<
    Array<JobVacancyClosedFeedbackTemplate | null>
  >([]);
  useQuery<JobVacancyClosedFeedbackTemplatesQuery>(
    QUERY_JOB_VACANCY_CLOSED_FEEDBACK_TEMPLATES,
    {
      fetchPolicy: 'no-cache',
      onCompleted: ({ jobVacancyClosedFeedbackTemplates }) => {
        if (!jobVacancyClosedFeedbackTemplates?.list?.length) return;
        setOptions(jobVacancyClosedFeedbackTemplates?.list);
      },
    },
  );
  const [submitFeedback, { loading }] = useMutation<
    CreateJobVacancyClosedFeedbackMutation,
    CreateJobVacancyClosedFeedbackMutationVariables
  >(MUTATION_CREATE_JOB_VACANCY_CLOSED_FEEDBACK, {
    onCompleted: ({ createJobVacancyClosedFeedback }) => {
      if (!createJobVacancyClosedFeedback?.isSuccess) return;
      handleClose();
    },
  });

  const handleClose = () => {
    dispatch(clearDialog('vacancyClosedSurveyDialog'));
  };

  const handleSubmit = () => {
    const answer = choice?.content ?? '';

    mpe.vacancyCloseSurvey({
      vacancyCode: content?.vacancyCode ?? '',
      surveyAnswer:
        choice?.inputType === JobVacancyClosedFeedbackTemplateEnum.InputText
          ? message
          : answer,
    });

    submitFeedback({
      variables: {
        payload: {
          id: choice?.id,
          jobVacancyId: content?.jobVacancyId,
          otherComment:
            choice?.inputType === JobVacancyClosedFeedbackTemplateEnum.InputText
              ? message
              : '',
        },
      },
    });
  };

  const renderOption = (option: Nullable<JobVacancyClosedFeedbackTemplate>) => {
    if (option?.inputType === JobVacancyClosedFeedbackTemplateEnum.InputText)
      return (
        <Stack mt={1} gap={1} width="100%">
          <Typography variant="body2">{option?.content}</Typography>
          <TextField
            size="small"
            label=""
            variant="outlined"
            fullWidth
            value={message}
            disabled={
              choice?.inputType !==
              JobVacancyClosedFeedbackTemplateEnum.InputText
            }
            onChange={(e) => {
              if (e.target.value.length > MAX_TEXTFIELD_LENGTH) return;
              setMessage(e.target.value);
            }}
          />
          <Typography variant="caption" color="text.secondary" alignSelf="end">
            {`${message.length}/${MAX_TEXTFIELD_LENGTH}`}
          </Typography>
        </Stack>
      );
    return <Typography variant="body2">{option?.content}</Typography>;
  };

  // Set default Choice ID
  useEffect(() => {
    if (!options.length) return;
    setChoice(options[0]);
  }, [options]);

  if (!options.length) return null;

  return (
    <Dialog
      {...props}
      fullWidth
      PaperProps={{ sx: { maxWidth: 760, p: { xs: 1, sm: 1 } } }}
    >
      <DialogContent sx={{ pt: 3, pl: 3 }}>
        <Stack gap={2}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="start"
          >
            <Typography variant="h5" component="div">
              {t('vacancy:closeVacancySurvey.title')}
            </Typography>
            <IconButton size="small" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Stack gap={2}>
            <Typography
              variant="subtitle1"
              component="div"
              sx={{ color: theme.palette.common.black }}
            >
              {t('vacancy:closeVacancySurvey.subTitle')}
            </Typography>
            <FormControl>
              <RadioGroup
                defaultValue={options[0]?.id}
                onChange={(e) =>
                  setChoice(options.find((x) => x?.id === e.target.value))
                }
                sx={{
                  '& .MuiRadio-root': {
                    alignSelf: 'start',
                  },
                  '& .MuiFormControlLabel-label': {
                    width: '100%',
                  },
                }}
              >
                {options.map((option) => (
                  <FormControlLabel
                    key={option?.id}
                    value={option?.id}
                    control={<Radio />}
                    sx={{ ml: 1 }}
                    label={renderOption(option)}
                  />
                ))}
              </RadioGroup>
            </FormControl>
            <Typography variant="body2" component="div" color="text.secondary">
              {t('vacancy:closeVacancySurvey.footer')}
            </Typography>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ pb: 3, pr: 3 }}>
        <LoadingButton
          color="primary"
          variant="contained"
          loading={loading}
          sx={{ minWidth: 80 }}
          onClick={handleSubmit}
        >
          {t('common:send')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default VacancyClosedSurveyDialog;
