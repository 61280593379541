import {
  alpha,
  Box,
  BoxProps,
  Button,
  IconButton,
  Iconify,
  Paper,
  Stack,
  Typography,
  useTheme,
} from '@kitalulus/web-ui-kit';
import { AlertColor, Snackbar as MuiSnackbar } from '@mui/material';
import { ReactNode, SyntheticEvent, useCallback, useMemo } from 'react';

import {
  SnackbarAction,
  SnackbarProps,
  SnackbarSeverity,
} from './Snackbar.types';
import SnackbarIcon from './SnackbarIcon';

const iconOption: Partial<Record<SnackbarSeverity, ReactNode>> = {
  info: <SnackbarIcon icon={'eva:info-fill'} color="info" />,
  success: (
    <SnackbarIcon icon={'eva:checkmark-circle-2-fill'} color="success" />
  ),
  warning: <SnackbarIcon icon={'eva:alert-triangle-fill'} color="warning" />,
  error: <SnackbarIcon icon={'eva:alert-circle-fill'} color="error" />,
};

const Snackbar = ({
  showIcon,
  customIcon,
  severity = 'default',
  message,
  description,
  closeable,
  actionSpacing = 'full-tight',
  actions,
  StackProps,
  ...props
}: SnackbarProps) => {
  const theme = useTheme();
  const predefinedIcon = iconOption[severity];
  const isDefault = !predefinedIcon;

  const hasIcon = (showIcon && !!predefinedIcon) || !!customIcon;

  const handleCloseAction = useCallback(
    (e: Event | SyntheticEvent<any, Event>) => {
      props.onClose?.(e, 'closeButtonClick');
    },
    [props.onClose],
  );

  const actionButtons = useMemo(() => actions ?? [], [actions, props.onClose]);
  const mapActionButtons = useMemo(() => {
    const newActionButtons: ('divider' | SnackbarAction)[] = [];
    const actions =
      typeof actionButtons === 'function'
        ? actionButtons(handleCloseAction)
        : actionButtons;
    for (const i in actions) {
      // last item doesn't have a divider
      newActionButtons.push(actions[i]);
      if (+i !== actions.length - 1) newActionButtons.push('divider');
    }
    return newActionButtons;
  }, [actionButtons, handleCloseAction]);

  const colorPalette = theme.palette[severity as AlertColor];

  return (
    <MuiSnackbar {...props}>
      <Paper
        sx={{
          width: '100%',
          boxShadow: theme.customShadows.z8,
          borderRadius: 1.5,
          bgcolor: theme.palette.common.white,
          overflow: 'hidden',
        }}
      >
        <Stack
          direction="row"
          color={isDefault ? theme.palette.common.white : colorPalette?.darker}
          bgcolor={
            isDefault
              ? theme.palette.grey[900]
              : alpha(colorPalette?.main, 0.16)
          }
          spacing={1}
        >
          <Stack
            flex={1}
            spacing={4}
            direction="row"
            alignItems="center"
            px={theme.spacing(hasIcon ? 2 : 0.75)}
            py={theme.spacing(hasIcon ? 1.5 : 1)}
          >
            <Stack
              flex={1}
              spacing={0}
              direction="row"
              alignItems="center"
              {...StackProps}
            >
              {showIcon && (
                <Box className="SnackbarIcon">
                  {customIcon ?? predefinedIcon}
                </Box>
              )}
              <Stack className="SnackbarText" spacing={0.5} px={1.5} py={0.5}>
                {message && (
                  <Typography
                    variant="subtitle2"
                    color="inherit"
                    fontWeight="semibold"
                  >
                    {message}
                  </Typography>
                )}
                {description && (
                  <Typography
                    variant="body2"
                    color="inherit"
                    fontWeight="semibold"
                  >
                    {description}
                  </Typography>
                )}
              </Stack>
            </Stack>
          </Stack>

          {/* Regular close button */}
          {closeable && (
            <Box alignSelf="center">
              <IconButton
                size="small"
                sx={{ mr: 1.5 }}
                onClick={handleCloseAction}
              >
                <Iconify icon={'eva:close-fill'} color={colorPalette?.darker} />
              </IconButton>
            </Box>
          )}

          {/* Custom action button */}
          {mapActionButtons.length > 0 && (
            <Stack
              padding={0}
              justifyContent="center"
              borderLeft={`1px solid ${theme.palette.divider}`}
            >
              {mapActionButtons.map((act, i) => {
                if (act === 'divider')
                  return (
                    <Box
                      key={`action${i + 1}`}
                      height="1px"
                      bgcolor={theme.palette.divider}
                    />
                  );

                const isTight = actionSpacing === 'tight';
                const isFull = actionSpacing === 'full';
                const isFullTight = !isTight && !isFull;

                const isSingle = mapActionButtons.length === 1;
                const isFirstItem = !isSingle && i === 0;
                const isLastItem =
                  !isSingle && i === mapActionButtons.length - 1;
                const isMiddleItem = !isSingle && !isFirstItem && !isLastItem;

                let buttonFlex: BoxProps['flex'] = 1;
                let alignItems: BoxProps['alignItems'] = 'center';
                if (isTight) {
                  buttonFlex = 0;
                }

                if (isFull) {
                  buttonFlex = 1;
                }

                if (isFullTight) {
                  if (isMiddleItem) buttonFlex = 0;
                  if (isFirstItem) alignItems = 'flex-end';
                  if (isLastItem) alignItems = 'flex-start';
                }

                return (
                  <Button
                    key={act.label}
                    variant="text"
                    size="small"
                    color="inherit"
                    fullWidth
                    children={act.label}
                    onClick={act.onClick}
                    {...act.buttonProps}
                    sx={{
                      borderRadius: 0,
                      flex: buttonFlex,
                      color: act.color,
                      alignItems: alignItems,
                      ...act.buttonProps?.sx,
                    }}
                  />
                );
              })}
            </Stack>
          )}
        </Stack>
      </Paper>
    </MuiSnackbar>
  );
};

export default Snackbar;
