import { gql } from '~/types/graphql/gql';

export const QUERY_VERIFY_CODE = gql(`
  query verifyCode($code: String!) {
    verifyCode: epVerifyCode(code: $code) {
      isSuccess
      data {
        authCode
        email
        status
      }
      error
      warning
    }
  }
`);

export const MUTATION_SIGNUP = gql(`
  mutation signup($data: EpUserSignUpInput!) {
    signup: epSignUp(data: $data) {
      isSuccess
      error
      data
    }
  }
`);

export const MUTATION_SELF_SIGNUP = gql(`
  mutation selfSignup($data: EpUserSignUpInput!) {
    selfSignup: epSelfSignUp(data: $data) {
      isSuccess
      error
      data {
        id
      }
    }
  }
`);

export const MUTATION_FORGOT_PASSWORD = gql(`
  mutation forgotPassword($email: String!) {
    forgotPassword: epForgotPassword(email: $email) {
      isSuccess
      error
      data
    }
  }
`);

export const MUTATION_FORGOT_PASSWORD_SUBMISSION = gql(`
  mutation forgotPasswordSubmission($data: EpUserForgotPasswordInput!) {
    forgotPasswordSubmission: epForgotPasswordSubmission(data: $data) {
      isSuccess
      error
      data
    }
  }
`);

export const MUTATION_RESET_PASSWORD = gql(`
  mutation resetPassword($data: EpUserResetPasswordInput!) {
    resetPassword: epResetPassword(data: $data) {
      isSuccess
      error
      data
    }
  }
`);

export const MUTATION_RESEND_SIGNUP_LINK = gql(`
  mutation resendSignUpLink($id: ID!) {
    resendSignUpLink: epResendSignUpLink(id: $id) {
      isSuccess
      data {
        id
      }
      error
      warning
    }
  }
`);

export const QUERY_VERIFY_SELF_SIGNUP_CODE = gql(`
  query verifySelfSignUpCode($code: String!) {
    verifySelfSignUpCode: epVerifySelfSignUpCode(code: $code) {
      isSuccess
      data {
        id
        authCode
        email
        status
      }
      error
      warning
    }
  }
`);
