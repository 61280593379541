import {
  Box,
  Divider,
  Paper,
  PaperProps,
  Stack,
  Typography,
  useTheme,
} from '@kitalulus/web-ui-kit';
import { Skeleton } from '@mui/material';
import camelCase from 'lodash.camelcase';
import { Nullable } from '~/types';
import { createArray } from '~/utils/array';

export interface ICardEducationItem {
  label: string;
  subLabel?: string;
  subLabel2?: string;
  subLabel2TestId?: string;
  captionTop?: string;
  captionBottom?: string;
  description?: Nullable<string>;
}

interface ICardEducation {
  title: string;
  emptyState?: string;
  items: ICardEducationItem[];
  spacing?: number;
  fullWidth?: boolean;
  PaperProps?: PaperProps;
  isLoading?: boolean;
}

const Shimmer = () => {
  const theme = useTheme();

  return (
    <Stack paddingTop={theme.spacing(4)} gap={1} className="kl-separator">
      <Skeleton variant="rounded" height={18} width={246} />
      <Skeleton variant="rounded" height={18} width={246} />
      <Skeleton variant="rounded" height={18} width={246} />
      <Skeleton
        variant="rounded"
        height={18}
        width={'100%'}
        sx={{ marginTop: '24px' }}
      />
      <Skeleton variant="rounded" height={18} width={'100%'} />
      <Skeleton variant="rounded" height={18} width={'100%'} />
    </Stack>
  );
};

const CardEducation = ({
  title,
  emptyState,
  items,
  spacing,
  fullWidth,
  PaperProps,
  isLoading,
}: ICardEducation) => {
  const theme = useTheme();
  return (
    <>
      <Paper
        id={camelCase(`applicant ${title}`)}
        sx={{
          borderRadius: theme.spacing(2),
          padding: theme.spacing(3),
          width: {
            xs: '100%',
            lg: fullWidth ? '100%' : '320px',
          },
          overflowWrap: 'anywhere',
          boxShadow: theme.customShadows['card'],
        }}
        {...PaperProps}
      >
        {isLoading && (
          <>
            <Typography variant="h6">{title}</Typography>
            {createArray(3).map((n) => (
              <Shimmer key={n} />
            ))}
          </>
        )}
        {!isLoading && (
          <>
            <Typography variant="h6">{title}</Typography>
            <Stack paddingTop={theme.spacing(4)} gap={spacing ?? 1}>
              {items.length ? (
                items.map((item, index) => (
                  <div key={item.label}>
                    <Box id={camelCase(`applicant${item.label}${index}`)}>
                      {item.captionTop && (
                        <Typography variant="caption" color="text.secondary">
                          {item.captionTop}
                        </Typography>
                      )}
                      {item.label && (
                        <Typography
                          variant="subtitle2"
                          textTransform="capitalize"
                        >
                          {item.label}
                        </Typography>
                      )}
                      {item.subLabel && (
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          textTransform="capitalize"
                        >
                          {item.subLabel}
                        </Typography>
                      )}
                      {item.subLabel2 && (
                        <Typography
                          variant="body2"
                          color="text.disabled"
                          data-test-id={item.subLabel2TestId}
                        >
                          {item.subLabel2}
                        </Typography>
                      )}
                      {item.description && (
                        <Typography
                          variant="body2"
                          color="text.disabled"
                          paddingTop={theme.spacing(1.5)}
                          whiteSpace="pre-wrap"
                        >
                          {item.description}
                        </Typography>
                      )}
                      {item.captionBottom && (
                        <Typography
                          variant="caption"
                          color="text.disabled"
                          paddingTop={theme.spacing(1.5)}
                        >
                          {item.captionBottom}
                        </Typography>
                      )}
                    </Box>
                    {index != items.length - 1 && (
                      <Divider sx={{ mt: '8px' }} />
                    )}
                  </div>
                ))
              ) : (
                <Box>
                  <Typography
                    variant="subtitle2"
                    textTransform="capitalize"
                    color="text.disabled"
                  >
                    {emptyState ?? '-'}
                  </Typography>
                </Box>
              )}
            </Stack>
          </>
        )}
      </Paper>
    </>
  );
};

export default CardEducation;
