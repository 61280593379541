import { useLazyQuery } from '@apollo/client';
import { useCallback } from 'react';

import { QUERY_VERIFY_JOB_VACANCY_CANDIDATE_INVITATION } from '~/graphql/candidate-recommendation';
import {
  VerifyJobVacancyCandidateInvitationQuery,
  VerifyJobVacancyCandidateInvitationQueryVariables,
} from '~/types/graphql/graphql';

export const useCandidateRecommendationVerification = () => {
  const [verifyCandidate, { loading: isLoading }] = useLazyQuery<
    VerifyJobVacancyCandidateInvitationQuery,
    VerifyJobVacancyCandidateInvitationQueryVariables
  >(QUERY_VERIFY_JOB_VACANCY_CANDIDATE_INVITATION, {
    fetchPolicy: 'no-cache',
  });

  const handleVerify = useCallback(async (candidateId: string) => {
    const res = await verifyCandidate({ variables: { id: candidateId } });
    const data = res.data?.verifyJobVacancyCandidateInvitation;
    if (!data) throw new Error('Unknown error');
    return data;
  }, []);

  return { handleVerify, isLoading };
};
