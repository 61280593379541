import { useAppDispatch } from '../use-store';
import { OpenVacancyShareDialogProps } from '~/components/GlobalDialogs/VacancyShareDialog';
import { clearDialog, setDialog } from '~/store/views/dialogs-slice';
import { useRemoteConfigValue } from '../firebase/use-remote-config';

export const useVacancyShareDialog = () => {
  const dispatch = useAppDispatch();
  const isVacancyShareActive = useRemoteConfigValue('ff_vacancy_share');

  const openVacancyShareDialog = ({
    content,
    onClose,
  }: OpenVacancyShareDialogProps) => {
    if (!isVacancyShareActive) return;

    dispatch(
      setDialog({
        vacancyShareDialog: {
          open: true,
          content,
          onClose,
        },
      }),
    );
  };

  const closeVacancyShareDialog = () =>
    dispatch(clearDialog('vacancyShareDialog'));

  return { openVacancyShareDialog, closeVacancyShareDialog };
};
