import { SvgIcon, SvgIconProps } from '@kitalulus/web-ui-kit';

const TikTok = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 16 16" {...props}>
      <path d="M11.3814 0H8.68488V10.8985C8.68488 12.1971 7.6478 13.2638 6.35718 13.2638C5.06656 13.2638 4.02947 12.1971 4.02947 10.8985C4.02947 9.6232 5.04352 8.57969 6.28806 8.53333V5.79711C3.54549 5.84347 1.33301 8.09276 1.33301 10.8985C1.33301 13.7276 3.59158 16 6.38024 16C9.16886 16 11.4274 13.7044 11.4274 10.8985V5.31013C12.4415 6.05218 13.686 6.49276 14.9997 6.51596V3.77971C12.9716 3.71015 11.3814 2.04058 11.3814 0Z" />
    </SvgIcon>
  );
};

export default TikTok;
