import {
  InputAdornment,
  InputAdornmentProps,
  useTheme,
} from '@kitalulus/web-ui-kit';
import { forwardRef } from 'react';

const InputAdornmentWithBorder = forwardRef<
  HTMLDivElement,
  InputAdornmentProps
>((props, ref) => {
  const theme = useTheme();

  const styleByPosition = {
    start: {
      borderRight: `2px solid ${theme.palette.grey[500]}`,
      marginRight: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    end: {
      borderLeft: `2px solid ${theme.palette.grey[500]}`,
      marginLeft: theme.spacing(1),
      paddingLeft: theme.spacing(1),
    },
  };

  return (
    <InputAdornment
      ref={ref}
      {...props}
      sx={{ height: '16px', ...styleByPosition[props.position], ...props.sx }}
    />
  );
});

export default InputAdornmentWithBorder;
