import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { createLocalStoragePersist } from '~/utils/redux-persist';

export const SLICE_KEY = 'applicantDetail21stLimitation';

export type ApplicantDetailFreeplanPersistCompanies = Array<{
  companyID: string;
  vacancies: Array<string>;
}>;

export type ApplicantDetailFreeplanPersistStateSlice = {
  companies: ApplicantDetailFreeplanPersistCompanies;
};

const initialState: ApplicantDetailFreeplanPersistStateSlice = {
  companies: [],
};

export const applicantDetailFreeplanPersistSlice = createSlice({
  name: SLICE_KEY,
  initialState,
  reducers: {
    markAsViewed: (
      state,
      action: PayloadAction<{
        companyID: string;
        vacancyCode: string;
      }>,
    ) => {
      const { companyID, vacancyCode } = action.payload;

      const companyIndex = state.companies?.findIndex(
        (comp) => comp.companyID === companyID,
      );
      if (companyIndex === -1) {
        state.companies?.push({
          companyID: companyID,
          vacancies: [vacancyCode],
        });
        return;
      }
      state.companies[companyIndex] = {
        companyID,
        vacancies: Array.from(
          new Set([...state.companies[companyIndex].vacancies, vacancyCode]),
        ),
      };
    },
    clearVacancies: (state, action: PayloadAction<{ companyID: string }>) => {
      const { companyID } = action.payload;
      const companyIndex = state.companies?.findIndex(
        (comp) => comp.companyID === companyID,
      );
      if (companyIndex === -1) return;
      state.companies[companyIndex] = {
        companyID,
        vacancies: [],
      };
    },
    clearApplcantDetailFreeplanPersistMark: () => initialState,
  },
});

export const {
  markAsViewed,
  clearVacancies,
  clearApplcantDetailFreeplanPersistMark,
} = applicantDetailFreeplanPersistSlice.actions;

export default createLocalStoragePersist(
  SLICE_KEY,
  applicantDetailFreeplanPersistSlice.reducer,
);
