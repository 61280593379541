export enum VACANCY_TABS_INDONESIA {
  ALL = 'Semua',
  OPEN = 'Dibuka',
  ON_HOLD = 'Ditunda',
  CLOSED = 'Ditutup',
}

export enum VACANCY_FORM_TYPE {
  CREATE = 'CREATE',
  COPY = 'COPY',
  UPDATE = 'UPDATE',
  REPOST = 'REPOST',
}

export enum VACANCY_CONTACT_TYPE {
  EMAIL = 'EMAIL',
  WHATSAPP = 'WHATSAPP',
}

export enum VACANCY_EDUCATION_LEVEL {
  MIDDLE_SCHOOL = 'MIDDLE_SCHOOL',
  HIGH_SCHOOL = 'HIGH_SCHOOL',
  DIPLOMA = 'DIPLOMA',
  BACHELOR = 'BACHELOR',
  MASTER = 'MASTER',
  DOCTORATE = 'DOCTORATE',
  OTHER = 'OTHER',
}

export enum VACANCY_UTM {
  PREVIEW = '?utm_source=klep&utm_medium=klep-web&utm_campaign=klep-preview',
  COPY_LINK = '?utm_source=Copylink&utm_medium=social&utm_campaign=employer-share&status=share',
  SHARE_TO_LINKED_IN = '?utm_source=LinkedIn&utm_medium=social&utm_campaign=employer-share&status=share',
}

export enum EXPERIENCE_LEVEL {
  EXPERIENCE = 'EXPERIENCE',
  NO_EXPERIENCE = 'NO_EXPERIENCE',
}

export const EXPERIENCE_LEVEL_OPTION = [
  {
    label: 'Berpengalaman',
    value: EXPERIENCE_LEVEL.EXPERIENCE,
  },
  {
    label: 'Tidak Berpengalaman',
    value: EXPERIENCE_LEVEL.NO_EXPERIENCE,
  },
];

export enum QUOTA_RESTRICTION_VARIANT {
  NEXT_STEP_INFO = 'NEXT_STEP_INFO',
  NEXT_STEP_BLOCKER = 'NEXT_STEP_BLOCKER',
  NEXT_STEP_BLOCKER_V2 = 'NEXT_STEP_BLOCKER_V2',
  NO_QUOTA_BLOCKER = 'NO_QUOTA_BLOCKER',
  NO_QUOTA_BLOCKER_V2 = 'NO_QUOTA_BLOCKER_V2',
  NO_QUOTA_BLOCKER_REPOST = 'NO_QUOTA_BLOCKER_REPOST',
  NO_QUOTA_BLOCKER_REPOST_V2 = 'NO_QUOTA_BLOCKER_REPOST_V2',
  NO_JOB_CATEGORY = 'NO_JOB_CATEGORY',
  SUBMIT_CONFIRMATION = 'SUBMIT_CONFIRMATION',
  SUBMIT_CONFIRMATION_REPOST = 'SUBMIT_CONFIRMATION_REPOST',
}

export enum JOB_TYPES {
  GROWING_JOB = 'Growing Job',
  REGULAR_JOB = 'Regular Job',
  PRIORITY_JOB = 'Priority Job',
}
