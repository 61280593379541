import { ConfirmDialogV1Props } from './ConfirmDialogV1.types';
import {
  Dialog,
  DialogContent,
  Typography,
  IconButton,
  DialogActions,
  Button,
  Stack,
} from '@kitalulus/web-ui-kit';
import CloseIcon from '~/components/Icons/CloseIcon';
import LoadingButton from '~/components/LoadingButton/LoadingButton';

const ConfirmDialogV1 = ({
  title,
  titleProps,
  titleIcon,
  closeButtonProps,
  body,
  bodyProps,
  acceptProps,
  cancelProps,
  dialogContentProps,
  dialogActionProps,
  hideCloseButton,
  ...props
}: ConfirmDialogV1Props) => {
  return (
    <Dialog {...props}>
      <DialogContent
        {...dialogContentProps}
        sx={{ px: { xs: 1, sm: 3 }, ...dialogContentProps?.sx }}
      >
        {!hideCloseButton && (
          <IconButton
            size="small"
            onClick={cancelProps?.onClick}
            {...closeButtonProps}
            sx={{
              position: 'absolute',
              top: 10,
              right: 10,
              ...closeButtonProps?.sx,
            }}
          >
            <CloseIcon />
          </IconButton>
        )}

        <Stack direction="row" gap={2}>
          {titleIcon && <Stack>{titleIcon}</Stack>}
          <Stack gap={2}>
            <Typography variant="h6" component="div" mr={2} {...titleProps}>
              {title}
            </Typography>
            <Typography
              variant="body1"
              component="div"
              color="text.secondary"
              {...bodyProps}
            >
              {body}
            </Typography>
          </Stack>
        </Stack>
      </DialogContent>

      <DialogActions {...dialogActionProps}>
        {cancelProps?.children && (
          <Button autoFocus color="inherit" variant="outlined" {...cancelProps}>
            {cancelProps.children}
          </Button>
        )}
        {acceptProps?.children && (
          <LoadingButton color="primary" variant="contained" {...acceptProps}>
            {acceptProps.children}
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialogV1;
