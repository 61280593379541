import { SvgIcon, SvgIconProps } from '@kitalulus/web-ui-kit';

const Apartment = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path d="M15.5833 10.0833V4.58333C15.5833 3.575 14.7583 2.75 13.75 2.75H8.25C7.24167 2.75 6.41667 3.575 6.41667 4.58333V6.41667H4.58333C3.575 6.41667 2.75 7.24167 2.75 8.25V17.4167C2.75 18.425 3.575 19.25 4.58333 19.25H9.16667C9.67083 19.25 10.0833 18.8375 10.0833 18.3333V15.5833H11.9167V18.3333C11.9167 18.8375 12.3292 19.25 12.8333 19.25H17.4167C18.425 19.25 19.25 18.425 19.25 17.4167V11.9167C19.25 10.9083 18.425 10.0833 17.4167 10.0833H15.5833ZM6.41667 17.4167H4.58333V15.5833H6.41667V17.4167ZM6.41667 13.75H4.58333V11.9167H6.41667V13.75ZM6.41667 10.0833H4.58333V8.25H6.41667V10.0833ZM10.0833 13.75H8.25V11.9167H10.0833V13.75ZM10.0833 10.0833H8.25V8.25H10.0833V10.0833ZM10.0833 6.41667H8.25V4.58333H10.0833V6.41667ZM13.75 13.75H11.9167V11.9167H13.75V13.75ZM13.75 10.0833H11.9167V8.25H13.75V10.0833ZM13.75 6.41667H11.9167V4.58333H13.75V6.41667ZM17.4167 17.4167H15.5833V15.5833H17.4167V17.4167ZM17.4167 13.75H15.5833V11.9167H17.4167V13.75Z" />
    </SvgIcon>
  );
};

export default Apartment;
