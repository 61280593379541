import { useEffect } from 'react';

import { logout } from '~/utils/auth/helper';
import {
  LOGOUT_ACTION,
  createAuthBroadcastChannel,
} from '~/utils/broadcast-channel';

export const useAuthBroadcastChannel = () => {
  useEffect(() => {
    const channel = createAuthBroadcastChannel();

    channel.addEventListener('message', (event) => {
      if (event === LOGOUT_ACTION) logout({ runBroadcast: false });
    });

    return () => {
      channel.close().catch(console.error);
    };
  }, []);
};
