import { Skeleton, Stack } from '@mui/material';

const ScreeningQuestionAnswersShimmer = () => {
  return (
    <Stack spacing={1}>
      <Stack>
        <Skeleton
          sx={{
            fontSize: 22,
            my: '-6px',
            width: { xs: '100%', sm: '33%' },
          }}
        />
        <Skeleton
          sx={{
            fontSize: 18,
            mt: '-4px',
            width: { xs: '60%', sm: '35%' },
          }}
        />
      </Stack>
      <Stack pb={1}>
        <Skeleton sx={{ fontSize: 28, width: { xs: '45%', sm: '20%' } }} />
      </Stack>
      <Stack
        spacing={{ xs: '6px', sm: '12px' }}
        sx={{
          borderRadius: 2,
          border: '1px solid rgba(145, 158, 171, 0.24)',
          p: { xs: '6px 12px', sm: 3 },
        }}
      >
        <Stack direction="row" justifyContent="space-between">
          <Skeleton sx={{ fontSize: 22, width: { xs: '100%', sm: '50%' } }} />
          <Skeleton
            width="11%"
            sx={{ fontSize: 22, display: { xs: 'none', sm: 'flex' } }}
          />
        </Stack>
        <Stack
          alignItems="flex-end"
          sx={{ fontSize: 22, display: { xs: 'flex', sm: 'none' } }}
        >
          <Skeleton width="40%" sx={{ fontSize: 22 }} />
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ display: { xs: 'none', sm: 'flex' } }}
        >
          <Skeleton width="50%" sx={{ fontSize: 22 }} />
          <Stack direction="row" width="100%" ml={6}>
            <Stack direction="row" width="5%" mr="12px">
              <Skeleton width="100%" sx={{ fontSize: 22 }} />
            </Stack>
            <Stack direction="row" width="30%">
              <Skeleton width="100%" sx={{ fontSize: 22 }} />
            </Stack>
          </Stack>
          <Skeleton width="19%" sx={{ fontSize: 22 }} />
        </Stack>
        <Stack
          spacing="6px"
          pt="6px"
          sx={{ display: { xs: 'flex', sm: 'none' } }}
        >
          <Skeleton width="90%" sx={{ fontSize: 22 }} />
          <Skeleton width="90%" sx={{ fontSize: 22 }} />
        </Stack>
      </Stack>
    </Stack>
  );
};
export default ScreeningQuestionAnswersShimmer;
