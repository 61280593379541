import { SvgIcon, SvgIconProps } from '@kitalulus/web-ui-kit';

const ConsFactCheckIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 16 16" {...props}>
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.333 2H2.66634C1.93301 2 1.33301 2.6 1.33301 3.33333V12.6667C1.33301 13.4 1.93301 14 2.66634 14H13.333C14.0663 14 14.6663 13.4 14.6663 12.6667V3.33333C14.6663 2.6 14.0663 2 13.333 2ZM13.333 12.6667H2.66634V3.33333H13.333V12.6667Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.94 6.94667L11.9933 6L9.88 8.11333L8.94 7.16667L8 8.10667L9.88 10L12.94 6.94667Z"
        />
        <path d="M6.66634 4.66699H3.33301V6.00033H6.66634V4.66699Z" />
        <path d="M6.66634 7.33301H3.33301V8.66634H6.66634V7.33301Z" />
        <path d="M6.66634 10H3.33301V11.3333H6.66634V10Z" />
      </g>
    </SvgIcon>
  );
};

export default ConsFactCheckIcon;
