import {
  Label,
  Stack,
  Tooltip,
  Typography,
  brandColors,
  useTheme,
} from '@kitalulus/web-ui-kit';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ACCOUNT_TABS } from '~/utils/constants';
import { PackageBadgeProps } from './PackageBadge.types';
import { InfoIcon } from '~/components/Icons/InfoIcon';
import { useFeatureFlag } from '~/hooks/firebase/use-feature-flag';

const PackageBadge = ({ packageName, notice, ...props }: PackageBadgeProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();
  const isPayPerPostActive = useFeatureFlag('pay_per_post');

  return (
    <Label
      variant="filled"
      onClick={() =>
        navigate(`/account/${ACCOUNT_TABS.PACKAGE_DETAIL.toLowerCase()}`)
      }
      {...props}
      sx={{
        py: 0.5,
        height: 'unset',
        background: `${theme.palette.primary.lighter}`,
        cursor: 'pointer',
        ...props.sx,
      }}
      data-test-id="ctSidebarCompanyPackageName"
    >
      <Stack direction="row" gap={0.75} alignItems="center">
        <Tooltip arrow title={t('common:packageUsed')}>
          <Typography
            variant="caption"
            fontWeight={700}
            data-test-id="lbSidebarCompanyPackageName"
          >
            {isPayPerPostActive
              ? `${t('common:package')} ${packageName}`
              : packageName}
          </Typography>
        </Tooltip>
        {notice && (
          <Tooltip
            title={<Typography variant="caption">{notice}</Typography>}
            PopperProps={{
              sx: {
                whiteSpace: 'pre-line',
                textAlign: 'center',
                '& .MuiTooltip-tooltip': {
                  p: '12px',
                },
              },
            }}
            arrow
          >
            <span>
              <InfoIcon
                sx={{
                  color: brandColors.secondary[300],
                  fontSize: '22px',
                }}
              />
            </span>
          </Tooltip>
        )}
      </Stack>
    </Label>
  );
};

export default PackageBadge;
