import { SvgIcon, SvgIconProps } from '@kitalulus/web-ui-kit';

export const FiSsShieldCheck = (props: SvgIconProps) => {
  return (
    <SvgIcon width="20" height="20" viewBox="0 0 20 20" {...props}>
      <g>
        <path d="M16.6203 2.2021L10.0003 0.00793457L3.38033 2.2021C2.88155 2.36745 2.44756 2.68575 2.13999 3.1118C1.83243 3.53784 1.66692 4.04997 1.66699 4.57543V10.0004C1.66699 16.2704 9.33366 19.7329 9.66199 19.8771L9.95699 20.0079L10.2637 19.9063C10.5937 19.7963 18.3337 17.1479 18.3337 10.0004V4.57543C18.3337 4.04997 18.1682 3.53784 17.8607 3.1118C17.5531 2.68575 17.1191 2.36745 16.6203 2.2021ZM10.3703 11.6579C10.2246 11.8046 10.0513 11.9208 9.86031 12C9.66933 12.0791 9.46454 12.1194 9.25783 12.1188H9.23033C9.0194 12.1155 8.81132 12.0696 8.61867 11.9836C8.42602 11.8977 8.25281 11.7736 8.10949 11.6188L6.18783 9.61877L7.39033 8.4671L9.26033 10.4171L13.5778 6.0996L14.7562 7.27793L10.3703 11.6579Z" />
      </g>
    </SvgIcon>
  );
};
