import { useLazyQuery } from '@apollo/client';

import { QUERY_JOB_APPLICATION_REJECTED_REASONS } from '~/graphql/applicants';
import {
  setApplicantRejectReasons,
  setApplicantRejectReasonsStatus,
} from '~/store/features/applicant-detail-slice';
import {
  JobApplicationRejectedReasonsQuery,
  JobApplicationRejectedReasonsQueryVariables,
} from '~/types/graphql/graphql';
import { FETCH_STATUS } from '~/utils/constants';
import { useAppDispatch } from '../use-store';

export const useApplicantRejectReasons = () => {
  const dispatch = useAppDispatch();
  const [getApplicantRejectReasons, { loading }] = useLazyQuery<
    JobApplicationRejectedReasonsQuery,
    JobApplicationRejectedReasonsQueryVariables
  >(QUERY_JOB_APPLICATION_REJECTED_REASONS, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ jobApplicationRejectedReasons }) => {
      if (jobApplicationRejectedReasons)
        dispatch(setApplicantRejectReasons(jobApplicationRejectedReasons));
    },
  });

  const queryRejectReasons = (id: string) => {
    dispatch(setApplicantRejectReasonsStatus(FETCH_STATUS.PENDING));
    getApplicantRejectReasons({
      variables: {
        id: id,
      },
    });
  };

  return { queryRejectReasons, loading };
};
