import { Nullable } from '~/types';
import { EXPERIENCE_LEVEL, EXPERIENCE_LEVEL_OPTION } from './constants/vacancy';
import {
  emojiPattern,
  fancyTextPattern,
  websitePatternWithWWW,
  websitePatternWithoutWWW,
} from './validation';

export const isValidJson = (str: string) => {
  try {
    JSON.parse(str);
    return true;
  } catch {
    return false;
  }
};

export const isValidDate = (date: string | number | Date) => {
  return !Number.isNaN(new Date(date).getTime());
};

export const removeHttp = (data: string) => {
  const httpPattern = /^https?:\/\//gi;
  return data.replace(httpPattern, '');
};

export const parseValue = <T = any>(val: string): T => {
  if (val === 'undefined') return undefined as any;
  if (val === 'null') return null as any;
  // parse boolean
  if (val === 'false' || val === 'true') return (val === 'true') as any;
  // parse integer
  if (!isNaN(+val)) return +val as any;
  // parse json
  if (isValidJson(val)) return JSON.parse(val);
  return val as any;
};

export const parseNumber = <T extends number | undefined | null>(
  str: string,
  defaultVal: T,
): T | number => {
  if (!str) return defaultVal;
  const parsed = +str;
  if (isNaN(parsed)) return defaultVal;
  return parsed;
};

export const removeHashAndQueryURL = (str: string) => {
  // we limit the hash string and qs pattern to 0-100
  // because if we use *, sonarqube reads it as `Security Hotspots`
  const hashAndQsPattern = /(#.{0,100})?\?.{0,100}$/;
  return str.replace(hashAndQsPattern, '');
};

export const generateQueryString = <T extends Record<string, any>>(
  obj: T,
): string => {
  // parse object values to string
  const cloned: Record<string, string> = { ...obj };
  for (const key in cloned) {
    let val = cloned[key];
    if (typeof val === 'object') {
      val = JSON.stringify(val);
    } else if (typeof val === 'undefined') {
      val = '';
    }
    val = val.toString();
    cloned[key] = val;
  }
  return new URLSearchParams(cloned).toString();
};

/**
 * Create dummy asynchronus sleep
 *
 * @param timeout - Time to sleep
 * @param type - Return type
 * @returns void
 */
export const sleep = (
  timeout: number,
  type: 'resolve' | 'reject' = 'resolve',
) => {
  return new Promise((resolve, reject) =>
    setTimeout(() => {
      if (type === 'resolve') resolve('Sleep resolved...');
      reject(new Error('Sleep rejected...'));
    }, timeout),
  );
};

export const convertDateToISOString = <T>(
  date: string | number,
  emptyValue?: T,
) => {
  if (!isValidDate(date)) return emptyValue ?? '';
  return date ? new Date(date).toISOString() : emptyValue ?? '';
};

export const joinNullableStrings = (
  items: Array<Nullable<string>>,
  divider: string,
  emptyState?: string,
) => {
  const filtered = items.filter((val) => !!val);
  if (filtered.length <= 0) return emptyState ?? '-';
  return filtered.join(` ${divider} `);
};

// in effort to reduce cognitive complexity
export const ternary = <T1, T2, T3>(
  condition: T1,
  ifTrue: T2,
  ifFalse?: T3,
) => {
  return condition ? ifTrue : ifFalse ?? undefined;
};

// in effort to reduce cognitive complexity
export const nullingCoalescing = <T1, T2>(item: T1, ifNull: T2) => {
  return item ?? ifNull;
};

export const boldToStrongTags = (html: string) => {
  const pattern = /<(\/?)b\b((?:[^>"']|"[^"]*"|'[^']*')*)>/g;
  const replacement = '<$1strong$2>';
  return html.replace(pattern, replacement);
};

export const isValidURL = (url: string) => {
  // Check is the URL starts with www
  if (url.startsWith('www.')) {
    return websitePatternWithWWW.test(url);
  }

  // Checks is the URL matches the regex pattern without www
  return websitePatternWithoutWWW.test(url);
};

export const stringToBoolean = (s: string) => {
  return s.toLowerCase() === 'true';
};

export const experienceLevelStr = (minimumExperience: number) => {
  if (minimumExperience > 0) {
    return EXPERIENCE_LEVEL_OPTION.find(
      (experienceLevel) =>
        experienceLevel.value === EXPERIENCE_LEVEL.EXPERIENCE,
    );
  }

  return EXPERIENCE_LEVEL_OPTION.find(
    (experienceLevel) =>
      experienceLevel.value === EXPERIENCE_LEVEL.NO_EXPERIENCE,
  );
};

export const removeFancyTextAndEmoji = (str: string) =>
  str.replace(fancyTextPattern, '').replace(emojiPattern, '');

export const formatStringURLToHTML = (
  str: string,
  cb?: ({ text, url }: { text: string; url: string }) => string,
) => {
  const pattern =
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/g;
  return str.replace(pattern, (match, p1) => {
    // return unchanged if it's an HTML anchor tag
    if (!p1) return match;

    // ensure the URL has a protocol
    const url = p1.startsWith('http') ? match : `http://${match}`;
    return cb
      ? cb({ text: match, url: url })
      : `<a target="_blank" href="${url}">${match}</a>`;
  });
};

export const countValidValues = (obj: {
  [key: string]: string | undefined | null;
}) => {
  if (Object.keys(obj).length === 0) return 0;

  let count = 0;
  for (const key in obj) {
    const value = obj[key];
    if (value && value !== '') count++;
  }
  return count;
};
