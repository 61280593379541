import { useLazyQuery, useMutation } from '@apollo/client';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  MUTATION_FORGOT_PASSWORD,
  MUTATION_FORGOT_PASSWORD_SUBMISSION,
  MUTATION_RESEND_SIGNUP_LINK,
  MUTATION_RESET_PASSWORD,
  MUTATION_SELF_SIGNUP,
  MUTATION_SIGNUP,
  QUERY_VERIFY_CODE,
  QUERY_VERIFY_SELF_SIGNUP_CODE,
} from '~/graphql/auth';
import { setAuth } from '~/store/features/auth-slice';
import { setSnackbar } from '~/store/views/snackbar-slice';
import { Nullable } from '~/types';
import { SelfSignUpParams, SignUpParams } from '~/types/auth';
import {
  EpAuthResponse,
  EpResendSignUpLinkResponse,
  EpSignUpResponse,
  EpUserForgotPasswordInput,
  ForgotPasswordMutation,
  ForgotPasswordMutationVariables,
  ForgotPasswordSubmissionMutation,
  ForgotPasswordSubmissionMutationVariables,
  MutationResponse,
  ResendSignUpLinkMutation,
  ResendSignUpLinkMutationVariables,
  ResetPasswordMutation,
  ResetPasswordMutationVariables,
  SelfSignupMutation,
  SelfSignupMutationVariables,
  SignupMutation,
  SignupMutationVariables,
  VerifyCodeQuery,
  VerifyCodeQueryVariables,
  VerifySelfSignUpCodeQuery,
  VerifySelfSignUpCodeQueryVariables,
} from '~/types/graphql/graphql';
import { logout } from '~/utils/auth/helper';
import { encodeAuthPassword } from '~/utils/auth/user-password';
import { useAppDispatch, useAppSelector } from './use-store';
import { AUTH_STATUS, FETCH_STATUS } from '~/utils/constants';
import * as mpe from '~/utils/mixpanel';
import {
  reauthenticateWithCredentialUser,
  signInAuthUserWithEmailAndPassword,
  signOutUser,
} from '~/utils/auth/firebase';
import { FIREBASE_ERROR_CODE } from '~/utils/constants/firebase-error-code';
import { RTD_ACTION_TYPE } from '~/utils/constants/realtime-data';

export const useAuthValue = () => {
  const auth = useAppSelector((state) => state.auth);

  return { isAuthenticated: auth?.status === AUTH_STATUS.AUTHENTICATED, auth };
};

export const useSignIn = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const auth = useAppSelector((state) => state.auth);

  const [error, setError] = useState<Nullable<string>>();

  const handleSignIn = useCallback(
    async (username: string, password: string) => {
      setError(null);
      dispatch(
        setAuth({
          status: AUTH_STATUS.PENDING,
        }),
      );

      signInAuthUserWithEmailAndPassword(username, password)
        .then((response) => {
          if (!response?.user.emailVerified) {
            setError(t('auth:unverifiedEmail'));
            dispatch(
              setAuth({
                status: AUTH_STATUS.UNAUTHENTICATED,
              }),
            );
          } else {
            mpe.setAliasOnlyEmail({ email: username });
            mpe.onboardingSignIn();

            dispatch(
              setAuth({
                status: AUTH_STATUS.AUTHENTICATED,
              }),
            );
          }
        })
        .catch((error) => {
          dispatch(
            setAuth({
              status: AUTH_STATUS.UNAUTHENTICATED,
            }),
          );

          const code = error.code;

          if (code === FIREBASE_ERROR_CODE.INVALID_PASSWORD) {
            setError(t('auth:wrongEmailAndPassword'));
          } else if (code === FIREBASE_ERROR_CODE.TOO_MANY_ATTEMPTS_TRY_LATER) {
            setError(t('auth:tooManyAttempsTryLater'));
          } else if (code === FIREBASE_ERROR_CODE.USER_NOT_FOUND) {
            setError(t('auth:userNotFound'));
          } else if (code === FIREBASE_ERROR_CODE.USER_DISABLED) {
            navigate('/info/fraud', { state: { persistToShow: true } });
          }
        });
    },
    [],
  );

  return {
    handleSignIn,
    isLoading: auth?.status === AUTH_STATUS.PENDING,
    error,
  };
};

export const useSignUp = () => {
  const [error, setError] = useState<Nullable<string>>();
  const [signup, { loading }] = useMutation<
    SignupMutation,
    SignupMutationVariables
  >(MUTATION_SIGNUP);

  const handleSignUp = useCallback(
    (
      data: Omit<SignUpParams, 'agreement'>,
      onCompleted?: (data: Nullable<MutationResponse>) => void,
    ) => {
      setError(null);
      const timemilis = new Date().getTime();
      signup({
        variables: {
          data: {
            ...data,
            email: data.email,
            timestamp: timemilis,
            password: encodeAuthPassword('signup', 'password', {
              email: data.email,
              password: data.password,
              timemilis,
            }),
            confirmedPassword: encodeAuthPassword(
              'signup',
              'password-confirmation',
              {
                email: data.email,
                password: data.confirmedPassword,
                timemilis,
              },
            ),
          },
        },
        onCompleted: ({ signup }) => {
          if (!signup?.isSuccess) return setError(signup?.error);
          mpe.setAliasOnlyEmail({ email: data.email });
          mpe.onboardingRegister();
          onCompleted?.(signup);
        },
      });
    },
    [],
  );

  return { handleSignUp, isLoading: loading, error };
};

export const useSelfSignUp = () => {
  const [error, setError] = useState<Nullable<string>>();
  const [signup, { loading }] = useMutation<
    SelfSignupMutation,
    SelfSignupMutationVariables
  >(MUTATION_SELF_SIGNUP, { fetchPolicy: 'no-cache' });

  const handleSelfSignUp = useCallback(
    (
      data: Omit<SelfSignUpParams, 'agreement'>,
      onCompleted?: (data: Nullable<EpSignUpResponse>) => void,
    ) => {
      setError(null);
      const timemilis = new Date().getTime();
      signup({
        variables: {
          data: {
            ...data,
            email: data.email,
            timestamp: timemilis,
            password: encodeAuthPassword('signup', 'password', {
              email: data.email,
              password: data.password,
              timemilis,
            }),
            confirmedPassword: encodeAuthPassword(
              'signup',
              'password-confirmation',
              {
                email: data.email,
                password: data.confirmedPassword,
                timemilis,
              },
            ),
          },
        },
        onCompleted: ({ selfSignup }) => {
          if (!selfSignup?.isSuccess) return setError(selfSignup?.error);
          onCompleted?.(selfSignup);
        },
      });
    },
    [],
  );

  return { handleSelfSignUp, isLoading: loading, error };
};

export const useVerifyAuthCode = () => {
  const [fetchStatus, setFetchStatus] = useState(FETCH_STATUS.IDLE);
  const [verifyCode] = useLazyQuery<VerifyCodeQuery, VerifyCodeQueryVariables>(
    QUERY_VERIFY_CODE,
  );

  const handleVerifyCode = useCallback(
    (code: string, onCompleted?: (data: Nullable<EpAuthResponse>) => void) => {
      setFetchStatus(FETCH_STATUS.PENDING);
      verifyCode({
        variables: { code },
        onCompleted: ({ verifyCode }) => {
          setFetchStatus(FETCH_STATUS.RESOLVED);
          onCompleted?.(verifyCode);
        },
      });
    },
    [],
  );

  const isLoading = [FETCH_STATUS.IDLE, FETCH_STATUS.PENDING].includes(
    fetchStatus,
  );

  return { handleVerifyCode, isLoading };
};

export const useForgotPassword = () => {
  const [error, setError] = useState<Nullable<string>>();
  const [forgotPassword, { loading }] = useMutation<
    ForgotPasswordMutation,
    ForgotPasswordMutationVariables
  >(MUTATION_FORGOT_PASSWORD);

  const handleForgotPassword = useCallback(
    (
      email: string,
      onCompleted?: (data: Nullable<MutationResponse>) => void,
    ) => {
      setError(null);
      forgotPassword({
        variables: { email },
        onCompleted: ({ forgotPassword }) => {
          if (!forgotPassword?.isSuccess)
            return setError(forgotPassword?.error);
          onCompleted?.(forgotPassword);
        },
      });
    },
    [],
  );

  return { handleForgotPassword, isLoading: loading, error, setError };
};

export const useResetPassword = () => {
  const [error, setError] = useState<Nullable<string>>();
  const [resetPassword, { loading }] = useMutation<
    ForgotPasswordSubmissionMutation,
    ForgotPasswordSubmissionMutationVariables
  >(MUTATION_FORGOT_PASSWORD_SUBMISSION);

  const handleResetPassword = useCallback(
    (
      data: Omit<EpUserForgotPasswordInput, 'timestamp'>,
      onCompleted?: (data: Nullable<MutationResponse>) => void,
    ) => {
      setError(null);
      const timemilis = new Date().getTime();
      resetPassword({
        variables: {
          data: {
            code: data.code,
            timestamp: timemilis,
            password: encodeAuthPassword('reset-password', 'password', {
              password: data.password,
              timemilis,
            }),
            confirmedPassword: encodeAuthPassword(
              'reset-password',
              'password-confirmation',
              {
                password: data.confirmedPassword,
                timemilis,
              },
            ),
          },
        },
        onCompleted: ({ forgotPasswordSubmission }) => {
          if (!forgotPasswordSubmission?.isSuccess)
            return setError(forgotPasswordSubmission?.error);
          onCompleted?.(forgotPasswordSubmission);
        },
      });
    },
    [],
  );

  return { handleResetPassword, isLoading: loading, error };
};

export const useResendSignUpLink = () => {
  const [error, setError] = useState<Nullable<string>>();
  const [resend, { loading }] = useMutation<
    ResendSignUpLinkMutation,
    ResendSignUpLinkMutationVariables
  >(MUTATION_RESEND_SIGNUP_LINK);

  const handleResend = useCallback(
    (
      id: string,
      onCompleted?: (data: Nullable<EpResendSignUpLinkResponse>) => void,
    ) => {
      setError(null);
      resend({
        variables: { id },
        onCompleted: ({ resendSignUpLink }) => {
          if (!resendSignUpLink?.isSuccess)
            return setError(resendSignUpLink?.error);
          onCompleted?.(resendSignUpLink);
        },
      });
    },
    [],
  );

  return {
    handleResend,
    isLoading: loading,
    error,
  };
};

export const useVerifySelfSignUpCode = () => {
  const [error, setError] = useState<Nullable<string>>();
  const [verify, { loading }] = useLazyQuery<
    VerifySelfSignUpCodeQuery,
    VerifySelfSignUpCodeQueryVariables
  >(QUERY_VERIFY_SELF_SIGNUP_CODE, {
    fetchPolicy: 'no-cache',
  });

  const handleVerify = useCallback(
    (code: string, onCompleted?: (data: Nullable<EpAuthResponse>) => void) => {
      setError(null);
      verify({
        variables: { code },
        onCompleted: ({ verifySelfSignUpCode }) => {
          onCompleted?.(verifySelfSignUpCode);
          if (!verifySelfSignUpCode?.isSuccess)
            return setError(verifySelfSignUpCode?.error);
        },
      });
    },
    [],
  );

  return {
    handleVerify,
    isLoading: loading,
    error,
  };
};

export const useSignOut = () => {
  const logoutConfirmationDialog = useAppSelector(
    (state) => state.dialogs.logoutConfirmationDialog,
  );
  const dispatch = useAppDispatch();

  const handleSignOut = async (isSendTracking = true) => {
    if (isSendTracking) {
      mpe.logout({
        logoutType: 'by user',
        logoutLocation: logoutConfirmationDialog?.content?.logoutLocation,
      });
    }
    dispatch({ type: RTD_ACTION_TYPE.RTD_UNSUBSCRIBE });
    logout();
    await signOutUser();
  };

  return { handleSignOut };
};

export const useChangePassword = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [handleChange, { loading: isChangingPassword }] = useMutation<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >(MUTATION_RESET_PASSWORD);

  const timemilis = new Date().getTime();

  const changePassword = (
    data: {
      password: string;
      confirmedPassword: string;
    },
    onCompleted?: (data: Nullable<MutationResponse>) => void,
  ) => {
    const payload = {
      data: {
        timestamp: timemilis,
        password: encodeAuthPassword('change-password', 'password', {
          password: data.password,
          timemilis,
        }),
        confirmedPassword: encodeAuthPassword(
          'change-password',
          'password-confirmation',
          {
            password: data.confirmedPassword,
            timemilis,
          },
        ),
      },
    };
    handleChange({
      variables: payload,
      onCompleted: ({ resetPassword }) => {
        if (resetPassword?.isSuccess) {
          reauthenticateWithCredentialUser(data.password);
          dispatch(
            setSnackbar({
              layout: 'auth',
              severity: 'success',
              message: t('common:updateSuccessful'),
            }),
          );
        }
        onCompleted?.(resetPassword);
      },
    });
  };

  return { changePassword, isChangingPassword };
};
