import { SvgIcon, SvgIconProps } from '@kitalulus/web-ui-kit';

const LocationIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 16 16" {...props}>
      <circle cx="8" cy="6.33333" r="1" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.66699 6.61333C2.69624 3.68866 5.07551 1.33318 8.00033 1.33333C10.9251 1.33318 13.3044 3.68866 13.3337 6.61333C13.3337 10.2667 8.66699 14.3333 8.43366 14.5067C8.18418 14.7201 7.81647 14.7201 7.56699 14.5067L7.5659 14.5057C7.35056 14.3191 2.66699 10.2603 2.66699 6.61333ZM5.66699 6.33333C5.66699 7.62199 6.71166 8.66666 8.00033 8.66666C8.61916 8.66666 9.21266 8.42083 9.65024 7.98324C10.0878 7.54566 10.3337 6.95217 10.3337 6.33333C10.3337 5.04466 9.28899 3.99999 8.00033 3.99999C6.71166 3.99999 5.66699 5.04466 5.66699 6.33333Z"
      />
    </SvgIcon>
  );
};

export default LocationIcon;
