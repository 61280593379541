import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoadingPage3DotsProps } from '~/components/AuthenticatedDialogs/LoadingPage3DotsDialog';

import { Nullable } from '~/types';

export type AuthenticatedDialogState = {
  loadingPage3DotsDialog?: Nullable<
    Pick<LoadingPage3DotsProps, 'open' | 'content'>
  >;
};

export const AuthenticatedDialogsSlice = createSlice({
  name: 'authenticatedDialogs',
  initialState: {} as AuthenticatedDialogState,
  reducers: {
    setAuthenticatedDialog: (
      state,
      action: PayloadAction<Partial<AuthenticatedDialogState>>,
    ) => {
      return { ...state, ...action.payload };
    },
    clearAuthenticatedDialog: (
      state,
      action: PayloadAction<keyof AuthenticatedDialogState>,
    ) => {
      state[action.payload] = null;
    },
    clearAuthenticatedDialogs: () => {
      return {};
    },
  },
});

export const {
  setAuthenticatedDialog,
  clearAuthenticatedDialog,
  clearAuthenticatedDialogs,
} = AuthenticatedDialogsSlice.actions;

export default AuthenticatedDialogsSlice.reducer;
