import { SvgIcon, SvgIconProps } from '@kitalulus/web-ui-kit';

const PhoneIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path d="M17.4 22.0001C8.89938 21.9891 2.01102 15.1007 2 6.60011C2 4.0596 4.05949 2.00011 6.6 2.00011C6.85834 1.99815 7.11625 2.02159 7.37 2.07011C7.61531 2.10641 7.85647 2.1667 8.09 2.25011C8.42419 2.36737 8.67143 2.65265 8.74 3.00011L10.11 9.00011C10.185 9.33103 10.0871 9.67742 9.85 9.92011C9.72 10.0601 9.71 10.0701 8.48 10.7101C9.46499 12.871 11.1932 14.6063 13.35 15.6001C14 14.3601 14.01 14.3501 14.15 14.2201C14.3927 13.983 14.7391 13.8851 15.07 13.9601L21.07 15.3301C21.4061 15.4081 21.6782 15.6538 21.79 15.9801C21.8744 16.2175 21.938 16.4617 21.98 16.7101C22.0202 16.9614 22.0403 17.2156 22.04 17.4701C22.0016 19.9989 19.929 22.0224 17.4 22.0001Z" />
    </SvgIcon>
  );
};

export default PhoneIcon;
