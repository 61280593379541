import {
  Alert,
  Box,
  brandColors,
  Button,
  Tooltip,
  Typography,
} from '@kitalulus/web-ui-kit';
import debounce from 'lodash.debounce';
import { useCallback, useRef, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import useDependentState from '~/hooks/use-dependent-state';
import { useAppDispatch, useAppSelector } from '~/hooks/use-store';
import { setBannerFreeTrial } from '~/store/views/banner-free-trial-slice';
import { setDialog } from '~/store/views/dialogs-slice';
import { EpFreeTrialStatus } from '~/types/graphql/graphql';
import * as mpe from '~/utils/mixpanel';

const BannerFreeTrial = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { realtimeData } = useAppSelector((state) => state);

  const realtimeDataCompany = realtimeData?.company;
  const packageRemainingDays =
    realtimeDataCompany?.data?.package?.remainingDays;
  const freeTrialStatus = realtimeDataCompany?.data?.package?.freeTrialStatus;
  const errorMessage = realtimeDataCompany?.data?.copywriting?.errorMessage;

  const elementRef = useRef<HTMLDivElement>(null);
  const [show, setShow] = useDependentState(!!errorMessage);

  const updateBanner = useCallback(() => {
    dispatch(
      setBannerFreeTrial({
        height: elementRef.current?.clientHeight ?? 0,
      }),
    );
  }, []);

  // debounce for better window resizing performance
  const updateBannerWithDebounce = useCallback(debounce(updateBanner, 100), [
    updateBanner,
  ]);

  // update banner size on toggle
  // using th original update banner (without debounce) for better load transition
  useEffect(() => {
    updateBanner();
  }, [show, updateBanner]);

  // update banner size on window resize
  useEffect(() => {
    window.addEventListener('resize', updateBannerWithDebounce);
    return () => {
      window.removeEventListener('resize', updateBannerWithDebounce);
    };
  }, [updateBannerWithDebounce]);

  const handleOpenModal = useCallback(() => {
    mpe.premiumOffering({
      linkPosition: 'FreeTrialEnd',
    });
    dispatch(
      setDialog({
        subscriptionPlanDialog: {
          open: true,
          content: {
            linkPosition: 'FreeTrialEnd',
          },
        },
      }),
    );
  }, []);

  const handleNavigateToPackageDetail = () => {
    mpe.premiumOffering({
      linkPosition: 'FreeTrialEnd',
    });
    navigate('/account/package-detail');
  };

  const contentTrialValid = useMemo(() => {
    return (
      <>
        <Typography variant="inherit" color="warning.darker" fontWeight={600}>
          {t('subscription:freeTrialBanner.trialValid.message', {
            packageRemainingDays,
          })}
          <Tooltip
            arrow
            title={t('subscription:freeTrialBanner.trialValid.tooltipMessage')}
          >
            <Box component="span" sx={{ textDecoration: 'underline' }}>
              {t('subscription:freeTrialBanner.trialValid.withTooltip')}
            </Box>
          </Tooltip>
        </Typography>

        <Button
          variant="contained"
          sx={{
            lineHeight: 'normal',
            color: brandColors.neutral[900],
            background: brandColors.secondary[200],
            boxShadow: 'none',
            ':hover': {
              bgcolor: brandColors.secondary[500],
            },
          }}
          onClick={handleOpenModal}
        >
          {t('common:upgradePackage')}
        </Button>
      </>
    );
  }, [handleOpenModal, realtimeData]);

  const contentTrialExpired = useMemo(() => {
    return (
      <>
        <Typography variant="inherit" color="warning.darker" fontWeight={600}>
          {t('subscription:freeTrialBanner.trialExpired.message')}
        </Typography>
        <Button
          variant="contained"
          sx={{
            lineHeight: 'normal',
            color: brandColors.neutral[900],
            background: brandColors.secondary[200],
            boxShadow: 'none',
            ':hover': {
              bgcolor: brandColors.secondary[500],
            },
          }}
          onClick={handleOpenModal}
        >
          {t('common:upgradePackage')}
        </Button>
      </>
    );
  }, [handleOpenModal]);

  const contentTrialValidQuotaExceed = useMemo(() => {
    return (
      <>
        <Typography variant="inherit" color="warning.darker" fontWeight={600}>
          {t('subscription:freeTrialBanner.trialValidQuotaExceed.message', {
            packageRemainingDays,
          })}
          <Tooltip
            arrow
            title={t(
              'subscription:freeTrialBanner.trialValidQuotaExceed.tooltipMessage',
            )}
          >
            <Box component="span" sx={{ textDecoration: 'underline' }}>
              {t(
                'subscription:freeTrialBanner.trialValidQuotaExceed.withTooltip',
              )}
            </Box>
          </Tooltip>
        </Typography>

        <Button
          variant="contained"
          sx={{
            lineHeight: 'normal',
            color: brandColors.neutral[900],
            background: brandColors.secondary[200],
            boxShadow: 'none',
            ':hover': {
              bgcolor: brandColors.secondary[500],
            },
          }}
          onClick={handleNavigateToPackageDetail}
        >
          {t('common:seeQuotaDetail')}
        </Button>
      </>
    );
  }, [handleOpenModal, realtimeData]);

  const contentTrialExpiredQuotaExceed = useMemo(() => {
    return (
      <>
        <Typography variant="inherit" color="warning.darker" fontWeight={600}>
          {t('subscription:freeTrialBanner.trialExpiredQuotaExceed.message')}
          <Tooltip
            arrow
            title={t(
              'subscription:freeTrialBanner.trialExpiredQuotaExceed.tooltipMessage',
            )}
          >
            <Box component="span" sx={{ textDecoration: 'underline' }}>
              {t(
                'subscription:freeTrialBanner.trialExpiredQuotaExceed.withTooltip',
              )}
            </Box>
          </Tooltip>
        </Typography>

        <Button
          variant="contained"
          sx={{
            lineHeight: 'normal',
            color: brandColors.neutral[900],
            background: brandColors.secondary[200],
            boxShadow: 'none',
            ':hover': {
              bgcolor: brandColors.secondary[500],
            },
          }}
          onClick={handleNavigateToPackageDetail}
        >
          {t('common:seeQuotaDetail')}
        </Button>
      </>
    );
  }, []);

  const contentQuotaExceeded = useMemo(() => {
    return (
      <>
        <Typography variant="inherit" color="warning.darker" fontWeight={600}>
          {t('subscription:freeTrialBanner.trialExceeded.message')}
          <Tooltip
            arrow
            title={t(
              'subscription:freeTrialBanner.trialExceeded.tooltipMessage',
            )}
          >
            <Box component="span" sx={{ textDecoration: 'underline' }}>
              {t('subscription:freeTrialBanner.trialExceeded.withTooltip')}
            </Box>
          </Tooltip>
        </Typography>

        <Button
          variant="contained"
          sx={{
            lineHeight: 'normal',
            color: brandColors.neutral[900],
            background: brandColors.secondary[200],
            boxShadow: 'none',
            ':hover': {
              bgcolor: brandColors.secondary[500],
            },
          }}
          onClick={() => navigate('/account/package-detail')}
        >
          {t('common:seeQuotaDetail')}
        </Button>
      </>
    );
  }, []);

  const content = useMemo(() => {
    switch (freeTrialStatus) {
      case EpFreeTrialStatus.FreeTrialValid:
        return contentTrialValid;
      case EpFreeTrialStatus.FreeTrialExpired:
        return contentTrialExpired;
      case EpFreeTrialStatus.FreeTrialValidAndQuotaExceeded:
        return contentTrialExpiredQuotaExceed;
      case EpFreeTrialStatus.QuotaExceeded:
        return contentQuotaExceeded;
      default:
        return errorMessage;
    }
  }, [
    freeTrialStatus,
    errorMessage,
    contentTrialValid,
    contentTrialExpired,
    contentTrialValidQuotaExceed,
    contentTrialExpiredQuotaExceed,
    contentQuotaExceeded,
  ]);

  return (
    <Box position="fixed" zIndex={1300} width="100%" top={0} ref={elementRef}>
      {show && (
        <Alert
          variant="standard"
          color="warning"
          severity="warning"
          sx={{
            alignItems: 'center',
            gap: 1.5,
            border: `1px solid ${brandColors.warning[300]}`,
            borderRadius: 0,
            '& .MuiAlert-message': {
              flex: 1,
              textAlign: { xs: 'left', lg: 'center' },
            },
            '& .MuiAlert-icon .MuiSvgIcon-root': {
              fontSize: { xs: '32px', lg: '24px' },
            },
          }}
          onClose={() => setShow(false)}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems={{ xs: 'flex-start', lg: 'center' }}
            gap={{ xs: '4px', lg: '10px' }}
            flexDirection={{ xs: 'column', lg: 'row' }}
          >
            {content}
          </Box>
        </Alert>
      )}
    </Box>
  );
};

export default BannerFreeTrial;
