import { useLazyQuery, useMutation } from '@apollo/client';
import { useCallback, useState } from 'react';

import {
  VacancyHighlightDialogContent,
  VacancyHighlightDialogAction,
} from '~/components/GlobalDialogs/VacancyHighlightDialog';
import {
  MUTATION_ACTIVATE_JOB_VACANCY_HIGHLIGHT,
  MUTATION_ACTIVATE_JOB_VACANCY_HIGHLIGHT_V2,
  MUTATION_DEACTIVATE_JOB_VACANCY_HIGHLIGHT,
  MUTATION_DEACTIVATE_JOB_VACANCY_HIGHLIGHT_V2,
  QUERY_VALIDATE_HIGHLIGHT_PROMPT,
} from '~/graphql/vacancy';
import { setDialog } from '~/store/views/dialogs-slice';
import { setSnackbar } from '~/store/views/snackbar-slice';
import { Nullable } from '~/types';
import {
  ActivateJobVacancyHighlightMutation,
  ActivateJobVacancyHighlightMutationVariables,
  ActivateJobVacancyHighlightV2Mutation,
  ActivateJobVacancyHighlightV2MutationVariables,
  DeactivateJobVacancyHighlightMutation,
  DeactivateJobVacancyHighlightMutationVariables,
  DeactivateJobVacancyHighlightV2Mutation,
  DeactivateJobVacancyHighlightV2MutationVariables,
  EpActivateJobVacancyHighlightV2ErrorEnum,
  ValidateHighlightPromptQuery,
} from '~/types/graphql/graphql';
import { useAppDispatch, useAppSelector } from './use-store';
import { useFeatureFlag } from './firebase/use-feature-flag';
import { useTranslation } from 'react-i18next';

export const useActivateJobVacancyHighlight = () => {
  const dispatch = useAppDispatch();
  const [error, setError] = useState<Nullable<string>>();
  const isPayPerPostActive = useFeatureFlag('pay_per_post');
  const { t } = useTranslation();

  const [activateVacancy, { loading }] = useMutation<
    ActivateJobVacancyHighlightMutation,
    ActivateJobVacancyHighlightMutationVariables
  >(MUTATION_ACTIVATE_JOB_VACANCY_HIGHLIGHT);

  const [activateVacancyV2, { loading: loadingV2 }] = useMutation<
    ActivateJobVacancyHighlightV2Mutation,
    ActivateJobVacancyHighlightV2MutationVariables
  >(MUTATION_ACTIVATE_JOB_VACANCY_HIGHLIGHT_V2);

  const handleActivateVacancy = useCallback(
    async (vacancyId: string) => {
      try {
        if (isPayPerPostActive) {
          const { data } = await activateVacancyV2({
            variables: { id: vacancyId },
          });
          if (
            data?.activateJobVacancyHighlightV2.__typename ===
            'EpActivateJobVacancyHighlightV2Error'
          ) {
            if (
              data.activateJobVacancyHighlightV2.typeError ===
              EpActivateJobVacancyHighlightV2ErrorEnum.AlreadyPromoted
            ) {
              dispatch(
                setSnackbar({
                  severity: 'error',
                  message: t('vacancy:activatePromoteError.alreadyPromoted'),
                }),
              );
            }
          }
          return {
            isSuccess:
              data?.activateJobVacancyHighlightV2.__typename ===
              'EpJobVacancyV3IdResponse',
          };
        } else {
          const { data } = await activateVacancy({
            variables: { jobVacancyId: vacancyId },
          });
          const res = data?.activateJobVacancyHighlight;
          if (!res?.isSuccess) throw new Error(res?.error ?? '');
          return res;
        }
      } catch (err: any) {
        setError(err.message);
        dispatch(
          setSnackbar({
            layout: 'dashboard',
            severity: 'error',
            message: err.message,
          }),
        );
      }
    },
    [activateVacancy],
  );

  return { handleActivateVacancy, isLoading: loading || loadingV2, error };
};

export const useDeactivateJobVacancyHighlight = () => {
  const dispatch = useAppDispatch();
  const isPayPerPostActive = useFeatureFlag('pay_per_post');
  const [error, setError] = useState<Nullable<string>>();
  const [deactivateVacancy, { loading }] = useMutation<
    DeactivateJobVacancyHighlightMutation,
    DeactivateJobVacancyHighlightMutationVariables
  >(MUTATION_DEACTIVATE_JOB_VACANCY_HIGHLIGHT);

  const [deactivateVacancyV2, { loading: loadingV2 }] = useMutation<
    DeactivateJobVacancyHighlightV2Mutation,
    DeactivateJobVacancyHighlightV2MutationVariables
  >(MUTATION_DEACTIVATE_JOB_VACANCY_HIGHLIGHT_V2);

  const handleDeactivateVacancy = useCallback(
    async (vacancyId: string) => {
      try {
        if (isPayPerPostActive) {
          const { data } = await deactivateVacancyV2({
            variables: { id: vacancyId },
          });
          return { isSuccess: !!data?.deactivateJobVacancyHighlightV2 };
        } else {
          const { data } = await deactivateVacancy({
            variables: { jobVacancyId: vacancyId },
          });
          const res = data?.deactivateJobVacancyHighlight;
          if (!res?.isSuccess) throw new Error(res?.error ?? '');
          return res;
        }
      } catch (err: any) {
        setError(err.message);
        dispatch(
          setSnackbar({
            layout: 'dashboard',
            severity: 'error',
            message: err.message,
          }),
        );
      }
    },
    [deactivateVacancy],
  );

  return { handleDeactivateVacancy, isLoading: loading || loadingV2, error };
};

export const useValidateJobVacancyHighlight = () => {
  const [error, setError] = useState<Nullable<string>>();
  const [validateVacancyHighlight, { loading }] =
    useLazyQuery<ValidateHighlightPromptQuery>(
      QUERY_VALIDATE_HIGHLIGHT_PROMPT,
      {
        fetchPolicy: 'no-cache',
      },
    );

  const handleValidateVacancyHighlight = useCallback(async () => {
    try {
      const { data } = await validateVacancyHighlight();
      return !!data?.validateHighlightPrompt;
    } catch (err: any) {
      setError(err.message);
      return false;
    }
  }, [validateVacancyHighlight]);

  return { handleValidateVacancyHighlight, isLoading: loading, error };
};

export const useJobVacancyHighlightComposer = () => {
  const dispatch = useAppDispatch();
  const isPayPerPostActive = useFeatureFlag('pay_per_post');
  const realtimeData = useAppSelector((state) => state.realtimeData);
  const { handleValidateVacancyHighlight } = useValidateJobVacancyHighlight();
  const [error, setError] = useState<Nullable<string>>();
  const [isLoading, setIsLoading] = useState(false);

  const handleVacancyHighlight = useCallback(
    async (
      action: VacancyHighlightDialogAction,
      vacancy: VacancyHighlightDialogContent['vacancy'],
      onCompleted?: VacancyHighlightDialogContent['onCompleted'],
    ) => {
      try {
        setIsLoading(true);
        let isHighlightQuotaAvailable = true;
        // validate is company able to do more highlight
        if (action === 'activate') {
          if (isPayPerPostActive) {
            const current =
              realtimeData.highlightedByCompanyId.data?.current ?? 0;
            const max = realtimeData.highlightedByCompanyId.data?.max ?? 0;
            isHighlightQuotaAvailable = current < max;
          } else {
            // validate only if ppp toggled off
            isHighlightQuotaAvailable = await handleValidateVacancyHighlight();
          }
        }

        // if it's allowed show confirmation modal
        if (isHighlightQuotaAvailable) {
          if (action === 'activate') {
            return dispatch(
              setDialog({
                vacancyHighlightDialog: {
                  open: true,
                  content: {
                    action,
                    vacancy,
                    onCompleted,
                  },
                },
              }),
            );
          } else {
            return dispatch(
              setDialog({
                vacancyUnHighlightDialog: {
                  open: true,
                  content: {
                    action,
                    vacancy,
                    onCompleted,
                  },
                },
              }),
            );
          }
        }

        // if it's not, show limit quota modal
        dispatch(
          setDialog({ vacancyHighlightQuotaExceededDialog: { open: true } }),
        );
      } catch (err: any) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    },
    [realtimeData],
  );

  return { handleVacancyHighlight, isLoading, error };
};
