import { SvgIcon, SvgIconProps } from '@kitalulus/web-ui-kit';

const LinkedIn = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 18 17" {...props}>
      <path d="M3.96585 5.4624H0.54555C0.393752 5.4624 0.270752 5.58546 0.270752 5.7372V16.7252C0.270752 16.877 0.393752 17 0.54555 17H3.96585C4.11765 17 4.24065 16.877 4.24065 16.7252V5.7372C4.24065 5.58546 4.11765 5.4624 3.96585 5.4624Z" />
      <path d="M2.25697 0C1.01247 0 0 1.01137 0 2.2545C0 3.49818 1.01247 4.50993 2.25697 4.50993C3.50049 4.50993 4.51213 3.49812 4.51213 2.2545C4.51218 1.01137 3.50049 0 2.25697 0Z" />
      <path d="M12.6657 5.18933C11.292 5.18933 10.2765 5.77987 9.66058 6.45087V5.73722C9.66058 5.58548 9.53758 5.46243 9.38579 5.46243H6.11025C5.95845 5.46243 5.83545 5.58548 5.83545 5.73722V16.7252C5.83545 16.877 5.95845 17 6.11025 17H9.52307C9.67487 17 9.79787 16.877 9.79787 16.7252V11.2887C9.79787 9.45673 10.2955 8.74302 11.5725 8.74302C12.9633 8.74302 13.0739 9.88717 13.0739 11.383V16.7252C13.0739 16.877 13.1969 17 13.3486 17H16.7627C16.9145 17 17.0375 16.877 17.0375 16.7252V10.6982C17.0375 7.97408 16.5181 5.18933 12.6657 5.18933Z" />
    </SvgIcon>
  );
};

export default LinkedIn;
