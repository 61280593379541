import { store } from '~/store';
import { clearApplicantCount } from '~/store/features/applicant-count-slice';
import { clearApplicantDetail } from '~/store/features/applicant-detail-slice';
import { clearAuth } from '~/store/features/auth-slice';
import { clearCompany } from '~/store/features/company-slice';
import { clearDashboard } from '~/store/features/dashboard-slice';
import { clearMenus } from '~/store/features/menus-slice';
import { clearUserConfig } from '~/store/features/user-config-slice';
import { clearUser } from '~/store/features/user-slice';
import { clearVacancyDetail } from '~/store/features/vacancy-detail-slice';
import { clearOnboarding } from '~/store/views/onboarding-slice';
import { clearVisibility } from '~/store/views/visibility-slice';
import { clearRealtimeData } from '~/store/features/realtime-data-slice';
import {
  LOGOUT_ACTION,
  createAuthBroadcastChannel,
} from '../broadcast-channel';
import * as mpe from '../mixpanel';

export const logout = ({ runBroadcast = true } = {}) => {
  // clear auth data
  store.dispatch(clearAuth());
  // clear all states related to authenticated user data
  store.dispatch(clearApplicantCount());
  store.dispatch(clearApplicantDetail());
  store.dispatch(clearCompany());
  store.dispatch(clearDashboard());
  store.dispatch(clearMenus());
  store.dispatch(clearOnboarding());
  store.dispatch(clearUser());
  store.dispatch(clearUserConfig());
  store.dispatch(clearVacancyDetail());
  store.dispatch(clearVisibility());
  store.dispatch(clearRealtimeData());

  // clear mixpanel to reset user attributes
  mpe.reset();

  // optionally run broadcast to force logout on all opened tabs
  // to avoid infinite broadcasting
  // runBroadcast will be `false` only when it's updated from the broadcast listener
  if (runBroadcast) {
    const channel = createAuthBroadcastChannel();
    channel.postMessage(LOGOUT_ACTION);
  }
};
