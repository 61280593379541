import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type PreloadedImagesState = {
  disconnectedImage?: string;
};

export const preloadedImagesSlice = createSlice({
  name: 'preloaded-images',
  initialState: {} as PreloadedImagesState,
  reducers: {
    setPreloadImage: <T extends keyof PreloadedImagesState>(
      state: PreloadedImagesState,
      action: PayloadAction<{ key: T; value: PreloadedImagesState[T] }>,
    ) => {
      state[action.payload.key] = action.payload.value;
    },
    clearPreloadImages: () => ({}),
  },
});

export const { setPreloadImage } = preloadedImagesSlice.actions;

export default preloadedImagesSlice.reducer;
