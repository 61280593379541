import { ComponentType } from 'react';
import { Navigate, useLocation, useSearchParams } from 'react-router-dom';
import AuthenticatedDialogs from '~/components/AuthenticatedDialogs';

import { useAuthValue } from '~/hooks/use-auth';
import { useAppSelector } from '~/hooks/use-store';
import { useUserOnboarding } from '~/hooks/use-user-onboarding';
import Company from '~/pages/CompanyForm';
import SubscriptionOffer from '~/pages/SubscriptionOffer';
import { EpCompanyVerificationStatus } from '~/types/graphql/graphql';
import { sanitizeUrl, removeOriginFromUrl } from '~/utils/url';
import { generateQueryString } from '~/utils/helper';
import { AUTH_STATUS, ONBOARDING_STEPS } from '~/utils/constants';

export function withAuthorized<T extends JSX.IntrinsicAttributes>(
  Content: ComponentType<T>,
): ComponentType<T> {
  return function AuthorizedContent(props) {
    const { auth } = useAuthValue();
    const { onboarding } = useUserOnboarding();
    const company = useAppSelector((state) => state.company);
    const realtimeData = useAppSelector((state) => state.realtimeData);

    const location = useLocation();
    const realtimeDataCompany = realtimeData.company;

    const redirectUrl =
      location && location.key === 'default' && location.pathname !== '/'
        ? sanitizeUrl(
            `${window.location.origin}${location.pathname}${location.search}`,
          )
        : '';

    const redirectQueryString = redirectUrl
      ? `?${generateQueryString({
          redirect: redirectUrl,
        })}`
      : '';

    if (!auth.status || auth.status === AUTH_STATUS.UNAUTHENTICATED)
      return <Navigate to={`/auth/signin${redirectQueryString}`} />;

    // fraud checking
    if (
      realtimeDataCompany?.data?.company?.status ===
      EpCompanyVerificationStatus.Fraud
    )
      return <Navigate to="/info/fraud" />;

    // onboarding pages
    if (!company && onboarding.currentStep === ONBOARDING_STEPS.COMPANY_FORM)
      return <Company />;
    if (onboarding.currentStep === ONBOARDING_STEPS.SUBSCRIPTION_OFFER)
      return <SubscriptionOffer />;

    return (
      <>
        <AuthenticatedDialogs />
        <Content {...props} />
      </>
    );
  };
}

export function withUnauthorized<T extends JSX.IntrinsicAttributes>(
  Content: ComponentType<T>,
): ComponentType<T> {
  return function UnauthorizedContent(props) {
    const { auth } = useAuthValue();
    const [searchParams] = useSearchParams();
    const redirectParam = searchParams.get('redirect');
    const redirectUrl = sanitizeUrl(redirectParam ?? '');
    const redirectPath = removeOriginFromUrl(redirectUrl);

    if (auth.status && auth.status === AUTH_STATUS.AUTHENTICATED)
      return <Navigate to={redirectPath || '/dashboard'} />;

    return <Content {...props} />;
  };
}
