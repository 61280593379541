import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@kitalulus/web-ui-kit';
import { useTranslation } from 'react-i18next';

import CloseIcon from '~/components/Icons/CloseIcon';
import { InterviewInvitationSessionSuccessDialogProps } from '.';

const InterviewInvitationSessionSuccessDialog = ({
  title,
  description,
  onContinue,
  onCancel,
  onClose,
}: InterviewInvitationSessionSuccessDialogProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      open
      fullWidth
      onClose={onClose}
      PaperProps={{
        sx: {
          maxWidth: '500px',
          width: 'auto',
          mx: 2,
          p: 4,
          ...(isMobile && { p: 3 }),
        },
      }}
    >
      <Stack gap={3}>
        <DialogTitle component="div" sx={{ p: 0 }}>
          <Stack
            direction="row"
            alignItems="flex-start"
            justifyContent="space-between"
            gap={2}
          >
            <Typography
              variant="h6"
              color="text.primary"
              fontSize="18px !important"
            >
              {title}
            </Typography>

            <IconButton
              aria-label="close"
              size="small"
              edge="end"
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>

        <DialogContent sx={{ p: 0 }}>
          <Typography variant="body1">{description}</Typography>
        </DialogContent>

        <DialogActions sx={{ p: 0, gap: 0.5 }}>
          <Button
            variant="outlined"
            color="inherit"
            onClick={onCancel}
            sx={{ flex: isMobile ? 1 : undefined }}
          >
            {t(
              'interview-invitation:interviewInvitationSessionSuccessDialog.cancel',
            )}
          </Button>
          <Button variant="contained" color="info" onClick={onContinue}>
            {t(
              'interview-invitation:interviewInvitationSessionSuccessDialog.continue',
            )}
          </Button>
        </DialogActions>
      </Stack>
    </Dialog>
  );
};

export default InterviewInvitationSessionSuccessDialog;
