import { useMemo } from 'react';
import { useFeatureFlag } from './firebase/use-feature-flag';
import { useAppSelector } from './use-store';
import { EpUserSubscriptionPlanTypeEnum } from '~/types/graphql/graphql';
import { FETCH_STATUS } from '~/utils/constants';

const usePayPerPost = () => {
  const realtimeData = useAppSelector((state) => state.realtimeData);
  const isPayPerPostActive = useFeatureFlag('pay_per_post');
  const isFreePlan = useMemo(() => {
    if (
      realtimeData.packageByCompanyId.fetchStatus !== FETCH_STATUS.RESOLVED &&
      realtimeData.company.fetchStatus !== FETCH_STATUS.RESOLVED
    )
      return;

    if (isPayPerPostActive)
      return !realtimeData.packageByCompanyId.data?.isPremium;
    return (
      realtimeData.company?.data?.package?.subscription ===
      EpUserSubscriptionPlanTypeEnum.Fp
    );
  }, [isPayPerPostActive, realtimeData]);

  return { isFreePlan };
};

export default usePayPerPost;
