import { useLazyQuery, useMutation } from '@apollo/client';
import debounce from 'lodash.debounce';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  MUTATION_UPDATE_JOB_APPLICATION_IS_READ,
  MUTATION_UPDATE_JOB_APPLICATION_NOTE,
  MUTATION_UPDATE_JOB_APPLICATION_STATUS_AND_REMARKS,
  QUERY_JOB_APPLICATION,
  QUERY_JOB_APPLICATION_PREV_NEXT,
} from '~/graphql/applicants';
import {
  setApplicantDetail,
  setApplicantDetailError,
  setApplicantPrevNext,
  setApplicantFetchStatusPrevNext,
  setApplicantDetailStatus,
  setApplicantDetailIsUpdatingStatus,
  setApplicantScreeningQuestion,
} from '~/store/features/applicant-detail-slice';
import { clearDialog } from '~/store/views/dialogs-slice';
import { setSnackbar } from '~/store/views/snackbar-slice';
import {
  EpJobApplicationEpStatusEnum,
  EpJobApplicationRemarks,
  EpJobApplicationStatusAndRemarksUpdateInput,
  JobApplicationPrevNextQuery,
  JobApplicationPrevNextQueryVariables,
  JobApplicationQuery,
  JobApplicationQueryVariables,
  UpdateJobApplicationIsReadMutation,
  UpdateJobApplicationIsReadMutationVariables,
  UpdateJobApplicationNoteMutation,
  UpdateJobApplicationNoteMutationVariables,
  UpdateJobApplicationStatusAndRemarksMutation,
} from '~/types/graphql/graphql';
import { APPLICANT_TAB_PRIMARY, FETCH_STATUS } from '~/utils/constants';
import { generateQueryString } from '~/utils/helper';
import { generateWhatsappLink } from '~/utils/whatsapp';
import { useMultiQueryState } from '../use-query-state';
import {
  ApplicantPageQueryParams,
  useApplicantPageQuery,
} from '../page-query/use-applicant-page-query';
import { useAppDispatch, useAppSelector } from '../use-store';
import { useVacancyDetail } from '../use-vacancy-detail';
import { useMutateApplicantStatus } from '../applicants/use-applicant-status';
import { useApplicantRejectReasons } from './use-applicant-reject-reasons';
import { GRAPHQL_ERROR_CODE } from '~/utils/constants/graphql-error-code';
import { useApplicantScreeningQuestion } from './use-applicant-screening-question';
import useApplicantScreeningQuestionFilter from '../applicants/use-applicant-screening-question-filter';

interface ICopmpanyNote {
  companyNote: string;
}

interface IRemarksNote {
  remarksNote: string;
}

export type TypeApplicantDetailQueryParams = {
  id: string;
};

export const useApplicantDetailFetch = (
  runPrefecth = false,
  runPrevNext = true,
) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [urlParams] = useApplicantPageQuery();
  const { getVacancyDetail } = useVacancyDetail(true);
  const { fetchApplicantScreeningQuestion } = useApplicantScreeningQuestion();
  const {
    screeningQuestionPersistedFilter,
    generateScreeningQuestionPayload,
    loading: IDBLoading,
  } = useApplicantScreeningQuestionFilter(urlParams.vacancy_id);
  const { applicantDetail, vacancyDetailV2 } = useAppSelector((state) => state);

  const haveScreeningQuestions =
    vacancyDetailV2?.screeningQuestionsFilter?.fetchStatus ===
      FETCH_STATUS.RESOLVED &&
    vacancyDetailV2?.screeningQuestionsFilter?.data?.length;

  const dispatch = useAppDispatch();

  const [params] = useMultiQueryState<TypeApplicantDetailQueryParams>({
    id: '',
  });

  const handleBackToApplicants = () => {
    const { id: _, ...rest } = urlParams as ApplicantPageQueryParams & {
      id: string;
    };
    return `/applicants/?${generateQueryString({
      ...rest,
    })}`;
  };

  const [getApplicationPrevNext] = useLazyQuery<
    JobApplicationPrevNextQuery,
    JobApplicationPrevNextQueryVariables
  >(QUERY_JOB_APPLICATION_PREV_NEXT, {
    fetchPolicy: 'no-cache',
    onCompleted: async ({ jobApplicationPrevNext }) => {
      if (!jobApplicationPrevNext) return;
      dispatch(setApplicantPrevNext(jobApplicationPrevNext));
    },
  });

  const [
    getApplicantDetail,
    { loading: _loadingApplicantDetail, refetch: _refetchApplicationDetail },
  ] = useLazyQuery<JobApplicationQuery, JobApplicationQueryVariables>(
    QUERY_JOB_APPLICATION,
    {
      fetchPolicy: 'no-cache',
      onCompleted: async ({ jobApplication }) => {
        const vacancyId = jobApplication?.jobVacancyId;
        if (!vacancyId) return;
        getVacancyDetail({
          variables: {
            id: vacancyId,
          },
        });

        if (runPrevNext) {
          dispatch(setApplicantFetchStatusPrevNext(FETCH_STATUS.PENDING));
          getApplicationPrevNext({
            variables: {
              id: params.id,
              jobVacancyId: vacancyId,
              epStatus:
                urlParams.active_tab_secondary ||
                jobApplication?.userProfile?.companyStatus,
              tab: urlParams.active_tab_primary || APPLICANT_TAB_PRIMARY.ALL,
              lastEducationLevels: urlParams.educations ?? [],
              educationInstitutionIds: urlParams.education_institutions?.map(
                (educInstitution) => educInstitution.value,
              ),
              gender: urlParams.gender ?? 'all',
              name: urlParams.name.toString() || '',
              sort: urlParams.sort || 'DESC',
              locations: urlParams.location_values.map((loc) => ({
                areaType: loc.additional,
                name: loc.value,
              })),
              jobSpecializationRoles: urlParams.job_functions?.map(
                (jobFunction) => jobFunction.value,
              ),
              skillsV2: urlParams.skills?.map((skill) => skill.value),
              ageMin: urlParams.age_min,
              ageMax: urlParams.age_max,
              minSalary: urlParams.salary_min,
              maxSalary: urlParams.salary_max,
              experienceYear: urlParams.experience_year?.value,
              ...(haveScreeningQuestions && {
                screeningQuestions: generateScreeningQuestionPayload(
                  screeningQuestionPersistedFilter,
                ),
              }),
            },
          });
        }

        if (haveScreeningQuestions && jobApplication?.id) {
          dispatch(
            setApplicantScreeningQuestion({
              fetchStatus: FETCH_STATUS.PENDING,
            }),
          );

          fetchApplicantScreeningQuestion({
            variables: {
              jobApplicantId: jobApplication?.id,
              screeningQuestions: generateScreeningQuestionPayload(
                screeningQuestionPersistedFilter,
              ),
            },
          });
        }

        setIsLoading(false);
        if (!jobApplication) return;
        dispatch(
          setApplicantDetail({
            ...jobApplication,
          }),
        );
      },
      onError: (error) => {
        const extensionCode = error.graphQLErrors.map(
          (error) => error.extensions?.code,
        );
        const isNotFound = extensionCode.includes(GRAPHQL_ERROR_CODE.NOT_FOUND);
        const errorCode = isNotFound ? GRAPHQL_ERROR_CODE.NOT_FOUND : '';
        dispatch(setApplicantDetailError(errorCode));
      },
    },
  );

  const [
    updateApplicantAsRead,
    { data: _dataProfile, loading: _loadingProfile },
  ] = useMutation<
    UpdateJobApplicationIsReadMutation,
    UpdateJobApplicationIsReadMutationVariables
  >(MUTATION_UPDATE_JOB_APPLICATION_IS_READ, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
  });

  const doRefetch = useCallback(() => {
    // Prevent fetching data if applicant preview not found
    if (applicantDetail.error === GRAPHQL_ERROR_CODE.NOT_FOUND && IDBLoading)
      return;

    setIsLoading(true);
    getApplicantDetail({
      variables: {
        id: params.id,
      },
    });
  }, [params.id, IDBLoading]);

  useEffect(() => {
    if (!runPrefecth) return;
    doRefetch();
  }, [doRefetch, runPrefecth, params.id]);

  const loading = _loadingProfile || _loadingApplicantDetail || isLoading;

  return {
    isLoading: loading,
    doRefetch,
    updateApplicantAsRead,
    handleBackToApplicants,
    urlParams,
  };
};

export const useApplicantDetailMutate = (applicantID: string) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isSuccess, setIsSuccess] = useState(false);
  const noteState = useForm<ICopmpanyNote>();
  const remarksState = useForm<IRemarksNote>();
  const { doRefetch } = useApplicantDetailFetch(false, true);
  const { changeApplicantStatus } = useMutateApplicantStatus();
  const { queryRejectReasons } = useApplicantRejectReasons();
  const { applicantDetail } = useAppSelector((state) => state);
  const { data: dataApplicantDetail } = applicantDetail;

  const [handleUpdateNote, { loading: _loadingSaveNote, data: _dataNote }] =
    useMutation<
      UpdateJobApplicationNoteMutation,
      UpdateJobApplicationNoteMutationVariables
    >(MUTATION_UPDATE_JOB_APPLICATION_NOTE, {
      notifyOnNetworkStatusChange: true,
      onCompleted: ({ updateJobApplicationNote }) => {
        if (updateJobApplicationNote) {
          setIsSuccess(updateJobApplicationNote.isSuccess);
          doRefetch();
          if (updateJobApplicationNote.error) {
            dispatch(
              setSnackbar({
                layout: 'dashboard',
                severity: 'error',
                message: updateJobApplicationNote.error,
              }),
            );
            noteState.resetField('companyNote');
          }
        }
        setTimeout(() => setIsSuccess(false), 3000);
      },
    });

  const [handleUpdateRemark, { loading: _loadingRemarks }] =
    useMutation<UpdateJobApplicationStatusAndRemarksMutation>(
      MUTATION_UPDATE_JOB_APPLICATION_STATUS_AND_REMARKS,
      {
        fetchPolicy: 'no-cache',
      },
    );

  const doHandleUpdateStatus = async (
    status: EpJobApplicationEpStatusEnum,
    reasons: string[] | null,
  ) => {
    dispatch(setApplicantDetailIsUpdatingStatus(true));
    const { isSuccess } = await changeApplicantStatus({
      ids: [applicantID],
      status,
      reasons,
    });
    dispatch(setApplicantDetailIsUpdatingStatus(false));
    if (isSuccess) {
      doRefetch();
      dispatch(clearDialog('applicantInterviewDialog'));

      let snackBarMessage = '';
      switch (status) {
        case EpJobApplicationEpStatusEnum.Accepted:
          dispatch(clearDialog('applicantAcceptDialog'));
          snackBarMessage = t('applicant:acceptDialog.acceptMessage');
          break;
        case EpJobApplicationEpStatusEnum.Rejected:
          dispatch(clearDialog('applicantRejectDialog'));
          snackBarMessage = t('applicant:rejectDialog.rejectMessage');
          break;
        default:
          snackBarMessage = t('applicant-detail:statusAlert.changeStatus');
      }
      dispatch(
        setSnackbar({
          layout: 'dashboard',
          severity: 'success',
          message: snackBarMessage,
          closeable: true,
        }),
      );
    }
    dispatch(clearDialog('applicantFeedbackDialog'));
  };

  const handleComplete = (
    updateJobApplicationStatusAndRemarks: EpJobApplicationRemarks,
    type: 'email' | 'wa' | 'skip',
    status: EpJobApplicationEpStatusEnum,
  ) => {
    const { isSuccess, data, error } = updateJobApplicationStatusAndRemarks;

    if (isSuccess || data?.note) {
      doRefetch();
      const copywritingKey =
        status === EpJobApplicationEpStatusEnum.Accepted ? 'accept' : 'reject';
      dispatch(
        setSnackbar({
          layout: 'dashboard',
          severity: 'success',
          message:
            type === 'skip'
              ? t(`applicant-detail:remarks.${copywritingKey}.withOutRemarks`)
              : t(`applicant-detail:remarks.${copywritingKey}.withRemarks`),
        }),
      );
      if (type === 'wa') {
        const waMessage = `${data?.note}`;
        if (dataApplicantDetail?.userProfile?.phoneNumber) {
          window.open(
            generateWhatsappLink(
              dataApplicantDetail?.userProfile?.phoneNumber,
              waMessage,
            ),
            '_blank',
          );
        }
      }
      dispatch(setApplicantDetailStatus(status));
      dispatch(clearDialog('remarksDialog'));
      dispatch(clearDialog('applicantFeedbackDialog'));
    } else {
      dispatch(
        setSnackbar({
          layout: 'dashboard',
          severity: 'error',
          message: error ?? '',
        }),
      );
    }
  };

  const doHandleUpdateRemarks = (
    type: 'email' | 'wa' | 'skip',
    status?: EpJobApplicationEpStatusEnum,
  ) => {
    const payload: EpJobApplicationStatusAndRemarksUpdateInput = {
      id: applicantID,
      companyStatus: status ?? '',
      note: remarksState.getValues('remarksNote'),
      isSendEmail: type === 'email',
      isSendNotification: true,
    };
    handleUpdateRemark({
      variables: {
        data: payload,
      },
      onCompleted: async ({ updateJobApplicationStatusAndRemarks }) => {
        if (!updateJobApplicationStatusAndRemarks || !status) return;
        handleComplete(updateJobApplicationStatusAndRemarks, type, status);
      },
      onError: (err) => {
        dispatch(
          setSnackbar({
            layout: 'dashboard',
            severity: 'error',
            message: err.message,
          }),
        );
      },
    });
  };

  const handleCopy = async (text: string) => {
    await navigator.clipboard.writeText(text ?? '').then(() => {
      dispatch(
        setSnackbar({
          layout: 'dashboard',
          severity: 'success',
          message: t('applicant-detail:noteCopyClipboard'),
        }),
      );
    });
  };

  const doSaveNotes = useCallback(
    debounce((data: ICopmpanyNote) => {
      handleUpdateNote({
        variables: {
          data: {
            id: applicantID,
            companyNote: data.companyNote.slice(0, 2000),
          },
        },
      });
    }, 1000),
    [],
  );

  useEffect(() => {
    if (noteState.formState.isDirty) {
      doSaveNotes(noteState.getValues());
    }
  }, [noteState.watch('companyNote')]);

  useEffect(() => {
    // Query only reject reasons when status is rejected
    if (
      applicantDetail.data?.id &&
      applicantDetail.data.companyStatus ===
        EpJobApplicationEpStatusEnum.Rejected
    )
      queryRejectReasons(applicantDetail.data.id);
  }, [applicantDetail.data?.id]);

  const loading = _loadingSaveNote || _loadingRemarks;

  return {
    isLoading: loading,
    isSuccess,
    doHandleUpdateStatus,
    doHandleUpdateRemarks,
    noteState,
    remarksState,
    handleCopy,
    doSaveNotes,
  };
};
