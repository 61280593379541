import { appConfig } from '~/config/app';

export type PasswordAuthPurposes =
  | 'signup'
  | 'reset-password'
  | 'change-password';
export type PasswordAuthTargets = 'password' | 'password-confirmation';

export function encodeAuthPassword(
  purpose: PasswordAuthPurposes,
  target: PasswordAuthTargets,
  data: { password: string; email?: string; timemilis: number },
) {
  const prefixNumber: Record<PasswordAuthTargets, string> = {
    password: '1',
    'password-confirmation': '2',
  };
  const purposeKey: Record<PasswordAuthPurposes, string> = {
    signup: data.email ?? '',
    'change-password': 'CHANGE_PASS',
    'reset-password': 'FORGOT_PASS',
  };

  return btoa(
    `${prefixNumber[target]}:${purposeKey[purpose]}:${appConfig.authSecretKey}:${data.timemilis}:${data.password}`,
  );
}
