import Cookies from 'js-cookie';
import { WebStorage } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

export const localStorage = storage;

export const cookies: WebStorage = {
  getItem: async (key) => {
    return Cookies.get(key) ?? null;
  },
  setItem: async (key, item) => {
    const expDate = new Date();
    expDate.setMonth(expDate.getMonth() + 1);
    Cookies.set(key, item, { secure: true, expires: expDate });
  },
  removeItem: async (key) => {
    Cookies.remove(key);
  },
};
