import {
  Box,
  brandColors,
  Stack,
  Typography,
  Grid,
} from '@kitalulus/web-ui-kit';
import { useTranslation } from 'react-i18next';
import ConsFaceUnlockIcon from '~/components/ConsComponents/Icons/ConsFaceUnlockIcon';
import ConsFactCheckIcon from '~/components/ConsComponents/Icons/ConsFactCheckIcon';
import ConsPersonIcon from '~/components/ConsComponents/Icons/ConsPersonIcon';
import ConsSchoolIcon from '~/components/ConsComponents/Icons/ConsSchoolIcon';
import { VacancyPreviewDialogContent } from '../VacancyPreviewDialog.types';

const BoxTwo = ({ content }: { content: VacancyPreviewDialogContent }) => {
  const { t } = useTranslation();
  return (
    <Box sx={{ bgcolor: '#FFF', py: 4, px: 2 }}>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Stack direction="row" mt={2} gap={2}>
            <ConsSchoolIcon color="primary" />
            <Typography variant="caption" color={brandColors.neutral[700]}>
              {t('vacancy-form:preview.education')}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack direction="row" mt={2} gap={2}>
            <ConsFaceUnlockIcon color="primary" />
            <Typography variant="caption" color={brandColors.neutral[700]}>
              {t('vacancy-form:preview.gender')}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">
            {content?.education ?? t('vacancy-form:preview.noConditions')}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">
            {content?.gender ?? t('vacancy-form:preview.noConditions')}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Stack direction="row" mt={2} gap={2}>
            <ConsPersonIcon color="primary" />
            <Typography variant="caption" color={brandColors.neutral[700]}>
              {t('vacancy-form:preview.age')}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack direction="row" mt={2} gap={2}>
            <ConsFactCheckIcon color="primary" />
            <Typography variant="caption" color={brandColors.neutral[700]}>
              {t('vacancy-form:preview.experience')}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">
            {content?.age
              ? t('vacancy-form:preview.ageStr', { age: content.age })
              : t('vacancy-form:preview.noConditions')}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">
            {content?.experience
              ? t('vacancy-form:preview.experienceStr', {
                  experience: content.experience,
                })
              : t('vacancy-form:preview.noConditions')}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BoxTwo;
