import { useMutation } from '@apollo/client';
import { MUTATION_SUBMIT_CONTACTED_PHONE_NUMBER } from '~/graphql/vacancy';
import {
  SubmitContactedPhoneNumberMutation,
  SubmitContactedPhoneNumberMutationVariables,
} from '~/types/graphql/graphql';
import { useAppDispatch } from '../use-store';
import { setSnackbar } from '~/store/views/snackbar-slice';

const useOfferingModalConfirmation = () => {
  const dispatch = useAppDispatch();
  const [submitContact, { loading }] = useMutation<
    SubmitContactedPhoneNumberMutation,
    SubmitContactedPhoneNumberMutationVariables
  >(MUTATION_SUBMIT_CONTACTED_PHONE_NUMBER);

  const saveWhatsappNumber = async ({
    packageId,
    phoneNumber,
  }: {
    packageId: string;
    phoneNumber: string;
  }) => {
    try {
      const res = await submitContact({
        variables: { packageId, phoneNumber },
      });

      const data = res.data?.submitContactedPhoneNumber;
      if (!data) return;
      if (data.__typename === 'EpSubmitContactedPhoneNumberResponseError') {
        throw new Error(data.message ?? 'Unknown');
      }

      return data;
    } catch (err: any) {
      dispatch(
        setSnackbar({
          severity: 'error',
          message: err.message,
        }),
      );
    }
  };
  return { saveWhatsappNumber, loading };
};

export default useOfferingModalConfirmation;
