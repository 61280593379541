import {
  signOut,
  signInWithEmailAndPassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
  getIdTokenResult,
  User,
} from 'firebase/auth';
import { firebaseAuth } from '~/config/firebase';

export const signInAuthUserWithEmailAndPassword = async (
  email: string,
  password: string,
) => {
  if (!email || !password) return;

  return await signInWithEmailAndPassword(firebaseAuth, email, password);
};

export const signOutUser = async () => await signOut(firebaseAuth);

export const reauthenticateWithCredentialUser = async (password: string) => {
  if (!firebaseAuth.currentUser) return;

  try {
    const credential = EmailAuthProvider.credential(
      firebaseAuth.currentUser.email || '',
      password,
    );
    await reauthenticateWithCredential(firebaseAuth.currentUser, credential);
  } catch (error) {
    Promise.reject(error);
  }
};

export const getIdTokenAuth = async (userCredential: User) => {
  try {
    const idTokenResult = await getIdTokenResult(userCredential);
    const claims = idTokenResult.claims;
    return claims;
  } catch (error) {
    Promise.reject(error);
  }
};
