import { Box, Stack, useMediaQuery, useTheme } from '@kitalulus/web-ui-kit';

import ChevronLeftIcon from '~/components/Icons/ChevronLeftIcon';
import ChevronRightIcon from '~/components/Icons/ChevronRightIcon';
import LoadingButton from '~/components/LoadingButton';
import { InterviewInvitationFormNavigationProps } from './';

const InterviewInvitationFormNavigation = ({
  activeStep,
  navigationButtons,
  ...props
}: InterviewInvitationFormNavigationProps) => {
  // responsive state
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigation = navigationButtons[activeStep];

  if (!navigation) return null;

  const spaceGap = navigation.back && navigation.next ? 2 : 0;

  return (
    <Stack
      direction={isMobile ? 'column-reverse' : 'row'}
      alignItems={isMobile ? 'center' : 'stretch'}
      justifyContent="space-between"
      width="100%"
      gap={spaceGap}
      {...props}
    >
      <Box width={isMobile ? '100%' : 'auto'}>
        {navigation.back && (
          <LoadingButton
            fullWidth={isMobile}
            variant="text"
            color="inherit"
            startIcon={<ChevronLeftIcon transform="scale(0.6)" />}
            {...navigation.back}
            sx={{ py: 1, textTransform: 'none', ...navigation.back.sx }}
          />
        )}
      </Box>

      <Box width={isMobile ? '100%' : 'auto'}>
        {navigation.next && (
          <LoadingButton
            fullWidth={isMobile}
            variant="contained"
            color="primary"
            endIcon={<ChevronRightIcon transform="scale(0.6)" />}
            {...navigation.next}
            sx={{ py: 1, textTransform: 'none', ...navigation.next.sx }}
          />
        )}
      </Box>
    </Stack>
  );
};

export default InterviewInvitationFormNavigation;
