import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type SideMenuState = {
  isOpen: boolean;
  hasActiveMenu: boolean;
};

const initialState: SideMenuState = {
  isOpen: false,
  hasActiveMenu: true,
};

export const sideMenuSlice = createSlice({
  name: 'side-menu',
  initialState: initialState,
  reducers: {
    setSideMenu: (state, action: PayloadAction<Partial<SideMenuState>>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { setSideMenu } = sideMenuSlice.actions;

export default sideMenuSlice.reducer;
