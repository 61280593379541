import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Nullable } from '~/types';
import { createLocalStoragePersist } from '~/utils/redux-persist';

export const COACH_MARK = 'coachMark';

export type CoachMarkState = {
  onboardingApplicantMenuItem?: Nullable<string[]>;
  onboardingProfilePicture?: Nullable<string[]>;
  onboardingFirstJobPoster?: Nullable<string[]>;
  onboardingHelpButton?: Nullable<string[]>;
  applicantMatchRecommendationTab?: Nullable<string[]>;
  vacancyListTabOpen?: Nullable<string[]>;
  vacancyListTabOnHold?: Nullable<string[]>;
  vacancyListTabClosed?: Nullable<string[]>;
  vacancyHighlightJobButton?: Nullable<string[]>;
};

export type CoachMarkStateSlice = keyof CoachMarkState;

const initialState: CoachMarkState = {};

export const coachMarkSlice = createSlice({
  name: COACH_MARK,
  initialState,
  reducers: {
    setCoachMark: (_, action: PayloadAction<CoachMarkState>) => action.payload,
    markDoneCoachMark: (
      state,
      action: PayloadAction<{ key: keyof CoachMarkState; companyId: string }>,
    ) => {
      const newCompanyIdList = [
        ...(state[action.payload.key] ?? []),
        action.payload.companyId,
      ];
      state[action.payload.key] = [...new Set(newCompanyIdList)];
      return state;
    },
    clearCoachMark: () => initialState,
  },
});

export const { setCoachMark, markDoneCoachMark, clearCoachMark } =
  coachMarkSlice.actions;

export default createLocalStoragePersist(COACH_MARK, coachMarkSlice.reducer);
