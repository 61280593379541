import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DropdownOption } from '~/types';
import { EpScreeningQuestion } from '~/types/graphql/graphql';
import { FETCH_STATUS } from '~/utils/constants';

export interface TypeApplicants {
  list: {
    fetchStatusDetail: string;
    fetchStatusOther: string;
    items: DropdownOption[];
    selected: DropdownOption | null;
    isFirstFetch: boolean;
    fetchCount: number;
  };
  screeningQuestions: {
    data: EpScreeningQuestion[];
    fetchStatus: FETCH_STATUS;
  };
}

const initialState: TypeApplicants = {
  list: {
    fetchStatusDetail: FETCH_STATUS.IDLE,
    fetchStatusOther: FETCH_STATUS.IDLE,
    items: [],
    selected: null,
    isFirstFetch: false,
    fetchCount: 0,
  },
  screeningQuestions: {
    data: [],
    fetchStatus: FETCH_STATUS.IDLE,
  },
};

export const vacancyOptionsSlice = createSlice({
  name: 'vacancyOptions',
  initialState,
  reducers: {
    setVacancyOptionsDetail: (
      state,
      action: PayloadAction<Partial<TypeApplicants['list']>>,
    ) => {
      const payloadSelected = action.payload?.selected
        ? [action.payload?.selected]
        : [];
      const payloadValue = payloadSelected.map((data) => data.value);
      const payloadFiltered = (state.list.items as DropdownOption[]).filter(
        (item) => !payloadValue?.includes(item?.value),
      );

      return {
        ...state,
        list: {
          ...state.list,
          fetchStatusDetail: action.payload.fetchStatusDetail as FETCH_STATUS,
          items: [...payloadSelected, ...payloadFiltered] as DropdownOption[],
          selected: (state.list.selected ||
            action.payload.selected) as DropdownOption,
          isFirstFetch: Boolean(state.list.fetchCount <= 0),
          fetchCount:
            action.payload.fetchStatusDetail === FETCH_STATUS.RESOLVED
              ? state.list.fetchCount + 1
              : state.list.fetchCount,
        },
      };
    },
    setVacancyOptionsOther: (
      state,
      action: PayloadAction<Partial<TypeApplicants['list']>>,
    ) => {
      const payloadItems = action.payload?.items || [];
      const payloadValue = payloadItems?.map((data) => data.value);
      const payloadFiltered = (state.list.items as DropdownOption[]).filter(
        (item) => !payloadValue?.includes(item?.value),
      );

      return {
        ...state,
        list: {
          ...state.list,
          fetchStatusOther: action.payload.fetchStatusOther as FETCH_STATUS,
          items: [...payloadFiltered, ...payloadItems] as DropdownOption[],
          isFirstFetch: Boolean(state.list.fetchCount <= 0),
          fetchCount:
            action.payload.fetchStatusOther === FETCH_STATUS.RESOLVED
              ? state.list.fetchCount + 1
              : state.list.fetchCount,
        },
      };
    },
    setVacancyOptionsSelected: (
      state,
      action: PayloadAction<DropdownOption>,
    ) => {
      return {
        ...state,
        list: {
          ...state.list,
          selected: action.payload,
        },
      };
    },
    setVacancyOptionsScreeningQuestions: (state, action) => ({
      ...state,
      screeningQuestions: {
        ...state.screeningQuestions,
        ...action.payload,
      },
    }),
    clearVacancyOptionsSelected: (state) => {
      return {
        ...state,
        list: {
          ...state.list,
          selected: null,
        },
      };
    },
    clearVacancyOptions: () => initialState,
  },
});

export const {
  setVacancyOptionsDetail,
  setVacancyOptionsOther,
  setVacancyOptionsSelected,
  setVacancyOptionsScreeningQuestions,
  clearVacancyOptionsSelected,
  clearVacancyOptions,
} = vacancyOptionsSlice.actions;

export default vacancyOptionsSlice.reducer;
