import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { StateSetterFunc } from '~/types';
import {
  EpJobVacancyCandidateStatus,
  JobVacancyCandidatesQuery,
} from '~/types/graphql/graphql';
import { FETCH_STATUS } from '~/utils/constants';

export type SummaryState = {
  label: string;
  value: EpJobVacancyCandidateStatus;
  count: number;
};

export type CandidateRecommendationListFilterType = {
  fetchCountStatus: FETCH_STATUS;
  totalCount: number;
};

export type CandidateRecommendationListState = {
  summary: {
    fetchStatus: FETCH_STATUS;
    items: SummaryState[];
    isFirstFetch: boolean;
    fetchCount: number;
  };
  candidate: Partial<JobVacancyCandidatesQuery['jobVacancyCandidates']> & {
    fetchStatus: FETCH_STATUS;
    error: string | null;
  };
  listFilter: CandidateRecommendationListFilterType;
};

const defaultSummary = {
  fetchStatus: FETCH_STATUS.IDLE,
  items: [],
  isFirstFetch: false,
  fetchCount: 0,
};

const defaultCandidate = {
  fetchStatus: FETCH_STATUS.IDLE,
  list: [],
  error: null,
};

const defaultListFilter = {
  fetchCountStatus: FETCH_STATUS.IDLE,
  totalCount: 0,
};

const initialState: CandidateRecommendationListState = {
  summary: defaultSummary,
  candidate: defaultCandidate,
  listFilter: defaultListFilter,
};

export const candidateRecommendationListSlice = createSlice({
  name: 'candidateRecommendationList',
  initialState: initialState,
  reducers: {
    setCandidateRecommendationSummary: (
      state,
      action: PayloadAction<
        Partial<CandidateRecommendationListState['summary']>
      >,
    ) => {
      return {
        ...state,
        summary: {
          ...state.summary,
          ...action.payload,
          isFirstFetch: Boolean(state.summary.fetchCount <= 0),
          fetchCount:
            action.payload.fetchStatus === FETCH_STATUS.RESOLVED
              ? state.summary.fetchCount + 1
              : state.summary.fetchCount,
        },
      };
    },
    setCandidateRecommendationList: (
      state,
      action: PayloadAction<
        StateSetterFunc<Partial<CandidateRecommendationListState['candidate']>>
      >,
    ) => {
      return {
        ...state,
        candidate: {
          ...state.candidate,
          ...action.payload,
        },
      };
    },
    setCandidateRecommendationListError: (
      state,
      action: PayloadAction<string | null>,
    ) => {
      return {
        ...state,
        candidate: {
          ...state.candidate,
          fetchStatus: FETCH_STATUS.FAILED,
          error: action.payload,
        },
      };
    },
    setCandidateRecomendationListFilter: (
      state,
      action: PayloadAction<Partial<CandidateRecommendationListFilterType>>,
    ) => {
      state.listFilter = { ...state.listFilter, ...action.payload };
    },
    clearCandidateRecommendationSummary: (state) => {
      return {
        ...state,
        summary: defaultSummary,
      };
    },
    clearCandidateRecommendationList: (state) => {
      return {
        ...state,
        candidate: defaultCandidate,
      };
    },
  },
});

export const {
  setCandidateRecommendationSummary,
  setCandidateRecommendationList,
  setCandidateRecommendationListError,
  setCandidateRecomendationListFilter,
  clearCandidateRecommendationList,
  clearCandidateRecommendationSummary,
} = candidateRecommendationListSlice.actions;

export default candidateRecommendationListSlice.reducer;
