import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DropdownOption, Nullable } from '~/types';
import {
  CommonFilter,
  EpJobApplication,
  EpJobApplicationEpStatusEnum,
  EpJobApplicationSummary,
  EpMenuTab,
  JobApplicationsQuery,
} from '~/types/graphql/graphql';
import { APPLICANT_TAB_PRIMARY, FETCH_STATUS } from '~/utils/constants';
import { APPLICANT_LIST_PREVIEW_TAB } from '~/utils/constants/applicant-list';

export type TApplicantPreview = {
  tab: APPLICANT_LIST_PREVIEW_TAB;
};

export interface Location {
  areaType: string;
  name: string;
}

export interface ApplicantsFilters {
  filter?: CommonFilter;
  epStatus?: string;
  tab?: string;
  jobVacancyId?: string;
  lastEducationLevel?: string;
  gender?: string;
  name?: string;
  sort?: string;
  locations?: Location[];
  jobFunctions?: string[];
  skills?: (string | null)[] | null;
  ageMin?: number;
  ageMax?: number;
  minSalary?: number;
  maxSalary?: number;
  experienceYear?: string;
}

export type ApplicantsData = Nullable<JobApplicationsQuery['jobApplications']>;

interface InterfaceCheckedAndDisable {
  isChecked: boolean;
  isDisabled: boolean;
}

export type TypeFilterTooltip = {
  showListAutoFilterTooltip: boolean;
  listAutoFilterTooltipInitialCheck: boolean;
};

export type TypeDisplayData = EpJobApplication & InterfaceCheckedAndDisable;

export interface Applicants {
  fetchStatusDataTable: FETCH_STATUS;
  fetchStatusAutoCompleteOptions: FETCH_STATUS;
  fetchStatusAutoCompleteOptionsMore: FETCH_STATUS;
  fetchStatusTabs: FETCH_STATUS;
  fetchStatusSummaryCount: FETCH_STATUS;
  mutationStatusUpdateApplicantsStatus: FETCH_STATUS;
  fetchStatusisNoVacancy: FETCH_STATUS;
  tabPrimary: string;
  tabSeccondary: string;
  filters: ApplicantsFilters;
  totalActiveScreeningQuestion: number;
  data: ApplicantsData;
  displayData: TypeDisplayData[];
  autocompleteVacancySelected: DropdownOption | null;
  autocompleteVacancyOptions: DropdownOption[];
  tabs: EpMenuTab[];
  summaryCount: Partial<EpJobApplicationSummary>;
  isShowModalFilter: boolean;
  isNoVacancy: boolean;
  error: string | null;
  filterTooltip: TypeFilterTooltip;
  showSelectBulkErrorCopywriting: boolean;
  applicantPreview: TApplicantPreview;
}

const defaultSummaryCount = {
  total: 0,
  accepted: 0,
  saved: 0,
  withdrawn: 0,
  rejected: 0,
  onProgress: 0,
  pending: 0,
};

const defaultAutocompleteVacancySelected = {
  label: '',
  value: '',
};

const initialState: Applicants = {
  fetchStatusDataTable: FETCH_STATUS.IDLE,
  fetchStatusAutoCompleteOptions: FETCH_STATUS.IDLE,
  fetchStatusAutoCompleteOptionsMore: FETCH_STATUS.IDLE,
  fetchStatusTabs: FETCH_STATUS.IDLE,
  fetchStatusSummaryCount: FETCH_STATUS.IDLE,
  mutationStatusUpdateApplicantsStatus: FETCH_STATUS.IDLE,
  fetchStatusisNoVacancy: FETCH_STATUS.IDLE,
  tabPrimary: APPLICANT_TAB_PRIMARY.ALL,
  tabSeccondary: EpJobApplicationEpStatusEnum.Pending,
  filters: {
    filter: {
      page: 0,
      limit: 25,
    },
    tab: 'ALL',
    gender: 'all',
    sort: 'DESC',
    epStatus: EpJobApplicationEpStatusEnum.Pending,
    name: '',
    jobVacancyId: '',
    lastEducationLevel: '',
    locations: [],
    jobFunctions: [],
    skills: [],
  },
  totalActiveScreeningQuestion: 0,
  autocompleteVacancySelected: defaultAutocompleteVacancySelected,
  autocompleteVacancyOptions: [],
  tabs: [],
  summaryCount: defaultSummaryCount,
  isShowModalFilter: false,
  data: null as ApplicantsData,
  displayData: [],
  isNoVacancy: false,
  error: null,
  filterTooltip: {
    showListAutoFilterTooltip: false,
    listAutoFilterTooltipInitialCheck: false,
  },
  showSelectBulkErrorCopywriting: false,
  applicantPreview: {
    tab: APPLICANT_LIST_PREVIEW_TAB.PROFILE_PREVIEW,
  },
};

export const applicantsSlice = createSlice({
  name: 'applicants',
  initialState,
  reducers: {
    setApplicantsFilters: (
      state,
      action: PayloadAction<Partial<ApplicantsFilters>>,
    ) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      };
    },
    setAutocompleteVacancySelected: (
      state,
      action: PayloadAction<DropdownOption>,
    ) => {
      return {
        ...state,
        autocompleteVacancySelected: action.payload,
      };
    },
    setAutocompleteVacancyOptions: (
      state,
      action: PayloadAction<DropdownOption>,
    ) => {
      const payloadFiltered = state.autocompleteVacancyOptions.filter(
        ({ value }) => value !== action.payload.value,
      );

      return {
        ...state,
        fetchStatusisNoVacancy: FETCH_STATUS.RESOLVED,
        fetchStatusAutoCompleteOptions: FETCH_STATUS.RESOLVED,
        autocompleteVacancySelected: action.payload,
        autocompleteVacancyOptions: [action.payload, ...payloadFiltered],
        isNoVacancy: false,
      };
    },
    setAutocompleteVacancyOptionsMore: (
      state,
      action: PayloadAction<DropdownOption[]>,
    ) => {
      const payloadValue = action.payload.map((data) => data.value);
      const payloadFiltered = state.autocompleteVacancyOptions.filter(
        ({ value }) => !payloadValue.includes(value),
      );

      return {
        ...state,
        fetchStatusisNoVacancy: FETCH_STATUS.RESOLVED,
        fetchStatusAutoCompleteOptionsMore: FETCH_STATUS.RESOLVED,
        autocompleteVacancyOptions: [...payloadFiltered, ...action.payload],
        isNoVacancy: false,
      };
    },
    setApplicantsTabs: (state, action: PayloadAction<EpMenuTab[]>) => {
      return {
        ...state,
        fetchStatusTabs: FETCH_STATUS.RESOLVED,
        tabs: [...action.payload],
      };
    },
    setApplicantsSummaryCount: (
      state,
      action: PayloadAction<Partial<EpJobApplicationSummary>>,
    ) => {
      return {
        ...state,
        fetchStatusSummaryCount: FETCH_STATUS.RESOLVED,
        summaryCount: { ...state.summaryCount, ...action.payload },
      };
    },
    setApplicantsIsShowModalFilter: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isShowModalFilter: action.payload,
      };
    },
    setApplicantsData: (state, action: PayloadAction<ApplicantsData>) => {
      return {
        ...state,
        fetchStatusDataTable: FETCH_STATUS.RESOLVED,
        data: action.payload,
      };
    },
    setApplicantsDisplayData: (
      state,
      action: PayloadAction<TypeDisplayData[]>,
    ) => {
      state.displayData = action.payload;
      state.fetchStatusDataTable = FETCH_STATUS.RESOLVED;
    },
    setApplicantsFetchStatusDataTable: (
      state,
      action: PayloadAction<FETCH_STATUS>,
    ) => {
      return {
        ...state,
        displayData: [],
        fetchStatusDataTable: action.payload,
      };
    },
    setApplicantsChangeStatus: (state, action: PayloadAction<string[]>) => {
      state.mutationStatusUpdateApplicantsStatus = FETCH_STATUS.PENDING;
      state.displayData = state.displayData.map((data) =>
        action.payload.includes(data.id as string)
          ? { ...data, isDisabled: true }
          : data,
      );
    },
    setApplicantsRemoveByIds: (state, action: PayloadAction<string[]>) => {
      state.displayData = state.displayData.filter(
        (data) => !action.payload.includes(data.id as string),
      );
    },
    setApplicantsMutationStatusUpdateStatus: (
      state,
      action: PayloadAction<FETCH_STATUS>,
    ) => {
      state.mutationStatusUpdateApplicantsStatus = action.payload;
    },
    setApplicantsFetchStatusAutoCompleteOptions: (
      state,
      action: PayloadAction<FETCH_STATUS>,
    ) => {
      state.fetchStatusAutoCompleteOptions = action.payload;
    },
    setApplicantsError: (state, action: PayloadAction<string | null>) => {
      return {
        ...state,
        fetchStatusDataTable: FETCH_STATUS.FAILED,
        error: action.payload,
      };
    },
    clearApplicantsVacancy: (state) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          name: '',
        },
        data: null,
        displayData: [],
        summaryCount: defaultSummaryCount,
        autocompleteVacancySelected: null,
        isNoVacancy: false,
      };
    },
    setApplicantisNoVacancy: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        fetchStatusisNoVacancy: FETCH_STATUS.RESOLVED,
        isNoVacancy: action.payload,
      };
    },
    setApplicantFilterTooltip: (
      state,
      action: PayloadAction<Partial<TypeFilterTooltip>>,
    ) => {
      state.filterTooltip = { ...state.filterTooltip, ...action.payload };
    },
    setApplicantShowSelectBulkErrorCopywriting: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.showSelectBulkErrorCopywriting = action.payload;
    },
    setApplicantPreview: (
      state,
      action: PayloadAction<Partial<TApplicantPreview>>,
    ) => {
      state.applicantPreview = { ...state.applicantPreview, ...action.payload };
    },
    setApplicantTotalActiveScreeningQuestion: (
      state,
      action: PayloadAction<number>,
    ) => {
      state.totalActiveScreeningQuestion = action.payload;
    },
    clearApplicantData: () => initialState,
  },
});

export const {
  setApplicantsFilters,
  setAutocompleteVacancySelected,
  setAutocompleteVacancyOptions,
  setAutocompleteVacancyOptionsMore,
  setApplicantsTabs,
  setApplicantsSummaryCount,
  setApplicantsIsShowModalFilter,
  setApplicantsData,
  setApplicantsDisplayData,
  setApplicantsFetchStatusDataTable,
  setApplicantsMutationStatusUpdateStatus,
  setApplicantsFetchStatusAutoCompleteOptions,
  setApplicantsChangeStatus,
  setApplicantsRemoveByIds,
  setApplicantsError,
  setApplicantisNoVacancy,
  setApplicantFilterTooltip,
  setApplicantShowSelectBulkErrorCopywriting,
  setApplicantPreview,
  setApplicantTotalActiveScreeningQuestion,
  clearApplicantsVacancy,
  clearApplicantData,
} = applicantsSlice.actions;

export default applicantsSlice.reducer;
