import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FETCH_STATUS } from '~/utils/constants';

export type FirebaseRemoteConfigInitialState = {
  fetchStatus: FETCH_STATUS;
};

const initialState = {
  fetchStatus: FETCH_STATUS.IDLE,
};

export const firebaseSlice = createSlice({
  name: 'firebase',
  initialState: initialState,
  reducers: {
    setFirebaseRemoteConfig: (
      _,
      action: PayloadAction<FirebaseRemoteConfigInitialState>,
    ) => action.payload,
    clearFirebaseRemoteConfig: () => initialState,
  },
});

export const { setFirebaseRemoteConfig, clearFirebaseRemoteConfig } =
  firebaseSlice.actions;

export default firebaseSlice.reducer;
