import { Paper, Stack, useTheme, brandColors } from '@kitalulus/web-ui-kit';

import { useAppSelector } from '~/hooks/use-store';
import { FETCH_STATUS } from '~/utils/constants';
import { PROFILE_LOCATION } from '~/utils/constants/profile';

import Title from './Title';
import ResultAndAlert from './ResultAndAlert';
import ScreeningQuestion from './ScreeningQuestion';
import ScreeningQuestionAnswersShimmer from './ScreeningQuestionAnswersShimmer';
import EmptyState from './EmptyState';
import { ScreeningQuestionAnswerProps } from './ScreeningQuestionAnswers.types';

const ScreeningQuestionAnswers = ({
  location,
}: ScreeningQuestionAnswerProps) => {
  const theme = useTheme();

  const { applicantScreeningQuestion } = useAppSelector(
    (state) => state.applicantDetail,
  );
  const { fetchStatus, data } = applicantScreeningQuestion;

  const isLoading =
    fetchStatus !== FETCH_STATUS.RESOLVED &&
    fetchStatus !== FETCH_STATUS.FAILED;

  return (
    <Paper
      sx={{
        borderRadius: theme.spacing(2),
        boxShadow: theme.customShadows['card'],
        border:
          location === PROFILE_LOCATION.PREVIEW
            ? `1px solid ${brandColors.neutral[400]}`
            : 'unset',
        p: 3,
        height: {
          sm:
            location === PROFILE_LOCATION.PREVIEW && !data?.data?.length
              ? 'calc(100vh - 536px)'
              : 'unset',
        },
      }}
    >
      {isLoading ? (
        <ScreeningQuestionAnswersShimmer />
      ) : (
        <Stack
          spacing={3}
          sx={{
            height: {
              sm: !data?.data?.length ? '100%' : 'unset',
            },
          }}
        >
          <Title />
          {data?.resultStr && data?.noticeStr && (
            <ResultAndAlert
              resultStr={data?.resultStr}
              noticeStr={data?.noticeStr}
            />
          )}
          {data?.data?.length ? (
            data?.data?.map((applicantScreeningQuestion) => (
              <ScreeningQuestion
                key={applicantScreeningQuestion?.id}
                screeningQuestion={applicantScreeningQuestion}
              />
            ))
          ) : (
            <EmptyState location={location} resultStr={data?.resultStr} />
          )}
        </Stack>
      )}
    </Paper>
  );
};

export default ScreeningQuestionAnswers;
