import { Grid, Stack, Typography, Image, Button } from '@kitalulus/web-ui-kit';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useAppSelector } from '~/hooks/use-store';

const DisconnectedPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { disconnectedImage } = useAppSelector(
    (state) => state.preloadedImages,
  );

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{ minHeight: '100vh' }}
      p={4}
    >
      <Grid container item xs={12} spacing={3}>
        <Grid item xs={12}>
          <Stack spacing={3} alignItems="center">
            <Typography
              variant="h3"
              component="div"
              sx={{ fontWeight: 700, textAlign: 'center' }}
            >
              {t('disconnected:title')}
            </Typography>
            <Typography
              variant="body1"
              component="div"
              color="grey.600"
              sx={{ textAlign: 'center', maxWidth: '500px' }}
            >
              {t('disconnected:message')}
            </Typography>
            <Image
              src={disconnectedImage}
              alt="Disconnected-Illustration"
              my={3}
            />
          </Stack>
        </Grid>
        <Grid item container xs={12} justifyContent="center">
          <Button size="large" variant="contained" onClick={() => navigate(-1)}>
            {t('common:tryAgain')}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DisconnectedPage;
