import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';

import { authLink, authRefreshTokenLink } from './auth-link';
import errorLink from './error-link';
import httpLink from './http-link';
import loggerLink from './logger-link';
import retryLink from './retry-link';
import businessErrorLink from './business-error-link';

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.from([
    authLink,
    retryLink,
    businessErrorLink,
    errorLink,
    loggerLink,
    httpLink,
  ]),
  name: 'klep-web',
  version: import.meta.env.NPM_PACKAGE_VERSION,
});

export const refreshTokenClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.from([
    authRefreshTokenLink,
    retryLink,
    errorLink,
    loggerLink,
    httpLink,
  ]),
  name: 'klep-web',
  version: import.meta.env.NPM_PACKAGE_VERSION,
});
