import db, {
  ApplicantScreeningQuestionVacancies,
} from '~/models/dexie/db.applicantScreeningQuestionFilter';
import { useLiveQuery } from 'dexie-react-hooks';
import { useAppSelector } from '../use-store';
import * as Sentry from '@sentry/react';

export type saveApplicantScreeningQuestionFilterType = Omit<
  ApplicantScreeningQuestionVacancies,
  'lastUpdated' | 'createdDate'
>;

const useIDBApplicantScreeningQuestionFilter = () => {
  const loggedUser = useAppSelector((state) => state.user);
  const applicantScreeningQuestionFilter = useLiveQuery(async () => {
    const res = await db.applicantScreeningQuestionFilters?.toArray();
    return (
      res?.find((user) => user.username === loggedUser?.username)?.vacancies ??
      []
    );
  });

  const loading = applicantScreeningQuestionFilter === undefined;

  const saveApplicantScreeningQuestionFilter = async (
    filter: saveApplicantScreeningQuestionFilterType,
  ) => {
    if (!loggedUser?.username) return;
    let newVacancies = applicantScreeningQuestionFilter
      ? [...applicantScreeningQuestionFilter]
      : [];
    const exist = newVacancies?.find(
      (sq) => sq.jobVacancyId === filter.jobVacancyId,
    );

    if (exist) {
      newVacancies = newVacancies?.map((sqf) => {
        if (sqf.jobVacancyId === filter.jobVacancyId) {
          return {
            ...filter,
            lastUpdated: new Date().toISOString(),
            createdDate: exist?.createdDate,
          };
        }
        return sqf;
      });
    } else {
      newVacancies.push({
        ...filter,
        lastUpdated: new Date().toISOString(),
        createdDate: new Date().toISOString(),
      });
    }

    try {
      await db.applicantScreeningQuestionFilters.put(
        {
          username: loggedUser.username,
          displayName: loggedUser.displayName ?? '',
          vacancies: newVacancies,
        },
        [loggedUser.username],
      );
    } catch (error) {
      Sentry.captureException(error);
      console.error(
        `Failed to save Screening Question with vacancyID ${filter.jobVacancyId} to IDB: ${error}`,
      );
    }
  };

  const deleteApplicantScreeningQuestionFilter = async (vacancyID: string) => {
    if (!loggedUser?.username) return;
    try {
      await db.applicantScreeningQuestionFilters.update(loggedUser.username, {
        vacancies: applicantScreeningQuestionFilter?.filter(
          (vacancy) => vacancy.jobVacancyId !== vacancyID,
        ),
      });
    } catch (error) {
      Sentry.captureException(error);
      console.error(
        `Failed to removed persisted Screening Question with vacancyID ${vacancyID} from IDB: ${error}`,
      );
    }
  };

  const getVacancyFilter = (vacancyID: string) => {
    return applicantScreeningQuestionFilter?.find(
      (x) => x.jobVacancyId === vacancyID,
    )?.screeningQuestionsFilter;
  };

  const deleteExpiredScreeningQuestion = async () => {
    if (!loggedUser?.username) return;
    const expireDate = new Date().getTime() - 1000 * 60 * 60 * 24 * 60; // 60 DAYS AGO

    try {
      const expiredVacancies = applicantScreeningQuestionFilter?.filter(
        (sqf) => expireDate > Date.parse(sqf.lastUpdated),
      );
      if (expiredVacancies?.length) {
        await db.applicantScreeningQuestionFilters.update(loggedUser.username, {
          vacancies: applicantScreeningQuestionFilter?.filter(
            (sqf) => expireDate < Date.parse(sqf.lastUpdated),
          ),
        });
      }
    } catch (error) {
      Sentry.captureException(error);
      console.error(
        `Failed to removed expired persisted Screening Question from IDB: ${error}`,
      );
    }
  };

  return {
    applicantScreeningQuestionFilter,
    loading,
    getVacancyFilter,
    saveApplicantScreeningQuestionFilter,
    deleteExpiredScreeningQuestion,
    deleteApplicantScreeningQuestionFilter,
  };
};

export default useIDBApplicantScreeningQuestionFilter;
