import { SvgIcon, SvgIconProps } from '@kitalulus/web-ui-kit';

const X = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 16 16" {...props}>
      <path d="M12.2179 1.26929H14.4668L9.55347 6.88495L15.3337 14.5266H10.8078L7.26302 9.89198L3.20696 14.5266H0.956613L6.21195 8.52002L0.666992 1.26929H5.30773L8.51192 5.50551L12.2179 1.26929ZM11.4285 13.1805H12.6747L4.63059 2.54471H3.2933L11.4285 13.1805Z" />
    </SvgIcon>
  );
};

export default X;
