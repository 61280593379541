/*eslint no-control-regex: "off"*/
const invalidProtocolRegex = /^([^\w]*)(javascript|data|vbscript)/im;
const htmlEntitiesRegex = /&#(\w+)(^\w|;)?/g;
const htmlCtrlEntityRegex = /&(newline|tab);/gi;
const ctrlCharactersRegex =
  /[\u0000-\u001F\u007F-\u009F\u2000-\u200D\uFEFF]/gim;
const urlSchemeRegex = /^.+(:|&colon;)/gim;
const relativeFirstCharacters = ['.', '/'];

const isRelativeUrlWithoutProtocol = (url: string) => {
  return relativeFirstCharacters.indexOf(url[0]) > -1;
};

const decodeHtmlCharacters = (str: string) => {
  return str.replace(htmlEntitiesRegex, (match, dec) =>
    String.fromCharCode(dec),
  );
};

export const sanitizeUrl = (url: string) => {
  const sanitizedUrl = decodeHtmlCharacters(url || '')
    .replace(htmlCtrlEntityRegex, '')
    .replace(ctrlCharactersRegex, '')
    .trim();

  if (!sanitizedUrl) return '';

  if (isRelativeUrlWithoutProtocol(sanitizedUrl)) return sanitizedUrl;

  const urlSchemeParseResults = sanitizedUrl.match(urlSchemeRegex);

  if (!urlSchemeParseResults) return sanitizedUrl;

  const urlScheme = urlSchemeParseResults[0];

  if (invalidProtocolRegex.test(urlScheme)) return '';

  return sanitizedUrl;
};

export const removeOriginFromUrl = (url: string) => {
  const origin = window.location.origin;

  // Check if the redirect url is valid with our domain
  if (url.includes(origin)) return url.replace(origin, '');

  return '';
};
