import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  Typography,
} from '@kitalulus/web-ui-kit';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import LoadingButton from '~/components/LoadingButton';
import { useAppDispatch } from '~/hooks/use-store';
import { closeDialog } from '~/store/views/dialogs-slice';
import { Nullable } from '~/types';
import { SubscriptionPlanConfirmationDialogProps } from './SubscriptionPlanConfirmationDialog.types';
import * as mpe from '~/utils/mixpanel';

const SubscriptionPlanConfirmationDialog = ({
  content,
  ...props
}: SubscriptionPlanConfirmationDialogProps) => {
  const {
    body,
    cancelLabel,
    imageSrc = '/illustrations/character-10-illustration.svg',
    onSubmit,
    pageLocation,
    submitLabel,
    title,
  } = content ?? {};
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [_, setError] = useState<Nullable<string>>();

  const handleClose = useCallback(() => {
    if (pageLocation === 'onBoarding') mpe.onBoardingPremiumCancel();
    if (pageLocation === 'dialog') mpe.premiumOfferingCancel();
    dispatch(closeDialog('subscriptionPlanConfirmationDialog'));
  }, [pageLocation]);

  const handleSubmit = useCallback(async () => {
    if (!onSubmit) return;
    try {
      setIsLoading(true);
      await onSubmit(handleClose);
    } catch (err: any) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  }, [onSubmit, handleClose]);

  return (
    <Dialog
      maxWidth="xs"
      {...props}
      sx={{
        textAlign: 'center',
        '.MuiPaper-root': {
          maxWidth: '450px',
          width: '100%',
          p: { xs: 2, md: 3 },
          borderRadius: 2,
        },
        ...props.sx,
      }}
    >
      <DialogContent>
        <Stack spacing={4} alignItems="center" pb={1}>
          {imageSrc && (
            <Box
              component="img"
              src={imageSrc}
              alt="Subscription package confirmation illustration"
              width="auto"
              height="134px"
            />
          )}
          <Stack spacing={2}>
            {title && (
              <Typography
                component="div"
                variant="h5"
                color="text.primary"
                textAlign="center"
              >
                <div dangerouslySetInnerHTML={{ __html: title }} />
              </Typography>
            )}
            {body && (
              <Typography
                component="div"
                variant="body1"
                textAlign="center"
                color="text.primary"
              >
                <div dangerouslySetInnerHTML={{ __html: body }} />
              </Typography>
            )}
          </Stack>
        </Stack>
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'space-between', gap: 1 }}>
        <LoadingButton
          variant="contained"
          sx={{ flex: 1 }}
          onClick={handleSubmit}
          loading={isLoading}
          disabled={!onSubmit}
        >
          {submitLabel ?? t('common:yes')}
        </LoadingButton>
        <LoadingButton
          variant="outlined"
          color="inherit"
          sx={{ flex: 1 }}
          onClick={handleClose}
          disabled={isLoading}
          autoFocus
        >
          {cancelLabel ?? t('common:later')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default SubscriptionPlanConfirmationDialog;
