import { ReactNode, useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
  useTheme,
  StackProps,
  BoxProps,
  IconButtonProps,
  PaperProps,
  ButtonProps,
} from '@kitalulus/web-ui-kit';
import camelCase from 'lodash.camelcase';

import TruncateText from '~/components/TruncateText';
import CopyIcon from '~/components/Icons/CopyIcon';
import { useCopyText } from '~/hooks/use-copy-text';
import { useTranslation } from 'react-i18next';
import Analytics, { ANALYTICS_EVENTS } from '~/utils/analytics';
import * as mpe from '~/utils/mixpanel';
import { EpJobApplicationEpStatusEnum } from '~/types/graphql/graphql';
import { Skeleton } from '@mui/material';
import PopoverDialogV1 from '~/components/Dialogs/PopoverDialogV1';
import { Nullable } from '~/types';
import { useAppSelector } from '~/hooks/use-store';

export type TCardContactsMaskContent = {
  containerProps?: StackProps & { 'data-test-id'?: string };
  title: string;
  message: string;
  button: ButtonProps & { 'data-test-id'?: string };
  variant?: 'preview' | 'detail';
};
export interface ICardContactsItem {
  label: string;
  contact: string;
  icon: ReactNode;
  currentStatus?: EpJobApplicationEpStatusEnum | null;
  appliedDate?: string | null;
  onClick?: () => void;
  popuptitle?: string | ReactNode;
  popupBody?: string | ReactNode;
  handlePopupAccept?: () => Promise<void>;
  handlePopupClose?: () => void;
  copyIconProps?: IconButtonProps & { 'data-test-id'?: string };
  lookButtonProps?: ButtonProps & { 'data-test-id'?: string };
}

interface ICardContacts {
  PaperProps?: PaperProps;
  title: string;
  info: string;
  boxTitleProps?: BoxProps;
  stackContainerProps?: StackProps;
  items: ICardContactsItem[];
  itemsProps?: StackProps;
  spacing?: number;
  fullWidth?: boolean;
  isLoading?: boolean;
  restricted?: boolean;
  maskContent?: TCardContactsMaskContent;
}

const CardContacts = ({
  PaperProps,
  title,
  info,
  boxTitleProps,
  stackContainerProps,
  items,
  itemsProps,
  spacing,
  fullWidth,
  isLoading,
  restricted = false,
  maskContent,
}: ICardContacts) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { copy } = useCopyText();

  const maskContentFromDetails = maskContent?.variant === 'detail';

  return (
    <>
      <Paper
        id={camelCase(`applicant ${title}`)}
        {...PaperProps}
        sx={{
          borderRadius: theme.spacing(2),
          width: {
            xs: '100%',
            lg: fullWidth ? '100%' : '320px',
          },
          overflowWrap: 'anywhere',
          position: 'relative',
          ...PaperProps?.sx,
        }}
      >
        <Box
          paddingY={3}
          paddingX={3}
          paddingRight={1}
          sx={{
            backgroundColor: 'grey.100',
            borderTopLeftRadius: theme.spacing(2),
            borderTopRightRadius: theme.spacing(2),
            ...(maskContent && { filter: 'blur(8px)' }),
            ...boxTitleProps?.sx,
          }}
        >
          <Typography variant="h6">{title}</Typography>
          <Typography variant="body2">{info}</Typography>
        </Box>
        <Stack
          paddingX={3}
          paddingBottom={3}
          paddingTop={2}
          gap={spacing ?? 2}
          {...stackContainerProps}
          sx={{
            ...(maskContent && { filter: 'blur(8px)' }),
          }}
        >
          {items.map((item, index) => {
            return (
              <Stack
                id={camelCase(`applicant ${item.label} ${index}`)}
                key={camelCase(`applicant-${item.label}-${index}`)}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                {...itemsProps}
              >
                <Stack direction="row" alignItems="center" gap={2}>
                  {item.icon}
                  <Stack>
                    <Typography variant="subtitle2">{item.label}</Typography>
                    {isLoading ? (
                      <Skeleton variant="rounded" height={22} width={196} />
                    ) : (
                      <TruncateText noOfLines={1}>
                        <Typography
                          variant="body2"
                          sx={{
                            ...(!restricted && {
                              textDecoration: 'underline',
                              cursor: 'pointer',
                              color: 'primary.main',
                            }),
                          }}
                          {...(!restricted && {
                            onClick: item.onClick,
                          })}
                          data-test-id={
                            item.label === 'Whatsapp'
                              ? 'lbApplicantWhatsappNomor'
                              : 'lbApplicantEmailText'
                          }
                        >
                          {item.contact}
                        </Typography>
                      </TruncateText>
                    )}
                  </Stack>
                </Stack>
                {isLoading && (
                  <Skeleton variant="rounded" height={24} width={24} />
                )}

                {!isLoading && restricted && (
                  <LookEmail
                    popuptitle={item.popuptitle}
                    popupBody={item.popupBody}
                    handleAccept={item.handlePopupAccept}
                    handleClose={item.handlePopupClose}
                    btnProps={item.lookButtonProps}
                  />
                )}
                {!isLoading && !restricted && (
                  <Tooltip
                    arrow
                    enterTouchDelay={0}
                    title={t('common:copyLink')}
                  >
                    <IconButton
                      onClick={() => {
                        if (item.label === 'Email') {
                          Analytics.sendEvent(
                            ANALYTICS_EVENTS.APPLICANT_CONTACT_COPY,
                          );
                          if (item.currentStatus) {
                            mpe.applicantContactCopy({
                              contactType: 'email',
                              currentApplicantStatus: item.currentStatus,
                              appliedDate: item.appliedDate ?? '',
                            });
                          }
                        }

                        if (item.label === 'Whatsapp') {
                          Analytics.sendEvent(
                            ANALYTICS_EVENTS.APPLICANT_CONTACT_COPY,
                          );
                          if (item.currentStatus) {
                            mpe.applicantContactCopy({
                              contactType: 'whatsapp',
                              currentApplicantStatus: item.currentStatus,
                              appliedDate: item.appliedDate ?? '',
                            });
                          }
                        }

                        copy(
                          item.contact,
                          `${item.label} ${t('common:copyToClipboard')}`,
                        );
                      }}
                      {...item.copyIconProps}
                    >
                      <CopyIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </Stack>
            );
          })}
        </Stack>
        {maskContent && (
          <Stack
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              height: '100%',
              width: '100%',
              background:
                'linear-gradient(92.71deg, rgba(255, 255, 255, 0.6) -1.74%, rgba(255, 255, 255, 0.4) 104.13%)',
              p: 3,
            }}
            justifyContent={maskContentFromDetails ? 'start' : 'center'}
            alignItems={{
              xs: 'start',
              sm: maskContentFromDetails ? 'start' : 'center',
            }}
            gap={0.5}
            {...maskContent.containerProps}
          >
            <Typography variant={maskContentFromDetails ? 'h6' : 'subtitle1'}>
              {maskContent.title}
            </Typography>
            <Typography
              variant="body2"
              textAlign={{
                xs: 'left',
                sm: maskContentFromDetails ? 'left' : 'center',
              }}
              sx={{ whiteSpace: 'pre-line' }}
              dangerouslySetInnerHTML={{
                __html: maskContent.message,
              }}
            />
            <Button
              variant="contained"
              size="small"
              sx={{ mt: { xs: 1.5, sm: 1 } }}
              {...maskContent.button}
            >
              {t('common:explorePackages')}
            </Button>
          </Stack>
        )}
      </Paper>
    </>
  );
};

const LookEmail = ({
  popuptitle,
  popupBody,
  handleAccept,
  handleClose,
  btnProps,
}: {
  btnProps?: ButtonProps;
  popuptitle?: string | ReactNode;
  popupBody?: string | ReactNode;
  handleAccept?: () => Promise<void>;
  handleClose?: () => void;
}) => {
  const [popupAnchor, setPopupAnchor] = useState<Nullable<HTMLElement>>(null);
  const { isUpdatingStatus } = useAppSelector((state) => state.applicantDetail);
  const { t } = useTranslation();
  return (
    <>
      <Button
        variant="text"
        onClick={(event) => {
          setPopupAnchor(event.currentTarget);
        }}
        {...btnProps}
      >
        <Typography variant="subtitle2" sx={{ whiteSpace: 'nowrap' }}>
          {t('common:look')}
        </Typography>
      </Button>
      <PopoverDialogV1
        triggerElem={popupAnchor}
        setTriggerElem={setPopupAnchor}
        popuptitle={popuptitle}
        popupBody={popupBody}
        menuPopoverProps={{
          PaperProps: { sx: { width: 420 } },
        }}
        StackContainerProps={{
          'data-test-id': 'ctChangeStatus',
        }}
        onClose={handleClose}
        btnCancelProps={{
          'data-test-id': 'btnCancelChangeStatus',
        }}
        btnAcceptProps={{
          loading: isUpdatingStatus,
          onClick: async () => {
            await handleAccept?.();
            setPopupAnchor(null);
          },
          'data-test-id': 'btnAcceptChangeStatus',
        }}
      />
    </>
  );
};

export default CardContacts;
