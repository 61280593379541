import { Box } from '@kitalulus/web-ui-kit';
import { keyframes } from '@mui/material';

import { Loader3DotsProps } from './';

const Loader3Dots = (props: Loader3DotsProps) => {
  const ellipsis1 = keyframes({
    from: { transform: 'scale(0)' },
    to: { transform: 'scale(1)' },
  });

  const ellipsis2 = keyframes({
    from: { transform: 'translate(0, 0)' },
    to: { transform: 'translate(24px, 0)' },
  });

  const ellipsis3 = keyframes({
    from: { transform: 'scale(1)' },
    to: { transform: 'scale(0)' },
  });

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        width: '80px',
        height: '30px',
        '& div': {
          position: 'absolute',
          width: '16px',
          height: '16px',
          borderRadius: '50%',
          background: '#1E88E5',
          animationTimingFunction: 'cubic-bezier(0, 1, 1, 0)',
        },
        '& div:nth-of-type(1)': {
          left: '8px',
          animation: `${ellipsis1} 0.6s infinite`,
        },
        '& div:nth-of-type(2)': {
          left: '8px',
          animation: `${ellipsis2} 0.6s infinite`,
        },
        '& div:nth-of-type(3)': {
          left: '32px',
          animation: `${ellipsis2} 0.6s infinite`,
        },
        '& div:nth-of-type(4)': {
          left: '56px',
          animation: `${ellipsis3} 0.6s infinite`,
        },
        ...props.sx,
      }}
    >
      <div />
      <div />
      <div />
      <div />
    </Box>
  );
};

export default Loader3Dots;
