import { Box, Button, CircularProgress } from '@kitalulus/web-ui-kit';

import { LoadingButtonProps } from './LoadingButton.types';

const spinnerSizeMap = {
  small: 12,
  medium: 16,
  large: 20,
};

const LoadingButton = ({ loading, ...props }: LoadingButtonProps) => {
  props.disabled = props.disabled || loading;

  const spinnerSize =
    spinnerSizeMap[props.size ?? 'medium'] ?? spinnerSizeMap['medium'];

  return (
    <Button {...props}>
      <Box position="relative">
        <Box sx={{ opacity: loading ? 0 : 1 }} zIndex={1}>
          {props.children}
        </Box>
        {loading && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <CircularProgress color="inherit" size={spinnerSize} />
          </Box>
        )}
      </Box>
    </Button>
  );
};

export default LoadingButton;
