export enum RTD_ACTION_TYPE {
  RTD_LISTEN_COMPANY = 'realtime-data/listen-company',
  RTD_UNSUBSCRIBE_COMPANY = 'realtime-data/unsubscribe-company',
  RTD_LISTEN_VACANCY_BY_COMPANY_ID = 'realtime-data/listen-vacancy-by-companyId',
  RTD_UNSUBSCRIBE_VACANCY_BY_COMPANY_ID = 'realtime-data/unsubscribe-vacancy-by-companyId',
  RTD_UNSUBSCRIBE = 'realtime-data/unsubscribe',
  RTD_LISTEN_PACKAGE_BY_COMPANY_ID = 'realtime-data/listen-package-by-company-id',
  RTD_UNSUBSCRIBE_PACKAGE_BY_COMPANY_ID = 'realtime-data/unsubscribe-package-by-company-id',
  RTD_LISTEN_HIGHLIGHTED_BY_COMPANY_ID = 'realtime-data/listen-highlighted-by-company-id',
  RTD_UNSUBSCRIBE_HIGHLIGHTED_BY_COMPANY_ID = 'realtime-data/unsubscribe-highlighted-by-company-id',
}
