import { Nullable } from '~/types';

export const convertImageToBase64 = async (url: string) => {
  const res = await fetch(url);
  const blob = await res.blob();
  const reader = new FileReader();
  reader.readAsDataURL(blob);

  return await new Promise<Nullable<string>>((resolve, reject) => {
    reader.onload = () => {
      if (!reader.result || typeof reader.result !== 'string')
        return resolve(undefined);
      return resolve(reader.result);
    };
    reader.onerror = reject;
  });
};
