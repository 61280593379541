import { SvgIcon, SvgIconProps } from '@kitalulus/web-ui-kit';

const ArrowForwardIosIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="13" height="24" viewBox="0 0 13 24" {...props}>
      <path d="M2.24766 0.00628184L12.1207 9.87928C12.6831 10.4419 12.999 11.2048 12.999 12.0003C12.999 12.7958 12.6831 13.5587 12.1207 14.1213L2.25366 23.9883L0.839664 22.5743L10.7067 12.7073C10.8941 12.5198 10.9994 12.2654 10.9994 12.0003C10.9994 11.7351 10.8941 11.4808 10.7067 11.2933L0.833662 1.42028L2.24766 0.00628184Z" />
    </SvgIcon>
  );
};

export default ArrowForwardIosIcon;
