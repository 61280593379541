import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '~/hooks/use-store';
import { RTD_ACTION_TYPE } from '~/utils/constants/realtime-data';
import usePayPerPost from '../use-pay-per-post';

export const useRealtimeData = (paramInitLoad = true) => {
  const dispatch = useAppDispatch();
  const auth = useAppSelector((state) => state.auth);
  const { isFreePlan } = usePayPerPost();

  const companyCode = auth?.companyCode;

  const getVacancyRealtime = (vacancyCode: string) => {
    if (!isFreePlan) return;

    // Unsub first before listening again to avoid mem leak
    dispatch({
      type: RTD_ACTION_TYPE.RTD_UNSUBSCRIBE_VACANCY_BY_COMPANY_ID,
    });
    dispatch({
      type: RTD_ACTION_TYPE.RTD_LISTEN_VACANCY_BY_COMPANY_ID,
      payload: { companyCode, vacancyCode },
    });
  };

  const initialLoad = () => {
    // Unsub first before listening again to avoid mem leak
    dispatch({
      type: RTD_ACTION_TYPE.RTD_UNSUBSCRIBE_COMPANY,
    });
    dispatch({
      type: RTD_ACTION_TYPE.RTD_UNSUBSCRIBE_PACKAGE_BY_COMPANY_ID,
    });
    dispatch({
      type: RTD_ACTION_TYPE.RTD_UNSUBSCRIBE_HIGHLIGHTED_BY_COMPANY_ID,
    });

    // Listen
    dispatch({
      type: RTD_ACTION_TYPE.RTD_LISTEN_COMPANY,
      payload: { companyCode },
    });
    dispatch({
      type: RTD_ACTION_TYPE.RTD_LISTEN_PACKAGE_BY_COMPANY_ID,
      payload: { companyCode },
    });
    dispatch({
      type: RTD_ACTION_TYPE.RTD_LISTEN_HIGHLIGHTED_BY_COMPANY_ID,
      payload: { companyCode },
    });
  };

  useEffect(() => {
    const triggerInitialLoad =
      companyCode && companyCode !== '0' && paramInitLoad;
    if (triggerInitialLoad) {
      initialLoad();
    }

    return () => {
      if (triggerInitialLoad) {
        dispatch({
          type: RTD_ACTION_TYPE.RTD_UNSUBSCRIBE_COMPANY,
        });
        dispatch({
          type: RTD_ACTION_TYPE.RTD_UNSUBSCRIBE_PACKAGE_BY_COMPANY_ID,
        });
        dispatch({
          type: RTD_ACTION_TYPE.RTD_UNSUBSCRIBE_HIGHLIGHTED_BY_COMPANY_ID,
        });
      }
    };
  }, [dispatch, companyCode, paramInitLoad]);

  return { getVacancyRealtime };
};
