import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  Typography,
} from '@kitalulus/web-ui-kit';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { appConfig } from '~/config/app';
import { useAppDispatch } from '~/hooks/use-store';
import { clearDialog } from '~/store/views/dialogs-slice';
import { VacancyOnReviewDialogProps } from './VacancyOnReviewDialog.types';

const VacancyOnReviewDialog = ({
  onConfirm,
  ...props
}: VacancyOnReviewDialogProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleClose = useCallback(() => {
    dispatch(clearDialog('vacancyOnReviewDialog'));
  }, []);

  return (
    <Dialog
      {...props}
      sx={{
        '.MuiPaper-root': { maxWidth: '470px' },
        ...props.sx,
      }}
    >
      <DialogContent sx={{ paddingX: 1 }}>
        <Stack spacing={4} alignItems="center">
          <Box
            component="img"
            src="/illustrations/vacancy-on-review-illustration.webp"
            alt="Vacancy On Review Illustration"
            maxWidth="240px"
            width="100%"
            height="auto"
          />
          <Stack spacing={2}>
            <Typography variant="h4" color="text.primary" textAlign="center">
              {t('vacancy:vacancyBeingVerified')}
            </Typography>
            <Typography variant="body1" textAlign="center" color="text.primary">
              {t('vacancy:vacancyBeingVerifiedDescription', {
                appName: appConfig.appName,
              })}
            </Typography>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={() => {
            if (onConfirm) onConfirm(handleClose);
          }}
          fullWidth
        >
          {t('common:okayGotIt')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default VacancyOnReviewDialog;
