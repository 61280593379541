import { format, set } from 'date-fns';

import {
  InterviewInvitationFormType,
  InterviewInvitationFormSecondStep,
  InterviewInvitationFormThirdStep,
} from '~/components/InterviewInvitationForm';
import {
  EpCreateInterviewInvitationInput,
  EpInterviewSessionDropdown,
  EpInterviewSessionType,
  EpUpdateInterviewInvitationInput,
} from '~/types/graphql/graphql';
import { InterviewInvitationFormSessionSelectTypeKey } from './constants/interview-invitation';

export const mapInterviewInvitationDataToFormValue = (
  data: EpInterviewSessionDropdown,
): {
  secondStep: InterviewInvitationFormSecondStep;
  thirdStep: InterviewInvitationFormThirdStep;
} => {
  const schedule = new Date(data.schedule);
  const [scheduleStartTimeHours, scheduleStartTimeMinutes] =
    data.startTimeStr.split(':');
  const [scheduleEndTimeHours, scheduleEndTimeMinutes] =
    data.endTimeStr.split(':');

  return {
    secondStep: {
      schedule,
      scheduleStartTime: set(schedule, {
        hours: +scheduleStartTimeHours,
        minutes: +scheduleStartTimeMinutes,
        seconds: 0,
        milliseconds: 0,
      }),
      scheduleEndTime: set(schedule, {
        hours: +scheduleEndTimeHours,
        minutes: +scheduleEndTimeMinutes,
        seconds: 0,
        milliseconds: 0,
      }),
      sizeType: data.sizeType,
      type: data.type,
      offlineDetail: {
        address: data.offlineDetail?.address ?? '',
        url: data.offlineDetail?.url ?? '',
      },
      onlineDetail: {
        url: data.onlineDetail?.url ?? '',
        password: data.onlineDetail?.password ?? '',
      },
    },
    thirdStep: {
      recruiterPhoneNumber: data.recruiterPhoneNumber
        ? data.recruiterPhoneNumber.replace(/^62/, '')
        : '',
      recruiterMessage: data.recruiterMessage ?? '',
    },
  };
};

export const mapInterviewInvitationFormValueToData = (
  form: InterviewInvitationFormType & {
    vacancyId: string;
    applicationIds: string[];
  },
): EpCreateInterviewInvitationInput & EpUpdateInterviewInvitationInput => {
  const isOffline = form.type === EpInterviewSessionType.Offline;
  const isOnline = form.type === EpInterviewSessionType.Online;
  const isExistingSession =
    form.sessionSelectType ===
    InterviewInvitationFormSessionSelectTypeKey.EXISTING_SESSION;

  return {
    applicationIds: form.applicationIds,
    vacancyId: form.vacancyId,
    id: isExistingSession ? form.selectedSession?.additional?.id : null,
    name: isExistingSession
      ? form.selectedSession?.additional?.name
      : form.newSessionName,
    schedule: form.schedule ? format(form.schedule, 'yyyy-MM-dd') : null,
    startTimeStr: form.scheduleStartTime
      ? `${form.scheduleStartTime
          .getHours()
          .toString()
          .padStart(2, '0')}:${form.scheduleStartTime
          .getMinutes()
          .toString()
          .padStart(2, '0')}`
      : undefined,
    endTimeStr: form.scheduleEndTime
      ? `${form.scheduleEndTime
          .getHours()
          .toString()
          .padStart(2, '0')}:${form.scheduleEndTime
          .getMinutes()
          .toString()
          .padStart(2, '0')}`
      : undefined,
    sizeType: form.sizeType,
    type: form.type,
    offlineDetail: isOffline
      ? {
          address: form.offlineDetail.address,
          url: form.offlineDetail.url,
        }
      : null,
    onlineDetail: isOnline
      ? {
          url: form.onlineDetail.url,
          password: form.onlineDetail.password,
        }
      : null,
    recruiterPhoneNumber: `62${form.recruiterPhoneNumber}`,
    recruiterMessage: form.recruiterMessage,
  };
};
