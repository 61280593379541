import {
  Box,
  brandColors,
  CircularProgress,
  Dialog,
  DialogContent,
  IconButton,
  Link,
  Stack,
  Typography,
} from '@kitalulus/web-ui-kit';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import CloseIcon from '~/components/Icons/CloseIcon';
import {
  useCandidateRecommendationDetail,
  useCandidateRecommendationVerification,
} from '~/hooks/candidate-recommendation';
import { useAppDispatch } from '~/hooks/use-store';
import { clearDialog } from '~/store/views/dialogs-slice';
import { setSnackbar } from '~/store/views/snackbar-slice';
import { EpInviteJobVacancyCandidateVerificationStatus } from '~/types/graphql/graphql';
import { CandidateRecommendationVerificationDialogProps } from './CandidateRecommendationVerificationDialog.types';

const CandidateRecommendationVerificationDialog = ({
  content,
  ...props
}: CandidateRecommendationVerificationDialogProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { queryCandidateDetail, loading: isLoading } =
    useCandidateRecommendationDetail();
  const { handleVerify, isLoading: isVerifying } =
    useCandidateRecommendationVerification();
  const [applicationId, setApplicationId] = useState<string>();

  const candidateId = content?.candidateId;
  const onSuccess = content?.onSuccess;

  const handleClose = useCallback(() => {
    dispatch(clearDialog('candidateRecommendationVerificationDialog'));
  }, []);

  const handleVerifyCandidate = useCallback(async () => {
    try {
      if (!candidateId) return;
      await queryCandidateDetail(candidateId);
      const { status, copyStr, jobApplicationId, templateStr } =
        await handleVerify(candidateId);

      switch (status) {
        case EpInviteJobVacancyCandidateVerificationStatus.AlreadyApplied:
          // show already applied modal
          return setApplicationId(jobApplicationId ?? undefined);

        case EpInviteJobVacancyCandidateVerificationStatus.AlreadyInvited:
          // show info alert that says the candidate already invited
          dispatch(
            setSnackbar({
              severity: 'info',
              message: copyStr,
            }),
          );
          return handleClose();

        default:
          // call the next step which it's invitation action modal
          if (onSuccess) return onSuccess(handleClose, templateStr ?? '');
      }
    } catch {
      return handleClose();
    }
  }, [handleVerify, handleClose, candidateId, onSuccess]);

  const handleClickApplication = useCallback(() => {
    if (!applicationId) return;
    navigate(`/applicants/detail?id=${applicationId}`);
    handleClose();
  }, [handleClose, applicationId]);

  useEffect(() => {
    if (!candidateId) return;
    handleVerifyCandidate();
  }, [handleVerifyCandidate]);

  if (isLoading || isVerifying)
    return (
      <Dialog
        PaperProps={{
          sx: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
            color: 'white',
          },
        }}
        {...props}
      >
        <Stack spacing={4} alignItems="center">
          <CircularProgress size={38} color="inherit" />
          <Stack
            spacing={0.75}
            color={brandColors.grey[100]}
            textAlign="center"
          >
            <Typography variant="h5">
              {t('candidate-recommendation:checking.title')}
            </Typography>
            <Typography variant="body2">
              {t('candidate-recommendation:checking.subtitle')}
            </Typography>
          </Stack>
        </Stack>
      </Dialog>
    );

  return (
    <Dialog
      maxWidth="xs"
      {...props}
      sx={{
        textAlign: 'center',
        '.MuiPaper-root': {
          maxWidth: '460px',
          width: '100%',
          p: { xs: 2, md: 3 },
          borderRadius: 2,
        },
        ...props.sx,
      }}
    >
      <DialogContent>
        <IconButton
          sx={{ position: 'absolute', top: '16px', right: '16px' }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>

        <Stack spacing={4} alignItems="center" pb={1}>
          <Box
            component="img"
            src="/illustrations/character-12-illusatration.png"
            alt="Character 12"
            width="auto"
            height="160px"
          />
          <Stack spacing={1}>
            <Typography
              component="div"
              variant="h5"
              color="text.primary"
              textAlign="center"
            >
              {t('candidate-recommendation:checkingResult.alreadyAppliedTitle')}
            </Typography>
            <Typography
              component="div"
              variant="body1"
              textAlign="center"
              color="text.primary"
            >
              {t('candidate-recommendation:checkingResult.alreadyAppliedBody')}{' '}
              <Link
                fontWeight="bold"
                sx={{ cursor: 'pointer', textTransform: 'lowercase' }}
                onClick={handleClickApplication}
              >
                {t('common:here')}
              </Link>
            </Typography>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default CandidateRecommendationVerificationDialog;
