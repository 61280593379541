import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
  Image,
  DialogProps,
} from '@kitalulus/web-ui-kit';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import CloseIcon from '~/components/Icons/CloseIcon';
import LoadingButton from '~/components/LoadingButton';
import { appConfig } from '~/config/app';
import { useCandidateRecommendationInvite } from '~/hooks/candidate-recommendation';
import { useAppDispatch, useAppSelector } from '~/hooks/use-store';
import { closeDialog, setDialog } from '~/store/views/dialogs-slice';
import { convertDateToISOString } from '~/utils/helper';
import * as mpe from '~/utils/mixpanel';

const CandidateRecommendationEmailPreviewDialog = ({
  ...props
}: DialogProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const { inviteCandidate, inviteLoading } = useCandidateRecommendationInvite();
  const {
    candidateRecommendationDetail,
    company,
    vacancyDetail: vacancy,
  } = useAppSelector((state) => state);
  const { candidateRecommendationInviteDialog } = useAppSelector(
    (state) => state.dialogs,
  );
  const candidateDetailData = candidateRecommendationDetail?.detail?.data;

  const handleClose = useCallback(() => {
    dispatch(closeDialog('candidateRecommendationEmailPreviewDialog'));
  }, []);

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (!candidateRecommendationInviteDialog?.content) return null;
  const { emailContent, defaultTemplateStr, onSuccess, triggerSource } =
    candidateRecommendationInviteDialog.content;

  return (
    <Dialog
      maxWidth="sm"
      fullWidth={!isMobile}
      {...props}
      PaperProps={{ sx: { p: { xs: 0.3, sm: 1 }, m: 1 } }}
    >
      <Box sx={{ p: 0 }}>
        <DialogTitle>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="start"
          >
            <Typography
              component="div"
              variant="h5"
              color="text.primary"
              sx={{ flexBasis: '70%' }}
            >
              Preview
            </Typography>
            <Stack sx={{ flexBasis: '30%', alignItems: 'end' }}>
              <IconButton
                sx={{ top: -5, right: -5 }}
                size="small"
                onClick={handleClose}
              >
                <CloseIcon />
              </IconButton>
            </Stack>
          </Stack>
        </DialogTitle>
        <Divider />
        <DialogContent
          sx={{ py: 3, display: 'flex', justifyContent: 'center' }}
        >
          <Stack
            spacing={3}
            sx={{
              bgcolor: '#EDF7FF',
              p: 3,
              borderRadius: '16px',
              maxWidth: 375,
              minWidth: 350,
            }}
          >
            <Stack
              direction="row"
              sx={{ textAlign: 'right' }}
              justifyContent="space-between"
            >
              <Image
                src="/kitalulus-logo.svg"
                alt={appConfig.appName}
                sx={{ width: '130px' }}
              />
              <Typography
                variant="caption"
                color="text.disabled"
                sx={{ whiteSpace: 'pre-wrap' }}
              >
                {t(
                  'candidate-recommendation-detail:emailPreview.dontReplyNote',
                )}
              </Typography>
            </Stack>
            <Typography
              variant="h4"
              sx={{ whiteSpace: 'pre-wrap', fontSize: '20px !important' }}
            >
              {t('candidate-recommendation-detail:emailPreview.header', {
                companyName: company?.name,
              })}
            </Typography>
            <Typography
              variant="body2"
              sx={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}
            >
              {candidateRecommendationInviteDialog?.content?.emailContent ||
                candidateRecommendationInviteDialog?.content
                  ?.defaultTemplateStr}
            </Typography>
            <Button
              size="large"
              variant="contained"
              sx={{
                bgcolor: `${theme.palette.primary.main} !important`,
                color: '#FFF !important',
              }}
              disabled
            >
              {t('candidate-recommendation-detail:emailPreview.action')}
            </Button>
          </Stack>
        </DialogContent>
        <DialogActions
          sx={{
            boxShadow: theme.shadows[4],
            mb: { xs: -0.3, sm: -1 },
            px: 4,
            mx: { xs: -0.3, sm: -1 },
          }}
        >
          <Button
            size={isMobile ? 'medium' : 'large'}
            variant="outlined"
            sx={{ width: { xs: 'auto', sm: 150 } }}
            onClick={() => {
              handleClose();
              dispatch(
                setDialog({
                  candidateRecommendationInviteDialog: {
                    open: true,
                    content: {
                      emailContent,
                      defaultTemplateStr,
                      onSuccess,
                      triggerSource,
                    },
                  },
                }),
              );
            }}
            autoFocus
          >
            {t('common:edit')}
          </Button>
          <LoadingButton
            size={isMobile ? 'medium' : 'large'}
            variant="contained"
            sx={{ width: { xs: 'auto', sm: 150 } }}
            onClick={async () => {
              if (!candidateDetailData?.id) return;
              mpe.candidateActionConfirm({
                vacancyCode: vacancy?.code ?? '',
                vacancyCreatedAt: convertDateToISOString(vacancy?.createdAt),
                vacancyCloseDate: convertDateToISOString(vacancy?.closeDate),
                candidateID: candidateDetailData.id,
                candidateName: candidateDetailData?.userProfile?.name ?? '',
                action: 'invite',
                actionPosition: triggerSource,
              });
              const { isSuccess } = await inviteCandidate(
                candidateDetailData.id,
                candidateRecommendationInviteDialog?.content?.emailContent,
              );
              handleClose();
              if (isSuccess && onSuccess) return onSuccess();
            }}
            loading={inviteLoading}
          >
            {t('candidate-recommendation-detail:sendEmail')}
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default CandidateRecommendationEmailPreviewDialog;
