import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@kitalulus/web-ui-kit';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CloseIcon from '~/components/Icons/CloseIcon';
import InfoIcon from '~/components/Icons/InfoIcon';
import {
  InterviewInvitationDialogInvitationTypeKey,
  InterviewInvitationDialogVariantKey,
} from '~/utils/constants/interview-invitation';
import * as mpe from '~/utils/mixpanel';
import { SelectingTypeDialogOption, SelectingTypeDialogProps } from './';

const SelectingTypeDialog = ({
  content: { applicationIds },
  onSwitchVariant,
  onClose,
  ...props
}: SelectingTypeDialogProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [selectedKey, setSelectedKey] =
    useState<InterviewInvitationDialogInvitationTypeKey>(
      InterviewInvitationDialogInvitationTypeKey.WITH_INVITATION,
    );

  const options = useMemo<SelectingTypeDialogOption[]>(() => {
    return [
      {
        key: InterviewInvitationDialogInvitationTypeKey.WITH_INVITATION,
        isRecommended: true,
        title: t(
          'interview-invitation:selectingTypeDialog.withInvitationTitle',
        ),
        description: t(
          'interview-invitation:selectingTypeDialog.withInvitationDesc',
        ),
      },
      {
        key: InterviewInvitationDialogInvitationTypeKey.WITHOUT_INVITATION,
        isRecommended: false,
        title: t(
          'interview-invitation:selectingTypeDialog.withoutInvitationTitle',
        ),
        description: t(
          'interview-invitation:selectingTypeDialog.withoutInvitationDesc',
        ),
      },
    ];
  }, []);

  const handleSubmit = useCallback(() => {
    mpe.applicantProceedPopup({
      buttonType: 'continue',
      applicantNumber: applicationIds.length,
      option: selectedKey,
    });

    switch (selectedKey) {
      case InterviewInvitationDialogInvitationTypeKey.WITHOUT_INVITATION:
        return onSwitchVariant(
          InterviewInvitationDialogVariantKey.DIRECT_CHANGE_STATUS_TO_INTERVIEW_DIALOG,
        );

      case InterviewInvitationDialogInvitationTypeKey.WITH_INVITATION:
        return onSwitchVariant(
          InterviewInvitationDialogVariantKey.INTERVIEW_INVITATION_SESSION_DIALOG,
        );

      default:
        return;
    }
  }, [selectedKey, onSwitchVariant, JSON.stringify(applicationIds)]);

  const handleClose = useCallback(() => {
    mpe.applicantProceedPopup({
      buttonType: 'close',
      applicantNumber: applicationIds.length,
      option: selectedKey,
    });

    onClose();
  }, [selectedKey, onClose, JSON.stringify(applicationIds)]);

  return (
    <Dialog
      fullWidth
      fullScreen={isMobile}
      onClose={handleClose}
      sx={{
        '.MuiDialog-container': {
          alignItems: isMobile ? 'flex-end' : 'center',
        },
      }}
      PaperProps={{
        sx: {
          maxWidth: '540px',
          p: 4,
          ...(isMobile && {
            maxWidth: 'auto',
            height: 'auto',
            p: 3,
            borderTopRightRadius: '8px',
            borderTopLeftRadius: '8px',
          }),
        },
      }}
      {...props}
    >
      <Stack gap={3}>
        <DialogTitle component="div" sx={{ p: 0 }}>
          <Stack direction="row" alignItems="center" gap={2}>
            <InfoIcon color="primary" sx={{ fontSize: '32px' }} />
            <Typography
              variant="h6"
              color="text.primary"
              fontSize="18px !important"
            >
              {t('interview-invitation:selectingTypeDialog.title')}
            </Typography>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{ alignSelf: 'flex-start', mt: '-12px', mr: '-12px' }}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>

        <DialogContent sx={{ p: 0 }}>
          <Stack component={RadioGroup} gap={2}>
            {options.map((opt) => {
              const isSelected = selectedKey === opt.key;

              return (
                <Stack
                  key={opt.key}
                  onClick={() => setSelectedKey(opt.key)}
                  sx={{
                    cursor: 'pointer',
                    borderWidth: '1px',
                    borderRadius: '8px',
                    borderColor: isSelected ? 'primary.main' : 'divider',
                    borderStyle: 'solid',
                    overflow: 'hidden',
                    transitionDuration: '0.3s',
                  }}
                >
                  {opt.isRecommended && (
                    <Box
                      bgcolor={isSelected ? 'primary.main' : 'divider'}
                      color={isSelected ? 'white' : 'text.secondary'}
                      py={0.5}
                      sx={{ transitionDuration: '0.3s' }}
                    >
                      <Typography
                        variant="body2"
                        fontSize="14px"
                        textAlign="center"
                      >
                        {t('common:recommended')}
                      </Typography>
                    </Box>
                  )}

                  <Stack direction="row" p={0.5} alignItems="flex-start">
                    <Radio checked={isSelected} />
                    <Stack gap={0.75} py={1}>
                      <Typography variant="body2" color="text.primary">
                        {opt.title}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {opt.description}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              );
            })}
          </Stack>
        </DialogContent>

        {isMobile && (
          <Box>
            <Divider sx={{ position: 'absolute', left: 0, width: '100%' }} />
          </Box>
        )}

        <DialogActions sx={{ p: 0 }}>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            {t('common:continue')}
          </Button>
        </DialogActions>
      </Stack>
    </Dialog>
  );
};

export default SelectingTypeDialog;
